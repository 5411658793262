import * as React from 'react';

import { Alert, Button } from 'react-bootstrap';
import { IActionResult, IMenuItem, ITenancyDetail, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import EditIcon from 'mdi-react/EditIcon';
import ErrorIcon from 'mdi-react/ErrorIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import Shortcuts from './shortcuts';
import { ThunkDispatch } from 'redux-thunk';
import WarningIcon from 'mdi-react/WarningIcon';
import { connect } from 'react-redux';
import { fetchDashboardNavigation } from 'slices/navigationSlice';
import { fetchNote } from 'slices/tenantSettingsSlice';
import moment from 'moment';
import note from './note';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: ITenancyDetail | null;
}

interface IStateProps {
  noteResult: IActionResult<string>;
  canSeeTenantNote: boolean;
  dashboardNavigationResult: IActionResult<IMenuItem[]>;
  isTenantAdmin: boolean;
}

interface IDispatchProps {
  fetchNote: () => void;
  fetchDashboardNavigation: () => void;
  openEditModal: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class Home extends React.Component<IProps> {
  public componentDidMount() {
    const {
      fetchNote,
      fetchDashboardNavigation,
      data,
      canSeeTenantNote,
    } = this.props;
    if (canSeeTenantNote) {
      fetchNote();
    }
    if (data) {
      fetchDashboardNavigation();
    }
  }

  public render() {
    const {
      data,
      noteResult,
      canSeeTenantNote,
      dashboardNavigationResult,
      openEditModal,
      isTenantAdmin,
    } = this.props;
    if (!data) {
      return (
        <Alert variant="danger">
          <h6 className="my-0">
            <ErrorIcon size="1.2rem" className="mr-2" /> Your tenancy has been
            removed. Please contact your Smartlogic account manager.
          </h6>
        </Alert>
      );
    }

    const timeToExpire = data.expiryDate
      ? moment.duration(moment.utc(data.expiryDate).diff(moment.utc()))
      : undefined;
    const timeToExpireText = data.expiryDate
      ? moment
          .duration(moment.utc(data.expiryDate).diff(moment.utc()))
          .humanize()
      : undefined;
    return (
      <>
        <div className="my-5 text-center">
          <h1 className="font-weight-light">Welcome to Semaphore Cloud</h1>
        </div>
        {isTenantAdmin ? (
          <Button
            variant="link"
            className="float-right"
            onClick={openEditModal}
          >
            <EditIcon size="1.2rem" className="mr-1" />
            Manage tenancy
          </Button>
        ) : null}
        <h4 className="mb-4 font-weight-normal">
          <HomeCityOutlineIcon size="1.4rem" className="mr-2" />
          {data.name}
        </h4>
        {data.active ? null : (
          <Alert variant="danger">
            <h6 className="my-0">
              <ErrorIcon size="1.2rem" className="mr-2" /> This tenancy is
              currently <strong>not active</strong> - {data.inactiveReason}
            </h6>
          </Alert>
        )}
        {timeToExpire &&
        timeToExpire.asDays() < 14 &&
        timeToExpire.asDays() > 0 &&
        canSeeTenantNote ? (
          <Alert variant="warning">
            <h6 className="my-0">
              <WarningIcon size="1.2rem" className="mr-2" /> Your tenancy will
              stop working in {timeToExpireText}. If you wish to continue using
              Semaphore Cloud please contact your account manager if you have
              not already done so to arrange your renewal.
            </h6>
          </Alert>
        ) : null}
        {canSeeTenantNote ? (
          <Loader component={note} actionresult={noteResult} />
        ) : null}
        <Loader
          component={Shortcuts}
          actionresult={dashboardNavigationResult}
        />
      </>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchNote: () => dispatch(fetchNote()),
  fetchDashboardNavigation: () => dispatch(fetchDashboardNavigation()),
  openEditModal: () =>
    dispatch(
      openModal(
        'Manage Tenancy',
        ModalContents.EditTenant,
        {
          position: 'right',
          size: 'lg',
        },
        ownProps.data
      )
    ),
});

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    noteResult: state.tenantSettings.noteResult,
    canSeeTenantNote: authorizer.canSeeTenantNote(),
    dashboardNavigationResult: state.navigation.dashboardNavigationResult,
    isTenantAdmin: authorizer.isTenantAdmin(),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Home);
