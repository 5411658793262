import * as React from 'react';

import Chart from 'react-apexcharts';
import { IUsageViewModel } from 'types';
import moment from 'moment';

interface IProps {
  data: IUsageViewModel;
}

interface IState {
  options: object;
  series: Array<any>;
}

export class UsageDataChart extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      options: {
        chart: {
          id: 'usage-data-chart',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: ['smooth', 'straight'],
        },
        yaxis: [
          {
            labels: {
              show: false,
              formatter: function (value: number) {
                if (value <= 2048) {
                  return value + ' bytes';
                }

                //if it's more than 2k
                if (value > 2048 && value <= 2097152) {
                  return (value / 1024).toFixed(2) + ' KB';
                }

                //if it's more than 2Mb
                if (value > 2097152) {
                  return (value / 1048576).toFixed(2) + ' MB';
                }
              },
            },
          },
          { labels: { show: false } },
        ],
        noData: {
          text: 'Nothing to Display',
        },
      },
      series: [
        {
          name: 'Data',
          data: props.data.usageData.reduceRight((result, d) => {
            result.push({ x: moment(d.period).format('LL'), y: d.usageBytes });
            return result;
          }, [] as any[]),
          type: 'area',
        },
        {
          name: 'Documents',
          data: props.data.usageData.reduceRight((result, d) => {
            result.push({ x: moment(d.period).format('LL'), y: d.usageCount });
            return result;
          }, [] as any[]),
        },
      ],
    };
  }

  public render() {
    const { data } = this.props;
    if (!data.usageData.length) return null;
    const unit = data.totalMegabytesUsed > 1024 ? 1024 : 1;
    const unitDesc = unit === 1024 ? 'GB' : 'MB';
    return (
      <>
        <Chart
          options={this.state.options}
          series={this.state.series}
          height={100}
        />
        <div className="mt-3 d-flex justify-content-center">
          {moment(data.startDate).format('LL') +
            ' to ' +
            moment(data.endDate).format('LL')}
        </div>
        <h3 className="d-flex justify-content-center mb-3">
          {`${(data.totalMegabytesUsed / unit).toFixed(2)} ${unitDesc}`}
        </h3>
      </>
    );
  }
}

export default UsageDataChart;
