import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  IAssociatedService,
  IServiceBasicInfo,
  IServiceViewModel,
  IStatusResponse,
  ServerType,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import ServiceEndpoint from 'endpoints/serviceEndpoint';
import { closeModal } from './modalSlice';
import { fetchNotifications } from './notificationSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ServiceState {
  fetchAllInAGroupResult: IActionResult<IServiceViewModel[]>;
  fetchAllResult: IActionResult<IAssociatedService[]>;
  fetchOfTypeResult: IActionResult<IServiceBasicInfo[]>;
  deleteResult: IActionResult<boolean>;
  createResult: IActionResult<boolean>;
  updateResult: IActionResult<boolean>;
  serviceStatusResults: { [serviceId: string]: IActionResult<IStatusResponse> };
}

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    fetchAllResult: {
      processing: false,
    },
    fetchOfTypeResult: {
      processing: false,
    },
    fetchAllInAGroupResult: {
      processing: false,
    },
    deleteResult: {
      processing: false,
    },
    createResult: {
      processing: false,
    },
    updateResult: {
      processing: false,
    },
    serviceStatusResults: {},
  } as ServiceState,
  reducers: {
    fetchingOfType(state) {
      state.fetchOfTypeResult = { processing: true };
    },
    fetchedOfType(state, action: PayloadAction<IServiceBasicInfo[]>) {
      state.fetchOfTypeResult = { processing: false, data: action.payload };
    },
    fetchOfTypeFailed(state, action: PayloadAction<IError>) {
      state.fetchOfTypeResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingAll(state) {
      state.fetchAllResult = { processing: true };
    },
    fetchedAll(state, action: PayloadAction<IAssociatedService[]>) {
      state.fetchAllResult = { processing: false, data: action.payload };
    },
    fetchAllFailed(state, action: PayloadAction<IError>) {
      state.fetchAllResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingAllInAGroup(state) {
      state.fetchAllInAGroupResult = { processing: true };
    },
    fetchedAllInAGroup(state, action: PayloadAction<IServiceViewModel[]>) {
      state.fetchAllInAGroupResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchAllInAGroupFailed(state, action: PayloadAction<IError>) {
      state.fetchAllInAGroupResult = {
        processing: false,
        error: action.payload,
      };
    },
    deleting(state) {
      state.deleteResult = { processing: true };
    },
    deleted(state, action: PayloadAction<IServiceViewModel>) {
      state.deleteResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllInAGroupResult.data)) {
        state.fetchAllInAGroupResult.data = [
          ...state.fetchAllInAGroupResult.data.filter(
            (s) => s.serverId !== action.payload.serverId
          ),
          action.payload,
        ];
      }
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.deleteResult = { processing: false, error: action.payload };
    },
    creating(state) {
      state.createResult = { processing: true };
    },
    created(state, action: PayloadAction<IServiceViewModel>) {
      state.createResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllInAGroupResult.data)) {
        state.fetchAllInAGroupResult.data.push(action.payload);
      }
    },
    creationFailed(state, action: PayloadAction<IError>) {
      state.createResult = { processing: false, error: action.payload };
    },
    updating(state) {
      state.updateResult = { processing: true };
    },
    updated(state, action: PayloadAction<IServiceViewModel>) {
      state.updateResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllInAGroupResult.data)) {
        state.fetchAllInAGroupResult.data = [
          ...state.fetchAllInAGroupResult.data.filter(
            (s) => s.serverId !== action.payload.serverId
          ),
          action.payload,
        ];
      }
    },
    updateFailed(state, action: PayloadAction<IError>) {
      state.updateResult = { processing: false, error: action.payload };
    },
    fetchingStatus(state, action: PayloadAction<string>) {
      state.serviceStatusResults[action.payload] = {
        processing: true,
        error: undefined,
      };
    },
    fetchedStatus(
      state,
      action: PayloadAction<{
        serviceId: string;
        response: IStatusResponse;
      }>
    ) {
      state.serviceStatusResults[action.payload.serviceId] = {
        processing: false,
        data: action.payload.response,
        error: undefined,
      };
    },
    fetchStatusFailed(
      state,
      action: PayloadAction<{
        serviceId: string;
        error: IError;
      }>
    ) {
      if (
        action.payload.error.status === 404 &&
        state.fetchAllInAGroupResult.data
      ) {
        state.fetchAllInAGroupResult.data = state.fetchAllInAGroupResult.data.filter(
          (s) => s.serverId !== action.payload.serviceId
        );
      }
      state.serviceStatusResults[action.payload.serviceId] = {
        processing: false,
        data: undefined,
        error: action.payload.error,
      };
    },
  },
});

export const fetchAllServices = (): AppThunk => async (dispatch) => {
  const endpoint = new ServiceEndpoint();
  dispatch(fetchingAll());
  try {
    const response = await endpoint.list();
    dispatch(fetchedAll(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAllFailed(Errors.getError(error)));
  }
};

export const fetchServicesOfType = (
  serverTypes: ServerType[]
): AppThunk => async (dispatch) => {
  const endpoint = new ServiceEndpoint();
  dispatch(fetchingOfType());
  try {
    const response = await endpoint.getServicesOfType(serverTypes);
    dispatch(fetchedOfType(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchOfTypeFailed(Errors.getError(error)));
  }
};

export const fetchAllServicesInAGroup = (groupId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new ServiceEndpoint();
  dispatch(fetchingAllInAGroup());
  try {
    const response = await endpoint.listServices(groupId);
    dispatch(fetchedAllInAGroup(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAllInAGroupFailed(Errors.getError(error)));
  }
};

export const deleteService = (id: string): AppThunk => async (dispatch) => {
  const endpoint = new ServiceEndpoint();
  dispatch(deleting());
  try {
    const response = await endpoint.deleteService(id);
    dispatch(deleted(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
  dispatch(fetchNotifications());
};

export const createService = (service: IServiceViewModel): AppThunk => async (
  dispatch
) => {
  const endpoint = new ServiceEndpoint();
  dispatch(creating());
  try {
    const response = await endpoint.createService(service);
    dispatch(created(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(creationFailed(Errors.getError(error)));
  }
  dispatch(fetchNotifications());
};

export const updateService = (service: IServiceViewModel): AppThunk => async (
  dispatch
) => {
  const endpoint = new ServiceEndpoint();
  dispatch(updating());
  try {
    const response = await endpoint.updateService(service);
    dispatch(updated(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateFailed(Errors.getError(error)));
  }
};

export const fetchStatus = (serviceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new ServiceEndpoint();
  dispatch(fetchingStatus(serviceId));
  try {
    const response = await endpoint.getStatus(serviceId);
    dispatch(
      fetchedStatus({
        serviceId,
        response: response.data,
      })
    );
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(
      fetchStatusFailed({
        serviceId,
        error: Errors.getError(error),
      })
    );
  }
};

const { actions, reducer } = serviceSlice;
export const {
  created,
  creating,
  creationFailed,
  deleteFailed,
  deleted,
  deleting,
  fetchAllFailed,
  fetchedAll,
  fetchingAll,
  updateFailed,
  updated,
  updating,
  fetchStatusFailed,
  fetchedStatus,
  fetchingStatus,
  fetchAllInAGroupFailed,
  fetchedAllInAGroup,
  fetchingAllInAGroup,
  fetchOfTypeFailed,
  fetchedOfType,
  fetchingOfType,
} = actions;
export default reducer;
