import * as React from 'react';

import { Button, ButtonProps, Spinner } from 'react-bootstrap';

interface IProps extends ButtonProps {
  inProgress: boolean;
  defaultMessage?: string;
  progressMessage?: string;
}

class SubmitButton extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    defaultMessage: 'Save',
    progressMessage: 'Saving',
  };

  public render() {
    const {
      inProgress: inprogress,
      defaultMessage,
      progressMessage,
      ...buttonProps
    } = this.props;
    return inprogress ? (
      <Button {...buttonProps} disabled={true}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-1"
        />
        <span>{progressMessage}</span>
      </Button>
    ) : (
      <Button
        {...buttonProps}
        type={buttonProps.onClick ? undefined : 'submit'}
      >
        {defaultMessage}
      </Button>
    );
  }
}

export default SubmitButton;
