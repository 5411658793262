import * as React from 'react';

import { Alert, Button } from 'react-bootstrap';

import ErrorIcon from 'mdi-react/ErrorIcon';
import { IError } from 'utils/errors';

interface IProps {
  error: IError;
}

interface IState {
  detailsVisible: boolean;
  errorVisible: boolean;
}

export default class Error extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { detailsVisible: false, errorVisible: true };
  }

  public render() {
    const { title, subTitle, stackTrace } = this.props.error;

    return this.state.errorVisible ? (
      <Alert variant="danger" onClose={this.handleAlertDismiss}>
        <h5>
          <ErrorIcon size="1.7rem" className="mr-1" />
          {title}
        </h5>
        {subTitle ? <p>{subTitle}</p> : null}
        {stackTrace ? (
          <>
            <Button
              onClick={this.handleDetailsToogle}
              variant="outline-danger"
              size="sm"
            >
              Show details
            </Button>
            {this.state.detailsVisible ? (
              <p className="mt-2">{stackTrace}</p>
            ) : null}
          </>
        ) : null}
      </Alert>
    ) : null;
  }

  private handleAlertDismiss = () => {
    this.setState(() => {
      return { errorVisible: false };
    });
  };

  private handleDetailsToogle = () => {
    this.setState((prevState: IState) => {
      return { detailsVisible: !prevState.detailsVisible };
    });
  };
}
