import * as React from 'react';

import { IActionResult, ITermStoreSyncJob } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteSyncJob } from 'slices/termStoreSlice';

interface IOwnProps {
  syncJob: ITermStoreSyncJob;
  interfaceId: string;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteSyncJob: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteSyncJobModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { deleteResult } = this.props;

    return (
      <>
        <Modal.Body>
          Are you sure you want to delete this sync job definition?
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          onSubmit={this.delete}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
        />
      </>
    );
  }

  private delete() {
    const { deleteSyncJob } = this.props;
    deleteSyncJob();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    deleteResult: state.termStore.deleteSyncJobResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteSyncJob: () =>
    dispatch(deleteSyncJob(ownProps.syncJob.id!, ownProps.interfaceId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSyncJobModal);
