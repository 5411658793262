import * as React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import AnonymousLayout from './components/common/layout/anonymousLayout';
import ClassifyItem from './components/pages/classifyItem/classifyItemPage';
import ConfirmEmailPage from 'components/pages/confirmEmail/confirmEmailPage';
import ErrorPage from 'components/pages/error/errorPage';
import LoginPage from './components/pages/authentication/loginPage';
import RegisterPage from './components/pages/authentication/registerPage';
import ResetPasswordPage from 'components/pages/resetPassword/resetPasswordPage';

export default class UnauthorizedRoutes extends React.Component {
  public render() {
    return (
      <Switch>
        <Route path="/register" component={this.register} />
        <Route exact path="/error/" component={this.error} />
        <Route
          path="/:interfaceId/classifyItem"
          exact={true}
          render={this.classifyItem}
        />
        <Route
          path="/confirmEmail/:userId"
          exact={true}
          render={this.confirmEmail}
        />
        <Route
          path="/resetPassword/:userId"
          exact={true}
          render={this.resetPassword}
        />
        <Route path="/relogin" component={this.relogin} />
        <Route path="/" component={this.login} />
      </Switch>
    );
  }

  private error = () => (
    <AnonymousLayout>
      <ErrorPage />
    </AnonymousLayout>
  );

  private register = () => (
    <AnonymousLayout showCopyright={true}>
      <RegisterPage />
    </AnonymousLayout>
  );

  private relogin = () => (
    <AnonymousLayout showCopyright={true}>
      <LoginPage
        error={{
          title: 'Oops...somethings up!',
          subTitle:
            'Your session has timed out or you have been restricted from accessing this page. Please login and try again',
        }}
      />
    </AnonymousLayout>
  );

  private login = () => (
    <AnonymousLayout showCopyright={true}>
      <LoginPage />
    </AnonymousLayout>
  );

  private classifyItem = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => <ClassifyItem interfaceId={props.match.params.interfaceId} />;

  private confirmEmail = (props: RouteComponentProps<{ userId: string }>) => (
    <AnonymousLayout>
      <ConfirmEmailPage userId={props.match.params.userId} />
    </AnonymousLayout>
  );

  private resetPassword = (props: RouteComponentProps<{ userId: string }>) => (
    <AnonymousLayout>
      <ResetPasswordPage userId={props.match.params.userId} />
    </AnonymousLayout>
  );
}
