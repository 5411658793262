import * as React from 'react';

import { Col, ListGroup, Row } from 'react-bootstrap';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { IServiceViewModel } from 'types';
import ServiceType from './serviceType';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deselect } from 'slices/serviceTypeSlice';

interface IOwnProps {
  data: IServiceViewModel[];
}

interface IDispatchProps {
  deselect: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ServiceTypes extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.deselect = this.deselect.bind(this);
  }

  public render() {
    const { data: services } = this.props;
    const serviceTypes = Array.apply(null, Array(8)).map((_, index) => (
      <ServiceType key={index} serverType={index} services={services} />
    ));
    return (
      <Col
        xs="12"
        md="2"
        as="aside"
        className="bg-white border-right"
        onClick={this.deselect}
      >
        <Row>
          <Col as="header" className="bg-light border-bottom">
            <p className="mt-3">
              <strong>Services</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup as={Row}>{serviceTypes}</ListGroup>
          </Col>
        </Row>
      </Col>
    );
  }

  private deselect() {
    const { deselect } = this.props;
    deselect();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  deselect: () => dispatch(deselect()),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ServiceTypes);
