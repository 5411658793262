import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IClassificationPreview, IMessage } from 'types';

import { AnyAction } from 'redux';
import ColumnResult from './columnResult';
import { IReduxState } from 'reducers';
import SubmitButton from 'components/common/submitButton';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { saveClassificationPreview } from 'slices/classifyItemSlice';

export interface IFormData {
  [key: string]: boolean;
}

interface IParams {
  spHostUrl: string;
  listId: string;
  itemId: string;
  interfaceId: string;
}

interface IOwnProps {
  data: IClassificationPreview;
  params: IParams;
}

interface IDispatchProps {
  savePreview: (
    interfaceId: string,
    spHostUrl: string,
    listId: string,
    itemId: string,
    values: string[]
  ) => void;
}

export interface IProps extends IOwnProps, IDispatchProps {}

export class ClassifyItem extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  public render() {
    const { handleSubmit, data, submitting } = this.props;

    const results = data.columnResults.map((result, index) => (
      <ColumnResult columnResult={result} key={index} />
    ));
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <div className="text-center">
          <h2 className="text-primary mt-0">Classify Document</h2>
        </div>
        <div id="resultsPane">
          {results}
          <Modal.Footer>
            <div className="text-center padder-v-xs">
              <small>
                Not what you expected to see? Review the support logs for
                correlationId:{' '}
                <strong>
                  <span>{data.correlationId}</span>
                </strong>
              </small>
            </div>
            <SubmitButton
              inProgress={submitting}
              variant="primary"
              id="saveButton"
              disabled={!this.hasChanges()}
            />
            <Button variant="secondary" id="cancelButton" onClick={this.cancel}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Form>
    );
  }

  private async save(data: IFormData) {
    const { data: preview, savePreview, params } = this.props;
    const { spHostUrl, listId, itemId, interfaceId } = params;

    const values = preview.columnResults.map((result) =>
      JSON.stringify(
        result.CurrentManuallyEdited
          ? Object.assign(result, {
              OverwriteManualEdits:
                data[`overrideManualClassification-${result.FieldId}`],
            })
          : result
      )
    );

    savePreview(interfaceId, spHostUrl, listId, itemId, values);
  }

  private cancel() {
    const { params } = this.props;
    const { spHostUrl } = params;
    window.parent.postMessage('CloseDialog', spHostUrl);
    const message: IMessage = {
      type: 'dialogEvent',
      hideFrame: false,
      data: { message: 'Classification cancelled', refresh: false },
    };
    window.parent.postMessage(message, spHostUrl);
  }

  private hasChanges() {
    const { data } = this.props;
    return data.columnResults.reduce<boolean>(
      (p, c) => p || c.HasUpdatedValues,
      false
    );
  }
}

const ClassifyItemForm = reduxForm<IFormData, IProps>({
  form: 'classifyItem',
})(ClassifyItem);

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  savePreview: (
    interfaceId: string,
    spHostUrl: string,
    listId: string,
    itemId: string,
    values: string[]
  ) =>
    dispatch(
      saveClassificationPreview(interfaceId, spHostUrl, listId, itemId, values)
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ClassifyItemForm);
