import * as React from 'react';

import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import SpoInterface from './spoInterface';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchDeployments } from 'slices/deploymentSlice';

interface IParams {
  interfaceId: string;
}

interface IOwnProps {
  data: IInterfaceConfigured[];
  params: IParams;
}

interface IStateProps {
  fetchInterfaceResult: IActionResult<IInterfaceConfigured>;
  canDeploy: boolean;
}

interface IDispatchProps {
  fetchDeployments: () => void;
}

interface IProps extends IOwnProps, IDispatchProps, IStateProps {}

export class SpoInterfaceSubPage extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchDeployments, canDeploy } = this.props;
    if (canDeploy) {
      fetchDeployments();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { fetchDeployments, params, canDeploy } = this.props;
    const { interfaceId } = params;
    if (prevProps.params.interfaceId !== interfaceId && canDeploy) {
      fetchDeployments();
    }
  }

  public render() {
    const { data, params } = this.props;
    const { interfaceId } = params;
    const interface_ = data.find((i) => i.id === interfaceId);
    return interface_ ? <SpoInterface data={interface_} /> : null;
  }
}
const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    fetchInterfaceResult: state.spo.fetchInterfaceResult,
    canDeploy: authorizer.canDeploy(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchDeployments: () =>
    dispatch(fetchDeployments(ownProps.params.interfaceId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SpoInterfaceSubPage);
