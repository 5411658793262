import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';
import { updateSpoInterface } from 'slices/spoSlice';

interface IOwnProps {
  spoInterface: IInterfaceConfigured;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
}

interface IDispatchProps {
  updateSpoInterface: (interface_: IInterfaceConfigured) => void;
}

export interface IFormData {
  active: boolean;
  name: string;
  description: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class EditSpoModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { updateResult, handleSubmit, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            type="text"
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="active"
            label="Enabled"
            component={checkBoxComponent}
            id="interface-active"
            type="switch"
          />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { updateSpoInterface, spoInterface } = this.props;
    const updatedInterface = Object.assign({}, spoInterface, {
      active: data.active,
      description: data.description,
      name: data.name,
    });
    updateSpoInterface(updatedInterface);
  }
}

const EditSpoModalForm = reduxForm<IFormData, IProps>({
  form: 'editSpo',
})(EditSpoModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    updateResult: state.spo.updateInterfaceResult,
    initialValues: {
      active: ownProps.spoInterface.active,
      name: ownProps.spoInterface.name,
      description: ownProps.spoInterface.description,
    },
    form: 'editSpo',
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateSpoInterface: (interface_: IInterfaceConfigured) =>
    dispatch(updateSpoInterface(interface_)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditSpoModalForm);
