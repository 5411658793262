import * as React from 'react';

import { IActionResult, IUserResult } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteUser } from 'slices/userSlice';

interface IOwnProps {
  user: IUserResult;
}

interface IStateProps {
  userDeleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteUser: (id: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteUserModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { user, userDeleteResult } = this.props;
    return (
      <>
        <Modal.Body>
          <p>
            Are you sure you want to delete user{' '}
            <strong>{user.displayName}</strong>?
          </p>
          <p className="text-danger">
            All user data for email address <strong>"{user.email}"</strong> will
            be erased
          </p>
        </Modal.Body>
        <ModalFormFooter
          actionResult={userDeleteResult}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
          onSubmit={this.delete}
        />
      </>
    );
  }

  private delete() {
    const { user, deleteUser } = this.props;
    deleteUser(user.id);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    userDeleteResult: state.user.userDeleteResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  deleteUser: (id: string) => dispatch(deleteUser(id)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteUserModal);
