import { IReduxState } from 'reducers';
import { IUserResult } from 'types';

class Authorizer {
  private state: IReduxState;
  private tenantAdminRoles = [
    'GlobalAdministrator',
    'GlobalCoAdministrator',
    'InfrastructureManager',
  ];
  constructor(state: IReduxState) {
    this.state = state;
  }

  public isInRoles(requiredRoles: string[]) {
    const account = this.state.account.fetchDetailsResult.data;
    if (account == null) return false;
    const { roles } = account;
    let result = false;
    requiredRoles.forEach((elem) => {
      if (roles.indexOf(elem) > -1) result = true;
    });
    return result;
  }

  public canDeleteUser(user: IUserResult) {
    const requiredRoles = [
      'TenantAdministrator',
      'TenantCoAdministrator',
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'AccountManager',
    ];
    return this.isInRoles(requiredRoles) && user.canBeDeleted;
  }

  public canDeleteUsers() {
    const requiredRoles = [
      'TenantAdministrator',
      'TenantCoAdministrator',
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'AccountManager',
    ];
    return this.isInRoles(requiredRoles);
  }

  public canEditUser() {
    const requiredRoles = [
      'TenantAdministrator',
      'TenantCoAdministrator',
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'AccountManager',
    ];
    return this.isInRoles(requiredRoles);
  }

  public userDisplayTenantColumn() {
    return this.isInRoles(this.tenantAdminRoles);
  }

  public canToggleUserActive() {
    const requiredRoles = [
      'TenantAdministrator',
      'TenantCoAdministrator',
      'GlobalAdministrator',
      'GlobalCoAdministrator',
    ];
    return this.isInRoles(requiredRoles);
  }

  public canViewUserActive() {
    const requiredRoles = [
      'TenantAdministrator',
      'TenantCoAdministrator',
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'AccountReader',
    ];
    return this.isInRoles(requiredRoles);
  }

  public canChangeTenant() {
    return this.isInRoles(this.tenantAdminRoles);
  }

  public canAddTenant() {
    return this.isInRoles(this.tenantAdminRoles);
  }

  public supportLogsTopSwitches() {
    return this.isInRoles(['InfrastructureManager']);
  }
  public canManagePublishig() {
    return this.isInRoles(['PublishingManager']);
  }

  public canSearchAll() {
    return this.isInRoles(['GlobalAdministrator', 'GlobalCoAdministrator']);
  }

  public canViewLogs() {
    return this.isInRoles(['SupportManager']);
  }

  public canDeploy() {
    return this.isInRoles(['DeploymentManager']);
  }

  public canManageInterfaces() {
    return this.isInRoles(['InfrastructureManager']);
  }

  public canManageTermStore() {
    return this.isInRoles(['TermstoreSyncManager']);
  }

  public hasGlobalRole() {
    return this.isInRoles([
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'InfrastructureManager',
      'AccountManager',
    ]);
  }

  public isTenantAdmin() {
    return this.isInRoles(['TenantAdministrator', 'TenantCoAdministrator']);
  }

  public canUseOntologyEditor() {
    return this.isInRoles(['Ontology Editor User']);
  }

  public canUseWorkbench() {
    return this.isInRoles(['Workbench User']);
  }

  public canUseBasicApi() {
    return this.isInRoles(['BasicApiUser']);
  }

  public canManageContacts() {
    const requiredRoles = [
      'TenantAdministrator',
      'TenantCoAdministrator',
      'AccountManager',
    ];
    return this.isInRoles(requiredRoles);
  }

  public canSeeTenantNote() {
    return this.isInRoles([
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'AccountManager',
      'InfrastructureManager',
      'AccountReader',
    ]);
  }

  public canEditTenantNote() {
    return this.isInRoles([
      'GlobalAdministrator',
      'GlobalCoAdministrator',
      'AccountManager',
      'InfrastructureManager',
    ]);
  }

  public canDeleteTenant() {
    return this.isInRoles(['GlobalAdministrator', 'GlobalCoAdministrator']);
  }

  public canDeactivateTenancy() {
    return this.isInRoles(['AccountManager']);
  }

  public canViewDomains() {
    return this.isInRoles([
      'AccountManager',
      'TenantAdministrator',
      'TenantCoAdministrator',
      'AccountReader',
    ]);
  }

  public canManageDomains() {
    return this.isInRoles([
      'AccountManager',
      'TenantAdministrator',
      'TenantCoAdministrator',
    ]);
  }
}

export default Authorizer;
