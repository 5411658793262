import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, ILoginForm, ModalContents } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import ModalFormFooter from 'components/common/modalFormFooter';
import ResendVerificationEmailButton from './resendVerificationEmailButton';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import inputComponent from 'components/common/formComponents/inputComponent';
import { logIn } from 'slices/accountSlice';
import { openModal } from 'slices/modalSlice';

interface IState {
  email: string;
}

interface IStateProps {
  loginResult: IActionResult<boolean>;
}

interface IDispatchProps {
  login: (loginForm: ILoginForm) => void;
  openResetPasswordModal: () => void;
}

export interface IProps extends IDispatchProps, IStateProps {}

export interface IFormData {
  email: string;
  password: string;
}

export class LoginModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
    this.state = { email: '' };
  }
  public render() {
    const {
      handleSubmit,
      pristine,
      loginResult,
      openResetPasswordModal,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="email"
            component={inputComponent}
            type="email"
            placeholder="Please enter your email address..."
            label="Email Address"
            autoComplete="off"
          />
          <Field
            name="password"
            component={inputComponent}
            type="password"
            placeholder="Please enter password..."
            label="Password"
            autoComplete="off"
          />
          {loginResult.error?.subTitle === 'Email address is not confirmed' ? (
            <ResendVerificationEmailButton email={this.state.email} />
          ) : (
            <Button
              variant="link"
              size="sm"
              className="p-0"
              onClick={openResetPasswordModal}
            >
              Forgot password?
            </Button>
          )}
        </Modal.Body>
        <ModalFormFooter
          actionResult={loginResult}
          submitMessage="Sign in"
          submittingMessage="Signing in"
          disabled={pristine}
        />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { login } = this.props;
    this.setState({ email: data.email });
    login({
      email: data.email,
      password: data.password,
      rememberMe: true,
    });
  }
}

const LoginModalForm = reduxForm<IFormData, IProps>({
  form: 'login',
})(LoginModal);

const mapStateToProps = (state: IReduxState): IStateProps => ({
  loginResult: state.account.loginResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  login: (loginForm: ILoginForm) => dispatch(logIn(loginForm)),
  openResetPasswordModal: () =>
    dispatch(
      openModal(
        'Send e-mail with password reset link',
        ModalContents.ResetPassword,
        { position: 'right', size: 'sm' }
      )
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalForm);
