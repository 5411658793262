import { Errors, IError } from 'utils/errors';
import { IActionResult, IClassificationPreview, IMessage } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { ClassifyItemEndpoint } from 'endpoints/classifyItemEndpoint';

interface IClassifyItemState {
  getClassificationPreviewResult: IActionResult<IClassificationPreview>;
  saveClassificationPreviewResult: IActionResult<boolean>;
}

const classifyItemSlice = createSlice({
  name: 'classifyItem',
  initialState: {
    getClassificationPreviewResult: {
      processing: false,
    },
    saveClassificationPreviewResult: {
      processing: false,
    },
  } as IClassifyItemState,
  reducers: {
    gettingPreview(state) {
      state.getClassificationPreviewResult = { processing: true };
    },
    gottenPreview(state, action: PayloadAction<IClassificationPreview>) {
      state.getClassificationPreviewResult = {
        processing: false,
        data: action.payload,
      };
    },
    getPreviewFailed(state, action: PayloadAction<IError>) {
      state.getClassificationPreviewResult = {
        processing: false,
        error: action.payload,
      };
    },
    savingPreview(state) {
      state.saveClassificationPreviewResult = { processing: true };
    },
    savedPreview(state) {
      state.saveClassificationPreviewResult = {
        processing: false,
        data: true,
      };
    },
    savePreviewFailed(state, action: PayloadAction<IError>) {
      state.saveClassificationPreviewResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const getClassificationPreview = (
  interfaceId: string,
  spHostUrl: string,
  listId: string,
  itemId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyItemEndpoint();
  dispatch(gettingPreview());
  try {
    const response = await endpoint.getPreview(
      interfaceId,
      spHostUrl,
      listId,
      itemId
    );
    const columnResults = response.data.values.map((p: string) =>
      JSON.parse(p)
    );
    const classificationPreview = {
      columnResults,
      result: response.data.result,
      correlationId: response.data.correlationId,
    };
    dispatch(gottenPreview(classificationPreview));
  } catch (error) {
    dispatch(getPreviewFailed(Errors.getError(error)));
  }
};

export const saveClassificationPreview = (
  interfaceId: string,
  spHostUrl: string,
  listId: string,
  itemId: string,
  values: string[]
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyItemEndpoint();
  dispatch(savingPreview());
  try {
    await endpoint.savePreview(interfaceId, spHostUrl, listId, itemId, values);
    dispatch(savedPreview());

    window.parent.postMessage('CloseDialog', spHostUrl);
    const message: IMessage = {
      hideFrame: false,
      type: 'dialogEvent',
      data: { message: 'Classifications saved', refresh: true },
    };
    window.parent.postMessage(message, spHostUrl);
  } catch (error) {
    dispatch(savePreviewFailed(Errors.getError(error)));

    window.parent.postMessage('CloseDialog', spHostUrl);

    const errorMessage: IMessage = {
      type: 'dialogEvent',
      hideFrame: false,
      data: {
        message: `Unable to save classification. ${error.title} ${error.subTitle} CorrelationId: ${error.correlationId}`,
        refresh: false,
      },
    };
    window.parent.postMessage(errorMessage, spHostUrl);
  }
};

const { actions, reducer } = classifyItemSlice;
export const {
  getPreviewFailed,
  gettingPreview,
  gottenPreview,
  savePreviewFailed,
  savedPreview,
  savingPreview,
} = actions;
export default reducer;
