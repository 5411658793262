import * as React from 'react';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  IActionResult,
  ISyncRun,
  ITermStoreSyncJob,
  ModalContents,
  SyncStatus,
} from 'types';
import {
  cancelTermStoreSync,
  fetchSyncRuns,
  fetchTermStoreSyncJob,
  fetchTermStores,
  queueTermStoreSync,
} from 'slices/termStoreSlice';

import { AnyAction } from '@reduxjs/toolkit';
import { FC } from 'react';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import SyncJobRuns from './syncJobRuns';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { localDate } from 'utils/dateUtils';
import { openModal } from 'slices/modalSlice';
import { syncStatus } from './statusIndicators';

interface IState {
  showSyncRuns: boolean;
}

interface IOwnProps {
  data: ITermStoreSyncJob;
  interfaceId: string;
  spHostUrl?: string;
}

interface IStateProps {
  fetchSyncRunsResult: IActionResult<ISyncRun[]>;
}

interface IDispatchProps {
  fetchSyncRuns: () => void;
  openDeleteModal: () => void;
  openEditModal: () => void;
  fetchTermStores: (spHostUrl: string) => void;
  queueSync: (spHostUrl: string) => void;
  cancelSync: () => void;
  fetchTermStoreSyncJob: (spHostUrl: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class SyncJob extends React.Component<IProps, IState> {
  private timerHandler?: number;
  private waiting: boolean = false;

  constructor(props: IProps) {
    super(props);
    this.state = { showSyncRuns: false };
    this.toggleSyncRuns = this.toggleSyncRuns.bind(this);
    this.edit = this.edit.bind(this);
    this.sync = this.sync.bind(this);
    this.cancel = this.cancel.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  public componentDidMount() {
    const { data } = this.props;
    if (this.waiting) return;
    if (
      data.status === SyncStatus.Queued ||
      data.status === SyncStatus.InProgress
    ) {
      this.waiting = true;
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    }
  }

  public componentDidUpdate() {
    const { data } = this.props;
    if (this.waiting) return;
    if (
      data.status === SyncStatus.Queued ||
      data.status === SyncStatus.InProgress
    ) {
      this.waiting = true;
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    } else {
      if (this.timerHandler) {
        clearTimeout(this.timerHandler);
      }
    }
  }

  public componentWillUnmount() {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }

  public render() {
    const {
      data,
      fetchSyncRunsResult,
      openDeleteModal,
      interfaceId,
    } = this.props;
    const syncRuns = (
      <tr>
        <td colSpan={7}>
          <LoaderWithParams
            component={SyncJobRuns}
            actionresult={fetchSyncRunsResult}
            params={{ interfaceId, syncJobId: data.id }}
          />
        </td>
      </tr>
    );

    const RunButtons: FC<{ status: SyncStatus }> = ({ status }) => {
      switch (status) {
        case SyncStatus.Queued:
        case SyncStatus.InProgress:
          return (
            <Button
              size="sm"
              className="py-0 px-1 mr-1"
              variant="warning"
              onClick={this.cancel}
            >
              Cancel
            </Button>
          );
        default:
          return (
            <Button
              size="sm"
              className="py-0 px-1 mr-1"
              variant="success"
              onClick={this.sync}
            >
              Sync
            </Button>
          );
      }
    };

    const siteInfo = data.siteCollection ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${data.id}`}>{data.siteCollection}</Tooltip>
        }
        trigger={['hover', 'focus']}
      >
        <td>{data.sesIndex}</td>
      </OverlayTrigger>
    ) : (
      <td>{data.sesIndex}</td>
    );

    return (
      <>
        <tr>
          {siteInfo}
          <td>{data.termStoreName}</td>
          <td>{data.groupName}</td>
          <td className="text-nowrap">
            {data.lastSyncDate ? localDate(data.lastSyncDate) : 'never'}
          </td>
          <td className="text-nowrap">{syncStatus[data.status]}</td>
          <td>
            {data.isValid ? (
              <>
                <RunButtons status={data.status} />
                <Button
                  size="sm"
                  className="py-0 px-1 mr-1"
                  variant="primary"
                  onClick={this.edit}
                  disabled={
                    data.status === SyncStatus.Queued ||
                    data.status === SyncStatus.InProgress
                  }
                >
                  Edit
                </Button>
              </>
            ) : null}
            <Button
              size="sm"
              className="py-0 px-1"
              variant="danger"
              onClick={openDeleteModal}
              disabled={
                data.status === SyncStatus.Queued ||
                data.status === SyncStatus.InProgress
              }
            >
              Delete
            </Button>
          </td>
          <td>
            <Button
              size="sm"
              className="py-0 px-1"
              variant="outline-dark"
              active={this.state.showSyncRuns}
              onClick={this.toggleSyncRuns}
            >
              View
            </Button>
          </td>
        </tr>
        {this.state.showSyncRuns ? syncRuns : null}
      </>
    );
  }

  private edit() {
    const { openEditModal, fetchTermStores, spHostUrl } = this.props;
    if (spHostUrl) {
      fetchTermStores(spHostUrl);
      openEditModal();
    }
  }

  private sync() {
    const { queueSync, spHostUrl } = this.props;
    if (spHostUrl) {
      queueSync(spHostUrl);
    }
  }

  private cancel() {
    const { cancelSync } = this.props;
    cancelSync();
  }

  private toggleSyncRuns() {
    const { fetchSyncRuns } = this.props;
    const { showSyncRuns } = this.state;
    if (!showSyncRuns) {
      fetchSyncRuns();
    }
    this.setState({ showSyncRuns: !showSyncRuns });
  }

  private checkStatus() {
    const { fetchTermStoreSyncJob, spHostUrl } = this.props;
    if (spHostUrl) {
      fetchTermStoreSyncJob(spHostUrl);
    }
    this.waiting = false;
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => ({
  fetchSyncRunsResult:
    state.termStore.fetchSyncRunsResults[ownProps.data.id] || {},
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchSyncRuns: () =>
    dispatch(fetchSyncRuns(ownProps.data.id, ownProps.interfaceId)),
  openDeleteModal: () =>
    dispatch(
      openModal(
        'Delete Sync Job',
        ModalContents.DeleteSyncJob,
        { position: 'right' },
        { interfaceId: ownProps.interfaceId, syncJob: ownProps.data }
      )
    ),
  openEditModal: () =>
    dispatch(
      openModal(
        'Edit Sync Job',
        ModalContents.EditTermStoreSyncJob,
        { position: 'right' },
        {
          interfaceId: ownProps.interfaceId,
          syncJob: ownProps.data,
          spHostUrl: ownProps.spHostUrl,
        }
      )
    ),
  fetchTermStores: (spHostUrl: string) =>
    dispatch(fetchTermStores(ownProps.interfaceId, spHostUrl)),
  queueSync: (spHostUrl: string) =>
    dispatch(
      queueTermStoreSync(ownProps.interfaceId, spHostUrl, ownProps.data.id)
    ),
  cancelSync: () =>
    dispatch(cancelTermStoreSync(ownProps.interfaceId, ownProps.data.id)),
  fetchTermStoreSyncJob: (spHostUrl: string) =>
    dispatch(
      fetchTermStoreSyncJob(ownProps.interfaceId, spHostUrl, ownProps.data.id)
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SyncJob);
