import * as React from 'react';

import { ITenancyDetail, ModalContents } from 'types';

import { AnyAction } from 'redux';
import Authorizer from 'utils/authorizer';
import { Button } from 'react-bootstrap';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';
import { selectTenant } from 'slices/tenantSlice';

interface IOwnProps {
  tenant: ITenancyDetail;
}

interface IStateProps {
  canDeleteTenant: boolean;
}

interface IDispatchProps {
  openEditModal: (tenant: ITenancyDetail) => void;
  openDeleteModal: (tenant: ITenancyDetail) => void;
  selectTenant: (tenantId: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class TenantRow extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.select = this.select.bind(this);
  }

  public render() {
    const { tenant, canDeleteTenant } = this.props;
    return (
      <tr className={tenant.isSelected ? 'table-info' : undefined}>
        <td className="align-middle">
          {tenant.isSelected ? <CheckIcon size="1.7rem" /> : null}
        </td>
        <td className="align-middle">{tenant.name}</td>
        <td className="align-middle">
          {tenant.expiryDate == null
            ? 'Never'
            : new Date(tenant.expiryDate).toLocaleDateString()}
        </td>
        <td className="align-middle">
          {tenant.active ? (
            <span>
              <CheckCircleIcon size="1.7rem" className="mr-1 text-success" />
              Active
            </span>
          ) : (
            <span>
              <CloseCircleIcon size="1.7rem" className="mr-1 text-danger" />
              Inactive
            </span>
          )}
        </td>
        <td className="align-middle">
          {tenant.isSelected ? (
            <Button variant="primary" block onClick={this.edit}>
              Edit
            </Button>
          ) : (
            <Button variant="outline-dark" block onClick={this.select}>
              Select
            </Button>
          )}
        </td>
        {canDeleteTenant ? (
          <td className="align-middle">
            <Button variant="danger" block onClick={this.delete}>
              Delete
            </Button>
          </td>
        ) : null}
      </tr>
    );
  }

  private delete() {
    const { openDeleteModal, tenant, canDeleteTenant } = this.props;
    if (canDeleteTenant) {
      openDeleteModal(tenant);
    }
  }

  private edit() {
    const { openEditModal, tenant } = this.props;
    openEditModal(tenant);
  }

  private select() {
    const { tenant, selectTenant } = this.props;
    selectTenant(tenant.id);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canDeleteTenant: authorizer.canDeleteTenant(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openEditModal: (tenant: ITenancyDetail) =>
    dispatch(
      openModal(
        'Edit Tenant',
        ModalContents.EditTenant,
        {
          position: 'right',
          size: 'lg',
        },
        tenant
      )
    ),
  openDeleteModal: (tenant: ITenancyDetail) =>
    dispatch(
      openModal(
        'Delete Tenant',
        ModalContents.DeleteTenant,
        {
          position: 'right',
          size: 'lg',
        },
        tenant
      )
    ),
  selectTenant: (tenantId: string) => dispatch(selectTenant(tenantId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(TenantRow);
