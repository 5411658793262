import * as React from 'react';

import { Container } from 'react-bootstrap';
import PageFooter from '../footer';
import NavigationBar from '../navigation/navigationBar';

interface IProps {}

class WideLayout extends React.Component<IProps> {
  public render() {
    return (
      <Container fluid className="d-flex flex-column flex-grow-1 min-vh-100">
        <NavigationBar />
        {this.props.children}
        <PageFooter />
      </Container>
    );
  }
}

export default WideLayout;
