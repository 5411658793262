import * as React from 'react';

import { IError } from 'utils/errors';

export interface IProps {
  error?: IError;
}

export interface IState {
  detailsVisible: boolean;
  errorVisible: boolean;
}

export default class SmallError extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { detailsVisible: false, errorVisible: true };
  }
  public render() {
    const { error } = this.props;
    if (error == null) return null;
    const { title, subTitle } = error;

    return (
      <div className="text-danger">
        <b>{title}: </b>
        {subTitle}
      </div>
    );
  }
}
