import * as React from 'react';

import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import BapiInterfacesSubPage from './bapi/bapiInterfacesSubPage';
import { IReduxState } from 'reducers';
import { Nav } from 'react-bootstrap';
import RoleChecker from 'components/common/roleChecker';
import { ServerType } from 'types';
import SpoInterfacesSubPage from './spo/spoInterfacesSubPage';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchServicesOfType } from 'slices/serviceSlice';

interface IOwnProps {
  interfaceType?: 'bapi' | 'spo';
}

interface IStateProps {
  canUseBasicApi: boolean;
}

interface IDispatchProps {
  fetchServices: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class InterfacesPage extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchServices } = this.props;
    fetchServices();
  }

  public render() {
    const { interfaceType, canUseBasicApi } = this.props;
    return (
      <>
        <RoleChecker
          showAccessError
          requiredRoles={[
            'DeploymentManager',
            'BasicApiUser',
            'InfrastructureManager',
            'TermstoreSyncManager',
          ]}
        >
          <h1 className="font-weight-light mt-5 mb-2">Interfaces</h1>
          <Nav variant="tabs" className="mb-4">
            <RoleChecker
              requiredRoles={['BasicApiUser', 'InfrastructureManager']}
            >
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/interfaces/bapi"
                  active={
                    interfaceType === 'bapi' || interfaceType === undefined
                  }
                  className="bg-white"
                >
                  Basic API
                </Nav.Link>
              </Nav.Item>
            </RoleChecker>
            <RoleChecker
              requiredRoles={[
                'DeploymentManager',
                'InfrastructureManager',
                'TermstoreSyncManager',
              ]}
            >
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/interfaces/spo"
                  active={interfaceType === 'spo' || !canUseBasicApi}
                  className="bg-white"
                >
                  SharePoint
                </Nav.Link>
              </Nav.Item>
            </RoleChecker>
          </Nav>
          <Switch>
            <Route
              exact
              path="/interfaces/"
              component={
                canUseBasicApi ? BapiInterfacesSubPage : SpoInterfacesSubPage
              }
            />
            <Route
              path="/interfaces/bapi/:interfaceId/"
              render={this.bapiInterfacesSubPage}
            />
            <Route path="/interfaces/bapi/" component={BapiInterfacesSubPage} />
            <Route
              path="/interfaces/spo/:interfaceId/"
              render={this.spoInterfacesSubPage}
            />
            <Route path="/interfaces/spo/" component={SpoInterfacesSubPage} />
          </Switch>
        </RoleChecker>
      </>
    );
  }

  private spoInterfacesSubPage = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => {
    const { interfaceId } = props.match.params;
    return <SpoInterfacesSubPage interfaceId={interfaceId} />;
  };

  private bapiInterfacesSubPage = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => {
    const { interfaceId } = props.match.params;
    return <BapiInterfacesSubPage interfaceId={interfaceId} />;
  };
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canUseBasicApi: authorizer.canUseBasicApi(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchServices: () =>
    dispatch(
      fetchServicesOfType([
        ServerType.ClassificationService,
        ServerType.SemanticEnhancementService,
      ])
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(InterfacesPage);
