import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { AnyAction } from 'redux';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from '../modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import textEditorComponent from 'components/common/formComponents/textEditorComponent';
import { updateNote } from 'slices/tenantSettingsSlice';

interface IOwnProps {
  data: string;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
}

interface IDispatchProps {
  updateNote: (note: string) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  note: string;
}

export class EditNoteModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, updateResult } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field name="note" component={textEditorComponent} />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { updateNote } = this.props;
    updateNote(data.note);
  }
}

const EditNoteModalForm = reduxForm<IFormData, IProps>({
  form: 'updateNote',
})(EditNoteModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      note: ownProps.data,
    },
    form: 'updateNote',
    updateResult: state.tenantSettings.updateNoteResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateNote: (note: string) => dispatch(updateNote(note)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditNoteModalForm);
