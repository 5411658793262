import { IHistorySearchResult, INotification } from '../types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export class NotificationEndpoint {
  public readonly ROOT = 'notification';

  public list(): Promise<AxiosResponse<INotification[]>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.get(url);
  }

  public history(
    correlationId: string
  ): Promise<AxiosResponse<IHistorySearchResult>> {
    const queryItems = [new QueryItem('correlationId', correlationId)];
    const url = Endpoint.getUrl([this.ROOT, 'history'], queryItems);
    return api.get(url);
  }
}
