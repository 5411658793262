import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export class RegisterEndpoint {
  public readonly ROOT = 'register';

  public async validateDomain(
    domainName: string | null = null
  ): Promise<AxiosResponse<string>> {
    const queryItems = [];
    if (domainName) {
      queryItems.push(new QueryItem('domainName', domainName));
    }
    const url = Endpoint.getUrl([this.ROOT, 'validateDomain'], queryItems);
    return await api.get(url);
  }
  public async validateEmail(address: string): Promise<AxiosResponse<string>> {
    const queryItems = [new QueryItem('address', address)];
    const url = Endpoint.getUrl([this.ROOT, 'validateEmail'], queryItems);
    return await api.get(url);
  }
}
