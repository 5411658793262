import { Alert } from 'react-bootstrap';
import { AnyAction } from 'redux';
import { IPopupMessage } from 'types';
import { IReduxState } from 'reducers';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { messageDismissed } from 'slices/messageSlice';

interface IOwnProps {
  message: IPopupMessage;
}

interface IDispatchProps {
  dismissMessage: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class Message extends React.Component<IProps> {
  public render() {
    const { message, dismissMessage } = this.props;
    return (
      <Alert variant="danger" onClose={dismissMessage} dismissible>
        <p>{message.contents}</p>
      </Alert>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  dismissMessage: () => dispatch(messageDismissed(ownProps.message.id)),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(Message);
