import * as React from 'react';

import { Form, InputGroup } from 'react-bootstrap';

import CopyButton from 'components/common/copyButton';
import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';

interface IProps {
  input: any;
  label: string;
  tooltip: string;
  type: string;
  meta: any;
  emptyPlaceholder?: string;
  copy?: boolean;
}

class InputComponent extends React.Component<IProps> {
  public render() {
    const {
      tooltip,
      label,
      input,
      type,
      copy,
      emptyPlaceholder,
      meta: { touched, error, warning },
      ...other
    } = this.props;
    const help =
      tooltip !== undefined ? (
        <span title={tooltip}>
          <HelpOutlineIcon size="1rem" />
        </span>
      ) : null;
    return (
      <Form.Group>
        <Form.Label>
          {label} {help}
        </Form.Label>
        <InputGroup>
          <Form.Control
            {...input}
            placeholder={emptyPlaceholder ? '' : label}
            type={type}
            {...other}
          />
          {copy ? (
            <InputGroup.Append>
              <CopyButton text={input.value} />
            </InputGroup.Append>
          ) : null}
        </InputGroup>
        {touched &&
          ((error && <Form.Text className="text-danger">{error}</Form.Text>) ||
            (warning && (
              <Form.Text className="text-warning">{warning}</Form.Text>
            )))}
      </Form.Group>
    );
  }
}

export default InputComponent;
