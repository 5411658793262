import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  IAvailableAzureVmValues,
  IDefaultAzureVmValues,
  ILanguagePack,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import AzureVmEndpoint from 'endpoints/azureVmEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IVmState {
  availableVmValuesResult: IActionResult<IAvailableAzureVmValues>;
  defaultVmValuesResult: IActionResult<IDefaultAzureVmValues>;
  currentSizeResult: IActionResult<string>;
  languagePacksResult: IActionResult<ILanguagePack[]>;
}

const vmSlice = createSlice({
  name: 'vm',
  initialState: {
    availableVmValuesResult: { processing: false },
    defaultVmValuesResult: { processing: false },
    currentSizeResult: { processing: false },
    languagePacksResult: { processing: false },
  } as IVmState,
  reducers: {
    fetchingAvailable(state) {
      state.availableVmValuesResult = { processing: true };
    },
    fetchedAvailable(state, action: PayloadAction<IAvailableAzureVmValues>) {
      state.availableVmValuesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchAvailableFailed(state, action: PayloadAction<IError>) {
      state.availableVmValuesResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingDefault(state) {
      state.defaultVmValuesResult = { processing: true };
    },
    fetchedDefault(state, action: PayloadAction<IDefaultAzureVmValues>) {
      state.defaultVmValuesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchDefaultFailed(state, action: PayloadAction<IError>) {
      state.defaultVmValuesResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingCurrentSize(state) {
      state.currentSizeResult = { processing: true };
    },
    fetchedCurrentSize(state, action: PayloadAction<string>) {
      state.currentSizeResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchCurrentSizeFailed(state, action: PayloadAction<IError>) {
      state.currentSizeResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingLanguagePacks(state) {
      state.languagePacksResult = { processing: true };
    },
    fetchedLanguagePacks(state, action: PayloadAction<ILanguagePack[]>) {
      state.languagePacksResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchLanguagePacksFailed(state, action: PayloadAction<IError>) {
      state.languagePacksResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchAvailableVmValues = (
  serviceGroupId: string,
  serviceId?: string
): AppThunk => async (dispatch) => {
  const endpoint = new AzureVmEndpoint();
  dispatch(fetchingAvailable());
  try {
    const response = await endpoint.getAvailableValues(
      serviceGroupId,
      serviceId
    );
    dispatch(fetchedAvailable(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAvailableFailed(Errors.getError(error)));
  }
};

export const fetchDefaultVmValues = (): AppThunk => async (dispatch) => {
  const endpoint = new AzureVmEndpoint();
  dispatch(fetchingDefault());
  try {
    const response = await endpoint.getDefaultValues();
    dispatch(fetchedDefault(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchDefaultFailed(Errors.getError(error)));
  }
};

export const fetchCurrentVmSize = (serverId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new AzureVmEndpoint();
  dispatch(fetchingCurrentSize());
  try {
    const response = await endpoint.getCurrentSize(serverId);
    dispatch(fetchedCurrentSize(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchCurrentSizeFailed(Errors.getError(error)));
  }
};

export const fetchLanguagePacks = (serverId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new AzureVmEndpoint();
  dispatch(fetchingLanguagePacks());
  try {
    const response = await endpoint.getLanguagePacks(serverId);
    dispatch(fetchedLanguagePacks(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchLanguagePacksFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = vmSlice;
export const {
  fetchAvailableFailed,
  fetchedAvailable,
  fetchingAvailable,
  fetchDefaultFailed,
  fetchedDefault,
  fetchingDefault,
  fetchCurrentSizeFailed,
  fetchedCurrentSize,
  fetchingCurrentSize,
  fetchLanguagePacksFailed,
  fetchedLanguagePacks,
  fetchingLanguagePacks,
} = actions;
export default reducer;
