import * as React from 'react';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface IOwnProps {
  input: {
    value: string[];
    onChange: (value: string[]) => void;
  };
  label: string;
  categories: string[];
}

interface IProps extends IOwnProps {}

class CategorySelectComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  public render() {
    const { input, label, categories } = this.props;
    const options = categories.map((category) => ({
      value: category,
      label: category,
    }));
    const customStyles = {
      valueContainer: (provided: any) => ({
        ...provided,
        maxHeight: '8rem',
        overflowY: 'auto',
      }),
    };
    const value = options.filter((o) => !input.value.includes(o.value));
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Select
          isMulti
          options={options}
          value={value}
          onChange={this.onChange}
          styles={customStyles}
        />
      </Form.Group>
    );
  }

  private onChange(data: any) {
    const { categories, input } = this.props;
    if (data == null) {
      input.onChange([]);
    } else {
      const selectedCategories = data.map(
        (d: { value: string; label: string }) => d.value
      );
      const newValue = categories.filter(
        (c) => !selectedCategories.includes(c)
      );
      input.onChange(newValue);
    }
  }
}

export default CategorySelectComponent;
