import * as React from 'react';

import { IAccountResult, ModalContents } from '../../../types';

import { AnyAction } from 'redux';
import { Button } from 'react-bootstrap';
import { IReduxState } from '../../../reducers';
import SquareEditOutline from 'mdi-react/SquareEditOutlineIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: IAccountResult;
}

interface IDispatchProps {
  openChangePasswordModal: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ChangePassword extends React.Component<IProps, any> {
  public render() {
    const { openChangePasswordModal, data } = this.props;
    return data!.hasPassword ? (
      <Button
        className="float-right mt-3"
        variant="link"
        as="a"
        onClick={openChangePasswordModal}
        title="Change Password"
      >
        <SquareEditOutline size="1.5em" className="mr-1" /> Change Password
      </Button>
    ) : null;
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openChangePasswordModal: () =>
    dispatch(
      openModal('Change Password', ModalContents.ChangePassword, {
        position: 'right',
      })
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ChangePassword);
