import { Button } from 'react-bootstrap';
import { Endpoint } from 'endpoints/base/endpoint';
import GoogleIcon from 'mdi-react/GoogleIcon';
import MicrosoftWindowsIcon from 'mdi-react/MicrosoftWindowsIcon';
import { QueryItem } from 'endpoints/base/queryItem';
import React from 'react';
import SalesforceIcon from 'mdi-react/SalesforceIcon';

interface IProps {
  data: string[];
}

const diplayNameMap: {
  [key: string]: string;
} = {
  Microsoft: 'Add Microsoft work account',
  Google: 'Add Google account',
  Salesforce: 'Add Salesforce account',
};

const iconMap: {
  [key: string]: JSX.Element;
} = {
  Microsoft: <MicrosoftWindowsIcon size="1.5em" className="mr-1" />,
  Google: <GoogleIcon size="1.5em" className="mr-1" />,
  Salesforce: <SalesforceIcon size="1.5em" className="mr-1" />,
};

class ExternalLoginProviders extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const parameters = [
      new QueryItem('redirectUri', encodeURIComponent(window.location.href)),
    ];
    const loginButtons = data.map((provider, index) => (
      <Button
        href={Endpoint.getApiUrl(
          ['account', 'addExternalLogin', provider],
          parameters
        )}
        variant="link"
        as="a"
        className="float-right pt-0 "
        key={index}
      >
        {iconMap[provider]}
        {diplayNameMap[provider]}
      </Button>
    ));
    return <>{loginButtons}</>;
  }
}

export default ExternalLoginProviders;
