import { Errors, IError } from 'utils/errors';
import { IActionResult, IHistorySearchResult, INotification } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { NotificationEndpoint } from 'endpoints/notificationEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface INotificationState {
  notificationsResult: IActionResult<INotification[]>;
  historyResult: IActionResult<IHistorySearchResult>;
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationsResult: {
      processing: false,
    },
    historyResult: {
      processing: false,
    },
  } as INotificationState,
  reducers: {
    fetching(state) {
      state.notificationsResult.processing = true;
    },
    fetched(state, action: PayloadAction<INotification[]>) {
      state.notificationsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.notificationsResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingHistory(state) {
      state.historyResult = { processing: true };
    },
    fetchedHistory(state, action: PayloadAction<IHistorySearchResult>) {
      state.historyResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchHistoryFailed(state, action: PayloadAction<IError>) {
      state.historyResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchNotifications = (): AppThunk => async (dispatch) => {
  const endpoint = new NotificationEndpoint();
  dispatch(fetching());
  try {
    const response = await endpoint.list();
    dispatch(fetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

export const loadHistory = (correlationId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new NotificationEndpoint();
  dispatch(fetchingHistory());
  try {
    const response = await endpoint.history(correlationId);
    dispatch(fetchedHistory(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchHistoryFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = notificationSlice;
export const {
  fetchFailed,
  fetched,
  fetching,
  fetchHistoryFailed,
  fetchedHistory,
  fetchingHistory,
} = actions;
export default reducer;
