import * as React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { AnyAction } from 'redux';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import { resetPassword } from 'slices/accountSlice';

interface IOwnProps {
  userId: string;
}

interface IStateProps {
  resetPasswordResult: IActionResult<boolean>;
}

interface IDispatchProps {
  resetPassword: (code: string, password: string) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  password: string;
  passwordConfirm: string;
}

export class ResetPasswordPage extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  private code: string;

  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.code = params.get('code')!;
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, resetPasswordResult } = this.props;
    return (
      <Row className="flex-grow-1 justify-content-md-center align-items-center">
        <Col sm={12} md={6} lg={4}>
          <Form onSubmit={handleSubmit(this.save)}>
            <Card>
              <Card.Body>
                <Card.Title>Reset password</Card.Title>
                <Field
                  name="password"
                  component={inputComponent}
                  type="password"
                  placeholder="Please enter a password..."
                  label="Password"
                  validate={[
                    formValidators.required,
                    formValidators.minLength(9),
                    formValidators.requireThreeTypesOfCharacters,
                  ]}
                  autoComplete="off"
                />
                <Field
                  name="passwordConfirm"
                  component={inputComponent}
                  type="password"
                  placeholder="Please reenter a password..."
                  label="Confirm Password"
                  validate={[
                    formValidators.required,
                    formValidators.passwordsMatch,
                  ]}
                  autoComplete="off"
                />
              </Card.Body>
              <ModalFormFooter
                actionResult={resetPasswordResult}
                disabled={pristine}
                noCancel
              />
            </Card>
          </Form>
        </Col>
      </Row>
    );
  }

  private save(data: IFormData) {
    const { resetPassword } = this.props;
    resetPassword(this.code, data.password);
  }
}

const ResetPasswordPageForm = reduxForm<IFormData, IProps>({
  form: 'resetPassword',
})(ResetPasswordPage);

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  resetPassword: (code: string, password: string) =>
    dispatch(resetPassword(ownProps.userId, { code, password })),
});

const mapStateToProps = (state: IReduxState): IStateProps => ({
  resetPasswordResult: state.account.resetPasswordResult,
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPageForm);
