import { Errors, IError } from 'utils/errors';
import { IActionResult, IInterfaceConfigured } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import SpoInterfaceEndpoint from 'endpoints/spoInterfaceEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISpoState {
  fetchInterfaceResult: IActionResult<IInterfaceConfigured>;
  fetchAllInterfacesResult: IActionResult<IInterfaceConfigured[]>;
  createInterfaceResult: IActionResult<boolean>;
  updateInterfaceResult: IActionResult<boolean>;
  deleteInterfaceResult: IActionResult<boolean>;
}

const spoSlice = createSlice({
  name: 'spo',
  initialState: {
    fetchInterfaceResult: { processing: false },
    fetchAllInterfacesResult: { processing: false },
    createInterfaceResult: { processing: false },
    updateInterfaceResult: { processing: false },
    deleteInterfaceResult: { processing: false },
  } as ISpoState,
  reducers: {
    fetching(state) {
      state.fetchInterfaceResult = { processing: true };
    },
    fetched(state, action: PayloadAction<IInterfaceConfigured>) {
      state.fetchInterfaceResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.fetchInterfaceResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingAll(state) {
      state.fetchAllInterfacesResult = { processing: true };
    },
    fetchedAll(state, action: PayloadAction<IInterfaceConfigured[]>) {
      state.fetchAllInterfacesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchAllFailed(state, action: PayloadAction<IError>) {
      state.fetchAllInterfacesResult = {
        processing: false,
        error: action.payload,
      };
    },
    deleting(state) {
      state.deleteInterfaceResult = { processing: true };
    },
    deleted(state, action: PayloadAction<string>) {
      state.deleteInterfaceResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllInterfacesResult.data)) {
        state.fetchAllInterfacesResult.data = state.fetchAllInterfacesResult.data.filter(
          (i) => i.id !== action.payload
        );
      }
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.deleteInterfaceResult = {
        processing: false,
        error: action.payload,
      };
    },
    creating(state) {
      state.createInterfaceResult = { processing: true };
    },
    created(state, action: PayloadAction<IInterfaceConfigured>) {
      state.createInterfaceResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllInterfacesResult.data)) {
        state.fetchAllInterfacesResult.data.push(action.payload);
      }
    },
    creationFailed(state, action: PayloadAction<IError>) {
      state.createInterfaceResult = {
        processing: false,
        error: action.payload,
      };
    },
    updating(state) {
      state.updateInterfaceResult = { processing: true };
    },
    updated(state, action: PayloadAction<IInterfaceConfigured>) {
      state.updateInterfaceResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllInterfacesResult.data)) {
        state.fetchAllInterfacesResult.data = [
          ...state.fetchAllInterfacesResult.data.filter(
            (i) => i.id !== action.payload.id
          ),
          action.payload,
        ];
      }
    },
    updateFailed(state, action: PayloadAction<IError>) {
      state.updateInterfaceResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchAllSpoInterfaces = (): AppThunk => async (dispatch) => {
  const endpoint = new SpoInterfaceEndpoint();
  dispatch(fetchingAll());
  try {
    const response = await endpoint.getConfiguredList();
    dispatch(fetchedAll(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAllFailed(Errors.getError(error)));
  }
};

export const fetchSpoInterface = (interfaceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SpoInterfaceEndpoint();
  dispatch(fetching());
  try {
    const response = await endpoint.getInterface(interfaceId);
    dispatch(fetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

export const createSpoInterface = (
  data: IInterfaceConfigured
): AppThunk => async (dispatch) => {
  const endpoint = new SpoInterfaceEndpoint();
  dispatch(creating());
  try {
    const response = await endpoint.create(data);
    dispatch(created(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(creationFailed(Errors.getError(error)));
  }
};

export const updateSpoInterface = (
  data: IInterfaceConfigured
): AppThunk => async (dispatch) => {
  const endpoint = new SpoInterfaceEndpoint();
  dispatch(updating());
  try {
    const response = await endpoint.update(data);
    dispatch(updated(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateFailed(Errors.getError(error)));
  }
};

export const deleteSpoInterface = (interfaceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SpoInterfaceEndpoint();
  dispatch(deleting());
  try {
    await endpoint.delete(interfaceId);
    dispatch(deleted(interfaceId));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = spoSlice;
export const {
  fetchFailed,
  fetched,
  fetching,
  fetchingAll,
  fetchedAll,
  fetchAllFailed,
  created,
  creating,
  creationFailed,
  deleteFailed,
  deleted,
  deleting,
  updateFailed,
  updated,
  updating,
} = actions;
export default reducer;
