import * as React from 'react';

import { IActionResult, ISiteLibrary } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchSiteLibrary } from 'slices/siteLibrarySlice';
import listSimSettingsPreLoad from './listSimSettingsPreLoad';

interface IStateProps {
  fetchSiteLibraryResult: IActionResult<ISiteLibrary>;
}

interface IOwnProps {
  interfaceId: string;
}

interface IDispatchProps {
  fetchSiteLibrary: (
    interfaceId: string,
    listId: string,
    spHostUrl: string
  ) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ListSimSettingsPage extends React.Component<IProps> {
  private spHostUrl: string;
  private listId: string;

  constructor(props: IProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.spHostUrl = params.get('spHostUrl')!;
    this.listId = params.get('listid')!;
  }

  public componentDidMount() {
    const { fetchSiteLibrary, interfaceId } = this.props;
    const { listId, spHostUrl } = this;

    fetchSiteLibrary(interfaceId, listId, spHostUrl);
  }

  public render() {
    const { fetchSiteLibraryResult } = this.props;
    const { spHostUrl } = this;
    return (
      <LoaderWithParams
        actionresult={fetchSiteLibraryResult}
        component={listSimSettingsPreLoad}
        params={{ spHostUrl }}
      />
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchSiteLibraryResult: state.siteLibrary.fetchResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchSiteLibrary: (interfaceId: string, listId: string, spHostUrl: string) =>
    dispatch(fetchSiteLibrary(interfaceId, listId, spHostUrl)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ListSimSettingsPage);
