import * as React from 'react';

import { Alert } from 'react-bootstrap';

interface IProps {
  data: string;
}

class Note extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    if (!data) return null;

    return (
      <Alert variant="info">
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </Alert>
    );
  }
}

export default Note;
