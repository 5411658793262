import * as React from 'react';

import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, ITenancyDetail } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createTenant } from 'slices/tenantSlice';
import datePickerComponent from 'components/common/formComponents/datePickerComponent';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';

interface IStateProps {
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createTenant: (tenant: ITenancyDetail) => void;
}

export interface IFormData {
  name: string;
  expiryDate: string;
}

export interface IProps extends IStateProps, IDispatchProps {}

export class CreateTenantModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { createResult, handleSubmit, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            label="Tenancy Name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a tenant name"
            validate={formValidators.required}
          />
          <Field
            name="expiryDate"
            label="Expiry Date"
            component={datePickerComponent}
            placeholder="Please specify an expiry date"
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { createTenant } = this.props;
    createTenant(data as ITenancyDetail);
  }
}

const CreateTenantModalForm = reduxForm<IFormData, IProps>({
  form: 'createTenant',
})(CreateTenantModal);

const mapStateToProps = (state: IReduxState): IStateProps => ({
  createResult: state.tenant.createResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createTenant: (tenant: ITenancyDetail) => dispatch(createTenant(tenant)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateTenantModalForm);
