import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { ITenantContactDetail } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class TenantSettingsEndpoint {
  public readonly ROOT = 'tenantsettings';

  public getContacts(): Promise<AxiosResponse<ITenantContactDetail[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'contact']);
    return api.get(url);
  }
  public getNote(): Promise<AxiosResponse<string>> {
    const url = Endpoint.getUrl([this.ROOT, 'note']);
    return api.get(url);
  }
  public deleteContact(id: string): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('id', id)];
    const url = Endpoint.getUrl([this.ROOT, 'contact'], queryItems);
    return api.delete(url);
  }
  public addContact(data: ITenantContactDetail): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT, 'contact']);
    return api.post(url, data);
  }

  public editContact(data: ITenantContactDetail): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT, 'contact']);
    return api.put(url, data);
  }

  public editNote(data: string): Promise<AxiosResponse<string>> {
    const url = Endpoint.getUrl([this.ROOT, 'note']);
    return api.post(url, `${JSON.stringify(data)}`, {
      withCredentials: true,
      headers: { 'content-type': 'application/json' },
    });
  }
}
