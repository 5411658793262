import * as React from 'react';

import { Button, ProgressBar } from 'react-bootstrap';
import {
  ISequentialListClassification,
  ModalContents,
  SequentialListClassificationState,
} from 'types';
import {
  fetchListClassification,
  fetchListClassificationDetails,
} from 'slices/classifyListSlice';

import { AnyAction } from '@reduxjs/toolkit';
import ClassificationButtons from './classificationButtons';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { listClassificationStatusStatus } from './statusIndicators';
import { localDate } from 'utils/dateUtils';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  classification: ISequentialListClassification;
}

interface IDispatchProps {
  openDetailsModal: () => void;
  fetchListClassificationDetails: () => void;
  fetchListClassification: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ListClassification extends React.Component<IProps> {
  private timerHandler?: number;

  constructor(props: IProps) {
    super(props);
    this.details = this.details.bind(this);
  }

  public componentDidMount() {
    const { classification } = this.props;
    if (
      classification.state === SequentialListClassificationState.Queued ||
      classification.state === SequentialListClassificationState.InProgress
    ) {
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    }
  }

  public componentDidUpdate() {
    const { classification } = this.props;
    if (
      classification.state === SequentialListClassificationState.Queued ||
      classification.state === SequentialListClassificationState.InProgress
    ) {
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    } else {
      if (this.timerHandler) {
        clearTimeout(this.timerHandler);
      }
    }
  }

  public componentWillUnmount() {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }

  public render() {
    const { classification } = this.props;
    const inProgress =
      classification.state === SequentialListClassificationState.InProgress;
    return (
      <tr>
        <td className="text-nowrap">
          <ProgressBar>
            <ProgressBar
              variant="success"
              now={
                (classification.completedItems / classification.queuedItems) *
                100
              }
              animated={inProgress}
              key={1}
            />
            <ProgressBar
              variant="danger"
              now={
                (classification.failedItems / classification.queuedItems) * 100
              }
              animated={inProgress}
              key={2}
            />
            <ProgressBar
              variant="warning"
              now={
                (classification.ignoredItems / classification.queuedItems) * 100
              }
              animated={inProgress}
              key={3}
            />
          </ProgressBar>
        </td>
        <td className="text-nowrap">
          {listClassificationStatusStatus[classification.state]}
        </td>
        <td className="text-nowrap">{localDate(classification.queueDate)}</td>
        {classification.startDate ? (
          <td className="text-nowrap">{localDate(classification.startDate)}</td>
        ) : (
          <td />
        )}
        {classification.finishDate ? (
          <td className="text-nowrap">
            {localDate(classification.finishDate)}
          </td>
        ) : (
          <td />
        )}
        <ClassificationButtons classification={classification} />
        <td>
          <Button
            size="sm"
            variant="outline-dark"
            className="py-0 px-1"
            onClick={this.details}
          >
            Details
          </Button>
        </td>
      </tr>
    );
  }

  private details() {
    const { openDetailsModal, fetchListClassificationDetails } = this.props;
    fetchListClassificationDetails();
    openDetailsModal();
  }

  private checkStatus() {
    const { fetchListClassification } = this.props;
    fetchListClassification();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDetailsModal: () =>
    dispatch(
      openModal(
        'List classification details',
        ModalContents.ListClassificationDetails,
        { position: 'right' }
      )
    ),
  fetchListClassificationDetails: () =>
    dispatch(fetchListClassificationDetails(ownProps.classification.id)),
  fetchListClassification: () =>
    dispatch(fetchListClassification(ownProps.classification.id)),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ListClassification);
