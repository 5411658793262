import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, IServiceBasicInfo, IServiceViewModel } from 'types';
import {
  removeConfigurations,
  replaceCsInConfigurations,
} from 'slices/csSlice';

import { AnyAction } from '@reduxjs/toolkit';
import CsServerOptions from './csServerOptions';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import ModalFormFooter from 'components/common/modalFormFooter';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchServicesOfType } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

export interface IFormData {
  newClassificationServerId: string;
}

interface IParams {
  service: IServiceViewModel;
  setSubmitEnabled: (value: boolean) => void;
}

interface IOwnProps {
  data: number;
  params: IParams;
}

interface IState {
  replace: boolean;
}

interface IStateProps {
  replaceCsInConfigurationsResult: IActionResult<boolean>;
  removeConfigurationsResult: IActionResult<boolean>;
  fetchOfTypeResult: IActionResult<IServiceBasicInfo[]>;
}

interface IDispatchProps {
  replaceCsInConfigurations: (newClassificationServerId: string) => void;
  removeConfigurations: () => void;
  fetchServicesOfType: () => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ReplaceCsExtra extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.replace = this.replace.bind(this);
    this.remove = this.remove.bind(this);
    this.changeAction = this.changeAction.bind(this);
    this.state = { replace: true };
  }

  public componentDidMount() {
    const { fetchServicesOfType, params, data } = this.props;
    const { setSubmitEnabled } = params;
    if (data === 0) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
      fetchServicesOfType();
    }
  }

  public render() {
    const {
      data,
      handleSubmit,
      params,
      fetchOfTypeResult,
      replaceCsInConfigurationsResult,
      removeConfigurationsResult,
    } = this.props;
    const { service } = params;
    const { replace } = this.state;

    if (data === 0) return null;
    return (
      <div className="mt-2">
        There are {data} field configurations that reference this CS server.
        Select a CS server to replace the references with or remove field
        configurations.
        <Form
          onSubmit={handleSubmit(replace ? this.replace : this.remove)}
          className="mt-2"
          id="formReplace"
        >
          <label>
            <Field
              type="radio"
              component="input"
              name="action"
              value="replace"
              defaultChecked
              onChange={this.changeAction}
            />{' '}
            Replace CS server
          </label>
          {replace ? (
            <Field
              name="newClassificationServerId"
              component={selectComponent}
              validate={formValidators.required}
              label="Choose replacement CS server"
            >
              <option value="">Select CS service</option>
              <LoaderWithParams
                component={CsServerOptions}
                actionresult={fetchOfTypeResult}
                params={{ excludedServiceIds: [service.serverId!] }}
              />
            </Field>
          ) : (
            <div />
          )}
          <label>
            <Field
              type="radio"
              component="input"
              name="action"
              value="remove"
              onChange={this.changeAction}
            />{' '}
            Remove field configurations
          </label>
          <ModalFormFooter
            as={'div'}
            actionResult={
              replace
                ? replaceCsInConfigurationsResult
                : removeConfigurationsResult
            }
            submitMessage={replace ? 'Replace' : 'Remove'}
            submittingMessage={replace ? 'Replacing' : 'Removing'}
            submitVariant="warning"
            noCancel
          />
        </Form>
      </div>
    );
  }

  private changeAction = (event: any) => {
    this.setState({ replace: event.target.value === 'replace' });
  };

  private replace(data: IFormData) {
    const { replaceCsInConfigurations } = this.props;
    replaceCsInConfigurations(data.newClassificationServerId);
  }

  private remove(data: IFormData) {
    const { removeConfigurations } = this.props;
    removeConfigurations();
  }
}

const ReplaceCsExtraForm = reduxForm<IFormData, IProps>({
  form: 'replaceCs',
})(ReplaceCsExtra);

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    replaceCsInConfigurationsResult: state.cs.replaceCsInConfigurationsResult,
    removeConfigurationsResult: state.cs.removeConfigurationsResult,
    fetchOfTypeResult: state.service.fetchOfTypeResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  replaceCsInConfigurations: (newClassificationServerId: string) =>
    dispatch(
      replaceCsInConfigurations(
        ownProps.params.service.serverId!,
        newClassificationServerId
      )
    ),
  removeConfigurations: () =>
    dispatch(removeConfigurations(ownProps.params.service.serverId!)),
  fetchServicesOfType: () =>
    dispatch(fetchServicesOfType([ownProps.params.service.serverType])),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ReplaceCsExtraForm);
