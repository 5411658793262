import { Errors, IError } from 'utils/errors';
import { IActionResult, ITenancyDetail } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import TenancyEndpoint from 'endpoints/tenancyEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ITenantState {
  searchResult: IActionResult<ITenancyDetail[]>;
  deleteResult: IActionResult<boolean>;
  selectResult: IActionResult<boolean>;
  createResult: IActionResult<boolean>;
  updateResult: IActionResult<boolean>;
  fetchCurrentResult: IActionResult<ITenancyDetail | null>;
}

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: {
    searchResult: { processing: false },
    deleteResult: { processing: false },
    selectResult: { processing: false },
    createResult: { processing: false },
    updateResult: { processing: false },
    fetchCurrentResult: { processing: false },
  } as ITenantState,
  reducers: {
    searching(state) {
      state.searchResult = { processing: true };
    },
    searched(state, action: PayloadAction<ITenancyDetail[]>) {
      state.searchResult = { processing: false, data: action.payload };
    },
    searchFailed(state, action: PayloadAction<IError>) {
      state.searchResult = { processing: false, error: action.payload };
    },
    deleting(state) {
      state.deleteResult = { processing: true };
    },
    deleted(state, action: PayloadAction<string>) {
      state.deleteResult = { processing: false, data: true };
      if (Array.isArray(state.searchResult.data)) {
        state.searchResult.data = state.searchResult.data.filter(
          (t) => t.id !== action.payload
        );
      }
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.deleteResult = { processing: false, error: action.payload };
    },
    selecting(state) {
      state.selectResult = { processing: true };
    },
    selected(state, action: PayloadAction<string>) {
      state.selectResult = { processing: false, data: true };
      if (Array.isArray(state.searchResult.data)) {
        const currentIndex = state.searchResult.data.findIndex(
          (t) => t.isSelected === true
        );
        if (currentIndex !== -1) {
          state.searchResult.data[currentIndex].isSelected = false;
        }
        const newIndex = state.searchResult.data.findIndex(
          (t) => t.id === action.payload
        );
        if (newIndex !== -1) {
          state.searchResult.data[newIndex].isSelected = true;
        }
      }
    },
    selectFailed(state, action: PayloadAction<IError>) {
      state.selectResult = { processing: false, error: action.payload };
    },
    creating(state) {
      state.createResult = { processing: true };
    },
    created(state, action: PayloadAction<ITenancyDetail>) {
      state.createResult = { processing: false, data: true };
      if (Array.isArray(state.searchResult.data)) {
        state.searchResult.data.push(action.payload);
      }
    },
    creationFailed(state, action: PayloadAction<IError>) {
      state.createResult = { processing: false, error: action.payload };
    },
    updating(state) {
      state.updateResult = { processing: true };
    },
    updated(state, action: PayloadAction<ITenancyDetail>) {
      state.updateResult = { processing: false, data: true };
      if (
        state.fetchCurrentResult.data &&
        state.fetchCurrentResult.data.id === action.payload.id
      ) {
        state.fetchCurrentResult.data = action.payload;
      }
      if (Array.isArray(state.searchResult.data)) {
        state.searchResult.data = [
          ...state.searchResult.data.filter((u) => u.id !== action.payload.id),
          action.payload,
        ];
      }
    },
    updateFailed(state, action: PayloadAction<IError>) {
      state.updateResult = { processing: false, error: action.payload };
    },
    fetchingCurrent(state) {
      state.fetchCurrentResult = { processing: true };
    },
    fetchedCurrent(state, action: PayloadAction<ITenancyDetail>) {
      state.fetchCurrentResult = { processing: false, data: action.payload };
    },
    fetchCurrentFailed(state, action: PayloadAction<IError>) {
      state.fetchCurrentResult = { processing: false, error: action.payload };
    },
  },
});

export const searchTenancies = (name?: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new TenancyEndpoint();
  dispatch(searching());
  try {
    const response = await endpoint.search(name);
    dispatch(searched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(searchFailed(Errors.getError(error)));
  }
};

export const deleteTenant = (id: string): AppThunk => async (dispatch) => {
  const endpoint = new TenancyEndpoint();
  dispatch(deleting());
  try {
    await endpoint.delete(id);
    dispatch(deleted(id));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
};

export const selectTenant = (tenantId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new TenancyEndpoint();
  dispatch(selecting());
  try {
    await endpoint.select(tenantId);
    dispatch(selected(tenantId));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(selectFailed(Errors.getError(error)));
  }
};

export const createTenant = (tenant: ITenancyDetail): AppThunk => async (
  dispatch
) => {
  const endpoint = new TenancyEndpoint();
  dispatch(creating());
  try {
    const response = await endpoint.create(tenant);
    dispatch(created(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(creationFailed(Errors.getError(error)));
  }
};

export const updateTenant = (tenant: ITenancyDetail): AppThunk => async (
  dispatch
) => {
  const endpoint = new TenancyEndpoint();
  dispatch(updating());
  try {
    const response = await endpoint.update(tenant);
    dispatch(updated(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateFailed(Errors.getError(error)));
  }
};

export const getCurrentTenant = (): AppThunk => async (dispatch) => {
  const endpoint = new TenancyEndpoint();
  dispatch(fetchingCurrent());
  try {
    const response = await endpoint.loadCurrent();
    const tenant = response.data;
    dispatch(fetchedCurrent(tenant));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchCurrentFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = tenantSlice;
export const {
  searching,
  searched,
  searchFailed,
  deleteFailed,
  deleted,
  deleting,
  selectFailed,
  selected,
  selecting,
  created,
  creating,
  creationFailed,
  updateFailed,
  updated,
  updating,
  fetchCurrentFailed,
  fetchedCurrent,
  fetchingCurrent,
} = actions;
export default reducer;
