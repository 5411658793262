import * as React from 'react';

import CheckBoxListComponent from '../../common/formComponents/checkboxListComponent';
import { Field } from 'redux-form';
import { formValidators } from 'utils/formValidators';

export interface IOwnProps {
  data: string[];
}

export interface IProps extends IOwnProps {}

class RulebasesSelect extends React.Component<IProps> {
  public render() {
    const { data } = this.props;

    return (
      <Field
        name="selectedRulebaseClasses"
        component={CheckBoxListComponent}
        id="rulebase"
        label="Rulebase classes"
        tooltip="Classifications are determined by rulebases. Selecting a rulebase class will use the results for that rulebase in your column."
        options={data.map((rulebase) => {
          return {
            key: rulebase,
            value: rulebase,
          };
        })}
        validate={formValidators.atLeastOneChecked}
      />
    );
  }
}

export default RulebasesSelect;
