import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  reset,
} from 'redux-form';
import { IActionResult, IInterfaceRow, ILogSearchQuery } from 'types';
import {
  clear,
  exportLogs,
  fetchCategories,
  searchLogs,
} from 'slices/supportSlice';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import InterfaceSelect from './interfaceSelect';
import Loader from 'components/common/loader';
import SubmitButton from 'components/common/submitButton';
import { ThunkDispatch } from 'redux-thunk';
import categorySelect from './categorySelect';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import { connect } from 'react-redux';
import datePickerComponent from 'components/common/formComponents/datePickerComponent';
import inputComponent from 'components/common/formComponents/inputComponent';
import { searchInterface } from 'slices/interfaceSlice';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IDispatchProps {
  fetchCategories: () => void;
  fetchInterfaces: () => void;
  searchLogs: (searchQuery: ILogSearchQuery) => void;
  exportLogs: (searchQuery: ILogSearchQuery) => void;
  resetForm: () => void;
  clearLogs: () => void;
}

interface IStateProps {
  fetchCategoriesResult: IActionResult<string[]>;
  fetchInterfacesResult: IActionResult<IInterfaceRow[]>;
  supportLogsTopSwitches: boolean;
  searchResult: IActionResult<boolean>;
  downloadResult: IActionResult<boolean>;
}

export interface IProps extends IStateProps, IDispatchProps {}

export interface IFormData {
  startDate: string;
  endDate: string;
  interface: string;
  correlationId: string;
  username: string;
  message: string;
  logLevel: number;
  excludedCategories: string[];
  includeAllTenants: boolean;
  includeDebug: boolean;
  includeError: boolean;
}

export class SupportSearch extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.search = this.search.bind(this);
    this.export = this.export.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  public componentDidMount() {
    const { fetchCategories, fetchInterfaces } = this.props;
    fetchCategories();
    fetchInterfaces();
  }

  public render() {
    const {
      handleSubmit,
      fetchCategoriesResult,
      fetchInterfacesResult,
      supportLogsTopSwitches,
      searchResult,
      downloadResult,
    } = this.props;

    const switches = (
      <>
        <Field
          name="includeAllTenants"
          component={checkBoxComponent}
          type="switch"
          id="includeAllTenants"
          label="All Tenants"
          className="m-0"
        />
        <Field
          name="includeDebug"
          component={checkBoxComponent}
          type="switch"
          id="includeDebug"
          label="Debug"
          className="m-0"
        />
        <Field
          name="includeError"
          component={checkBoxComponent}
          type="switch"
          id="includeError"
          label="Error info"
        />
      </>
    );
    return (
      <Col xs="6" md="3" lg="2" as="aside" className="bg-white border-right">
        <Row>
          <Col as="header" className="bg-light border-bottom">
            <p className="mt-3">
              <strong>Support logs</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(this.search)} className="mt-3">
              {supportLogsTopSwitches ? switches : null}
              <Field
                name="startDate"
                component={datePickerComponent}
                placeholder="Start Date"
                label="Start Date"
              />
              <Field
                name="endDate"
                component={datePickerComponent}
                placeholder="End Date"
                label="End Date"
              />
              <Loader
                component={categorySelect}
                actionresult={fetchCategoriesResult}
              />
              <Loader
                component={InterfaceSelect}
                actionresult={fetchInterfacesResult}
              />
              <Field
                name="correlationId"
                label="Correlation Id"
                component={inputComponent}
                type="text"
                placeholder="Please enter a Correlation Id"
              />
              <Field name="logLevel" label="Levels" component={selectComponent}>
                <option value={0}>All</option>
                <option value={1}>Low</option>
                <option value={2}>Medium</option>
                <option value={4}>High</option>
                <option value={8}>Critical</option>
              </Field>
              <Field
                name="username"
                label="Username"
                component={inputComponent}
                type="text"
                placeholder="Enter a username"
              />
              <Field
                name="message"
                label="Message"
                component={inputComponent}
                type="text"
                placeholder="Enter a message"
              />
              <Button
                variant="secondary"
                className="mr-1"
                onClick={this.cancel}
              >
                Reset
              </Button>
              <SubmitButton
                inProgress={downloadResult.processing}
                className="mr-1"
                variant="info"
                defaultMessage="Export"
                progressMessage="Exporting"
                onClick={handleSubmit(this.export)}
              />
              <SubmitButton
                inProgress={searchResult.processing}
                className="mr-1"
                defaultMessage="Search"
                progressMessage="Searching"
              />
            </Form>
          </Col>
        </Row>
      </Col>
    );
  }

  private search(data: IFormData) {
    const { searchLogs } = this.props;
    const query: ILogSearchQuery = {
      includeAllTenants: data.includeAllTenants,
      includeDebug: data.includeDebug,
      includeError: data.includeError,
      startDate: data.startDate,
      endDate: data.endDate,
      excludedCategories: data.excludedCategories,
      interfaceId: data.interface,
      correlationId: data.correlationId,
      logLevel: data.logLevel,
      username: data.username,
      message: data.message,
    };
    searchLogs(query);
  }

  private export(data: IFormData) {
    const { exportLogs } = this.props;
    const query: ILogSearchQuery = {
      includeAllTenants: data.includeAllTenants,
      includeDebug: data.includeDebug,
      includeError: data.includeError,
      startDate: data.startDate,
      endDate: data.endDate,
      excludedCategories: data.excludedCategories,
      interfaceId: data.interface,
      correlationId: data.correlationId,
      logLevel: data.logLevel,
      username: data.username,
      message: data.message,
    };
    exportLogs(query);
  }

  private cancel() {
    const { resetForm, clearLogs } = this.props;
    resetForm();
    clearLogs();
  }
}

const SupportSearchForm = reduxForm<IFormData, IProps>({
  form: 'supportSearch',
})(SupportSearch);

const mapStateToProps = (
  state: IReduxState
): IStateProps & ConfigProps<IFormData, IProps> => {
  const authorizer = new Authorizer(state);
  const displaySwitches = authorizer.supportLogsTopSwitches();
  const switches = displaySwitches
    ? {
        includeAllTenants: displaySwitches,
        includeDebug: displaySwitches,
        includeError: displaySwitches,
      }
    : {};
  return {
    fetchCategoriesResult: state.support.fetchCategoriesResult,
    fetchInterfacesResult: state.interface.searchResult,
    supportLogsTopSwitches: displaySwitches,
    searchResult: state.support.searchResult,
    downloadResult: state.support.downloadResult,
    initialValues: {
      startDate: new Date(new Date().getTime() - 5 * 60 * 1000).toISOString(),
      ...switches,
    },
    form: 'supportSearch',
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchCategories: () => dispatch(fetchCategories()),
  fetchInterfaces: () => dispatch(searchInterface()),
  searchLogs: (searchQuery: ILogSearchQuery) =>
    dispatch(searchLogs(searchQuery)),
  exportLogs: (searchQuery: ILogSearchQuery) =>
    dispatch(exportLogs(searchQuery)),
  resetForm: () => dispatch(reset('supportSearch')),
  clearLogs: () => dispatch(clear()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SupportSearchForm);
