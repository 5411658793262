import { Errors, IError } from 'utils/errors';
import { IActionResult, ISpoQueryError } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import SharePointErrorsEndpoint from 'endpoints/sharePointErrorsEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISharePointErrorsState {
  sharePointErrorsResult: IActionResult<ISpoQueryError[]>;
}

const sharePointErrorsSlice = createSlice({
  name: 'sharePointErrors',
  initialState: {
    sharePointErrorsResult: {
      processing: false,
    },
  } as ISharePointErrorsState,
  reducers: {
    fetchingErrors(state) {
      state.sharePointErrorsResult = { processing: true };
    },
    fetchedErrors(state, action: PayloadAction<ISpoQueryError[]>) {
      state.sharePointErrorsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchErrorsFailed(state, action: PayloadAction<IError>) {
      state.sharePointErrorsResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchSharePointErrors = (interfaceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SharePointErrorsEndpoint();
  dispatch(fetchingErrors());
  try {
    const response = await endpoint.getErrors(interfaceId);
    dispatch(fetchedErrors(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchErrorsFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = sharePointErrorsSlice;
export const { fetchErrorsFailed, fetchedErrors, fetchingErrors } = actions;
export default reducer;
