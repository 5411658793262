import * as React from 'react';

import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';

import { AnyAction } from '@reduxjs/toolkit';
import CopyButton from 'components/common/copyButton';
import { Endpoint } from 'endpoints/base/endpoint';
import { IInterfaceConfigured } from 'types';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { regenerateApiKey } from 'slices/accountSlice';

interface IParams {
  bapiInterface: IInterfaceConfigured;
}

interface IOwnProps {
  data: string;
  params: IParams;
}

interface IDispatchProps {
  regenerateApiKey: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class Instructions extends React.Component<IProps> {
  public render() {
    const { data, params, regenerateApiKey } = this.props;
    return (
      <>
        <h2 className="mt-4">Using your Basic API Interface</h2>
        <Row className="mt-3">
          <Col>
            <h5>
              <strong className="text-muted">Step 1</strong> - Authentication
            </h5>
            <Row>
              <Col>
                <p>
                  All communication with your Basic API interface is performed
                  via SSL and must include an authentication token. To generate
                  a token follow these steps:
                </p>
                <ol>
                  <li>
                    <strong>Generate an API Key</strong>
                    {data ? (
                      <p>
                        <span className="text-success">Good news!</span> You
                        already have an API key: <u>{data}</u>
                        <CopyButton
                          text={data}
                          size="sm"
                          iconSize="0.8rem"
                          className="py-0 px-1 ml-1"
                        />
                      </p>
                    ) : (
                      <>
                        <p>
                          It looks like you haven't generated an API key yet for
                          your user account.
                          <br />
                          API keys are used for accessing the interface because,
                          unlike passwords, they are designed not to be changed
                          frequently. Also, since they are randomly generated
                          complex keys they are much more difficult to guess
                          than passwords. These features make them ideal for
                          including in scripts.
                        </p>
                        <p>
                          <Button variant="success" onClick={regenerateApiKey}>
                            Generate an API Key
                          </Button>
                        </p>
                      </>
                    )}
                    <p>
                      You can generate an API key by clicking on your username
                      in the upper right corner and selecting <b>Account</b> and
                      then clicking <b>Re-Generate</b>
                    </p>
                  </li>
                  <li>
                    <strong>Generate a token</strong>
                    <p>
                      Tokens are used for all requests in a session and are
                      valid for up to 14 days. You can generate a new token by
                      submitting a POST request to the token service at:{' '}
                      <u>{`${Endpoint.backendUrl}/token/`}</u>
                    </p>
                    <p>
                      The request should have a content type of{' '}
                      <u>application/x-www-form-urlencoded</u> and should
                      contain the values:
                    </p>
                    <ul>
                      <li>
                        <b>grant_type</b>: apikey
                      </li>
                      <li>
                        <b>key</b>: {data}
                      </li>
                    </ul>
                  </li>
                </ol>
              </Col>
              <Col>
                <Tabs
                  defaultActiveKey="powershell"
                  id="authentication-code-tabs"
                  className="text-uppercase small"
                >
                  <Tab eventKey="powershell" title="PowerShell">
                    <p className="mt-2">
                      <em>
                        An example of creating a token request using PowerShell
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`$baseUrl="${Endpoint.backendUrl}" 
$apiKey="${data}"
$tokenRequest=@{
  grant_type = "apikey";
  key = $apiKey
}
$tokenResponse=Invoke-RestMethod -Method Post -ContentType "application/x-www-form-urlencoded" -Uri (New-Object System.Uri($baseUrl + "/token")) -Body $tokenRequest
$accessToken=$tokenResponse.access_token`}
                      </code>
                    </pre>
                  </Tab>
                  <Tab eventKey="cSharp" title="C#">
                    <p className="mt-2">
                      <em>
                        An example of creating a token request using C#. This
                        example requires that you add the Json.NET framework
                        from Newtonsoft to your project.
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`using System;
using System.Net.Http;
using System.Collections.Generic;
using System.Threading.Tasks;
using Newtonsoft.Json.Linq;

namespace Semaphore.CloudAPI.Examples
{
  class Program
  {
    static void Main(string[] args)
    {
      var tokenObject = Task.Run(async () =>
      {
        using (var client = new HttpClient())
        {
          var baseUrl = "${Endpoint.backendUrl}";
          var requestUrl = baseUrl + "/token";
          var apiKey = "${data}";
          var values = new Dictionary<string, string>
          {
            { "grant_type", "apikey" },
            { "key", apiKey }
          };
          var content = new FormUrlEncodedContent(values);
          var response = await client.PostAsync(requestUrl, content);

          var responseString = await response.Content.ReadAsStringAsync();

          return responseString;
        }
      });

        var token = JObject.Parse(tokenObject.Result);
        Console.WriteLine(token["access_token"]);
    }
  }
}`}
                      </code>
                    </pre>
                  </Tab>
                  <Tab eventKey="java" title="Java">
                    <p className="mt-2">
                      <em>
                        An example of creating a token request using Java. This
                        example requires that you add a dependency to your
                        project for the Semaphore Cloud Client. Additional
                        information can be found here:
                        https://search.maven.org/#search%7Cga%7C1%7Ccom.smartlogic.cloud
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`<dependency>
  <groupId>com.smartlogic.cloud</groupId>
  <artifactId>Semaphore-Cloud-Client</artifactId>
  <version>Semaphore-4.0.45</version>
</dependency>`}
                      </code>
                    </pre>
                    <p>
                      <em>
                        All Java examples require that you also create the
                        following configuration class in your project
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`public class CloudConfiguration {
  public final static String baseURL = "${Endpoint.backendUrl}";
  public final static String tokenRequestURL = baseURL + "/token";

  public final static String key = "${data}";
  ${
    params.bapiInterface.selectedSesServerId
      ? `public final static String sesURL =  "${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/SES/";`
      : ''
  }
  ${
    params.bapiInterface.selectedCsServerId
      ? `public final static String csURL =  "${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/";`
      : ''
  }
}`}
                      </code>
                    </pre>
                    <p>
                      <em>Then create the token request class</em>
                    </p>
                    <pre>
                      <code>
                        {`import java.io.IOException;
import java.security.KeyManagementException;
import java.security.KeyStoreException;
import java.security.NoSuchAlgorithmException;

import org.apache.http.client.ClientProtocolException;

import com.smartlogic.cloud.TokenFetcher;
import com.smartlogic.cloud.Token;
import com.smartlogic.cloud.CloudException;

public class CloudFetchToken {
  public static void main(String[] args) throws ClientProtocolException, IOException, CloudException, KeyManagementException, NoSuchAlgorithmException, KeyStoreException { 
    TokenFetcher tokenFetcher = new TokenFetcher(CloudConfiguration.tokenRequestURL, CloudConfiguration.key);
    Token token = tokenFetcher.getAccessToken();

    System.out.println(token.getAccess_token());
  }
}`}
                      </code>
                    </pre>
                  </Tab>
                  <Tab eventKey="curl" title="cURL">
                    <p className="mt-2">
                      <em>
                        An example of generating a token request using the
                        "cURL" utility:
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`curl -d "grant_type=apiKey&key=${data}" ${Endpoint.backendUrl}/token`}
                      </code>
                    </pre>
                    <p className="mt-2">
                      <em>
                        This returns JSON from which the "access_token" can be
                        extracted which is used for subsequent API calls.
                      </em>
                    </p>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h5>
              <strong className="text-muted">Step 2</strong> - Sign requests
            </h5>
            <p>
              Using the token generated above you should sign all requests by
              adding an authorization header with the value:
            </p>
            <blockquote>
              <b>bearer </b>
              <i>
                the_very_long_access_token_that_you_generated_for_this_session
              </i>
            </blockquote>
            <Tabs
              defaultActiveKey="powershell"
              id="sign-code-tabs"
              className="text-uppercase small"
            >
              <Tab eventKey="powershell" title="PowerShell">
                {params.bapiInterface.selectedSesServerId ? (
                  <>
                    <p className="mt-2">
                      <em>
                        An example of requesting a list of models from SES using
                        PowerShell
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`$baseUrl="${Endpoint.backendUrl}"
$sesUrl="${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/SES/"
#============================  
#Step 1 - get an access token
#============================  
$apiKey="${data}"
$tokenRequest=@{
  grant_type = "apikey";
  key = $apiKey
}
$tokenResponse=Invoke-RestMethod -Method Post -ContentType "application/x-www-form-urlencoded" -Uri (New-Object System.Uri($baseUrl + "/token")) -Body $tokenRequest
$accessToken=$tokenResponse.access_token
#=================================================================  
#Step 2 - submit request for a list of models in JSON format
#=================================================================  
$header=@{"Authorization" = "bearer " + $accessToken}
$jsonResponse=Invoke-RestMethod -Method Get -Uri (new-Object System.Uri($sesUrl + "/modelslist.json")) -Headers $header
            
#==========================================
#Finish - examine the response as an object
#==========================================
$jsonResponse.models`}
                      </code>
                    </pre>
                    <hr />
                  </>
                ) : null}
                {params.bapiInterface.selectedCsServerId ? (
                  <>
                    <p className="mt-2">
                      <em>
                        An example of classifying a document using PowerShell
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`$baseUrl="${Endpoint.backendUrl}"
$csUrl="${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/"
#============================  
#Step 1 - get an access token
#============================            
$apiKey="${data}"
$tokenRequest=@{
  grant_type = "apikey";
  key = $apiKey
}
$tokenResponse=Invoke-RestMethod -Method Post -ContentType "application/x-www-form-urlencoded" -Uri (New-Object System.Uri($baseUrl + "/token")) -Body $tokenRequest
$accessToken=$tokenResponse.access_token
#==========================================
#Step 2 - specify classification parameters
#==========================================
$request="<?xml version=\`"1.0\`" ?>
          <request op=\`"CLASSIFY\`">
          <document>
          <body type=\`"TEXT\`" />
          <path></path>
          <multiarticle />
          <language>Default</language>
          <threshold>48</threshold>
            </document>
          </request>"
#======================================
#Step 3 - select a document to classify
#======================================
[System.Reflection.Assembly]::LoadWithPartialName("System.windows.forms") | Out-Null
    
$OpenFileDialog = New-Object System.Windows.Forms.OpenFileDialog
$OpenFileDialog.ShowDialog() | Out-Null
$filename=$OpenFileDialog.filename
$fileBin = [IO.File]::ReadAllBytes($filename)
$enc = [System.Text.Encoding]::GetEncoding("iso-8859-1")
$fileEnc = $enc.GetString($fileBin)
            
#=========================================
#Step 4 - create a multi-part form message
#=========================================
$boundary = [System.Guid]::NewGuid().ToString() 
	           
$LF = "\`r\`n"
$bodyLines = (
  "--$boundary",        
  "Content-Disposition: form-data; name=\`"XML_INPUT\`"$LF",
  $request,
  "--$boundary",
  "Content-Disposition: form-data; name=\`"UploadFile\`"; filename=\`"$filename\`"$LF",       
  $fileEnc,        
  "--$boundary--$LF"
) -join $LF
            
#============================================================
#Step 5 - send the message including the authorization header
#============================================================            
$header=@{"Authorization" = "bearer " + $accessToken}
[xml]$xmlResponse=Invoke-RestMethod -Method POST -Uri (new-Object System.Uri($csUrl)) -contentType "multipart/form-data; boundary=$boundary" -Headers $header -Body  $bodyLines
#===============================================
#Finish - examine the results as an XML document
#===============================================
$xmlResponse.response.STRUCTUREDDOCUMENT.META`}
                      </code>
                    </pre>
                  </>
                ) : null}
              </Tab>
              <Tab eventKey="cSharp" title="C#">
                {params.bapiInterface.selectedSesServerId ? (
                  <>
                    <p className="mt-2">
                      <em>
                        An example of requesting all Concept Schemes from SES
                        using C#. For this example, you will need to download
                        the Smartlogic Semaphore.NET Client API from Github and
                        add it to your project,
                        https://github.com/Smartlogic-Semaphore-Limited/dotnet-client-api
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`using System;
using Smartlogic.Semaphore.Api;
using System.Net;
using System.IO;
		 
namespace SemanticEnhancementServerAPI
{
  class Program
  {
    static void Main(string[] args)
    {
      var sesUrl = "${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/SES/"; 
      var _apiKey="${data}" ;
      var sesIndex="<Your SES Index Name (ex. disp_taxonomy)>" ;
      ILogger logger=new DefaultTraceLogger();
      var client=new SemanticEnhancement(120,new Uri(sesUrl),logger,_apiKey);
      var results=client.GetJsonRootTerms(sesIndex, "" , "" );
      Console.WriteLine(results.ToJsonString());
      Console.ReadKey();
    }
  }
}`}
                      </code>
                    </pre>
                    <hr />
                  </>
                ) : null}
                {params.bapiInterface.selectedCsServerId ? (
                  <>
                    <p className="mt-2">
                      <em>
                        An example of classifying a document using C#. You will
                        need to download the Smartlogic Semaphore.NET Client API
                        from Github and add it to your project,
                        https://github.com/Smartlogic-Semaphore-Limited/dotnet-client-api
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`using System;
using System.Collections.Generic;
using Smartlogic.Semaphore.Api;
using System.Net;
using System.IO;
		 
namespace ClassificationServerAPI
{
  class Program
  {
    static void Main(string[] args)
    {
      var csUrl = "${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/";
      var _apiKey = "${data}";
      ILogger logger = new DefaultTraceLogger();
      
      var client = new ClassificationServer(120,new Uri(csUrl),logger,_apiKey);
      byte[] data = null;
      var title = "My File";
      var doc = File.ReadAllBytes(@"C:\\path\\to\\somedocument.pdf");
      var options = new ClassificationOptions
      {
        Threshold = 60
      };
      var metadata = new Dictionary<string, string>();
      var result = client.Classify(title, doc, "filename.pdf", metadata, "", options);
      
      var classifications = result.GetClassifications();
      
      Console.WriteLine("Results:-");
      foreach (var item in classifications)
      {
        Console.WriteLine($"{item.ClassName}    {item.Id}   {item.Score}    {item.Value}");
      }
      
      Console.ReadKey();
    }
  }
}`}
                      </code>
                    </pre>
                  </>
                ) : null}
              </Tab>
              <Tab eventKey="java" title="Java">
                <p className="mt-2">
                  <em>
                    All examples require that you add a dependency to your
                    project for the Semaphore Cloud Client.
                  </em>
                </p>
                <pre>
                  <code>
                    {`<dependency>
  <groupId>com.smartlogic.cloud</groupId>
  <artifactId>Semaphore-Cloud-Client</artifactId>
  <version>Semaphore-4.0.45</version>
</dependency>`}
                  </code>
                </pre>
                <p className="mt-2">
                  <em>
                    All Java examples require that you also create the following
                    configuration class in your project
                  </em>
                </p>
                <pre>
                  <code>
                    {`public class CloudConfiguration {
  public final static String baseURL = "${Endpoint.backendUrl}";
  public final static String tokenRequestURL = baseURL + "/token";

  public final static String key = "${data}";
  ${
    params.bapiInterface.selectedSesServerId
      ? `public final static String sesURL =  "${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/SES/";`
      : ''
  }
  ${
    params.bapiInterface.selectedCsServerId
      ? `public final static String csURL =  "${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/";`
      : ''
  }
}`}
                  </code>
                </pre>
                {params.bapiInterface.selectedSesServerId ? (
                  <>
                    <hr />
                    <p className="mt-2">
                      <em>
                        An example of requesting all terms from SES using Java.
                        This example requires that you add a dependency to your
                        project for the Semaphore Cloud Client. Additional
                        information can be found here,
                        https://search.maven.org/#search%7Cga%7C1%7Ccom.smartlogic.cloud
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`import java.io.IOException;
import java.security.KeyManagementException;
import java.security.KeyStoreException;
import java.security.NoSuchAlgorithmException;
import java.util.Map;
 
import org.apache.http.client.ClientProtocolException;

import com.smartlogic.cloud.TokenFetcher;
import com.smartlogic.cloud.Token;
import com.smartlogic.cloud.CloudException;
import com.smartlogic.ses.client.SESClient;
import com.smartlogic.ses.client.Term;
import com.smartlogic.ses.client.exceptions.SESException;

public class CloudSemanticEnhancement {
  public static void main(String[] args) throws SESException, KeyManagementException, ClientProtocolException, NoSuchAlgorithmException, KeyStoreException, IOException, CloudException {
    TokenFetcher tokenFetcher = new TokenFetcher(CloudConfiguration.tokenRequestURL, CloudConfiguration.key);
    Token token = tokenFetcher.getAccessToken();

    System.out.println(token.getAccess_token());

    SESClient sesClient = new SESClient();
    sesClient.setUrl(CloudConfiguration.sesURL);
    sesClient.setApiToken(token.getAccess_token());
    sesClient.setOntology("Your SES Index Name (ex. disp_taxonomy)");

    Map<String, Term> allTerms = sesClient.getAllTerms();
    for (Term term: allTerms.values()) {
        System.out.println(term.getId() + ":" + term.getName());
    }
  }
}`}
                      </code>
                    </pre>
                  </>
                ) : null}
                {params.bapiInterface.selectedCsServerId ? (
                  <>
                    <hr />
                    <p className="mt-2">
                      <em>
                        An example of classifying a document using Java. This
                        example requires that you add a dependency to your
                        project for the Semaphore Cloud Client. Additional
                        information can be found here,
                        https://search.maven.org/#search%7Cga%7C1%7Ccom.smartlogic.cloud
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`import java.io.File;
import java.io.IOException;
import java.security.KeyManagementException;
import java.security.KeyStoreException;
import java.security.NoSuchAlgorithmException;
import java.util.Collection;
import java.util.Map.Entry;

import org.apache.http.client.ClientProtocolException;

import com.smartlogic.cloud.TokenFetcher;
import com.smartlogic.cloud.Token;
import com.smartlogic.cloud.CloudException;
import com.smartlogic.classificationserver.client.ClassificationClient;
import com.smartlogic.classificationserver.client.ClassificationConfiguration;
import com.smartlogic.classificationserver.client.ClassificationException;
import com.smartlogic.classificationserver.client.ClassificationScore;
import com.smartlogic.classificationserver.client.Result;

public class CloudClassification {
  public static void main(String[] args) throws ClientProtocolException, IOException, CloudException, ClassificationException, KeyManagementException, NoSuchAlgorithmException, KeyStoreException {
    TokenFetcher tokenFetcher = new TokenFetcher(CloudConfiguration.tokenRequestURL, CloudConfiguration.key);
    Token token = tokenFetcher.getAccessToken();

    System.out.println(token.getAccess_token());

    ClassificationClient classificationClient = new ClassificationClient();
    ClassificationConfiguration classificationConfiguration = new ClassificationConfiguration();
    classificationConfiguration.setUrl(CloudConfiguration.csURL);
    classificationConfiguration.setApiToken(token.getAccess_token());

    classificationClient.setClassificationConfiguration(classificationConfiguration);
    Result result = classificationClient.getClassifiedDocument(new File("/path/to/some/file.pdf"), null);

    for (Entry<String, Collection<ClassificationScore>> entry: result.getAllClassifications().entrySet()) {
      System.out.println(entry.getKey() + ":");
      for (ClassificationScore classificationScore: entry.getValue()) {
        System.out.println(String.format(" %s %s %s %f",
          classificationScore.getRulebaseClass(),
          classificationScore.getId(),
          classificationScore.getName(),
          classificationScore.getScore()
          ));
      }
    }
  }
}`}
                      </code>
                    </pre>
                  </>
                ) : null}
              </Tab>
              <Tab eventKey="curl" title="cURL">
                {params.bapiInterface.selectedSesServerId ? (
                  <>
                    <p className="mt-2">
                      <em>
                        An example of using "curl" to retrieve a list of models
                        from SES. First, retrieve the session token:
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`curl -d "grant_type=apiKey&key=${data}" ${Endpoint.backendUrl}/token`}
                      </code>
                    </pre>
                    <p className="mt-2">
                      <em>
                        Then, take the "access_token" from the JSON returned and
                        paste it into the following "curl" command:
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`curl -H "Authorization: bearer <PASTEACCESS_TOKENVALUEHERE>" ${Endpoint.backendUrl}/svc/${params.bapiInterface.id}/SES/modelslist.json`}
                      </code>
                    </pre>
                    <p className="mt-2">
                      <em>
                        This returns JSON listing the models published to SES.
                      </em>
                    </p>
                    <hr />
                  </>
                ) : null}
                {params.bapiInterface.selectedCsServerId ? (
                  <>
                    <p className="mt-2">
                      <em>
                        An example of using "curl" to classify some text. First,
                        retrieve the session token:
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`curl -d "grant_type=apiKey&key=${data}" ${Endpoint.backendUrl}/token`}
                      </code>
                    </pre>
                    <p className="mt-2">
                      <em>
                        Then, take the "access_token" from the JSON returned and
                        paste it into the following "curl" command along with
                        the text you wish to classify:
                      </em>
                    </p>
                    <pre>
                      <code>
                        {`curl -H "Authorization: bearer <PASTEACCESS_TOKENVALUEHERE>" -d body="<PASTETEXTHERE>" ${Endpoint.backendUrl}/svc/${params.bapiInterface.id}`}
                      </code>
                    </pre>
                    <p className="mt-2">
                      <em>
                        This returns XML containing the classification results.
                      </em>
                    </p>
                  </>
                ) : null}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  regenerateApiKey: () => dispatch(regenerateApiKey()),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(Instructions);
