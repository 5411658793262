import * as React from 'react';

import { AnyAction } from 'redux';
import { Button } from 'react-bootstrap';
import ClipboardCheckOutlineIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IReduxState } from '../../../reducers';
import { ModalContents } from 'types';
import RecycleIcon from 'mdi-react/RecycleIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IState {
  copied: boolean;
  animateHide: boolean;
}

interface IOwnProps {
  data: string;
}

interface IDispatchProps {
  openRegenerateApiKeyModal: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ManageApiKey extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      copied: false,
      animateHide: false,
    };
  }

  public render() {
    const { data, openRegenerateApiKeyModal } = this.props;

    const copyToClipboard = data ? (
      <CopyToClipboard
        text={data}
        onCopy={() => {
          this.setState({ copied: true });
          setTimeout(() => {
            this.setState({ animateHide: false });
          }, 2000);
          setTimeout(() => {
            this.setState({ copied: false });
            this.setState({ animateHide: false });
          }, 1000);
        }}
      >
        <ClipboardCheckOutlineIcon size="1em" className="ml-1" />
      </CopyToClipboard>
    ) : null;
    return (
      <div>
        <h5>
          API Key:
          <Button
            className="float-right pt-0"
            variant="link"
            as="a"
            onClick={openRegenerateApiKeyModal}
            title="Re-generate"
          >
            <RecycleIcon size="1.5em" className="mr-1" />
            Re-generate Api Key
          </Button>
        </h5>
        <p>
          <span className="position-relative">
            <code>{data ? data : '<Not Set>'}</code>
            {this.state.copied ? (
              <span
                className={
                  this.state.animateHide
                    ? 'hide-animated '
                    : '' +
                      'copy-notify position-absolute bg-success text-xs inline text-center'
                }
              >
                Copied
              </span>
            ) : null}
            {copyToClipboard}
          </span>
        </p>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openRegenerateApiKeyModal: () =>
    dispatch(
      openModal('Re-generate Api Key', ModalContents.RegenerateApiKey, {
        position: 'right',
      })
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ManageApiKey);
