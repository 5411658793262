import * as React from 'react';

import { Col, ListGroup } from 'react-bootstrap';
import { IInterfaceConfigured, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Endpoint } from 'endpoints/base/endpoint';
import FileDocumentBoxOutlineIcon from 'mdi-react/FileDocumentBoxOutlineIcon';
import HelpIcon from 'mdi-react/HelpIcon';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  bapiInterface: IInterfaceConfigured;
}

interface IDispatchProps {
  openBapiHelpModal: (interfaceId: string) => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class BapiOptions extends React.Component<IProps> {
  public render() {
    const { bapiInterface } = this.props;
    return (
      <Col md={6} lg={4}>
        <h5 className="font-weight-bold">Basic API Options:</h5>
        <ListGroup className="shadow mb-2">
          <ListGroup.Item
            action
            disabled={bapiInterface.selectedCsServerId == null}
            href={`${Endpoint.backendUrl}/svc/${bapiInterface.id}/cat/index.html`}
          >
            <FileDocumentBoxOutlineIcon size="1rem" className="mr-3" />
            Test Classification
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={this.openBapiHelpModal(bapiInterface.id!)}
          >
            <HelpIcon size="1rem" className="mr-3" />
            Help
          </ListGroup.Item>
        </ListGroup>
      </Col>
    );
  }

  private openBapiHelpModal = (interfaceId: string) => () => {
    const { openBapiHelpModal } = this.props;
    openBapiHelpModal(interfaceId);
  };
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openBapiHelpModal: (interfaceId: string) =>
    dispatch(
      openModal(
        'Basic API Information',
        ModalContents.BapiHelp,
        { position: 'right', size: 'xl' },
        interfaceId
      )
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(BapiOptions);
