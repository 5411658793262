import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { IServiceGroup, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import PlusIcon from 'mdi-react/PlusIcon';
import ServiceGroup from './serviceGroup';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deselectServiceGroup } from 'slices/serviceGroupSlice';
import { fetchAllNetworks } from 'slices/subnetSlice';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: IServiceGroup[];
}

interface IDispatchProps {
  fetchAllNetworks: () => void;
  openCreateModal: () => void;
  deselect: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ServiceGroups extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.create = this.create.bind(this);
    this.deselect = this.deselect.bind(this);
  }

  public componentDidMount() {
    const { fetchAllNetworks } = this.props;
    fetchAllNetworks();
  }

  public render() {
    const { data } = this.props;
    const serviceGroups = data.map((serviceGroup, index) => (
      <ServiceGroup serviceGroup={serviceGroup} key={index} />
    ));
    return (
      <Col
        xs="12"
        md="2"
        as="aside"
        className="bg-white border-right"
        onClick={this.deselect}
      >
        <Row>
          <Col
            as="header"
            className="bg-light border-bottom d-flex align-items-center justify-content-between"
          >
            <p className="mt-3">
              <strong>Service groups</strong>
            </p>
            <Button
              size="sm"
              variant="success"
              onClick={this.create}
              className="p-0"
            >
              <PlusIcon size="1.4rem" />
            </Button>
          </Col>
        </Row>
        <Row>{serviceGroups}</Row>
      </Col>
    );
  }

  private create() {
    const { openCreateModal } = this.props;
    openCreateModal();
  }

  private deselect() {
    const { deselect } = this.props;
    deselect();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchAllNetworks: () => dispatch(fetchAllNetworks()),
  openCreateModal: () =>
    dispatch(
      openModal('New Service Group', ModalContents.CreateServiceGroup, {
        position: 'right',
        size: 'lg',
      })
    ),
  deselect: () => dispatch(deselectServiceGroup()),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ServiceGroups);
