import * as React from 'react';

import { Form } from 'react-bootstrap';
import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';

const checkBoxComponent = ({
  input,
  id,
  label,
  tooltip,
  type,
  meta: { touched, error, warning },
  className,
  ...other
}: {
  input: any;
  id: string;
  label: string;
  tooltip: string;
  type: string;
  meta: any;
  className: string;
}) => {
  const help =
    tooltip !== undefined ? (
      <span title={tooltip}>
        <HelpOutlineIcon size="1rem" />
      </span>
    ) : null;
  const fullLabel = (
    <>
      {label} {help}
    </>
  );
  return (
    <Form.Group controlId={id} className={className}>
      <Form.Check
        {...input}
        checked={input.value}
        placeholder={label}
        type={type}
        label={fullLabel}
        {...other}
      />
      {touched &&
        ((error && <Form.Text className="text-danger">{error}</Form.Text>) ||
          (warning && (
            <Form.Text className="text-warning">{warning}</Form.Text>
          )))}
    </Form.Group>
  );
};

export default checkBoxComponent;
