import { Errors, IError } from 'utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { IActionResult } from 'types';
import SharePointSubscriptionEndpoint from '../endpoints/sharePointSubscriptionEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISpoSubscriptionState {
  spoSubscriptionsResult: IActionResult<string[]>;
}

const spoSubscriptionSlice = createSlice({
  name: 'spoSubscription',
  initialState: {
    spoSubscriptionsResult: {
      processing: false,
    },
  } as ISpoSubscriptionState,
  reducers: {
    fetchingSpoSubscriptions(state) {
      state.spoSubscriptionsResult = { processing: true };
    },
    fetchedSpoSubscriptions(state, action: PayloadAction<string[]>) {
      state.spoSubscriptionsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchSpoSubscriptionsFailed(state, action: PayloadAction<IError>) {
      state.spoSubscriptionsResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchSpoSubscriptions = (interfaceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SharePointSubscriptionEndpoint();
  dispatch(fetchingSpoSubscriptions());
  try {
    const response = await endpoint.getSpoSubscriptions(interfaceId);
    dispatch(fetchedSpoSubscriptions(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchSpoSubscriptionsFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = spoSubscriptionSlice;
export const {
  fetchSpoSubscriptionsFailed,
  fetchedSpoSubscriptions,
  fetchingSpoSubscriptions,
} = actions;
export default reducer;
