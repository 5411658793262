import * as React from 'react';

import { IActionResult, IServiceViewModel } from 'types';

import { AnyAction } from 'redux';
import DeleteServiceExtras from './deleteServiceExtras';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteService } from 'slices/serviceSlice';

interface IState {
  submitEnabled: boolean;
}

interface IOwnProps {
  service: IServiceViewModel;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteService: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteServiceModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
    this.setSubmitEnabled = this.setSubmitEnabled.bind(this);
    this.state = { submitEnabled: false };
  }

  public render() {
    const { deleteResult, service } = this.props;
    return (
      <>
        <Modal.Body>
          Are you sure you want to delete <strong>"{service.name}"</strong>?
          <DeleteServiceExtras
            service={service}
            setSubmitEnabled={this.setSubmitEnabled}
          />
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
          onSubmit={this.delete}
          disabled={!this.state.submitEnabled}
        />
      </>
    );
  }

  private setSubmitEnabled(value: boolean) {
    this.setState({ submitEnabled: value });
  }

  private delete() {
    const { deleteService } = this.props;
    deleteService();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  deleteResult: state.service.deleteResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteService: () => dispatch(deleteService(ownProps.service.serverId!)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteServiceModal);
