import * as React from 'react';

import Authorizer from 'utils/authorizer';
import Error from './error';
import { IReduxState } from 'reducers';
import { connect } from 'react-redux';

interface IOwnProps {
  requiredRoles: string[];
  children: React.ReactNode;
  showAccessError?: boolean;
}

interface IStateProps {
  isInRoles: (requiredRoles: string[]) => boolean;
}

interface IProps extends IOwnProps, IStateProps {}
export class RoleChecker extends React.Component<IProps> {
  public render() {
    const { requiredRoles, isInRoles, showAccessError } = this.props;
    return (
      <>
        {isInRoles(requiredRoles) ? (
          this.props.children
        ) : showAccessError ? (
          <Error
            error={{
              title: 'Permission denied.',
              subTitle: `Following roles are required to access this part of application: ${requiredRoles}`,
            }}
          ></Error>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    isInRoles: (roles: string[]) => authorizer.isInRoles(roles),
  };
};

export default connect<IStateProps, {}, IOwnProps, IReduxState>(
  mapStateToProps
)(RoleChecker);
