import * as React from 'react';

import { Field } from 'redux-form';
import { IInterfaceRow } from 'types';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IOwnProps {
  data: IInterfaceRow[];
}

interface IProps extends IOwnProps {}

class InterfaceSelect extends React.Component<IProps> {
  public render() {
    const { data: interfaces } = this.props;
    const options = interfaces.map((i, index) => (
      <option value={i.id} key={index}>
        {i.name}
      </option>
    ));
    return (
      <Field name="interface" label="Interface" component={selectComponent}>
        <option>Please select an interface</option>
        {options}
      </Field>
    );
  }
}

export default InterfaceSelect;
