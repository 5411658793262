import * as React from 'react';

import { IActionResult, ITenancyDetail } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteTenant } from 'slices/tenantSlice';

interface IOwnProps {
  tenant: ITenancyDetail;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteTenant: (id: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteTenantModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { tenant, deleteResult } = this.props;
    return (
      <>
        <Modal.Body>
          <p>
            Are you sure you want to delete "<strong>{tenant.name}</strong>"?
          </p>
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
          onSubmit={this.delete}
        />
      </>
    );
  }

  private delete() {
    const { tenant, deleteTenant } = this.props;
    deleteTenant(tenant.id);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    deleteResult: state.tenant.deleteResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  deleteTenant: (id: string) => dispatch(deleteTenant(id)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTenantModal);
