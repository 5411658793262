import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';
import { IActionResult, ITenancyDetail, ITenancyDomain } from 'types';

import { AnyAction } from 'redux';
import Authorizer from 'utils/authorizer';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import ManageDomainsForm from './manageDomainsForm';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import datePickerComponent from 'components/common/formComponents/datePickerComponent';
import { fetchAllDomains } from 'slices/domainSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import { updateTenant } from 'slices/tenantSlice';

interface IOwnProps {
  tenant: ITenancyDetail;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
  fetchAllResult: IActionResult<ITenancyDomain[]>;
  hasGlobalRole: boolean;
  canDeactivateTenancy: boolean;
  canViewDomains: boolean;
}

interface IDispatchProps {
  updateTenant: (tenant: ITenancyDetail) => void;
  submitForm: () => void;
  fetchAllDomains: () => void;
}

export interface IFormData {
  name: string;
  expiryDate: string;
  inactiveReason: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class EditTenantModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
  }

  public componentDidMount() {
    const { fetchAllDomains, canViewDomains } = this.props;
    if (canViewDomains) {
      fetchAllDomains();
    }
  }

  public render() {
    const {
      updateResult,
      fetchAllResult,
      handleSubmit,
      pristine,
      tenant,
      hasGlobalRole,
      canDeactivateTenancy,
      canViewDomains,
    } = this.props;
    const ActiveHeader = () => (
      <Modal.Header className="bg-success text-white">
        <span>
          <CheckCircleOutlineIcon size="1.5rem" className="mr-1" />
          Tenant active
        </span>
      </Modal.Header>
    );

    const InactiveHeader: React.FC<{ inactiveReason: string }> = ({
      inactiveReason,
    }) => (
      <Modal.Header className="bg-danger text-white">
        <span>
          <CloseCircleOutlineIcon className="mr-1" />
          Tenant inactive - {inactiveReason}
        </span>
      </Modal.Header>
    );

    return (
      <>
        {tenant.active ? (
          <ActiveHeader />
        ) : (
          <InactiveHeader inactiveReason={tenant.inactiveReason} />
        )}
        <Modal.Body>
          <Form onSubmit={handleSubmit(this.save)}>
            <Field
              name="name"
              label="Tenancy Name"
              component={inputComponent}
              type="text"
              placeholder="Please enter a tenant name"
              validate={formValidators.required}
              disabled={!hasGlobalRole}
            />
            <Field
              name="inactiveReason"
              label="Inactive Reason"
              component={inputComponent}
              type="text"
              placeholder="Please enter a description"
              disabled={!canDeactivateTenancy}
            />
            <Field
              name="expiryDate"
              label="Expiry Date"
              component={datePickerComponent}
              placeholder="Please specify an expiry date"
              disabled={!canDeactivateTenancy}
            />
          </Form>
          <hr />
          {canViewDomains ? (
            <Loader
              component={ManageDomainsForm}
              actionresult={fetchAllResult}
            />
          ) : null}
        </Modal.Body>
        <ModalFormFooter
          actionResult={updateResult}
          disabled={pristine}
          onSubmit={this.submit}
          noSubmit={!hasGlobalRole}
          cancelMessage={hasGlobalRole ? undefined : 'Close'}
        />
      </>
    );
  }

  private submit() {
    const { submitForm, hasGlobalRole } = this.props;
    if (hasGlobalRole) {
      submitForm();
    }
  }

  private save(data: IFormData) {
    const { updateTenant, tenant } = this.props;
    const updatedTenant = Object.assign({}, tenant, data);
    updateTenant(updatedTenant);
  }
}

const EditTenantModalForm = reduxForm<IFormData, IProps>({
  form: 'editTenant',
})(EditTenantModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const authorizer = new Authorizer(state);
  return {
    hasGlobalRole: authorizer.hasGlobalRole(),
    canDeactivateTenancy: authorizer.canDeactivateTenancy(),
    canViewDomains: authorizer.canViewDomains(),
    updateResult: state.tenant.updateResult,
    fetchAllResult: state.domain.fetchAllResult,
    initialValues: {
      name: ownProps.tenant.name,
      expiryDate:
        ownProps.tenant.expiryDate != null
          ? ownProps.tenant.expiryDate
          : undefined,
      inactiveReason: ownProps.tenant.inactiveReason,
    },
    form: 'editTenant',
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateTenant: (tenant: ITenancyDetail) => dispatch(updateTenant(tenant)),
  submitForm: () => dispatch(submit('editTenant')),
  fetchAllDomains: () => dispatch(fetchAllDomains()),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditTenantModalForm);
