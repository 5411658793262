import * as React from 'react';

import { Badge, Table } from 'react-bootstrap';

import { IInterfaceConfigured } from 'types';

interface IProps {
  data: IInterfaceConfigured;
}

export class InterfaceDetails extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const interfaceBadge =
      data.active === true ? (
        <Badge variant="success">Active</Badge>
      ) : (
        <Badge variant="danger">Not Active</Badge>
      );
    const Description = () =>
      data.description ? (
        <tr>
          <th className="w-50">Description</th>
          <td>{data.description}</td>
        </tr>
      ) : null;
    return (
      <>
        <h5 className="font-weight-bold">Interface Details:</h5>
        <Table hover size="sm">
          <tbody>
            <tr>
              <th className="w-50">Status</th>
              <td>{interfaceBadge}</td>
            </tr>
            <Description />
          </tbody>
        </Table>
      </>
    );
  }
}

export default InterfaceDetails;
