import { Errors, IError } from 'utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { IActionResult } from 'types';
import RulebaseEndpoint from 'endpoints/rulebaseEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IRulebaseState {
  availableRulebasesResult: IActionResult<string[]>;
}

const rulebaseSlice = createSlice({
  name: 'rulebase',
  initialState: {
    availableRulebasesResult: {
      processing: false,
    },
  } as IRulebaseState,
  reducers: {
    fetchingAvailable(state) {
      state.availableRulebasesResult = { processing: true };
    },
    fetchedAvailable(state, action: PayloadAction<string[]>) {
      state.availableRulebasesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchAvailableFailed(state, action: PayloadAction<IError>) {
      state.availableRulebasesResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchAvailableRulebases = (serverId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new RulebaseEndpoint();
  dispatch(fetchingAvailable());
  try {
    const response = await endpoint.getAvailableRulebases(serverId);
    dispatch(fetchedAvailable(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAvailableFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = rulebaseSlice;
export const {
  fetchAvailableFailed,
  fetchedAvailable,
  fetchingAvailable,
} = actions;
export default reducer;
