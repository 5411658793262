import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { IActionResult, ILogResult } from 'types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import ReactTable from 'react-table';
import { ThunkDispatch } from 'redux-thunk';
import { closeModal } from 'slices/modalSlice';
import { connect } from 'react-redux';
import { fetchCorrelatedLogs } from 'slices/supportSlice';
import moment from 'moment';

interface IStateProps {
  correlatedLogsResult: IActionResult<ILogResult[]>;
}

interface IOwnProps {
  correlationId: string;
}

interface IDispatchProps {
  fetchLogs: () => void;
  onClose: () => void;
}

interface IProps extends IDispatchProps, IStateProps, IOwnProps {}

export class CorrelatedLogsModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  public componentDidMount() {
    const { fetchLogs } = this.props;
    fetchLogs();
  }

  public render() {
    const { correlatedLogsResult } = this.props;
    const columns = [
      {
        id: 'logDate',
        Header: 'Date',
        accessor: (row: any) => row.logDate,
        Cell: (row: any) => moment(row.value).format('lll'),
        width: 150,
      },
      {
        Header: 'Message',
        accessor: 'message',
        Cell: (row: any) => {
          const popover = (
            <Popover id={'popover_' + row.index}>
              <Popover.Title>Message</Popover.Title>
              <Popover.Content>
                <div className="text-sm wordwrap">{row.value}</div>
              </Popover.Content>
            </Popover>
          );
          const overlay = (
            <OverlayTrigger
              placement={row.viewIndex < 5 ? 'bottom' : 'top'}
              overlay={popover}
              trigger={['hover', 'focus']}
            >
              <div className="truncate">{row.value}</div>
            </OverlayTrigger>
          );
          return overlay;
        },
      },
    ];
    return (
      <>
        <Modal.Body>
          <ReactTable
            data={correlatedLogsResult.data}
            loading={correlatedLogsResult.processing}
            columns={columns}
            style={{ height: 'calc(100vh - 7.75rem - 33px)' }}
            showPageSizeOptions={false}
            defaultPageSize={50}
            defaultSorted={[{ id: 'logDate', desc: true }]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }

  private closeModal() {
    const { onClose } = this.props;
    onClose();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  correlatedLogsResult: state.support.correlatedLogsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchLogs: () => dispatch(fetchCorrelatedLogs(ownProps.correlationId)),
  onClose: () => dispatch(closeModal()),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CorrelatedLogsModal);
