import { Errors, IError } from 'utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { IActionResult } from 'types';
import ReportingEndpoint from 'endpoints/reportingEndpoint';
import { closeModal } from './modalSlice';
import fileDownload from 'js-file-download';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IReportingState {
  downloadResult: IActionResult<boolean>;
}

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: {
    downloadResult: {
      processing: false,
    },
  } as IReportingState,
  reducers: {
    downloading(state) {
      state.downloadResult = { processing: true };
    },
    downloaded(state) {
      state.downloadResult = {
        processing: false,
        data: true,
      };
    },
    downloadFailed(state, action: PayloadAction<IError>) {
      state.downloadResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const downloadUsageCsvForPeriod = (
  interfaceId: string,
  startDate: string,
  endDate: string,
  interfaceType: 'bapi' | 'spo'
): AppThunk => async (dispatch) => {
  const endpoint = new ReportingEndpoint();
  dispatch(downloading());
  try {
    const response = await endpoint.getUsageCsvForPeriod(
      interfaceId,
      startDate,
      endDate,
      interfaceType
    );
    fileDownload(response.data, 'usage.csv');
    dispatch(downloaded());
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(downloadFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = reportingSlice;
export const { downloadFailed, downloaded, downloading } = actions;
export default reducer;
