import * as React from 'react';

import { Col, Dropdown } from 'react-bootstrap';
import { IServiceViewModel, ServerType } from 'types';
import { fetchAvailableVmValues, fetchCurrentVmSize } from 'slices/vmSlice';

import { AnyAction } from 'redux';
import { Card } from 'react-bootstrap';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import { IReduxState } from 'reducers';
import { ModalContents } from 'types';
import NameResolver from 'utils/nameResolver';
import ServiceButtons from './serviceButtons';
import StatusIndicator from './statusIndicator';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  service: IServiceViewModel;
}

interface IDispatchProps {
  openEditModal: () => void;
  openDeleteModal: () => void;
  fetchAvailableVmValues: () => void;
  fetchCurrentVmSize: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

const modalContents: { [key in ServerType]: ModalContents } = {
  [ServerType.AzureVirtualMachineService]: ModalContents.EditVm,
  [ServerType.ClassificationService]: ModalContents.EditCs,
  [ServerType.OntologyEditor]: ModalContents.EditOe,
  [ServerType.SemanticEnhancementService]: ModalContents.EditSes,
  [ServerType.TripleStore]: ModalContents.EditTs,
  [ServerType.UnmanagedHostService]: ModalContents.EditUh,
  [ServerType.Workbench]: ModalContents.EditWb,
  [ServerType.StudioService]: ModalContents.EditStudio,
};

export class Service extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { service } = this.props;
    const canBeRemoved =
      service.dependentUpon == null || service.dependentUpon.length === 0;
    const description = service.description ? (
      <span className="text-sm mb-1 d-block">
        <small className="text-uppercase">Description:</small>
        <br />
        {service.description}
      </span>
    ) : null;
    return (
      <Col lg="4" className="mt-3 mb-3">
        <Card className="shadow-sm h-100">
          <Card.Body>
            <span className="align-items-center d-flex justify-content-between">
              <h5 className="font-weight-bold mb-1 block">{service.name}</h5>
              <Dropdown alignRight className="float-right">
                <Dropdown.Toggle
                  bsPrefix="no-arrow"
                  variant="link"
                  title="Menu Options"
                  id="dropdown-service-group"
                >
                  <DotsVerticalIcon size="1.2rem" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="shadow-sm">
                  <Dropdown.Header className="text-uppercase">
                    Menu
                  </Dropdown.Header>
                  <ServiceButtons service={service} />
                  <Dropdown.Item onClick={this.edit}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={this.delete} disabled={!canBeRemoved}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
            {description}
            <span className="text-sm mb-1 d-block">
              <small className="text-uppercase">URL:</small>
              <br />
              {service.url}
            </span>
            <StatusIndicator serverId={service.serverId!} />
          </Card.Body>
        </Card>
      </Col>
    );
  }

  private edit() {
    const {
      openEditModal,
      service,
      fetchAvailableVmValues,
      fetchCurrentVmSize,
    } = this.props;
    switch (service.serverType) {
      case ServerType.AzureVirtualMachineService:
        fetchAvailableVmValues();
        fetchCurrentVmSize();
        break;
    }
    openEditModal();
  }

  private delete() {
    const { openDeleteModal } = this.props;
    openDeleteModal();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => {
  const nameResolver = new NameResolver();
  return {
    openEditModal: () =>
      dispatch(
        openModal(
          `Edit ${nameResolver.getServerTypeName(ownProps.service.serverType)}`,
          modalContents[ownProps.service.serverType],
          {
            position: 'right',
            size: 'lg',
          },
          ownProps.service
        )
      ),
    openDeleteModal: () =>
      dispatch(
        openModal(
          `Delete ${nameResolver.getServerTypeName(
            ownProps.service.serverType
          )}`,
          ModalContents.DeleteService,
          {
            position: 'right',
            size: 'lg',
          },
          ownProps.service
        )
      ),
    fetchAvailableVmValues: () =>
      dispatch(
        fetchAvailableVmValues(
          ownProps.service.groupId,
          ownProps.service.serverId
        )
      ),
    fetchCurrentVmSize: () =>
      dispatch(fetchCurrentVmSize(ownProps.service.serverId!)),
  };
};

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(Service);
