import { IError } from './utils/errors';

export interface ISemaphoreAppDetails {
  interfaceId: string;
  clientId: string;
  appRootUrl: string;
}

export enum LogLevel {
  Unknown = 0,
  Low = 1,
  Medium = 2,
  High = 4,
  Error = 8,
  Critical = 16,
  Statistics = 256,
}

export enum DeploymentStatus {
  None,
  Pending,
  InProgress,
  Completed,
  Error,
  Cancelled,
  Retraced,
}

export enum SequentialListClassificationState {
  Queued,
  InProgress,
  Completed,
  Paused,
  Canceled,
  Failed,
}

export enum ServerType {
  AzureVirtualMachineService,
  UnmanagedHostService,
  OntologyEditor,
  ClassificationService,
  SemanticEnhancementService,
  TripleStore,
  Workbench,
  StudioService,
}

export enum ModalContents {
  Empty,
  Iframe,
  CreateCs,
  EditCs,
  CreateVm,
  EditVm,
  CreateUh,
  EditUh,
  CreateOe,
  EditOe,
  CreateSes,
  EditSes,
  CreateTs,
  EditTs,
  CreteWb,
  EditWb,
  CreateStudio,
  EditStudio,
  DeleteService,
  ListColumnSettings,
  ContentTypeColumnSettings,
  Login,
  Register,
  CreateServiceGroup,
  EditServiceGroup,
  DeleteServiceGroup,
  EditUser,
  DeleteUser,
  EditTenant,
  CreateTenant,
  DeleteTenant,
  Log,
  Menu,
  Notification,
  EditContact,
  CreateContact,
  EditNote,
  CorrelatedLogs,
  UsageReport,
  EditTermStoreSyncJob,
  CreateTermStoreSyncJob,
  CreateBapiInterface,
  CreateSpoInterface,
  EditBapiInterface,
  EditSpoInterface,
  DeleteSpoInterface,
  DeleteBapiInterface,
  SyncRunDetails,
  SidePanel,
  DeleteSyncJob,
  DeploymentDetails,
  DeleteDeployment,
  CreatePassword,
  ChangePassword,
  RegenerateApiKey,
  RemoveExternalLogin,
  ResetPassword,
  ListClassificationDetails,
  BapiHelp,
  DeleteListSimConfig,
  SpoErrors,
  LanguagePacks,
}

export enum ModuleActivationState {
  NotActivated,
  NotAppropriate,
  Activated,
  FailedActivation,
}

export enum ScopeType {
  SpTenancy,
  SpWebApplication,
  SpSiteCollection,
  SpWeb,
  SpSite,
  SpContentType,
  SpLibrary,
  SpPage,
  SpControl,
}

export enum ClassificationMode {
  None,
  Assisted,
}

export enum CSArticleType {
  Default,
  SingleArticle,
  MultiArticle,
}

export enum CSBodyType {
  Unknown,
  Text,
  Html,
}

export enum CSClusteringType {
  RMS,
  ALL,
  AVERAGE,
  COMMON,
  NONE,
  AVERAGE_INCLUDING_EMPTY,
  COMMON_INCLUDING_EMPTY,
  RMS_INCLUDING_EMPTY,
}

export enum ResponseResult {
  Unkown = 0,
  Success = 1,
  Failure = 2,
  Aborted = 3,
}

export enum SyncStatus {
  Unknown = 0,
  Queued = 1,
  InProgress = 2,
  Completed = 3,
  Failed = 4,
  Cancelled = 5,
}

export enum ProvisioningStatus {
  Unknown,
  New = 1,
  CreationInProgress = 2,
  Created = 3,
  AwaitingDelete = 4,
  DeletionInProgress = 5,
  Deleted = 6,
  CreationFailed = 7,
  DeletionFailed = 8,
}

export enum ServerStatus {
  Unknown = 0,
  Online = 1,
  Offline = 2,
}

export enum ClassificationErrorType {
  Unknown,
  File,
  SpoConnection,
  CsConnection,
  Classifier,
}

export enum LibrarySimStatus {
  Unknown,
  Queued,
  Changing,
  Active,
  Inactive,
  Error,
}

export enum SpoQueryErrorType {
  Unknown,
  ItemMissing,
  WebMissing,
}

export enum SpoFieldGroup {
  Standard,
  InformationProtection,
}

export interface INotification {
  responseHtml: string;
  refreshInterval: number;
  deploymentStatus: number;
  uniqueName: string;
  lastModified: string;
  created: string;
  correlationId: string;
}

export interface IUsageViewData {
  period: string;
  usageBytes: number;
  usageCount: number;
}

export interface ISubnet {
  subnetId: string;
  networkName: string;
  regionName: string;
  subnetName: string;
  subnetCIDR: string;
  networkCIDR: string;
  canAddSubnet: boolean;
  sharedSubnet: boolean;
}

export interface IUsageViewModel {
  endDate: string;
  maxPeriodCount: number;
  maxPeriodUsage: number;
  startDate: string;
  totalCount: number;
  totalMegabytesUsed: number;
  usageData: IUsageViewData[];
}

export interface IAssociatedService {
  serverId: string;
  name?: string;
  description?: string;
  serverType: ServerType;
}

export interface IDiagnostics {
  modules: IPluginDetailItem[];
  serverTypes: IPluginDetailItem[];
  modelTypes: IPluginDetailItem[];
  interfaceTypes: IPluginDetailItem[];
}

export interface IPluginDetail {
  id: string;
  displayName: string;
  items: IPluginDetailItem[];
}

export interface IPluginDetailItem {
  id: string;
  assemblyName: string;
  name: string;
  routePrefix: string;
  version: string;
}

export interface IVersionType {
  major: number;
  minor: number;
  build: number;
  revision: number;
  majorRevision: number;
  minorRevision: number;
}

export interface ITenantContactDetail {
  id?: string;
  type: string;
  name: string;
  email: string;
}

export interface IStatusResponse {
  status: ServerStatus;
  version: string;
  statusMessage: string;
  provisioningStatus: ProvisioningStatus;
}

export interface IUserResult {
  confirmed: boolean;
  isAdmin: boolean;
  canBeDeleted: boolean;
  lastLogin: string;
  lockedOut: boolean;
  canDelete: boolean;
  displayName: string;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  id: string;
  lastName: string;
  roles: string[];
  tenantId: string;
  tenantName: string;
  userName: string;
  configuredProviders: IConfiguredProvider[];
  hasPassword: boolean;
  active: boolean;
  inactivityReason: string;
}

export interface IRoleResult {
  id: string;
  name: string;
  provider: string;
  canSelect: boolean;
}

export interface IRoleGroupResult {
  name: string;
  description: string;
}

export interface IAccountResult {
  canDelete: boolean;
  displayName: string;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  id: string;
  lastName: string;
  roles: string[];
  tenantId?: string;
  tenantName: string;
  userName: string;
  configuredProviders: IConfiguredProvider[];
  hasPassword: boolean;
}

export interface IConfiguredProvider {
  loginProvider: string;
  displayName: string;
  multiTenant: boolean;
  externalTenantClaimValue: string;
  providerKey: string;
  userName: string;
}

export interface IHeaders {
  key: string;
  value: string[];
}

export interface IPopupMessage {
  id: number;
  contents: string;
}

export interface IPasswordForm {
  oldPassword: string;
  newPassword: string;
}

export interface ISupportSearchResult {
  results: ILogResult[];
  continuationToken: any;
  resultType: number;
  message: string;
}

export interface IHistorySearchResult {
  results: ILogResult[];
  continuationToken: any;
}

export interface ITenancyDomain {
  id: string;
  name: string;
}

export interface ITenancyDetail {
  active: boolean;
  isSelected: boolean;
  isAvailable: boolean;
  id: string;
  name: string;
  inactiveReason: string;
  expiryDate?: string;
}

export interface ITobeDeleted {
  tenancy: ITenancyDetail;
  interfaces: IInterfaceRow[];
  services: IAssociatedService[];
  servicesGroup: IServiceGroup[];
}

export interface IInterfaceType {
  description: string;
  id: string;
  name: string;
  version: string;
}

export interface IInterfaceRow {
  active: boolean;
  description: string;
  id: string;
  interfaceType: IInterfaceType;
  name: string;
  namespace: string;
}
export interface IModelType {
  description: string;
  id: string;
  name: string;
  version: string;
  prefix: string;
}
export interface IModelRow {
  active: boolean;
  description: string;
  id: string;
  modelType: IModelType;
  name: string;
  namespace: string;
}

export interface IContinuationToken {
  nextPartitionKey?: string;
  nextRowKey?: string;
  targetLocation?: number;
  prevPartitionKey?: string;
  prevRowKey?: string;
}

export interface ILogResult {
  timestamp: Date;
  level: LogLevel;
  message: string;
  correlationId: string;
  tenantId: string;
  interfaceName: string;
  username: string;
  category: string;
  logDate: string;
  component: string;
  partition: string;
}

export interface IGlobalProvisioningStatus {
  ResponseHtml: string;
  RefreshInterval: number;
  DeploymentStatus: number;
  CanCancel: boolean;
}

export interface IMenuGroup {
  group: string;
  activator: IMenuItem;
  sections: IMenuSection[];
}

export interface IMenuSection {
  header: string;
  items: IMenuItem[];
}

export interface IFeed {
  description: string;
  title: string;
  link: string;
  items: IFeedItem[];
}

export interface IFeedItem {
  description: string;
  title: string;
  link: string;
  date: string;
}

export interface IDefaultTripleStoreValues {
  databaseName: string;
  username: string;
  password: string;
  backupStartTime: string;
  url: string;
  adminUrl: string;
}

export interface IDefaultAzureVmValues {
  serviceName: string;
  adminUsername: string;
  adminPassword: string;
  hostName: string;
  defaultRoleSize: any;
  defaultTemplate: string;
  vmOsPublisher: string;
  vmOsOffer: string;
  vmOsSku: string;
  vmCreateWithPlan: boolean;
}

export interface IAvailableAzureVmValues {
  templates: string[];
  policies: {
    id: string;
    name: string;
  }[];
  roleSizes: IVmType[];
}

export interface IVmType {
  name: string;
  details: string;
  displayName: string;
}

export interface IConfigurableFields {
  activationState: ModuleActivationState;
  activationMessage: string;
  activationExpected: boolean;
  configurableFields: IFieldConfigurationInfo[];
}

export interface IFieldConfigurationInfo {
  classificationMode: ClassificationMode;
  displayName: string;
  fieldId: string;
  hasConfiguration: boolean;
  isDirectConfiguration: boolean;
  spoFieldGroup: SpoFieldGroup;
}

export interface IFieldConfigurationGetModel {
  availableCsServers: Array<{ key: string; value: string }>;
  classificationMode: ClassificationMode;
  csServerId: string;
  displayName: string;
  fieldId: string;
  fieldType: string;
  locked: boolean;
  articleType: CSArticleType;
  bodyType: CSBodyType;
  clusteringThreshold?: number;
  clusteringType: CSClusteringType;
  isRoot: boolean;
  selectedRulebaseClasses: string[];
  threshold?: number;
  inheritAdvancedOptions: boolean;
  hasConfiguration: boolean;
  isDirectConfiguration: boolean;
}

export interface IFieldConfiguration {
  classificationMode: ClassificationMode;
  csServerId: string;
  displayName: string;
  fieldId: string;
  fieldType: string;
  locked: boolean;
  articleType: CSArticleType;
  bodyType: CSBodyType;
  clusteringThreshold?: number;
  clusteringType: CSClusteringType;
  isRoot: boolean;
  selectedRulebaseClasses: string[];
  threshold?: number;
  inheritAdvancedOptions: boolean;
}

export interface IClassificationConfig {
  configurableFields: IMetadataField[];
  contentTypeId: string;
  contentTypeName: string;
  ignoreNoContentTypeEvents: boolean;
  includeBody: boolean;
  isDocumentLibrary: boolean;
  listDescription: string;
  listId?: string;
  listName: string;
  listUrl: string;
  properties: IMetadataProperty[];
  spRedirect: string;
  triggeringEventDetails: IAvailableEventDetail[];
  userProfileProperties: IAvailableUserProfileProperty[];
  maxClassifyFileSize: number;
  patternsToIgnore: string[];
  patternsToClassifyMetadataOnly: string[];
}

export interface IMetadataField extends IMetadataProperty {
  fieldId: string;
  typeAsString: string;
  trackChanges: boolean;
  trackManualEdit: boolean;
  isDefinedHereUsed: boolean;
  definedHere: boolean;
}

export interface IMetadataProperty {
  displayName: string;
  internalName: string;
  includeAsMetadata: boolean;
  includeInBody: boolean;
  prefix: string;
  metadataName: string;
}

export interface IAvailableEventDetail {
  description: string;
  isSelected: boolean;
  name: string;
  value: number;
}

export interface IAvailableUserProfileProperty {
  include: boolean;
  name: string;
}

export interface IColumnResult {
  CurrentDisplayValues: IResult[];
  CurrentManuallyEdited: boolean;
  DisplayValues: IResult[];
  Error?: IColumnError;
  FieldId: string;
  FieldName: string;
  HasUpdatedValues: boolean;
  OverwriteManualEdits: boolean;
  ResultCount: number;
  SerializedValue: string;
  CanBeOverriden: boolean;
}

export interface IColumnError {
  CorrelationId: string;
  Message: string;
}

export interface IResult {
  Id: string;
  Name: string;
  Score: number;
  Tag: string;
}

export interface IClassificationPreviewResponse {
  result: ResponseResult;
  values: string[];
  correlationId: string;
}

export interface IClassificationPreview {
  columnResults: IColumnResult[];
  result: ResponseResult;
  correlationId: string;
}

export interface IMessage {
  type: string;
  sender?: string;
  hideFrame?: boolean;
  data?: any;
  code?: any;
  correlationId?: string;
}

export interface IServiceTypeProps {
  count: number;
  name: string;
  serverType: ServerType;
  onSelect?: (serverType: ServerType, name: string) => void;
  selected: boolean;
}

export interface ICreateServiceProps {
  serviceType: ServerType;
  groupId: string;
  onRefresh: () => void;
}

export interface IEditGroupProps {
  canEdit: boolean;
  canDelete: boolean;
  onDeleteClick: () => void;
  onEditClick: () => void;
}

export interface IServiceGroup {
  id?: string;
  name: string;
  description: string;
  subnetId: string;
  networkId?: string;
  networkName?: string;
  regionName?: string;
  subnetCIDR?: string;
  services?: IAssociatedService[];
}

export interface ISubnet {
  subnetId: string;
  networkName: string;
  regionName: string;
  subnetName: string;
  subnetCIDR: string;
  networkCIDR: string;
  canAddSubnet: boolean;
  sharedSubnet: boolean;
}

export interface IGroupForm {
  state: any;
  data: IServiceGroup;
  visible: boolean;
  onSave: (e: React.FormEvent<HTMLFormElement>) => void;
  onClose: () => void;
}

export interface IServiceListProps {
  services: IServiceViewModel[];
  serviceType: IServiceTypeProps;
  selectedGroup: IServiceGroup;
  onServicesChanged: (success: boolean) => void;
}

export interface IServiceViewModel {
  serverId?: string;
  name: string;
  description: string;
  groupId: string;
  configData: any;
  active: boolean;
  serverType: ServerType;
  url?: string;
  dependsUpon: IAssociatedService[];
  dependentUpon?: IAssociatedService[];
  provisioningStatus?: ProvisioningStatus;
}

export interface IServiceBasicInfo {
  serverId: string;
  name: string;
  serverType: ServerType;
}

export interface ILoginForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface IRegisterForm {
  firstName: string;
  lastName: string;
  displayName: string;
  emailAddress: string;
  password: string;
}

export interface IActionResult<T> {
  processing: boolean;
  error?: IError;
  data?: T;
}

export interface ISupportQuery {
  correlationId: string;
  interfaceId: string;
  username: string;
  message: string;
  excludedCategories: string[];
  logLevel: number;
  startDate?: string;
  endDate?: string;
  includeAllTenants: boolean;
  includeDebug: boolean;
  includeError: boolean;
  maxRows?: number;
  continuationToken?: IContinuationToken;
}

export interface ISupportResult {
  results: ILogResult[];
  continuationToken: IContinuationToken;
}

export interface ILogSearchQuery {
  includeAllTenants: boolean;
  includeDebug: boolean;
  includeError: boolean;

  startDate?: string;
  endDate?: string;

  excludedCategories: string[];
  interfaceId: string;
  correlationId: string;
  logLevel: number;
  username: string;
  message: string;
}

export interface IModalMetadata {
  size?: 'sm' | 'lg' | 'xl';
  position?: 'right' | 'left';
}

export interface IModal {
  title?: string;
  data?: any;
  contents: ModalContents;
  meta: IModalMetadata;
}

export interface IInterfaceConfigured {
  active: boolean;
  description: string;
  id?: string;
  name: string;
  selectedSesServerId?: string;
  selectedCsServerId?: string;
}

export interface IDeployment {
  codeVersion: string;
  status: DeploymentStatus;
  finishDate: string;
  id: string;
  tenantInterfaceId: string;
  queueDate: string;
  siteId: string;
  webId: string;
  siteUrl: string;
  startDate: string;
  correlationId: string;
}

export interface ITermStoreSyncJob {
  isValid: boolean;
  canEdit: boolean;
  canForceSync: boolean;
  canSync: boolean;
  groupName: string;
  id: string;
  lastSyncDate: string;
  sesIndex: string;
  sesServerId: string;
  sesServerName: string;
  status: SyncStatus;
  syncErrors: string[];
  syncQueueDate: string;
  syncStartDate: string;
  termStoreGroupId: string;
  termStoreId: string;
  termStoreName: string;
  isSiteCollectionGroup?: boolean;
  siteCollection?: string;
}

export interface ISyncJob {
  sesIndex: string;
  sesServerId: string;
  termStoreGroupId: string;
  termStoreId: string;
}

export interface ISyncError {
  id: string;
  message: string;
  nodeId: string;
  nodeName: string;
  parentNodeId: string;
  parentNodeName: string;
  syncRunId: string;
}

export interface ISyncRun {
  correlationId: string;
  errors: ISyncError[];
  finishDate: string;
  id: string;
  queueDate?: string;
  startDate?: string;
  status: SyncStatus;
  syncJobId: string;
  currentMessage: string;
  progress: number;
  range: number;
  cancelRequest: boolean;
}

export interface ITermStoreJobRun {
  id: string;
  startDate: string;
  finishDate: string;
  errors: ITermStoreJobError[];
  correlationId: string;
  status: SyncStatus;
}

export interface ITermStoreJobError {
  message: string;
  nodeName: string;
  nodeId: string;
}

export interface ITermStoreGroupData {
  id: string;
  name: string;
  isSiteCollectionGroup: boolean;
}

export interface ITermStoreData {
  groups: ITermStoreGroupData[];
  name: string;
  termStoreId: string;
}

export type iconComponentType =
  | 'information-outline'
  | 'flask'
  | 'square-edit-outline'
  | 'studio'
  | 'archive'
  | 'menu'
  | 'logout'
  | 'account-circle-outline';

export interface IMenuItem {
  title: string;
  tooltipText: string;
  url: string;
  iconComponent?: iconComponentType;
  backgroundClass?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
  isModal?: boolean;
  modalTitle?: string;
  urlTarget?: string;
  id: string;
}

export interface IResetPasswordModel {
  code: string;
  password: string;
}

export interface ISequentialListClassification {
  cancelRequest: boolean;
  completedItems: number;
  correlationId: string;
  failedItems: number;
  ignoredItems: number;
  finishDate?: string;
  id: string;
  lastItemId: number;
  message: string;
  pauseRequest: boolean;
  queueDate: string;
  queuedItems: number;
  siteLibraryId: string;
  startDate?: string;
  state: SequentialListClassificationState;
}

export interface ISequentialListClassificationDetails
  extends ISequentialListClassification {
  siteUrl: string;
  listName: string;
  listId: string;
}

export interface IClassificationError {
  errorType: ClassificationErrorType;
  id: string;
  itemId: number;
  itemName: string;
  message: string;
  sequentialListClassificationId: string;
}

export interface ISiteLibrary {
  id: string;
  listId: string;
  queueDate: string;
  simStatus: LibrarySimStatus;
  siteDeploymentId: string;
  startDate: string;
  updateDate: string;
  updateMessage: string;
}

export interface ILanguagePack {
  name: string;
  version: string;
}

export interface ISpoQueryError {
  correlationId: string;
  interfaceId: string;
  message: string;
  serverErrorCode: number;
  siteUrl: string;
  stackTrace: string;
  time: string;
  type: SpoQueryErrorType;
}
