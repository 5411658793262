import * as React from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';
import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Card } from 'react-bootstrap';
import CopyButton from 'components/common/copyButton';
import { Endpoint } from 'endpoints/base/endpoint';
import { IReduxState } from 'reducers';
import Instructions from './instructions';
import LoaderWithParams from 'components/common/loaderWithParams';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchApiKey } from 'slices/accountSlice';

interface IOwnProps {
  data: IInterfaceConfigured;
}

interface IStateProps {
  apiKeyResult: IActionResult<string>;
}

interface IDispatchProps {
  fetchApiKey: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class BapiHelp extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchApiKey } = this.props;
    fetchApiKey();
  }

  public render() {
    const { data, apiKeyResult } = this.props;
    const sesUrl = `${Endpoint.backendUrl}/svc/${data.id}/SES/`;
    const csUrl = `${Endpoint.backendUrl}/svc/${data.id}/`;
    return (
      <>
        <Row>
          <Col>
            <h5>Endpoint Details</h5>
            <Card>
              <Table size="sm">
                <thead>
                  <tr className="bg-light">
                    <th>Type</th>
                    <th colSpan={2}>URL</th>
                  </tr>
                </thead>
                <tbody>
                  {data.selectedSesServerId ? (
                    <tr>
                      <td>Semantic Enhancement</td>
                      <td>
                        <Button
                          size="sm"
                          className="p-0 text-left"
                          variant="link"
                          as="a"
                          href={sesUrl}
                          target="_blank"
                        >
                          {sesUrl}
                        </Button>
                      </td>
                      <td>
                        <CopyButton
                          text={sesUrl}
                          size="sm"
                          iconSize="0.8rem"
                          className="py-0 px-1"
                        />
                      </td>
                    </tr>
                  ) : null}
                  {data.selectedCsServerId ? (
                    <tr>
                      <td>Classification</td>
                      <td>
                        <Button
                          variant="link"
                          size="sm"
                          className="p-0 text-left"
                          as="a"
                          href={csUrl}
                          target="_blank"
                        >
                          {csUrl}
                        </Button>
                      </td>
                      <td>
                        <CopyButton
                          text={csUrl}
                          size="sm"
                          iconSize="0.8rem"
                          className="py-0 px-1"
                        />
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <LoaderWithParams
          component={Instructions}
          actionresult={apiKeyResult}
          params={{ bapiInterface: data }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  apiKeyResult: state.account.apiKeyResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchApiKey: () => dispatch(fetchApiKey()),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(BapiHelp);
