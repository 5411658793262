import * as React from 'react';

import { Form, FormGroup, FormLabel } from 'react-bootstrap';

import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';

interface IProps {
  input: {
    value: string[];
    onChange: (value: string[]) => void;
    name: string;
  };
  id: string;
  label: string;
  tooltip: string;
  disabled: boolean;
  meta: { touched: boolean; error: string; warning: string };
  options: Array<{ key: string; value: string }>;
}

class CheckBoxComponent extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const { value: values, name } = this.props.input;
    const { disabled, id, label, meta, options, tooltip } = this.props;
    const checkboxes = options.map(
      (option: { key: string; value: string }, index: number) => (
        <li key={index}>
          <input
            id={`${id}-${index}`}
            type="checkbox"
            disabled={disabled}
            value={option.value}
            name={name}
            onChange={this.onChange}
            checked={
              values.find((value) => value === option.value) !== undefined
            }
          />
          <FormLabel htmlFor={`${id}-${index}`}>{option.key}</FormLabel>
        </li>
      )
    );
    const help =
      tooltip !== undefined ? (
        <span title={tooltip}>
          <HelpOutlineIcon size="1rem" />
        </span>
      ) : null;
    return (
      <FormGroup>
        <FormLabel htmlFor={id}>
          {label} {help}
        </FormLabel>
        <ul className="checkboxes list-unstyled" id={id}>
          {checkboxes}
        </ul>
        {meta.touched &&
          ((meta.error && (
            <Form.Text className="text-danger">{meta.error}</Form.Text>
          )) ||
            (meta.warning && (
              <Form.Text className="text-warning">{meta.warning}</Form.Text>
            )))}
      </FormGroup>
    );
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let selected = this.props.input.value;
    const changedValue = event.currentTarget.value;
    if (event.currentTarget.checked) {
      selected = [...selected, changedValue];
    } else {
      selected = selected.filter((checkbox) => checkbox !== changedValue);
    }
    this.props.input.onChange(selected);
  }
}

export default CheckBoxComponent;
