import * as React from 'react';

import { Col, Form, Modal, Row } from 'react-bootstrap';

import { ILogResult } from 'types';
import SupportUtilities from './supportUtilities';
import { localLongDate } from 'utils/dateUtils';

interface IOwnProps {
  log: ILogResult;
}

interface IProps extends IOwnProps {}

class LogModal extends React.Component<IProps> {
  public render() {
    const { log } = this.props;
    return (
      <Modal.Body>
        <Form.Group as={Row} controlId="log-date">
          <Form.Label column sm={2}>
            Date
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={localLongDate(log.logDate)}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="log-correlationId">
          <Form.Label column sm={2}>
            CorrelationId
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={log.correlationId} disabled />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="log-level">
          <Form.Label column sm={2}>
            Level
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={SupportUtilities.getLevelName(log.level)}
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="log-category">
          <Form.Label column sm={2}>
            Category
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={log.category} disabled />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="log-username">
          <Form.Label column sm={2}>
            User
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={log.username} disabled />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="log-message">
          <Form.Label column sm={2}>
            Message
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              rows={10}
              value={log.message}
              disabled
            />
          </Col>
        </Form.Group>
      </Modal.Body>
    );
  }
}

export default LogModal;
