import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IPasswordForm } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { changePassword } from 'slices/accountSlice';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';

interface IStateProps {
  updateResult: IActionResult<boolean>;
}

interface IDispatchProps {
  changePassword: (passwordForm: IPasswordForm) => void;
}

export interface IFormData {
  oldPassword: string;
  password: string;
  confirmNewPassword: string;
}

export interface IProps extends IStateProps, IDispatchProps {}

export class ChangePassword extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { updateResult, handleSubmit, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="oldPassword"
            component={inputComponent}
            type="password"
            label="Old password"
            validate={[formValidators.minLength(9), formValidators.required]}
          />
          <Field
            name="password"
            component={inputComponent}
            type="password"
            label="New password"
            validate={[
              formValidators.minLength(9),
              formValidators.required,
              formValidators.requireThreeTypesOfCharacters,
            ]}
          />
          <Field
            name="confirmNewPassword"
            component={inputComponent}
            type="password"
            label="Confirm new password"
            validate={[formValidators.required, formValidators.passwordsMatch]}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { changePassword } = this.props;
    changePassword({
      oldPassword: data.oldPassword,
      newPassword: data.password,
    });
  }
}

const ChangePasswordModalForm = reduxForm<IFormData, IProps>({
  form: 'changePassword',
})(ChangePassword);

const mapStateToProps = (
  state: IReduxState
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    updateResult: state.account.passwordResult,
    initialValues: {
      oldPassword: undefined,
      password: undefined,
      confirmNewPassword: undefined,
    },
    form: 'changePassword',
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  changePassword: (passwordForm: IPasswordForm) =>
    dispatch(changePassword(passwordForm)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModalForm);
