import * as React from 'react';

import { ISyncRun } from 'types';
import SyncRunDetailsModal from './syncRunDetailsModal';

interface IParams {
  syncRunId: string;
}

interface IOwnProps {
  data: ISyncRun[];
  params: IParams;
}

interface IProps extends IOwnProps {}

export class SyncRunDetailsModalConnector extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { syncRunId } = params;
    const syncRun = data.find((r) => r.id === syncRunId);
    return syncRun ? <SyncRunDetailsModal syncRun={syncRun} /> : null;
  }
}

export default SyncRunDetailsModalConnector;
