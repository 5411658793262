import { ISpoQueryError } from 'types';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { localDate } from 'utils/dateUtils';

interface IProps {
  error: ISpoQueryError;
}

class SpoError extends React.Component<IProps> {
  public render() {
    const { error } = this.props;
    return (
      <tr>
        <td>
          <Table size="sm" bordered>
            <tbody>
              <tr>
                <th>Date</th>
                <td>{localDate(error.time)}</td>
              </tr>
              <tr>
                <th>Site URL</th>
                <td>{error.siteUrl}</td>
              </tr>
              <tr>
                <th>Message</th>
                <td>{error.message}</td>
              </tr>
              <tr>
                <th>Correlation Id</th>
                <td>{error.correlationId}</td>
              </tr>
              {error.stackTrace ? (
                <tr>
                  <th>Stack trace</th>
                  <td>{error.stackTrace}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </td>
      </tr>
    );
  }
}

export default SpoError;
