import * as React from 'react';

import { IActionResult, IClassificationPreview } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Container } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import { ThunkDispatch } from 'redux-thunk';
import classifyItemForm from './classifyItemForm';
import { connect } from 'react-redux';
import { getClassificationPreview } from 'slices/classifyItemSlice';

interface IOwnProps {
  interfaceId: string;
}

interface IStateProps {
  getClassificationPreviewResult: IActionResult<IClassificationPreview>;
}

interface IDispatchProps {
  getPreview: (
    interfaceId: string,
    spHostUrl: string,
    listId: string,
    itemId: string
  ) => void;
}

interface IProps extends IStateProps, IOwnProps, IDispatchProps {}

export class ClassifyItemPage extends React.Component<IProps> {
  private spHostUrl: string;
  private listId: string;
  private itemId: string;

  constructor(props: IProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.spHostUrl = params.get('spHostUrl')!;
    this.listId = params.get('listId')!;
    this.itemId = params.get('itemId')!;
  }

  public componentDidMount() {
    const { getPreview, interfaceId } = this.props;
    const { spHostUrl, listId, itemId } = this;
    getPreview(interfaceId, spHostUrl, listId, itemId);
  }

  public render() {
    const { getClassificationPreviewResult, interfaceId } = this.props;
    const { spHostUrl, listId, itemId } = this;
    return (
      <Container fluid className="d-flex flex-column flex-grow-1 vh-100">
        <LoaderWithParams
          component={classifyItemForm}
          actionresult={getClassificationPreviewResult}
          params={{
            interfaceId,
            spHostUrl,
            listId,
            itemId,
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  getClassificationPreviewResult:
    state.classifyItem.getClassificationPreviewResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  getPreview: (
    interfaceId: string,
    spHostUrl: string,
    listId: string,
    itemId: string
  ) =>
    dispatch(getClassificationPreview(interfaceId, spHostUrl, listId, itemId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ClassifyItemPage);
