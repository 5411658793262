import { AnyAction } from '@reduxjs/toolkit';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import { ModalFormFooter } from 'components/common/modalFormFooter';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteListSimConfig } from 'slices/simSettingsSlice';

interface IOwnProps {
  fieldId: string;
  siteLibraryId: string;
}

interface IStateProps {
  deleteSimSettingsResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteListSimConfig: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteListSimConfigModal extends React.Component<IProps> {
  public render() {
    const { deleteSimSettingsResult, deleteListSimConfig } = this.props;
    return (
      <>
        <Modal.Body>
          Are you sure you want to remove this configuration?
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteSimSettingsResult}
          onSubmit={deleteListSimConfig}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
        />
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  deleteSimSettingsResult: state.simSettings.deleteSimSettingsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => {
  const { fieldId, siteLibraryId } = ownProps;
  return {
    deleteListSimConfig: () =>
      dispatch(deleteListSimConfig(fieldId, siteLibraryId)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteListSimConfigModal);
