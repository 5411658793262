import * as React from 'react';

import { AnyAction } from 'redux';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { regenerateApiKey } from 'slices/accountSlice';

interface IStateProps {
  apiKeyRegenerationResult: IActionResult<string>;
}

interface IDispatchProps {
  regenerateApiKey: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class WarnApiKeyRegenerationModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.regenerate = this.regenerate.bind(this);
  }

  public render() {
    const { apiKeyRegenerationResult } = this.props;

    return (
      <>
        <Modal.Body>
          Regenerating the key will invalidate all tokens generated using this
          key.
        </Modal.Body>
        <ModalFormFooter
          actionResult={apiKeyRegenerationResult}
          submitMessage="Proceed"
          submittingMessage="Regenerating"
          onSubmit={this.regenerate}
        />
      </>
    );
  }

  private regenerate() {
    const { regenerateApiKey } = this.props;
    regenerateApiKey();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    apiKeyRegenerationResult: state.account.apiKeyRegenerationResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  regenerateApiKey: () => dispatch(regenerateApiKey()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(WarnApiKeyRegenerationModal);
