import * as React from 'react';

import { Badge, Button, Col, Container, Image, Row } from 'react-bootstrap';
import { downloadApp, downloadModernUiApp } from 'slices/downloadSlice';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface IDispatchProps {
  downloadApp: () => void;
  downloadModernUiApp: () => void;
}

interface IProps extends IDispatchProps {}

export class SpoInstructionPage extends React.Component<IProps> {
  public render() {
    const { downloadApp, downloadModernUiApp } = this.props;
    return (
      <Container>
        <h1 className="text-center mt-4">
          Installing Semaphore for SharePoint Online
        </h1>
        <p className="lead">
          If you have not downloaded the app, you may{' '}
          <Button variant="link" className="p-0" onClick={downloadApp}>
            <p className="lead mb-0">download it here</p>
          </Button>
          . If you are using the SharePoint Modern UI you will also require the
          modern UI app,{' '}
          <Button variant="link" className="p-0" onClick={downloadModernUiApp}>
            <p className="lead mb-0">download it here</p>
          </Button>
          .
        </p>
        <h3>
          <Badge variant="success" className="mr-2">
            1
          </Badge>
          Adding the app to your app catalog
        </h3>
        <Row>
          <Col sm={6}>
            <p>
              In your app catalog site, upload the
              Smartlogic.semaphore.ShapePoint.app to your "Apps for SharePoint"
              library.
            </p>
          </Col>
          <Col sm={6}>
            <Image src="images/UploadApp.png" thumbnail />
          </Col>
        </Row>
        <h3>
          <Badge variant="success" className="mr-2">
            2
          </Badge>
          Setting app properties
        </h3>
        <Row>
          <Col sm={6}>
            <p>
              Once the app has been added, edit the properties, for example:
            </p>
            <Image src="images/Properties.png" thumbnail />
          </Col>
          <Col sm={6}>
            <Image src="images/EditingProperties.png" thumbnail />
          </Col>
        </Row>
        <h3>
          <Badge variant="success" className="mr-2">
            3
          </Badge>
          Add & Deploying Semaphore on your site
        </h3>
        <Row>
          <Col sm={6}>
            <p>
              After you’ve added the app to the app catalog, you can now add the
              app to your site. Navigate to site contents, and click on "Add an
              app", choose Semaphore Cloud and "Trust It"
            </p>
          </Col>
          <Col sm={6}>
            <Image src="images/TrustTheApp.png" thumbnail />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Once added to the site, you can deploy Semaphore by click on the
              Semaphore app, which will log you into Semaphore, then click on
              SharePoint Online Interfaces, select an interface and press
              "Deploy"
            </p>
          </Col>
          <Col sm={6}>
            <Image src="images/Deployment.png" thumbnail />
          </Col>
        </Row>
        <hr />
        <h3>
          Support for the modern UI <small>Optional</small>
        </h3>
        <Row>
          <Col sm={6}>
            <p>
              For those using Modern UI - In your app catalog site, upload the
              Smartlogic.semaphore.ShapePoint.ModernUI.app to your "Apps for
              SharePoint" library.
            </p>
          </Col>
          <Col sm={6}>
            <Image src="images/UploadModernUiApp.png" thumbnail />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Once deployed in the app catalog, you may add it to any site where
              you need support for the modern list user interface.
            </p>
          </Col>
          <Col sm={6}>
            <Image src="images/InstallApp.png" thumbnail />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  downloadApp: () => dispatch(downloadApp()),
  downloadModernUiApp: () => dispatch(downloadModernUiApp()),
});

export default connect<{}, IDispatchProps, {}, IReduxState>(
  null,
  mapDispatchToProps
)(SpoInstructionPage);
