import * as React from 'react';

import { Button, Collapse } from 'react-bootstrap';
import { CSArticleType, CSBodyType, CSClusteringType } from 'types';

import { Field } from 'redux-form';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IProps {
  inheritAdvancedOptions: boolean;
}

interface IState {
  isOpen: boolean;
}

class AdvancedClassificationOptions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isOpen: false };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  public render() {
    const { inheritAdvancedOptions } = this.props;
    return (
      <div id="advancedOptions">
        <Button onClick={this.toggleCollapse}>Advanced Settings</Button>
        <Collapse in={this.state.isOpen}>
          <div>
            <Field
              name="inheritAdvancedOptions"
              component={checkBoxComponent}
              label="Inherit from parent"
            />
            <Field
              name="threshold"
              component={inputComponent}
              disabled={inheritAdvancedOptions}
              type="number"
              label="Threshold"
              min={0}
              max={100}
              tooltip="Threshold - defines the minimum score a category should
                            reach before being included in the results, if this is not specified
                            the THRESHOLD defined in the server configuration is used."
            />
            <Field
              name="bodyType"
              component={selectComponent}
              disabled={inheritAdvancedOptions}
              label="Body Type"
              tooltip="Body Type - how the provided BODY should
                            be treated - as raw TEXT or as HTML."
            >
              <option value={CSBodyType.Unknown}>Unknown</option>
              <option value={CSBodyType.Text}>Text</option>
              <option value={CSBodyType.Html}>Html</option>
            </Field>
            <Field
              name="articleType"
              disabled={inheritAdvancedOptions}
              component={selectComponent}
              label="Article Type"
              tooltip="Article Type - Specifies whether the classified content
                            should be treated as a single article or split into multiple
                            articles. Selecting 'Default' will autodetect the article type based
                            on the content type."
            >
              <option value={CSArticleType.Default}>Unknown</option>
              <option value={CSArticleType.SingleArticle}>SingleArticle</option>
              <option value={CSArticleType.MultiArticle}>MultiArticle</option>
            </Field>
            <Field
              name="clusteringType"
              disabled={inheritAdvancedOptions}
              component={selectComponent}
              label="Clustering Type"
              tooltip="Clustering Type - has the following values (case insensitive):

                            ALL – Indicates that all the categories defined for all articles will be propagated at document level.
                            AVERAGE – Indicates that the average score (by article contribution) across all articles will be recalculated for each category and the category propagated at document level if its standard average is above the clustering threshold.
                            COMMON – Indicates that only the categories in common between all articles of the document will be propagated at document level with average score over all articles (if that average is above the clustering threshold).
                            NONE – Disables clustering of article metadata.
                            RMS – Indicates that the average score (by article contribution) across all articles will be recalculated for each category and the category propagated at document level if its root mean square average is above the clustering threshold."
            >
              <option value={CSClusteringType.RMS}>RMS</option>
              <option value={CSClusteringType.ALL}>ALL</option>
              <option value={CSClusteringType.AVERAGE}>AVERAGE</option>
              <option value={CSClusteringType.COMMON}>COMMON</option>
              <option value={CSClusteringType.NONE}>NONE</option>
              <option value={CSClusteringType.AVERAGE_INCLUDING_EMPTY}>
                AVERAGE_INCLUDING_EMPTY
              </option>
              <option value={CSClusteringType.COMMON_INCLUDING_EMPTY}>
                COMMON_INCLUDING_EMPTY
              </option>
              <option value={CSClusteringType.RMS_INCLUDING_EMPTY}>
                RMS_INCLUDING_EMPTY
              </option>
            </Field>
            <Field
              name="clusteringThreshold"
              component={inputComponent}
              type="number"
              disabled={inheritAdvancedOptions}
              label="Clustering Threshold"
              min={0}
              max={100}
              tooltip="Clustering Threshold - defines the minimum score a
                            category should reach before being propagated at document level, if
                            this is not specified the CLUSTERING_THRESHOLD defined
                            in the server configuration is used."
            />
          </div>
        </Collapse>
      </div>
    );
  }

  private toggleCollapse() {
    this.setState({ isOpen: !this.state.isOpen });
  }
}

export default AdvancedClassificationOptions;
