import * as React from 'react';

import { IAccountResult, IActionResult } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import ManageApiKey from './manageApiKey';
import ManageLogins from './externalLogins';
import ManagePassword from './changePassword';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchApiKey } from 'slices/accountSlice';

interface IStateProps {
  apiKeyResult: IActionResult<string>;
  fetchDetailsResult: IActionResult<IAccountResult>;
}

interface IDispatchProps {
  fetchApiKey: () => void;
}

interface IProps extends IDispatchProps, IStateProps {}

export class ManageAccountPage extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchApiKey } = this.props;
    fetchApiKey();
  }

  public render() {
    const { apiKeyResult, fetchDetailsResult } = this.props;
    return (
      <>
        <h1 className="font-weight-light mt-5 mb-5">
          Manage account settings
          <Loader
            component={ManagePassword}
            actionresult={fetchDetailsResult}
          />
          <Button
            className="float-right mt-3 "
            variant="link"
            as="a"
            href="/swagger"
            target="_blank"
          >
            <OpenInNewIcon size="1.5em" className="mr-1" />
            Management API help
          </Button>
        </h1>
        <hr />
        <Loader component={ManageApiKey} actionresult={apiKeyResult} />
        <hr />
        <Loader component={ManageLogins} actionresult={fetchDetailsResult} />
      </>
    );
  }
}
const mapStateToProps = (state: IReduxState): IStateProps => ({
  apiKeyResult: state.account.apiKeyResult,
  fetchDetailsResult: state.account.fetchDetailsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchApiKey: () => dispatch(fetchApiKey()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ManageAccountPage);
