import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class CsEndpoint {
  public readonly ROOT = 'cs';

  public getNumberOfConfigurations(
    classificationSererId: string
  ): Promise<AxiosResponse<number>> {
    const url = Endpoint.getUrl([
      this.ROOT,
      classificationSererId,
      'configurationsCount',
    ]);
    return api.get(url);
  }

  public replaceCsInConfigurations(
    classificationSererId: string,
    newClassificationServerId: string
  ): Promise<AxiosResponse> {
    const queryItems = [
      new QueryItem('newClassificationServerId', newClassificationServerId),
    ];
    const url = Endpoint.getUrl(
      [this.ROOT, classificationSererId, 'replaceInConfigurations'],
      queryItems
    );
    return api.post(url);
  }

  public removeConfigurations(
    classificationSererId: string
  ): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([
      this.ROOT,
      classificationSererId,
      'removeConfigurations',
    ]);
    return api.post(url);
  }
}
