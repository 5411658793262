import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { ITenancyDomain } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class DomainEndpoint {
  public readonly ROOT = 'domain';

  public getList(): Promise<AxiosResponse<ITenancyDomain[]>> {
    const queryItems: any[] = [];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }
  public delete(id: string): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('id', id)];

    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.delete(url);
  }

  public create(domainName: string): Promise<AxiosResponse<ITenancyDomain>> {
    const queryItems = [new QueryItem('domainName', domainName)];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.post(url, null);
  }
}
