import { Errors, IError } from 'utils/errors';
import { IActionResult, IFeed } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import RssFeedEndpoint from 'endpoints/rssFeedEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IRssFeedState {
  adminFeedResult: IActionResult<IFeed>;
  userFeedResult: IActionResult<IFeed>;
}

const rssFeedSlice = createSlice({
  name: 'rssFeed',
  initialState: {
    adminFeedResult: {
      processing: false,
    },
    userFeedResult: {
      processing: false,
    },
  } as IRssFeedState,
  reducers: {
    adminFeedLoading(state) {
      state.adminFeedResult = { processing: true };
    },
    adminFeedLoaded(state, action: PayloadAction<IFeed>) {
      state.adminFeedResult = {
        processing: false,
        data: action.payload,
      };
    },
    adminFeedLoadFailed(state, action: PayloadAction<IError>) {
      state.adminFeedResult = {
        processing: false,
        error: action.payload,
      };
    },
    userFeedLoading(state) {
      state.userFeedResult = { processing: true };
    },
    userFeedLoaded(state, action: PayloadAction<IFeed>) {
      state.userFeedResult = {
        processing: false,
        data: action.payload,
      };
    },
    userFeedLoadFailed(state, action: PayloadAction<IError>) {
      state.userFeedResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const loadAdminFeed = (): AppThunk => async (dispatch) => {
  const endpoint = new RssFeedEndpoint();
  dispatch(adminFeedLoading());
  try {
    const response = await endpoint.getAdminFeed();
    dispatch(adminFeedLoaded(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(adminFeedLoadFailed(Errors.getError(error)));
  }
};

export const loadUserFeed = (): AppThunk => async (dispatch) => {
  const endpoint = new RssFeedEndpoint();
  dispatch(userFeedLoading());
  try {
    const response = await endpoint.getUserFeed();
    dispatch(userFeedLoaded(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(userFeedLoadFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = rssFeedSlice;
export const {
  adminFeedLoadFailed,
  adminFeedLoaded,
  adminFeedLoading,
  userFeedLoadFailed,
  userFeedLoaded,
  userFeedLoading,
} = actions;
export default reducer;
