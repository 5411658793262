import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import api from './base/api';

export default class SharePointSubscriptionEndpoint {
  public readonly ROOT = 'sharePointSubscription';

  public getSpoSubscriptions(
    interfaceId: string
  ): Promise<AxiosResponse<string[]>> {
    const url = Endpoint.getUrl([interfaceId, this.ROOT]);
    return api.get(url);
  }
}
