import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IUsageViewModel } from 'types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class ReportingEndpoint {
  public readonly ROOT = 'reporting';

  public getUsageInterfaceLast30Days(
    interfaceId: string
  ): Promise<AxiosResponse<IUsageViewModel>> {
    const url = Endpoint.getUrl([this.ROOT, 'usage', interfaceId]);
    return api.get(url);
  }

  public getUsageCsvForPeriod(
    interfaceId: string,
    startDate: string,
    endDate: string,
    interfaceType: 'bapi' | 'spo'
  ): Promise<AxiosResponse<File>> {
    const queryItems = [
      new QueryItem('interfaceId', interfaceId),
      new QueryItem('startDate', startDate),
      new QueryItem('endDate', endDate),
    ];
    const url = Endpoint.getUrl(
      [this.ROOT, 'usage', interfaceType],
      queryItems
    );
    return api.get(url);
  }
}
