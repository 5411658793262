import * as React from 'react';

import { Alert, Col, ListGroup, Row } from 'react-bootstrap';

import { Field } from 'redux-form';
import { IColumnResult } from 'types';
import ResultTile from './resultTile';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';

interface IProps {
  columnResult: IColumnResult;
}

class ColumnResult extends React.Component<IProps> {
  public render() {
    const { columnResult } = this.props;
    const hasCurrentValues =
      columnResult.CurrentDisplayValues != null &&
      columnResult.CurrentDisplayValues.length !== 0;
    const previousResults = hasCurrentValues
      ? columnResult.CurrentDisplayValues.map((value, index) => (
          <ResultTile result={value} key={index} />
        ))
      : null;
    const hasNewValues =
      columnResult.DisplayValues != null &&
      columnResult.DisplayValues.length !== 0;
    const newResults = hasNewValues
      ? columnResult.DisplayValues.map((value, index) => (
          <ResultTile result={value} key={index} />
        ))
      : null;
    const errorAlert =
      columnResult.Error != null ? (
        <Alert variant="danger">
          <b>Unable to classify: </b>
          {columnResult.Error.Message}
          <p className="text-center">
            <small>
              <em>CorrelationId: {columnResult.Error.CorrelationId} </em>
            </small>
          </p>
        </Alert>
      ) : null;
    return (
      <Row className="mt-3">
        <Col sm={12}>
          <h4 className="text-center mb-3">
            <span>
              {columnResult.FieldName} ({' '}
              {columnResult.ResultCount === 0
                ? 'No results'
                : `${columnResult.ResultCount} ${
                    columnResult.ResultCount === 1 ? 'result' : 'results'
                  }`}{' '}
              )
            </span>
          </h4>
        </Col>
        <Col sm={6}>
          <h5 className="text-center">Before Classification</h5>
          {!hasCurrentValues ? (
            <div className="text-center">No current value</div>
          ) : null}
          <ListGroup className="mb-4">{previousResults}</ListGroup>
        </Col>
        <Col sm={6}>
          <h5 className="text-center">After Classification</h5>
          {!hasNewValues ? (
            <div className="text-center">No classifications</div>
          ) : null}
          <ListGroup className="mb-4">
            {newResults}
            {columnResult.HasUpdatedValues && !columnResult.CanBeOverriden ? (
              <div
                className="mt-1 ml-2 text-danger"
                style={{ fontSize: '12px' }}
              >
                This result won't override the current value!
              </div>
            ) : null}
          </ListGroup>
          {columnResult.CanBeOverriden && columnResult.CurrentManuallyEdited ? (
            <Field
              name={`overrideManualClassification-${columnResult.FieldId}`}
              component={checkBoxComponent}
              type="checkbox"
              label="&nbsp;&nbsp;Override Manual Classification?"
              id={`overrideManualClassification-${columnResult.FieldId}`}
            />
          ) : null}
        </Col>
        {errorAlert}
      </Row>
    );
  }
}

export default ColumnResult;
