import {
  IAvailableAzureVmValues,
  IDefaultAzureVmValues,
  ILanguagePack,
} from 'types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import api from './base/api';

export default class AzureVmEndpoint {
  public readonly ROOT = 'azureVm';

  public getDefaultValues(): Promise<AxiosResponse<IDefaultAzureVmValues>> {
    const url = Endpoint.getUrl([this.ROOT, 'getDefaultValues']);
    return api.get(url);
  }

  public getAvailableValues(
    serviceGroupId: string,
    serviceId?: string
  ): Promise<AxiosResponse<IAvailableAzureVmValues>> {
    const pathArray = [this.ROOT, 'getAvailableValues', serviceGroupId];
    if (serviceId) {
      pathArray.push(serviceId);
    }
    const url = Endpoint.getUrl(pathArray);
    return api.get(url);
  }

  public getCurrentSize(serverId: string): Promise<AxiosResponse<string>> {
    const url = Endpoint.getUrl([this.ROOT, 'getCurrentSize', serverId]);
    return api.get(url);
  }

  public getLanguagePacks(
    serverId: string
  ): Promise<AxiosResponse<ILanguagePack[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'languagePacks', serverId]);
    return api.get(url);
  }
}
