import * as React from 'react';

import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { AnyAction } from 'redux';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import datePickerComponent from 'components/common/formComponents/datePickerComponent';
import { downloadUsageCsvForPeriod } from 'slices/reportingSlice';
import { formValidators } from 'utils/formValidators';

interface IOwnProps {
  interfaceId: string;
  interfaceType: 'bapi' | 'spo';
}

interface IStateProps {
  downloadResult: IActionResult<boolean>;
}

interface IDispatchProps {
  downloadUsageCsvForPeriod: (startDate: string, endDate: string) => void;
}

export interface IFormData {
  startDate: string;
  endDate: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ExportUsageModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { downloadResult, handleSubmit, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="startDate"
            component={datePickerComponent}
            label="Start Date"
            validate={formValidators.required}
          />
          <Field
            name="endDate"
            component={datePickerComponent}
            label="End Date"
            validate={formValidators.required}
          />
        </Modal.Body>
        <ModalFormFooter
          actionResult={downloadResult}
          submitMessage="Export"
          submittingMessage="Exporting"
          disabled={pristine}
        />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { downloadUsageCsvForPeriod } = this.props;
    downloadUsageCsvForPeriod(data.startDate, data.endDate);
  }
}

const ExportUsageModalForm = reduxForm<IFormData, IProps>({
  form: 'exportUsage',
})(ExportUsageModal);

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    downloadResult: state.reporting.downloadResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  downloadUsageCsvForPeriod: (startDate: string, endDate: string) =>
    dispatch(
      downloadUsageCsvForPeriod(
        ownProps.interfaceId,
        startDate,
        endDate,
        ownProps.interfaceType
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ExportUsageModalForm);
