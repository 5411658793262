import * as React from 'react';

import { IActionResult, IServiceGroup } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from '../../../reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteGroup } from 'slices/serviceGroupSlice';

interface IOwnProps {
  group: IServiceGroup;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteGroup: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteServiceGroupModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { deleteResult, group } = this.props;
    return (
      <>
        <Modal.Body>
          Are you sure you want to delete <strong>"{group.name}"</strong>{' '}
          service group?
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
          onSubmit={this.delete}
        />
      </>
    );
  }

  private delete() {
    const { deleteGroup } = this.props;
    deleteGroup();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  deleteResult: state.serviceGroup.deleteResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteGroup: () => dispatch(deleteGroup(ownProps.group.id!)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteServiceGroupModal);
