import { AxiosError } from 'axios';
import { inIframe } from './iframe';

export const redirectToLoginWhenUnauthorized = (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (inIframe()) {
      window.parent.postMessage('relogin', '*');
    } else {
      redirectToLogin();
    }
  }
};

export const redirectToLogin = () => {
  window.location.href = `/spa/relogin?redirectUrl=${
    window.location.pathname + window.location.search
  }`;
};
