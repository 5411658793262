import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceGroup, ISubnet } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';
import { updateGroup } from 'slices/serviceGroupSlice';

interface IParams {
  group: IServiceGroup;
}

interface IOwnProps {
  data: ISubnet[];
  params: IParams;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
}

interface IDispatchProps {
  updateGroup: (group: IServiceGroup) => void;
}

export interface IFormData {
  name: string;
  description: string;
  subnetId: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class EditGroupModalContainer extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      data: availableSubnets,
      handleSubmit,
      pristine,
      updateResult,
    } = this.props;
    const subnetOptions = availableSubnets.map((subnet, index) => (
      <option
        value={subnet.subnetId}
        key={index}
      >{`${subnet.networkName}.${subnet.subnetName}(${subnet.subnetCIDR})@${subnet.regionName}`}</option>
    ));

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="subnetId"
            component={selectComponent}
            label="Network"
            validate={formValidators.required}
          >
            {subnetOptions}
          </Field>
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { params, updateGroup } = this.props;
    const { group } = params;
    const updatedGroup = Object.assign({}, group, data);

    updateGroup(updatedGroup);
  }
}

const EditGroupModalContainerWithForm = reduxForm<IFormData, IProps>({
  form: 'editServiceGroup',
})(EditGroupModalContainer);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const { group } = ownProps.params;
  return {
    initialValues: {
      name: group.name,
      description: group.description,
      subnetId: group.subnetId,
    },
    form: 'editServiceGroup',
    updateResult: state.serviceGroup.updateResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateGroup: (group: IServiceGroup) => dispatch(updateGroup(group)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditGroupModalContainerWithForm);
