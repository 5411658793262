import * as React from 'react';

import { Badge, NavDropdown } from 'react-bootstrap';
import { IActionResult, INotification } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import Notification from './notification';
import NotificationsNoneIcon from 'mdi-react/NotificationsNoneIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchNotifications } from 'slices/notificationSlice';

interface IState {
  timeout: number;
}

interface IStateProps {
  notificationsResult: IActionResult<INotification[]>;
}

interface IDispatchProps {
  fetchNotifications: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class NotificationDropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      timeout: 0,
    };
    this.getNotifications = this.getNotifications.bind(this);
  }

  public componentDidMount() {
    this.getNotifications(true);
  }

  public componentWillUnmount() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  public render() {
    const { notificationsResult } = this.props;
    const countMessage =
      !notificationsResult.data || notificationsResult.data.length === 0
        ? 'no notifications'
        : notificationsResult.data.length > 1
        ? notificationsResult.data.length + ' notifications'
        : '1 notification';
    const notificationCount =
      notificationsResult.data && notificationsResult.data.length > 0
        ? notificationsResult.data.length
        : null;
    const notifications = notificationsResult.data
      ? notificationsResult.data.map((notification, index) => (
          <Notification notification={notification} key={index} />
        ))
      : null;

    return (
      <NavDropdown
        bsPrefix="nav-link bg-primary p-3 my-n2 d-flex"
        title={
          <>
            <div>
              <Badge variant="danger" className="notification ml-n3">
                {notificationCount}
              </Badge>
            </div>
            <NotificationsNoneIcon size="1.5rem" />
          </>
        }
        id="notification-dropdown"
        alignRight
      >
        <NavDropdown.Header>
          You have <span className="font-bold">{countMessage}</span>
        </NavDropdown.Header>
        {notifications}
      </NavDropdown>
    );
  }

  private getNotifications(isFirstCall = false) {
    const { fetchNotifications, notificationsResult } = this.props;
    fetchNotifications();
    let maxTimeout = isFirstCall ? 5 : 60;
    const notifications = notificationsResult.data
      ? notificationsResult.data
      : [];
    for (const idx in notifications) {
      if (notifications.hasOwnProperty(idx)) {
        const notification = notifications[idx];
        if (
          notification.refreshInterval > 0 &&
          notification.refreshInterval < maxTimeout
        ) {
          maxTimeout = notification.refreshInterval;
        }
      }
    }

    const timeout = window.setTimeout(this.getNotifications, maxTimeout * 1000);
    this.setState(Object.assign({}, this.state, { timeout }));
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  notificationsResult: state.notification.notificationsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchNotifications: () => dispatch(fetchNotifications()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDropdown);
