import * as React from 'react';

import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import inputComponent from 'components/common/formComponents/inputComponent';
import { search } from 'slices/userSlice';

interface IDispatchProps {
  search: (userName: string) => void;
}

export interface IFormData {
  userName: string;
}

export interface IProps extends IDispatchProps {}

export class UsersSearch extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Field
          name="userName"
          component={inputComponent}
          type="text"
          placeholder="Search user name..."
          size="lg"
          className="rounded-pill"
        />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { search } = this.props;
    search(data.userName);
  }
}

const UsersSearchForm = reduxForm<IFormData, IProps>({
  form: 'userSearch',
})(UsersSearch);

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  search: (userName: string) => dispatch(search(userName)),
});

export default connect<{}, IDispatchProps, {}, IReduxState>(
  null,
  mapDispatchToProps
)(UsersSearchForm);
