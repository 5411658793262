import * as React from 'react';

import { IServiceViewModel, ModalContents, ServerType } from 'types';

import { AnyAction } from 'redux';
import Authorizer from 'utils/authorizer';
import { Dropdown } from 'react-bootstrap';
import { Endpoint } from 'endpoints/base/endpoint';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchLanguagePacks } from 'slices/vmSlice';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  service: IServiceViewModel;
}

interface IStateProps {
  canManagePublishig: boolean;
}

interface IDispatchProps {
  openIframeModal: (title: string, url: string) => void;
  openLanguagePacksModal: () => void;
  fetchLanguagePacks: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ServiceButtons extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onPublishingClick = this.onPublishingClick.bind(this);
    this.onLanguagePacksClick = this.onLanguagePacksClick.bind(this);
  }
  public render() {
    const { service, canManagePublishig } = this.props;
    switch (service.serverType) {
      case ServerType.AzureVirtualMachineService:
        return (
          <Dropdown.Item onClick={this.onLanguagePacksClick}>
            Language Packs
          </Dropdown.Item>
        );
      case ServerType.OntologyEditor:
        return (
          <>
            <Dropdown.Item
              href={`${Endpoint.backendUrl}/sw/client/${service.serverId}/`}
            >
              Go
            </Dropdown.Item>
            {canManagePublishig && service.configData.PublisherConfigEnabled ? (
              <Dropdown.Item onClick={this.onPublishingClick}>
                Publishing
              </Dropdown.Item>
            ) : null}
          </>
        );
      case ServerType.Workbench:
        return (
          <Dropdown.Item
            href={`${Endpoint.backendUrl}/sw3/client/${service.serverId}/`}
          >
            Go
          </Dropdown.Item>
        );
      case ServerType.StudioService:
        return (
          <Dropdown.Item
            href={`${Endpoint.backendUrl}/semaphore/${service.serverId}/`}
          >
            Go
          </Dropdown.Item>
        );
      default:
        return null;
    }
  }

  private onLanguagePacksClick() {
    const { fetchLanguagePacks, openLanguagePacksModal } = this.props;
    fetchLanguagePacks();
    openLanguagePacksModal();
  }

  private onPublishingClick() {
    const { service, openIframeModal } = this.props;
    openIframeModal(
      `Publishing for ${service.name}`,
      `${Endpoint.backendUrl}/PublishingManagement?serverId=${service.serverId}`
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canManagePublishig: authorizer.canManagePublishig(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openIframeModal: (title: string, url: string) =>
    dispatch(
      openModal(
        title,
        ModalContents.Iframe,
        {
          position: 'right',
          size: 'lg',
        },
        url
      )
    ),
  openLanguagePacksModal: () =>
    dispatch(
      openModal('Language packs', ModalContents.LanguagePacks, {
        position: 'right',
      })
    ),
  fetchLanguagePacks: () =>
    dispatch(fetchLanguagePacks(ownProps.service.serverId!)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ServiceButtons);
