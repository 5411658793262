import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { closeModal } from 'slices/modalSlice';
import { connect } from 'react-redux';

interface IOwnProps {
  url: string;
}
interface IDispatchProps {
  onClose: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class IframeModal extends React.Component<IProps> {
  public render() {
    const { onClose, url } = this.props;
    return (
      <>
        <Modal.Body>
          <iframe title={url} src={url} className="w-100 h-100 border-0" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  onClose: () => dispatch(closeModal()),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(IframeModal);
