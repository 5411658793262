import * as React from 'react';

import {
  IActionResult,
  ISequentialListClassification,
  SequentialListClassificationState,
} from 'types';
import {
  cancelListClassification,
  pauseListClassification,
  resumeListClassification,
} from 'slices/classifyListSlice';

import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface IOwnProps {
  classification: ISequentialListClassification;
}

interface IStateProps {
  cancelClassificationResult: IActionResult<boolean>;
  pauseClassificationResult: IActionResult<boolean>;
  resumeClassificationResult: IActionResult<boolean>;
}

interface IDispatchProps {
  cancelListClassification: () => void;
  pauseListClassification: () => void;
  resumeListClassification: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ClassificationButtons extends React.Component<IProps> {
  public render() {
    const {
      classification,
      cancelListClassification,
      pauseListClassification,
      resumeListClassification,
      cancelClassificationResult,
      pauseClassificationResult,
      resumeClassificationResult,
    } = this.props;

    return (
      <>
        <td>
          {resumeClassificationResult?.processing ? (
            <Button size="sm" variant="primary" className="py-0 px-1" disabled>
              Resuming
            </Button>
          ) : classification.state ===
              SequentialListClassificationState.Paused ||
            classification.state ===
              SequentialListClassificationState.Failed ? (
            <Button
              size="sm"
              variant="primary"
              className="py-0 px-1"
              onClick={resumeListClassification}
            >
              Resume
            </Button>
          ) : null}
          {pauseClassificationResult?.processing ||
          classification.pauseRequest ? (
            <Button size="sm" variant="warning" className="py-0 px-1" disabled>
              Pausing
            </Button>
          ) : (classification.state ===
              SequentialListClassificationState.InProgress ||
              classification.state ===
                SequentialListClassificationState.Queued) &&
            !cancelClassificationResult?.processing &&
            !classification.cancelRequest ? (
            <Button
              size="sm"
              variant="warning"
              className="py-0 px-1"
              onClick={pauseListClassification}
            >
              Pause
            </Button>
          ) : null}
        </td>
        <td>
          {cancelClassificationResult?.processing ||
          classification.cancelRequest ? (
            <Button size="sm" variant="danger" className="py-0 px-1" disabled>
              Cancelling
            </Button>
          ) : (classification.state ===
              SequentialListClassificationState.InProgress ||
              classification.state ===
                SequentialListClassificationState.Queued) &&
            !pauseClassificationResult?.processing &&
            !classification.pauseRequest ? (
            <Button
              size="sm"
              variant="danger"
              className="py-0 px-1"
              onClick={cancelListClassification}
            >
              Cancel
            </Button>
          ) : null}
        </td>
      </>
    );
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => ({
  cancelClassificationResult:
    state.classifyList.cancelClassificationResults[ownProps.classification.id],
  pauseClassificationResult:
    state.classifyList.pauseClassificationResults[ownProps.classification.id],
  resumeClassificationResult:
    state.classifyList.resumeClassificationResults[ownProps.classification.id],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  cancelListClassification: () =>
    dispatch(
      cancelListClassification(
        ownProps.classification.siteLibraryId,
        ownProps.classification.id
      )
    ),
  pauseListClassification: () =>
    dispatch(
      pauseListClassification(
        ownProps.classification.siteLibraryId,
        ownProps.classification.id
      )
    ),
  resumeListClassification: () =>
    dispatch(
      resumeListClassification(
        ownProps.classification.siteLibraryId,
        ownProps.classification.id
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ClassificationButtons);
