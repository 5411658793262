import * as React from 'react';

import { Col, Table } from 'react-bootstrap';
import { IInterfaceConfigured, IServiceBasicInfo } from 'types';

interface IParams {
  bapiInterface: IInterfaceConfigured;
}

interface IProps {
  data: IServiceBasicInfo[];
  params: IParams;
}

export class BapiDetails extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { bapiInterface } = params;
    const cs = bapiInterface.selectedCsServerId ? (
      <tr>
        <th className="w-50">Classification server</th>
        <td>
          {
            data.find((s) => s.serverId === bapiInterface.selectedCsServerId)
              ?.name
          }
        </td>
      </tr>
    ) : undefined;
    const ses = bapiInterface.selectedSesServerId ? (
      <tr>
        <th className="w-50">SES server</th>
        <td>
          {
            data.find((s) => s.serverId === bapiInterface.selectedSesServerId)
              ?.name
          }
        </td>
      </tr>
    ) : undefined;
    return (
      <Col md={6} lg={8}>
        <h5 className="font-weight-bold">Basic API Details:</h5>
        <Table hover size="sm">
          <tbody>
            {cs}
            {ses}
          </tbody>
        </Table>
      </Col>
    );
  }
}

export default BapiDetails;
