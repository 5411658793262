import * as React from 'react';

import { Card, ListGroup, Modal } from 'react-bootstrap';
import { IActionResult, IMenuGroup, IMenuItem, IMenuSection } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import Loader from '../loader';
import Shortcuts from './shortcuts';
import { ThunkDispatch } from 'redux-thunk';
import { closeModal } from 'slices/modalSlice';
import { connect } from 'react-redux';
import { fetchMenuAndTilesNavigation } from 'slices/navigationSlice';

interface IStateProps {
  menuResult: IActionResult<IMenuGroup[]>;
}

interface IDispatchProps {
  fetchMenuAndTilesNavigation: () => void;
  closeModal?: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class LeftMenuModal extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchMenuAndTilesNavigation } = this.props;
    fetchMenuAndTilesNavigation();
  }

  public render() {
    const { menuResult } = this.props;
    const menuData = menuResult.data
      ? menuResult.data.find((group) => group.group === 'Menu')
      : null;

    const menuItems = (items: IMenuItem[]) => {
      return items.map((item) => (
        <ListGroup.Item target={item.urlTarget} action href={item.url}>
          {item.title}
        </ListGroup.Item>
      ));
    };
    const cards = (sections: IMenuSection[]) => {
      return sections.map((section, index) => (
        <Card key={index} className="shadow border-0 mb-4">
          <Card.Header className="border-0">{section.header}</Card.Header>
          <ListGroup variant="flush">{menuItems(section.items)}</ListGroup>
        </Card>
      ));
    };
    return (
      <Modal.Body>
        <>{menuData ? cards(menuData.sections) : []}</>
        <Loader component={Shortcuts} actionresult={menuResult} />
      </Modal.Body>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchMenuAndTilesNavigation: () => dispatch(fetchMenuAndTilesNavigation()),
  closeModal: () => dispatch(closeModal()),
});

const mapStateToProps = (state: IReduxState): IStateProps => ({
  menuResult: state.navigation.menuResult,
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(LeftMenuModal);
