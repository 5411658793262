import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, ITenantContactDetail } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from '../modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createContact } from 'slices/tenantSettingsSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IStateProps {
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createContact: (contact: ITenantContactDetail) => void;
}

export interface IProps extends IStateProps, IDispatchProps {}

export interface IFormData {
  type: string;
  name: string;
  email: string;
}

export class CreateContactModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, createResult } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter contact name"
            label="Contact Name"
            validate={formValidators.required}
          />
          <Field
            name="type"
            component={textAreaComponent}
            placeholder="Please enter contact type"
            label="Contact Type"
            validate={formValidators.required}
          />
          <Field
            name="email"
            component={inputComponent}
            type="email"
            placeholder="Please enter email address"
            label="Email Address"
            validate={formValidators.required}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { createContact } = this.props;
    const contact: ITenantContactDetail = {
      name: data.name,
      email: data.email,
      type: data.type,
    };

    createContact(contact);
  }
}

const CreateContactModalForm = reduxForm<IFormData, IProps>({
  form: 'createContact',
})(CreateContactModal);

const mapStateToProps = (
  state: IReduxState
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {},
    form: 'createContact',
    createResult: state.tenantSettings.createContactResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createContact: (contact: ITenantContactDetail) =>
    dispatch(createContact(contact)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateContactModalForm);
