import * as React from 'react';

import {
  DeploymentStatus,
  SequentialListClassificationState,
  SyncStatus,
} from 'types';

import CancelIcon from 'mdi-react/CancelIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import ClearIcon from 'mdi-react/ClearIcon';
import HelpIcon from 'mdi-react/HelpIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import PlayArrowIcon from 'mdi-react/PlayArrowIcon';
import QueueIcon from 'mdi-react/QueueIcon';
import ReplyIcon from 'mdi-react/ReplyIcon';

export const syncStatus: { [key in SyncStatus]: JSX.Element } = {
  [SyncStatus.Unknown]: (
    <span>
      <HelpIcon size="1.2rem" className="text-info" />
      Unknown
    </span>
  ),
  [SyncStatus.Queued]: (
    <span>
      <QueueIcon size="1.2rem" />
      Queued
    </span>
  ),
  [SyncStatus.InProgress]: (
    <span>
      <PlayArrowIcon size="1.2rem" className="text-primary" />
      In Progress
    </span>
  ),
  [SyncStatus.Completed]: (
    <span>
      <CheckIcon size="1.2rem" className="text-success" />
      Completed
    </span>
  ),
  [SyncStatus.Failed]: (
    <span>
      <ClearIcon size="1.2rem" className="text-danger" />
      Failed
    </span>
  ),
  [SyncStatus.Cancelled]: (
    <span>
      <CancelIcon size="1.2rem" className="text-warning" />
      Canceled
    </span>
  ),
};

export const deploymentStatus: { [key in DeploymentStatus]: JSX.Element } = {
  [DeploymentStatus.None]: (
    <span>
      <HelpIcon size="1.2rem" className="text-info" />
      Unknown
    </span>
  ),
  [DeploymentStatus.Pending]: (
    <span>
      <QueueIcon size="1.2rem" />
      Queued
    </span>
  ),
  [DeploymentStatus.InProgress]: (
    <span>
      <PlayArrowIcon size="1.2rem" className="text-primary" />
      In Progress
    </span>
  ),
  [DeploymentStatus.Completed]: (
    <span>
      <CheckIcon size="1.2rem" className="text-success" />
      Completed
    </span>
  ),
  [DeploymentStatus.Error]: (
    <span>
      <ClearIcon size="1.2rem" className="text-danger" />
      Failed
    </span>
  ),
  [DeploymentStatus.Cancelled]: (
    <span>
      <CancelIcon size="1.2rem" className="text-warning" />
      Canceled
    </span>
  ),
  [DeploymentStatus.Retraced]: (
    <span>
      <ReplyIcon size="1.2rem" className="text-secondary" />
      Retracted
    </span>
  ),
};

export const listClassificationStatusStatus: {
  [key in SequentialListClassificationState]: JSX.Element;
} = {
  [SequentialListClassificationState.Queued]: (
    <span>
      <QueueIcon size="1.2rem" />
      Queued
    </span>
  ),
  [SequentialListClassificationState.InProgress]: (
    <span>
      <PlayArrowIcon size="1.2rem" className="text-primary" />
      In Progress
    </span>
  ),
  [SequentialListClassificationState.Completed]: (
    <span>
      <CheckIcon size="1.2rem" className="text-success" />
      Completed
    </span>
  ),
  [SequentialListClassificationState.Paused]: (
    <span>
      <PauseIcon size="1.2rem" className="text-secondary" />
      Paused
    </span>
  ),
  [SequentialListClassificationState.Canceled]: (
    <span>
      <CancelIcon size="1.2rem" className="text-warning" />
      Canceled
    </span>
  ),
  [SequentialListClassificationState.Failed]: (
    <span>
      <ClearIcon size="1.2rem" className="text-danger" />
      Failed
    </span>
  ),
};

export const messageClasses: { [key in SyncStatus]: string } = {
  [SyncStatus.Unknown]: 'd-none',
  [SyncStatus.Queued]: 'bg-secondary text-white',
  [SyncStatus.InProgress]: 'bg-info text-white',
  [SyncStatus.Completed]: 'bg-success text-white',
  [SyncStatus.Failed]: 'bg-danger text-white',
  [SyncStatus.Cancelled]: 'bg-warning text-white',
};

export const classificationMessageClasses: {
  [key in SequentialListClassificationState]: string;
} = {
  [SequentialListClassificationState.Queued]: 'bg-secondary text-white',
  [SequentialListClassificationState.InProgress]: 'bg-info text-white',
  [SequentialListClassificationState.Completed]: 'bg-success text-white',
  [SequentialListClassificationState.Paused]: 'bg-secondary text-white',
  [SequentialListClassificationState.Canceled]: 'bg-warning text-white',
  [SequentialListClassificationState.Failed]: 'bg-danger text-white',
};
