import {
  IAccountResult,
  ILoginForm,
  IRegisterForm,
  IResetPasswordModel,
} from '../types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export class AccountEndpoint {
  public readonly ROOT = 'account';

  public async getAccount(): Promise<AxiosResponse<IAccountResult>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return await api.get(url);
  }

  public async getLoginProviders(): Promise<AxiosResponse<string[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'loginProviders']);
    return await api.get(url);
  }

  public async getApiKey(): Promise<AxiosResponse<string>> {
    const url = Endpoint.getUrl([this.ROOT, 'apikey']);
    return await api.get(url);
  }

  public async postApiKey(): Promise<AxiosResponse<string>> {
    const url = Endpoint.getUrl([this.ROOT, 'apikey']);
    return await api.post(url, {});
  }

  public async setPassword(data: any): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([this.ROOT, 'setPassword']);
    return await api.post(url, data);
  }

  public async changePassword(data: any): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([this.ROOT, 'changePassword']);
    return await api.post(url, data);
  }

  public async login(
    data: ILoginForm
  ): Promise<AxiosResponse<string | undefined>> {
    const url = Endpoint.getUrl([this.ROOT, 'login']);
    return await api.post(url, data, { maxRedirects: 0 });
  }

  public async register(data: IRegisterForm): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([this.ROOT, 'register']);
    return await api.post(url, data);
  }

  public async externalLogin(provider: string): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([this.ROOT, 'externalLogin', provider]);
    return await api.get(url);
  }

  public async logout(): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([this.ROOT, 'logout']);
    return await api.post(url);
  }

  public async confirmEmail(
    userId: string,
    code: string
  ): Promise<AxiosResponse<boolean>> {
    const queryItems = [new QueryItem('code', encodeURIComponent(code))];
    const url = Endpoint.getUrl(
      [this.ROOT, 'confirmEmail', userId],
      queryItems
    );
    return await api.post(url);
  }

  public async sendResetPasswordEmail(email: string): Promise<AxiosResponse> {
    const queryItems = [new QueryItem('email', email)];
    const url = Endpoint.getUrl(
      [this.ROOT, 'sendResetPasswordEmail'],
      queryItems
    );
    return await api.post(url);
  }

  public async resetPassword(
    userId: string,
    resetPasswordModel: IResetPasswordModel
  ): Promise<AxiosResponse> {
    const url = Endpoint.getUrl([this.ROOT, 'resetPassword', userId]);
    return await api.post(url, resetPasswordModel);
  }

  public async resendConfirmationEmail(email: string): Promise<AxiosResponse> {
    const queryItems = [new QueryItem('email', email)];
    const url = Endpoint.getUrl(
      [this.ROOT, 'resendConfirmationEmail'],
      queryItems
    );
    return await api.post(url);
  }
}
