import * as React from 'react';

import { IInterfaceConfigured } from 'types';
import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

export interface IParams {
  interfaceId?: string;
}

interface IProps {
  params: IParams;
  data: IInterfaceConfigured[];
}

export class BapiInterfaces extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const interfaces = data.map((i, index) => (
      <ListGroup.Item
        as={Link}
        to={`/interfaces/bapi/${i.id!}`}
        action
        key={index}
        active={params.interfaceId === i.id}
      >
        {i.name}
      </ListGroup.Item>
    ));
    return (
      <>
        {data.length ? (
          <p className="font-weight-bold">Please select:</p>
        ) : null}
        <ListGroup className="shadow mb-2">{interfaces}</ListGroup>
      </>
    );
  }
}

export default BapiInterfaces;
