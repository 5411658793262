import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IMenuGroup } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class NavigationEndpoint {
  public readonly ROOT = 'navigation';

  public getShortcuts(): Promise<AxiosResponse<IMenuGroup[]>> {
    const queryItems = [new QueryItem('area', 'dashboard')];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }

  public getMenu(): Promise<AxiosResponse<IMenuGroup[]>> {
    const queryItems = [new QueryItem('area', 'menu')];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }

  public getUserMenu(): Promise<AxiosResponse<IMenuGroup[]>> {
    const queryItems = [new QueryItem('area', 'user')];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }
}
