import * as React from 'react';

import { Col, ListGroup, ProgressBar, Row } from 'react-bootstrap';

import { IResult } from 'types';

interface IProps {
  result: IResult;
}

class ResultTile extends React.Component<IProps> {
  public render() {
    const { result } = this.props;
    return (
      <ListGroup.Item>
        <Row>
          <Col xs={9} md={10}>
            {result.Name}
          </Col>
          <Col xs={3} md={2}>
            {result.Score === 0 ? (
              <span>Manual</span>
            ) : (
              <ProgressBar
                now={result.Score}
                label={result.Score}
                variant="success"
                className="h-100"
              />
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default ResultTile;
