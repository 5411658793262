import * as React from 'react';

import BapiInterfaces, { IParams } from './bapiInterfaces';
import { Button, Col, Row } from 'react-bootstrap';
import { IActionResult, IInterfaceConfigured, ModalContents } from 'types';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import BapiInterfaceSubPage from './bapiInterfaceSubPage';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import PlusIcon from 'mdi-react/PlusIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchAllBapiInterfaces } from 'slices/bapiSlice';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  interfaceId?: string;
}

interface IStateProps {
  fetchAllInterfacesResult: IActionResult<IInterfaceConfigured[]>;
  canManageInterfaces: boolean;
}

interface IDispatchProps {
  fetchAllBapiInterfaces: () => void;
  openCreateModal: () => void;
}

interface IProps extends IOwnProps, IDispatchProps, IStateProps {}

export class BapiInterfacesSubPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.interfaceSubPage = this.interfaceSubPage.bind(this);
  }

  public componentDidMount() {
    const { fetchAllBapiInterfaces } = this.props;
    fetchAllBapiInterfaces();
  }

  public render() {
    const {
      fetchAllInterfacesResult,
      interfaceId,
      openCreateModal,
      canManageInterfaces,
    } = this.props;
    return (
      <Row>
        <Col sm={3}>
          {canManageInterfaces ? (
            <p>
              <Button size="sm" variant="link" onClick={openCreateModal}>
                <PlusIcon size="1.2rem" className="mr-1" />
                Add a Basic API Interface
              </Button>
            </p>
          ) : null}
          <LoaderWithParams<IInterfaceConfigured[], IParams>
            component={BapiInterfaces}
            actionresult={fetchAllInterfacesResult}
            params={{ interfaceId }}
          />
        </Col>
        <Switch>
          <Route
            exact
            path="/interfaces/bapi/:interfaceId"
            render={this.interfaceSubPage}
          />
        </Switch>
      </Row>
    );
  }

  private interfaceSubPage = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => {
    const { interfaceId } = props.match.params;
    const { fetchAllInterfacesResult } = this.props;
    return (
      <LoaderWithParams
        component={BapiInterfaceSubPage}
        actionresult={fetchAllInterfacesResult}
        params={{ interfaceId }}
      />
    );
  };
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canManageInterfaces: authorizer.canManageInterfaces(),
    fetchAllInterfacesResult: state.bapi.fetchAllInterfacesResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchAllBapiInterfaces: () => dispatch(fetchAllBapiInterfaces()),
  openCreateModal: () =>
    dispatch(
      openModal('Add Basic API Interface', ModalContents.CreateBapiInterface, {
        position: 'right',
      })
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(BapiInterfacesSubPage);
