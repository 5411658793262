import { ISyncJob, ISyncRun, ITermStoreData, ITermStoreSyncJob } from 'types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class TermStoreEndpoint {
  public ROOT: string;

  constructor(interfaceId: string) {
    this.ROOT = interfaceId;
  }

  public getJobs(
    spoSubscriptionUrl: string,
    siteUrl?: string
  ): Promise<AxiosResponse<ITermStoreSyncJob[]>> {
    const queryItems = [
      new QueryItem('spoSubscriptionUrl', spoSubscriptionUrl),
      new QueryItem('spHostUrl', siteUrl),
    ];
    const url = Endpoint.getUrl([this.ROOT, 'sync'], queryItems);
    return api.get(url);
  }

  public getJobRuns(jobId: string): Promise<AxiosResponse<ISyncRun[]>> {
    const queryItem = [new QueryItem('jobId', jobId)];
    const url = Endpoint.getUrl([this.ROOT, 'sync', 'history'], queryItem);
    return api.get(url);
  }

  public getJobRun(runId: string): Promise<AxiosResponse<ISyncRun>> {
    const url = Endpoint.getUrl([this.ROOT, 'sync', 'run', runId]);
    return api.get(url);
  }

  public getJob(
    jobId: string,
    siteUrl: string
  ): Promise<AxiosResponse<ITermStoreSyncJob>> {
    const queryItems = [new QueryItem('spHostUrl', siteUrl)];
    const url = Endpoint.getUrl([this.ROOT, 'sync', jobId], queryItems);
    return api.get(url);
  }

  public loadTermstores(
    siteUrl: string
  ): Promise<AxiosResponse<ITermStoreData[]>> {
    const queryItem = [new QueryItem('spHostUrl', siteUrl)];
    const url = Endpoint.getUrl([this.ROOT, 'sync', 'termstores'], queryItem);
    return api.get(url);
  }

  public queueSync(
    jobId: string,
    siteUrl: string,
    forced = false
  ): Promise<AxiosResponse<ISyncRun>> {
    const queryItem = [
      new QueryItem('jobId', jobId),
      new QueryItem('spHostUrl', siteUrl),
      new QueryItem('force', forced.toString()),
    ];
    const url = Endpoint.getUrl([this.ROOT, 'sync', 'queue'], queryItem);
    return api.put(url, {});
  }

  public cancelSync(jobId: string): Promise<AxiosResponse<ISyncRun>> {
    const queryItem = [new QueryItem('jobId', jobId)];
    const url = Endpoint.getUrl([this.ROOT, 'sync', 'cancel'], queryItem);
    return api.delete(url);
  }

  public deleteJob(jobId: string): Promise<AxiosResponse<any>> {
    const queryItem = [new QueryItem('jobId', jobId)];
    const url = Endpoint.getUrl([this.ROOT, 'sync'], queryItem);
    return api.delete(url);
  }

  public createJob(
    jobData: ISyncJob,
    spoSubscriptionUrl: string,
    siteUrl: string
  ): Promise<AxiosResponse<ITermStoreSyncJob>> {
    const queryItems = [
      new QueryItem('spoSubscriptionUrl', spoSubscriptionUrl),
      new QueryItem('spHostUrl', siteUrl),
    ];
    const url = Endpoint.getUrl([this.ROOT, 'sync'], queryItems);
    return api.post(url, jobData);
  }

  public updateJob(
    jobData: ISyncJob,
    siteUrl: string
  ): Promise<AxiosResponse<ITermStoreSyncJob>> {
    const queryItems = [new QueryItem('spHostUrl', siteUrl)];
    const url = Endpoint.getUrl([this.ROOT, 'sync'], queryItems);
    return api.put(url, jobData);
  }
}
