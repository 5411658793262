import * as React from 'react';

import { Form, InjectedFormProps, reduxForm } from 'redux-form';
import {
  IActionResult,
  IServiceBasicInfo,
  ISyncJob,
  ITermStoreData,
} from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import LoaderWithParams from 'components/common/loaderWithParams';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import TermStoreSelector from './termStoreSelector';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createSyncJob } from 'slices/termStoreSlice';
import sesServerSelector from './sesServerSelector';

interface IOwnProps {
  spoSubscriptionUrl: string;
  interfaceId: string;
  spHostUrl: string;
}

interface IStateProps {
  createResult: IActionResult<boolean>;
  fetchServicesOfTypeResult: IActionResult<IServiceBasicInfo[]>;
  termStoreResult: IActionResult<ITermStoreData[]>;
}

interface IDispatchProps {
  createSyncJob: (data: ISyncJob) => void;
}

export interface IFormData {
  termStoreId: string;
  termStoreGroupId: string;
  sesServerId: string;
  sesIndex: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class CreateSyncJobModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      createResult,
      handleSubmit,
      pristine,
      fetchServicesOfTypeResult,
      termStoreResult,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Loader
            component={sesServerSelector}
            actionresult={fetchServicesOfTypeResult}
          />
          <LoaderWithParams
            component={TermStoreSelector}
            actionresult={termStoreResult}
            params={{ formName: 'createSyncJob' }}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { createSyncJob } = this.props;
    const newSyncJob: ISyncJob = {
      sesIndex: data.sesIndex,
      sesServerId: data.sesServerId,
      termStoreGroupId: data.termStoreGroupId,
      termStoreId: data.termStoreId,
    };

    createSyncJob(newSyncJob);
  }
}

const CreateSyncJobModalForm = reduxForm<IFormData, IProps>({
  form: 'createSyncJob',
})(CreateSyncJobModal);

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    createResult: state.termStore.createSyncJobResult,
    fetchServicesOfTypeResult: state.service.fetchOfTypeResult,
    termStoreResult: state.termStore.fetchResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  createSyncJob: (data: ISyncJob) =>
    dispatch(
      createSyncJob(
        data,
        ownProps.interfaceId,
        ownProps.spHostUrl,
        ownProps.spoSubscriptionUrl
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateSyncJobModalForm);
