import * as React from 'react';

import { Col, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Deployment, { IParams } from './deployment';
import {
  IActionResult,
  IDeployment,
  IInterfaceConfigured,
  ISequentialListClassification,
  ModalContents,
} from 'types';
import { downloadApp, downloadModernUiApp } from 'slices/downloadSlice';

import { AnyAction } from '@reduxjs/toolkit';
import AssessmentIcon from 'mdi-react/AssessmentIcon';
import AssociatedSites from './associatedSites';
import Authorizer from 'utils/authorizer';
import CloseIcon from 'mdi-react/CloseIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import { IReduxState } from 'reducers';
import InterfaceDetails from '../interfaceDetails';
import ListClassifications from './listClassifications';
import Loader from 'components/common/loader';
import LoaderWithParams from 'components/common/loaderWithParams';
import PencilIcon from 'mdi-react/PencilIcon';
import ReportIcon from 'mdi-react/ReportIcon';
import RoleChecker from 'components/common/roleChecker';
import RoundButton from 'components/common/roundButton';
import TermStoreSync from './termStoreSync';
import { ThunkDispatch } from 'redux-thunk';
import UsageData from '../usageData';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { fetchDeploymentStatus } from 'slices/deploymentSlice';
import { fetchListClassifications } from 'slices/classifyListSlice';
import { fetchSharePointErrors } from 'slices/sharePointErrorsSlice';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: IInterfaceConfigured;
}

interface IStateProps {
  deploymentsResult: IActionResult<IDeployment[]>;
  listClassificationsResult: IActionResult<ISequentialListClassification[]>;
  statusResult: IActionResult<IDeployment>;
  canManageInterfaces: boolean;
  canDeploy: boolean;
}

interface IDispatchProps {
  openDeleteModal: () => void;
  openEditModal: () => void;
  openErrorModal: () => void;
  fetchDeploymentStatus: (siteUrl: string) => void;
  openExportModal: () => void;
  downloadApp: () => void;
  downloadModernUiApp: () => void;
  fetchListClassifications: () => void;
  fetchSharePointErrors: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class SpoInterface extends React.PureComponent<IProps> {
  private spHostUrl?: string;

  constructor(props: IProps) {
    super(props);
    this.spHostUrl = cookie.load('SPHostUrl');
    this.onErrorButtonClick = this.onErrorButtonClick.bind(this);
  }

  public componentDidMount() {
    const {
      fetchDeploymentStatus,
      fetchListClassifications,
      canDeploy,
    } = this.props;
    if (canDeploy) {
      if (this.spHostUrl) {
        fetchDeploymentStatus(this.spHostUrl);
      }
      fetchListClassifications();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      fetchDeploymentStatus,
      fetchListClassifications,
      data,
      canDeploy,
    } = this.props;
    if (prevProps.data.id !== data.id) {
      if (canDeploy) {
        if (this.spHostUrl) {
          fetchDeploymentStatus(this.spHostUrl);
        }
        fetchListClassifications();
      }
    }
  }

  public render() {
    const {
      data,
      openEditModal,
      openDeleteModal,
      deploymentsResult,
      openExportModal,
      statusResult,
      canManageInterfaces,
      downloadApp,
      downloadModernUiApp,
      listClassificationsResult,
    } = this.props;

    const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        Get applications
      </Tooltip>
    );

    return (
      <Col sm={9}>
        <RoundButton
          icon={CloseIcon}
          disabled={!canManageInterfaces}
          onClick={openDeleteModal}
          variant="danger"
          className="float-right"
          title="Delete interface"
        />
        <RoundButton
          icon={PencilIcon}
          disabled={!canManageInterfaces}
          onClick={openEditModal}
          variant="primary"
          className="float-right mr-1"
          title="Edit interface"
        />
        <OverlayTrigger
          placement="top"
          delay={{ show: 50, hide: 50 }}
          overlay={renderTooltip}
        >
          <Dropdown className="float-right mr-1">
            <Dropdown.Toggle
              id="dropdown-download"
              variant="success"
              className="btn-circle"
            >
              <DownloadIcon size="1.4rem" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={downloadApp}>Download App</Dropdown.Item>
              <Dropdown.Item onClick={downloadModernUiApp}>
                Download Modern UI
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href={`/spa/spoInstruction`} target="_blank">
                Instructions
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>

        <RoundButton
          icon={AssessmentIcon}
          onClick={openExportModal}
          variant="info"
          className="float-right mr-1"
          title="Export usage data"
        />
        <RoundButton
          icon={ReportIcon}
          onClick={this.onErrorButtonClick}
          variant="warning"
          className="float-right mr-1"
          title="Show SPO connection errors"
        />
        <h2 className="mt-0 mb-5">{data.name}</h2>
        <UsageData interfaceId={data.id!} />
        <InterfaceDetails data={data} />
        <Row>
          <LoaderWithParams<IDeployment, IParams>
            component={Deployment}
            actionresult={statusResult}
            params={{ interfaceId: data.id!, spHostUrl: this.spHostUrl }}
          />
        </Row>
        <RoleChecker requiredRoles={['DeploymentManager']}>
          <LoaderWithParams
            component={AssociatedSites}
            actionresult={deploymentsResult}
            params={{ interfaceId: data.id! }}
          />
          <Loader
            component={ListClassifications}
            actionresult={listClassificationsResult}
          />
        </RoleChecker>
        <RoleChecker requiredRoles={['TermstoreSyncManager']}>
          <TermStoreSync interfaceId={data.id!} spHostUrl={this.spHostUrl} />
        </RoleChecker>
      </Col>
    );
  }

  private onErrorButtonClick() {
    const { openErrorModal, fetchSharePointErrors } = this.props;
    fetchSharePointErrors();
    openErrorModal();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    deploymentsResult: state.deployment.deploymentsResult,
    listClassificationsResult: state.classifyList.listClassificationsResult,
    statusResult: state.deployment.statusResult,
    canManageInterfaces: authorizer.canManageInterfaces(),
    canDeploy: authorizer.canDeploy(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDeleteModal: () =>
    dispatch(
      openModal(
        `Delete SharePoint Interface`,
        ModalContents.DeleteSpoInterface,
        { position: 'right' },
        ownProps.data
      )
    ),
  openEditModal: () =>
    dispatch(
      openModal(
        'Edit SharePoint Interface',
        ModalContents.EditSpoInterface,
        {
          position: 'right',
        },
        ownProps.data
      )
    ),
  openExportModal: () =>
    dispatch(
      openModal(
        'Export usage data',
        ModalContents.UsageReport,
        {
          position: 'right',
        },
        { interfaceId: ownProps.data.id!, interfaceType: 'spo' }
      )
    ),
  openErrorModal: () =>
    dispatch(
      openModal(`SharePoint connection errors`, ModalContents.SpoErrors, {
        position: 'right',
        size: 'lg',
      })
    ),
  fetchDeploymentStatus: (siteUrl: string) =>
    dispatch(fetchDeploymentStatus(ownProps.data.id!, siteUrl)),
  fetchListClassifications: () =>
    dispatch(fetchListClassifications(ownProps.data.id!)),
  downloadApp: () => dispatch(downloadApp()),
  downloadModernUiApp: () => dispatch(downloadModernUiApp()),
  fetchSharePointErrors: () =>
    dispatch(fetchSharePointErrors(ownProps.data.id!)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SpoInterface);
