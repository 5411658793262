import * as React from 'react';

import { INotification, ModalContents } from 'types';
import { Media, NavDropdown } from 'react-bootstrap';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { loadHistory } from 'slices/notificationSlice';
import { localLongDate } from 'utils/dateUtils';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  notification: INotification;
}

interface IStateProps {
  canViewLogs: boolean;
}

interface IDispatchProps {
  openDetails: () => void;
  loadHistory: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class Notification extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.showDetails = this.showDetails.bind(this);
  }
  public render() {
    const { notification } = this.props;
    return (
      <NavDropdown.Item as={Media} onClick={this.showDetails}>
        <Media.Body>
          <span
            dangerouslySetInnerHTML={{ __html: notification.responseHtml }}
          />
          <br />
          <small className="text-muted">
            {localLongDate(notification.lastModified)}
          </small>
        </Media.Body>
      </NavDropdown.Item>
    );
  }

  private showDetails() {
    const { canViewLogs, openDetails, loadHistory } = this.props;
    if (!canViewLogs) return;
    loadHistory();
    openDetails();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canViewLogs: authorizer.canViewLogs(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDetails: () =>
    dispatch(
      openModal(
        'Notification history',
        ModalContents.Notification,
        {
          size: 'lg',
          position: 'right',
        },
        ownProps.notification
      )
    ),
  loadHistory: () => dispatch(loadHistory(ownProps.notification.correlationId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
