import { ISpoQueryError } from 'types';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import SpoError from './spoError';
import { Table } from 'react-bootstrap';

interface IProps {
  data: ISpoQueryError[];
}

class SpoErrorsModal extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const errors = data.map((error, index) => (
      <SpoError key={index} error={error} />
    ));
    return (
      <Modal.Body>
        {data.length ? (
          <Table size="sm" borderless>
            <tbody>{errors}</tbody>
          </Table>
        ) : (
          <div>No errors to display</div>
        )}
      </Modal.Body>
    );
  }
}

export default SpoErrorsModal;
