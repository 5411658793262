import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  IContinuationToken,
  ILogResult,
  ILogSearchQuery,
  ISupportQuery,
  ISupportResult,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import SupportEndpoint from 'endpoints/supportEndpoint';
import fileDownload from 'js-file-download';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISupportState {
  correlatedLogsResult: IActionResult<ILogResult[]>;
  downloadResult: IActionResult<boolean>;
  fetchCategoriesResult: IActionResult<string[]>;
  searchResult: IActionResult<boolean>;
  lastQuery?: ILogSearchQuery;
  logs: ILogResult[];
  allLogs: ILogResult[];
  continuationToken?: IContinuationToken;
  currentPage: number;
}

const supportSlice = createSlice({
  name: 'support',
  initialState: {
    correlatedLogsResult: { processing: false },
    fetchCategoriesResult: { processing: false },
    searchResult: { processing: false },
    downloadResult: { processing: false },
    currentPage: 0,
  } as ISupportState,
  reducers: {
    downloading(state) {
      state.downloadResult = { processing: true };
    },
    downloaded(state) {
      state.downloadResult = {
        processing: false,
        data: true,
      };
    },
    downloadFailed(state, action: PayloadAction<IError>) {
      state.downloadResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingCorrelatedLogs(state) {
      state.correlatedLogsResult = { processing: true };
    },
    fetchedCorrelatedLogs(state, action: PayloadAction<ISupportResult>) {
      state.correlatedLogsResult = {
        processing: false,
        data: action.payload.results,
      };
    },
    fetchCorrelatedLogsFailed(state, action: PayloadAction<IError>) {
      state.correlatedLogsResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingCategories(state) {
      state.fetchCategoriesResult = { processing: true };
    },
    fetchedCategories(state, action: PayloadAction<string[]>) {
      state.fetchCategoriesResult = { processing: false, data: action.payload };
    },
    fetchCategoriesFailed(state, action: PayloadAction<IError>) {
      state.fetchCategoriesResult = {
        processing: false,
        error: action.payload,
      };
    },
    searching(state, action: PayloadAction<ILogSearchQuery>) {
      state.searchResult = { processing: true };
      state.lastQuery = action.payload;
    },
    searched(state, action: PayloadAction<ISupportResult>) {
      state.searchResult = { processing: false, data: true };
      state.continuationToken = action.payload.continuationToken;
      state.allLogs = action.payload.results;
      if (state.continuationToken) {
        const lastPartition = Math.max(
          ...state.allLogs.map((log) => parseInt(log.partition, 10))
        );
        const fullPartitionItems = state.allLogs.filter(
          (item) => parseInt(item.partition, 10) !== lastPartition
        );
        const evenNumber =
          fullPartitionItems.length - (fullPartitionItems.length % 250);
        state.logs = fullPartitionItems.slice(0, evenNumber);
      } else {
        state.logs = state.allLogs;
      }
      state.currentPage = 0;
    },
    searchFailed(state, action: PayloadAction<IError>) {
      state.searchResult = {
        processing: false,
        error: action.payload,
      };
    },
    searchingNext(state) {
      state.searchResult = { processing: true };
    },
    searchedNext(state, action: PayloadAction<ISupportResult>) {
      state.searchResult = { processing: false, data: true };
      state.continuationToken = action.payload.continuationToken;
      state.allLogs.push(...action.payload.results);
      if (state.continuationToken) {
        const lastPartition = Math.max(
          ...state.allLogs.map((log) => parseInt(log.partition, 10))
        );
        const fullPartitionItems = state.allLogs.filter(
          (item) => parseInt(item.partition, 10) !== lastPartition
        );
        const evenNumber =
          fullPartitionItems.length - (fullPartitionItems.length % 250);
        state.logs = fullPartitionItems.slice(0, evenNumber);
      } else {
        state.logs = state.allLogs;
      }
      state.currentPage += 1;
    },
    searchNextFailed(state, action: PayloadAction<IError>) {
      state.searchResult = {
        processing: false,
        error: action.payload,
      };
    },
    clear(state) {
      state.continuationToken = undefined;
      state.allLogs = [];
      state.logs = [];
      state.currentPage = 0;
    },
    setPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
});

export const fetchCategories = (): AppThunk => async (dispatch) => {
  const endpoint = new SupportEndpoint();
  dispatch(fetchingCategories());
  try {
    const response = await endpoint.categories();
    dispatch(fetchedCategories(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchCategoriesFailed(Errors.getError(error)));
  }
};

export const searchLogs = (searchQuery: ILogSearchQuery): AppThunk => async (
  dispatch
) => {
  const endpoint = new SupportEndpoint();
  try {
    dispatch(searching(searchQuery));
    const response = await endpoint.search(searchQuery);
    dispatch(searched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(searchFailed(Errors.getError(error)));
  }
};

export const searchNextLogs = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { continuationToken } = state.support;
  const { lastQuery } = state.support;
  const query: ISupportQuery = Object.assign({}, lastQuery, continuationToken);
  const endpoint = new SupportEndpoint();
  try {
    dispatch(searchingNext());
    const response = await endpoint.search(query);
    dispatch(searchedNext(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(searchNextFailed(Errors.getError(error)));
  }
};

export const fetchCorrelatedLogs = (correlationId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SupportEndpoint();
  try {
    dispatch(fetchingCorrelatedLogs());
    const response = await endpoint.getLogsByCorrelationId(correlationId);
    dispatch(fetchedCorrelatedLogs(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchCorrelatedLogsFailed(Errors.getError(error)));
  }
};

export const exportLogs = (searchQuery: ILogSearchQuery): AppThunk => async (
  dispatch
) => {
  const endpoint = new SupportEndpoint();
  dispatch(downloading());
  try {
    const response = await endpoint.export(searchQuery);
    fileDownload(response.data, 'logFile.csv');
    dispatch(downloaded());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(downloadFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = supportSlice;
export const {
  fetchingCategories,
  fetchedCategories,
  fetchCategoriesFailed,
  searchFailed,
  searched,
  searching,
  searchNextFailed,
  searchedNext,
  searchingNext,
  clear,
  setPage,
  fetchCorrelatedLogsFailed,
  fetchedCorrelatedLogs,
  fetchingCorrelatedLogs,
  downloadFailed,
  downloaded,
  downloading,
} = actions;
export default reducer;
