import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  IRoleGroupResult,
  IRoleResult,
  IUserResult,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import Authorizer from 'utils/authorizer';
import UsersEndpoint from 'endpoints/usersEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IUserState {
  searchResult: IActionResult<IUserResult[]>;
  userDeleteResult: IActionResult<boolean>;
  userResult: IActionResult<IUserResult>;
  saveUserResult: IActionResult<boolean>;
  availableRolesResult: IActionResult<IRoleResult[]>;
  availableRoleGroupsResult: IActionResult<IRoleGroupResult[]>;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    searchResult: {
      processing: false,
    },
    userDeleteResult: {
      processing: false,
    },
    userResult: {
      processing: false,
    },
    saveUserResult: {
      processing: false,
    },
    availableRolesResult: {
      processing: false,
    },
    availableRoleGroupsResult: {
      processing: false,
    },
  } as IUserState,
  reducers: {
    searching(state) {
      state.searchResult = { processing: true };
    },
    searched(state, action: PayloadAction<IUserResult[]>) {
      state.searchResult = { processing: false, data: action.payload };
    },
    searchFailed(state, action: PayloadAction<IError>) {
      state.searchResult = { processing: false, error: action.payload };
    },
    deleting(state) {
      state.userDeleteResult = { processing: true };
    },
    deleted(state, action: PayloadAction<string>) {
      state.userDeleteResult = { processing: false, data: true };
      if (Array.isArray(state.searchResult.data)) {
        state.searchResult.data = state.searchResult.data.filter(
          (u) => u.id !== action.payload
        );
      }
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.userDeleteResult = { processing: false, error: action.payload };
    },
    fetching(state) {
      state.userResult = { processing: true };
    },
    fetched(state, action: PayloadAction<IUserResult>) {
      state.userResult = { processing: false, data: action.payload };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.userResult = { processing: false, error: action.payload };
    },
    updating(state) {
      state.saveUserResult = { processing: true };
    },
    updated(state, action: PayloadAction<IUserResult>) {
      state.saveUserResult = { processing: false, data: true };
      if (Array.isArray(state.searchResult.data)) {
        state.searchResult.data = [
          ...state.searchResult.data.filter((u) => u.id !== action.payload.id),
          action.payload,
        ];
      }
    },
    updateFailed(state, action: PayloadAction<IError>) {
      state.saveUserResult = { processing: false, error: action.payload };
    },
    availableRolesFetching(state) {
      state.availableRolesResult = { processing: true };
    },
    availableRolesFetched(state, action: PayloadAction<IRoleResult[]>) {
      state.availableRolesResult = { processing: false, data: action.payload };
    },
    availableRolesFetchFailed(state, action: PayloadAction<IError>) {
      state.availableRolesResult = { processing: false, error: action.payload };
    },
    availableRoleGroupsFetching(state) {
      state.availableRoleGroupsResult = { processing: true };
    },
    availableRoleGroupsFetched(
      state,
      action: PayloadAction<IRoleGroupResult[]>
    ) {
      state.availableRoleGroupsResult = {
        processing: false,
        data: action.payload,
      };
    },
    availableRoleGroupsFetchFailed(state, action: PayloadAction<IError>) {
      state.availableRoleGroupsResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const search = (keyword: string): AppThunk => async (
  dispatch,
  getState
) => {
  const endpoint = new UsersEndpoint();
  const state = getState();
  const authorizer = new Authorizer(state);
  dispatch(searching());
  try {
    const search = authorizer.canSearchAll()
      ? endpoint.searchAll.bind(endpoint)
      : endpoint.search.bind(endpoint);
    const response = await search(keyword);
    dispatch(searched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(searchFailed(Errors.getError(error)));
  }
};

export const deleteUser = (id: string): AppThunk => async (dispatch) => {
  const endpoint = new UsersEndpoint();
  dispatch(deleting());
  try {
    await endpoint.delete(id);
    dispatch(deleted(id));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
};

export const loadUser = (userId: string): AppThunk => async (dispatch) => {
  const endpoint = new UsersEndpoint();
  dispatch(fetching());
  try {
    const response = await endpoint.getUser(userId);
    dispatch(fetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

export const saveUser = (user: IUserResult): AppThunk => async (dispatch) => {
  const endpoint = new UsersEndpoint();
  dispatch(updating());
  try {
    const response = await endpoint.update(user);
    dispatch(updated(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateFailed(Errors.getError(error)));
  }
};

export const getAvailableRoles = (): AppThunk => async (dispatch) => {
  const endpoint = new UsersEndpoint();
  dispatch(availableRolesFetching());
  try {
    const response = await endpoint.getRoles();
    dispatch(availableRolesFetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(availableRolesFetchFailed(Errors.getError(error)));
  }
};

export const getAvailableRoleGroups = (): AppThunk => async (dispatch) => {
  const endpoint = new UsersEndpoint();
  dispatch(availableRoleGroupsFetching());
  try {
    const response = await endpoint.getRoleGroups();
    dispatch(availableRoleGroupsFetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(availableRoleGroupsFetchFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = userSlice;
export const {
  searching,
  searched,
  searchFailed,
  deleting,
  deleted,
  deleteFailed,
  fetching,
  fetched,
  fetchFailed,
  updated,
  updating,
  updateFailed,
  availableRoleGroupsFetchFailed,
  availableRoleGroupsFetched,
  availableRoleGroupsFetching,
  availableRolesFetchFailed,
  availableRolesFetched,
  availableRolesFetching,
} = actions;
export default reducer;
