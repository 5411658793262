import * as React from 'react';

import { DeploymentStatus, IDeployment, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deploymentStatus } from './statusIndicators';
import { fetchListClassificationsForSite } from 'slices/classifyListSlice';
import { localDate } from 'utils/dateUtils';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  deployment: IDeployment;
  interfaceId: string;
}

interface IDispatchProps {
  openDetailsModal: () => void;
  openDeleteModal: () => void;
  fetchListClassificationsForSite: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class AssociatedSite extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.details = this.details.bind(this);
  }

  public render() {
    const { deployment, openDeleteModal } = this.props;
    return (
      <tr>
        <td className="text-break">
          <Button
            variant="link"
            href={deployment.siteUrl}
            size="sm"
            className="p-0 text-left"
          >
            {deployment.siteUrl}
          </Button>
        </td>
        <td>{deployment.codeVersion}</td>
        <td className="text-nowrap">{deploymentStatus[deployment.status]}</td>
        <td className="text-nowrap">{localDate(deployment.queueDate)}</td>
        <td>
          <Button
            size="sm"
            variant="danger"
            className="py-0 px-1"
            onClick={openDeleteModal}
            disabled={deployment.status === DeploymentStatus.Completed}
          >
            Delete
          </Button>
        </td>
        <td>
          <Button
            size="sm"
            variant="outline-dark"
            className="py-0 px-1"
            onClick={this.details}
          >
            Details
          </Button>
        </td>
      </tr>
    );
  }

  private details() {
    const { openDetailsModal, fetchListClassificationsForSite } = this.props;
    fetchListClassificationsForSite();
    openDetailsModal();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDetailsModal: () =>
    dispatch(
      openModal(
        'Deployment details',
        ModalContents.DeploymentDetails,
        { position: 'right' },
        ownProps.deployment
      )
    ),
  openDeleteModal: () =>
    dispatch(
      openModal(
        'Delete deployment',
        ModalContents.DeleteDeployment,
        { position: 'right' },
        { site: ownProps.deployment, interfaceId: ownProps.interfaceId }
      )
    ),
  fetchListClassificationsForSite: () =>
    dispatch(fetchListClassificationsForSite(ownProps.deployment.id)),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(AssociatedSite);
