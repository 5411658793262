import { IRoleGroupResult, IRoleResult, IUserResult } from '../types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class UsersEndpoint {
  public readonly ROOT = 'user';

  public search(userName: string): Promise<AxiosResponse<IUserResult[]>> {
    const queryItems = [new QueryItem('username', userName)];
    const url = Endpoint.getUrl([this.ROOT, 'search'], queryItems);
    return api.get(url);
  }

  public searchAll(userName: string): Promise<AxiosResponse<IUserResult[]>> {
    const queryItems = [new QueryItem('username', userName)];
    const url = Endpoint.getUrl([this.ROOT, 'searchall'], queryItems);
    return api.get(url);
  }

  public update(user: IUserResult): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.put(url, user);
  }

  public delete(userId: string): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('userId', userId)];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.delete(url);
  }

  public getUser(id: string): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT, id]);
    return api.get(url);
  }

  public getRoles(): Promise<AxiosResponse<IRoleResult[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'roles']);
    return api.get(url);
  }

  public getRoleGroups(): Promise<AxiosResponse<IRoleGroupResult[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'roleGroups']);
    return api.get(url);
  }

  public getAuthenticationProviders(): Promise<AxiosResponse<any[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'authenticationProviders']);
    return api.get(url);
  }

  public deleteProvider(
    userId: string,
    loginProvider: any
  ): Promise<AxiosResponse<any>> {
    const queryItems = [
      new QueryItem('providerKey', loginProvider.providerKey),
    ];
    const deleteUrl = Endpoint.getUrl(['user', userId, 'provider'], queryItems);
    return api.delete(deleteUrl);
  }
}
