import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceViewModel, ServerType } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createService } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IParams {
  groupId: string;
}

interface IOwnProps {
  data: IServiceViewModel[];
  params: IParams;
}

interface IStateProps {
  createResult: IActionResult<boolean>;
  availableHosts: IServiceViewModel[];
}

interface IDispatchProps {
  createService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  timeout: number;
  hostService: string;
}

export class CreateSesModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { availableHosts, handleSubmit, pristine, createResult } = this.props;

    const hostOptions = availableHosts.map((host, index) => (
      <option value={host.serverId} key={index}>
        {host.name}
      </option>
    ));

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="timeout"
            component={inputComponent}
            type="number"
            placeholder="Please enter a timeout (in seconds)"
            label="Timeout"
            validate={formValidators.required}
          />
          <Field
            name="hostService"
            component={selectComponent}
            label="Host Service"
            validate={formValidators.required}
          >
            <option value="">Select a host service</option>
            {hostOptions}
          </Field>
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { availableHosts, params, createService } = this.props;
    const { groupId } = params;
    const hostService = availableHosts.find(
      (s) => s.serverId === data.hostService
    );

    const service: IServiceViewModel = {
      name: data.name,
      description: data.description,
      groupId,
      configData: {
        Timeout: data.timeout,
      },
      active: true,
      serverType: ServerType.SemanticEnhancementService,
      dependsUpon: [],
    };

    service.dependsUpon = [
      {
        serverId: data.hostService,
        serverType: hostService!.serverType,
      },
    ];

    createService(service);
  }
}

const CreateSesModalForm = reduxForm<IFormData, IProps>({
  form: 'createSes',
})(CreateSesModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const availableHosts = ownProps.data.filter(
    (s) =>
      (s.serverType === ServerType.AzureVirtualMachineService ||
        s.serverType === ServerType.UnmanagedHostService) &&
      s.dependentUpon!.filter(
        (d) => d.serverType === ServerType.SemanticEnhancementService
      ).length === 0
  );
  return {
    initialValues: {
      timeout: 120,
      hostService:
        availableHosts.length === 0 ? undefined : availableHosts[0].serverId,
    },
    form: 'createSes',
    createResult: state.service.createResult,
    availableHosts,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createService: (service: IServiceViewModel) =>
    dispatch(createService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateSesModalForm);
