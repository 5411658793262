import * as React from 'react';

import { Button, ListGroupItem, Media } from 'react-bootstrap';

import { IFeedItem } from 'types';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import moment from 'moment';

interface IProps {
  data: IFeedItem;
}

class UserFeedItem extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    return (
      <ListGroupItem>
        <Media>
          <Media.Body>
            <h6 className="mb-0">{data.title}</h6>
            <small>Posted: {moment.utc(data.date).format('DD/MM/YYYY')}</small>
            <p className="mb-0">
              <Button
                as="a"
                variant="link"
                className="p-0"
                href={data.link}
                target="_blank"
              >
                <strong>
                  Read more <OpenInNewIcon size="13" className="mr-1" />
                </strong>
              </Button>
            </p>
          </Media.Body>
        </Media>
      </ListGroupItem>
    );
  }
}

export default UserFeedItem;
