import * as React from 'react';

import {
  IActionResult,
  IStatusResponse,
  ProvisioningStatus,
  ServerStatus,
} from 'types';

import { AnyAction } from 'redux';
import CircleIcon from 'mdi-react/CircleIcon';
import { IReduxState } from 'reducers';
import { Spinner } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchStatus } from 'slices/serviceSlice';

interface IOwnProps {
  serverId: string;
}

interface IState {
  timeout: number;
}

interface IStateProps {
  serviceStatusResult: IActionResult<IStatusResponse>;
}

interface IDispatchProps {
  getStatus: (serviceId: string) => void;
}

interface IProps extends IStateProps, IDispatchProps, IOwnProps {}

export class StatusIndicator extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      timeout: 0,
    };
    this.getStatus = this.getStatus.bind(this);
  }

  public componentDidMount() {
    this.getStatus();
  }

  public componentWillUnmount() {
    const { timeout } = this.state;
    if (timeout) {
      window.clearTimeout(timeout);
    }
  }

  public render() {
    const { serviceStatusResult } = this.props;
    const inProgress =
      serviceStatusResult == null || serviceStatusResult.processing;
    let statusElement = null;
    let additionalElement = null;

    if (serviceStatusResult == null || serviceStatusResult.data == null) {
      if (inProgress) {
        statusElement = (
          <>
            <Spinner animation="border" size="sm" className="text-muted mr-1" />
            <strong className="text-uppercase text-muted small">
              Checking....
            </strong>
          </>
        );
      } else {
        statusElement = (
          <>
            <CircleIcon size="1rem" className="text-warning mr-1" />
            <strong className="text-uppercase text-warning small">
              Unknown
            </strong>
          </>
        );
        if (serviceStatusResult.error) {
          additionalElement = (
            <>
              <span className="text-sm mb-1 d-block">
                <small className="text-uppercase">Error:</small>
                <br />
                <span className="align-items-center d-flex">
                  {serviceStatusResult.error.subTitle}
                </span>
              </span>
            </>
          );
        }
      }
    } else {
      let status = ServerStatus.Unknown;
      let message = '';
      if (
        serviceStatusResult.data!.provisioningStatus ===
        ProvisioningStatus.Created
      ) {
        if (serviceStatusResult.error != null) {
          message = `Unable to check status - ${serviceStatusResult.error.title}`;
        } else {
          status = serviceStatusResult.data!.status;
          message = serviceStatusResult.data!.statusMessage;
        }
        switch (status) {
          case ServerStatus.Online:
            statusElement = (
              <>
                <CircleIcon size="1rem" className="text-success mr-1" />
                <strong className="text-uppercase text-success small">
                  Online
                </strong>
              </>
            );
            additionalElement = serviceStatusResult.data!.version ? (
              <>
                <span className="text-sm mb-1 d-block">
                  <small className="text-uppercase">Version:</small>
                  <br />
                  <span className="align-items-center d-flex">
                    {serviceStatusResult.data!.version}
                  </span>
                </span>
              </>
            ) : null;
            break;
          case ServerStatus.Offline:
            statusElement = (
              <>
                <CircleIcon size="1rem" className="text-danger mr-1" />
                <strong className="text-uppercase text-danger small">
                  Offline
                </strong>
              </>
            );
            additionalElement = (
              <>
                <span className="text-sm mb-1 d-block">
                  <small className="text-uppercase">Error:</small>
                  <br />
                  <span className="align-items-center d-flex">{message}</span>
                </span>
              </>
            );
            break;
          default:
            statusElement = (
              <>
                <CircleIcon size="1rem" className="text-warning mr-1" />
                <strong className="text-uppercase text-warning small">
                  Unknown
                </strong>
              </>
            );
        }
      } else if (
        serviceStatusResult.data!.provisioningStatus <
        ProvisioningStatus.Created
      ) {
        statusElement = (
          <>
            <CircleIcon size="1rem" className="text-primary mr-1" />
            <strong className="text-uppercase text-primary small">
              Provisioning...
            </strong>
          </>
        );
      } else if (
        serviceStatusResult.data!.provisioningStatus <=
        ProvisioningStatus.Deleted
      ) {
        statusElement = (
          <>
            <CircleIcon size="1rem" className="text-secondary mr-1" />
            <strong className="text-uppercase text-secondary small">
              Removing...
            </strong>
          </>
        );
      }
    }

    return (
      <>
        <span className="text-sm mb-1 d-block">
          <small className="text-uppercase">Status:</small>
          <br />
          <span className="align-items-center d-flex">{statusElement}</span>
        </span>
        {additionalElement}
      </>
    );
  }

  private getStatus() {
    const { getStatus, serverId } = this.props;
    getStatus(serverId);
    const max = 23000;
    const min = 17000;
    const timeout = window.setTimeout(
      this.getStatus,
      Math.floor(Math.random() * (max - min + 1)) + min
    );
    this.setState(Object.assign({}, this.state, { timeout }));
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => ({
  serviceStatusResult: state.service.serviceStatusResults[ownProps.serverId],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  getStatus: (serviceId: string) => dispatch(fetchStatus(serviceId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(StatusIndicator);
