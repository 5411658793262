import * as React from 'react';

import { IActionResult, IMenuGroup, IMenuItem, IMenuSection } from 'types';
import { Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import { Link } from 'react-router-dom';
import MdIcon from 'components/common/icons/mdIcon';
import { ModalContents } from 'types';
import NotificationDropdown from '../notifications/notificationDropdown';
import SharePointButtons from './sharePointButtons';
import SidePanelSwitch from '../sidePanel/sidePanelSwitch';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchUserMenuNavigation } from 'slices/navigationSlice';
import { openModal } from 'slices/modalSlice';

interface IStateProps {
  userMenuResult: IActionResult<IMenuGroup>;
}

interface IDispatchProps {
  openMenu: () => void;
  fetchUserMenuNavigation: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class NavigationBar extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
  }

  public componentDidMount() {
    const { fetchUserMenuNavigation } = this.props;
    fetchUserMenuNavigation();
  }

  public render() {
    const { userMenuResult } = this.props;

    const renderSections = (
      section: IMenuSection,
      index: number,
      sections: IMenuSection[]
    ) => {
      const renderItems = (item: IMenuItem, ind: number) => {
        return (
          <NavDropdown.Item
            key={`item-${index}-${ind}`}
            target={item.urlTarget}
            href={item.url}
          >
            <MdIcon
              iconComponent={item.iconComponent}
              size="1.1rem"
              className="mr-1"
            />
            {item.title}
          </NavDropdown.Item>
        );
      };
      const header = section.header ? (
        <NavDropdown.Header key={`section-${index}`} className="text-uppercase">
          {section.header}
        </NavDropdown.Header>
      ) : null;

      return (
        <span key={index}>
          {header}
          {section.items.map(renderItems)}
          {sections.length - 1 !== index ? (
            <NavDropdown.Divider key={`div-${index}`} />
          ) : null}
        </span>
      );
    };
    const userMenuItems = userMenuResult.data
      ? userMenuResult.data.sections.map(renderSections)
      : [];
    const userMenuDropdown = userMenuResult.data ? (
      <NavDropdown
        bsPrefix="nav-link bg-primary p-3 my-n2 d-flex arrow"
        title={
          <>
            <MdIcon
              iconComponent={userMenuResult.data.activator.iconComponent}
              size="1.5rem"
              className="mr-1"
            />
          </>
        }
        id="right-nav-dropdown"
        alignRight
      >
        <NavDropdown.Header className="text-uppercase">
          Logged in as
        </NavDropdown.Header>
        <NavDropdown.Item disabled>
          {userMenuResult.data.activator.title}
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {userMenuItems}
      </NavDropdown>
    ) : null;
    return (
      <Navbar bg="primary" variant="dark" as={Row} className="shadow z-1">
        <Navbar.Brand onClick={this.openMenu} className="p-0 cursor-pointer">
          <MdIcon iconComponent={'menu'} size="1.8rem" />
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link
            as={Link}
            to="/"
            className="bg-primary p-3 my-n2 d-flex align-items-center text-center"
          >
            Home
          </Nav.Link>
          <SharePointButtons />
        </Nav>
        <Nav className="ml-auto mr-n3">
          <>
            <NotificationDropdown />
            <SidePanelSwitch />
            {userMenuDropdown}
          </>
        </Nav>
      </Navbar>
    );
  }

  private openMenu() {
    const { openMenu } = this.props;
    openMenu();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  userMenuResult: state.navigation.userMenuResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchUserMenuNavigation: () => dispatch(fetchUserMenuNavigation()),
  openMenu: () =>
    dispatch(
      openModal('', ModalContents.Menu, {
        position: 'left',
        size: 'sm',
      })
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar);
