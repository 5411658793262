import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { ITenancyDetail } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class TenancyEndpoint {
  public readonly ROOT = 'tenant';

  public search(name?: string): Promise<AxiosResponse<ITenancyDetail[]>> {
    const queryItems = [];
    if (name) {
      queryItems.push(new QueryItem('name', name));
    }
    const url = Endpoint.getUrl([this.ROOT, 'search'], queryItems);
    return api.post(url, null);
  }
  public delete(id: string): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('id', id)];

    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.delete(url);
  }
  public load(id: string): Promise<AxiosResponse<ITenancyDetail>> {
    const queryItems = [new QueryItem('id', id)];

    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }
  public select(id?: string): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('id', id)];
    const url = Endpoint.getUrl([this.ROOT, 'select'], queryItems);
    return api.post(url, null);
  }
  public create(
    tenant: ITenancyDetail
  ): Promise<AxiosResponse<ITenancyDetail>> {
    const data = {
      expiryDate: tenant.expiryDate,
      name: tenant.name,
      active: true,
    };
    const url = Endpoint.getUrl([this.ROOT]);
    return api.post(url, data);
  }
  public update(item: ITenancyDetail): Promise<AxiosResponse<ITenancyDetail>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.put(url, item);
  }
  public addDomain(name: string): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.put(url, name);
  }

  public loadCurrent(): Promise<AxiosResponse<ITenancyDetail>> {
    const url = Endpoint.getUrl([this.ROOT, 'current']);
    return api.get(url);
  }
}
