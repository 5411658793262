import * as React from 'react';

import { Field } from 'redux-form';
import categorySelectComponent from './categorySelectComponent';

interface IOwnProps {
  data: string[];
}

interface IProps extends IOwnProps {}

class CategorySelect extends React.Component<IProps> {
  public render() {
    const { data: categories } = this.props;
    return (
      <Field
        name="excludedCategories"
        component={categorySelectComponent}
        label="Category"
        categories={categories}
      />
    );
  }
}

export default CategorySelect;
