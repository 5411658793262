import { LogLevel } from 'types';
import moment from 'moment';

export default class SupportUtilities {
  public static getlevelClass(level: LogLevel): string {
    switch (level) {
      case 0: //Unknown
        return '';
      case 1: //Low
        return '';
      case 2: //Medium
        return 'bg-info';
      case 4: //High
        return 'bg-warning';
      case 8: //Error
        return 'bg-danger';
      case 16: //Critical
        return 'bg-danger';
      case 256: //Statistics
        return 'bg-light';
      default:
        return '';
    }
  }
  public static getLevelName(level: LogLevel): string {
    switch (level) {
      case 0: //Unknown
        return 'Unknown';
      case 1: //Low
        return 'Low';
      case 2: //Medium
        return 'Medium';
      case 4: //High
        return 'High';
      case 8: //Critical
        return 'Critical';
      case 16: //Error
        return 'Error';
      case 256: //Statistics
        return 'Statistics';
      default:
        return '';
    }
  }

  public static formatDate(date: any): string {
    return moment(date).format('lll');
  }
}
