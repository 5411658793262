import { Errors, IError } from 'utils/errors';
import { IActionResult, IInterfaceRow } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import InterfaceEndpoint from 'endpoints/interfaceEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IInterfaceState {
  searchResult: IActionResult<IInterfaceRow[]>;
}

const interfaceSlice = createSlice({
  name: 'interface',
  initialState: {
    searchResult: {
      processing: false,
    },
  } as IInterfaceState,
  reducers: {
    searching(state) {
      state.searchResult = { processing: true };
    },
    searched(state, action: PayloadAction<IInterfaceRow[]>) {
      state.searchResult = { processing: false, data: action.payload };
    },
    searchFailed(state, action: PayloadAction<IError>) {
      state.searchResult = { processing: false, error: action.payload };
    },
  },
});

export const searchInterface = (name?: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new InterfaceEndpoint();
  dispatch(searching());
  try {
    const response = await endpoint.list(name);
    dispatch(searched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(searchFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = interfaceSlice;
export const { searching, searched, searchFailed } = actions;
export default reducer;
