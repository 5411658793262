import * as React from 'react';

import { Alert, Modal } from 'react-bootstrap';
import {
  CSArticleType,
  CSBodyType,
  CSClusteringType,
  ClassificationMode,
  IActionResult,
  IFieldConfiguration,
  IFieldConfigurationGetModel,
} from 'types';
import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from 'redux-form';

import AdvancedClassificationOptions from './advancedClassificationOptions';
import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import ModalFormFooter from 'components/common/modalFormFooter';
import RulebasesSelect from './rulebasesSelect';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchAvailableRulebases } from 'slices/rulebaseSlice';
import { formValidators } from 'utils/formValidators';
import { saveListSimConfig } from 'slices/simSettingsSlice';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IParams {
  fieldId: string;
  siteLibraryId: string;
}

export interface IFormData {
  classificationMode: ClassificationMode;
  csServerId: string;
  articleType: CSArticleType;
  bodyType: CSBodyType;
  clusteringThreshold?: number;
  clusteringType: CSClusteringType;
  isRoot: boolean;
  selectedRulebaseClasses: string[];
  threshold?: number;
  inheritAdvancedOptions: boolean;
}

interface IState {
  classificationActive: boolean;
}

interface IOwnProps {
  params: IParams;
  data: IFieldConfigurationGetModel;
}

interface IStateProps {
  saveSimSettingsResult: IActionResult<boolean>;
  availableRulebasesResult: IActionResult<string[]>;
  inheritAdvancedOptions: boolean;
}

interface IDispatchProps {
  loadAvailableRulebases: (serverId: string) => void;
  saveListSimConfig: (configuration: IFieldConfiguration) => void;
}

export interface IProps extends IStateProps, IOwnProps, IDispatchProps {}

export class EditListColumnModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
    this.handleCsServerChanged = this.handleCsServerChanged.bind(this);
    this.handleClassificationModeChanged = this.handleClassificationModeChanged.bind(
      this
    );
    this.state = {
      classificationActive: props.data.classificationMode !== 0,
    };
  }

  public componentDidMount() {
    const { loadAvailableRulebases } = this.props;
    const csServerId = this.props.data.csServerId;
    loadAvailableRulebases(csServerId);
  }

  public render() {
    const {
      data,
      handleSubmit,
      pristine,
      saveSimSettingsResult,
      availableRulebasesResult,
      inheritAdvancedOptions,
    } = this.props;
    const { classificationActive } = this.state;
    const csServers = data.availableCsServers.map((server, index) => (
      <option value={server.key} key={index}>
        {server.value}
      </option>
    ));
    return (
      <div>
        {!data.isDirectConfiguration && data.hasConfiguration ? (
          <Alert variant="info">
            This column configuration is inherited from a content type. Updates
            made here will override the inherited configuration.
          </Alert>
        ) : null}
        <Form onSubmit={handleSubmit(this.save)}>
          <Modal.Body>
            <Field
              name="classificationMode"
              component={selectComponent}
              label="Classification State"
              onChange={this.handleClassificationModeChanged}
              tooltip="Select an appropriate classification state:
              Disabled - No automatic classification will take place.
              Enabled - Automatically classified when created/updated, unless manually edited."
            >
              <option value={ClassificationMode.None}>Disabled</option>
              <option value={ClassificationMode.Assisted}>Enabled</option>
            </Field>
            {classificationActive ? (
              <>
                <Field
                  name="csServerId"
                  component={selectComponent}
                  label="Classification Server"
                  onChange={this.handleCsServerChanged}
                  validate={formValidators.required}
                >
                  <option value="00000000-0000-0000-0000-000000000000">
                    Select Classification Server
                  </option>
                  {csServers}
                </Field>
                <Loader
                  component={RulebasesSelect}
                  actionresult={availableRulebasesResult}
                />
                <AdvancedClassificationOptions
                  inheritAdvancedOptions={inheritAdvancedOptions}
                />
              </>
            ) : null}
          </Modal.Body>
          <ModalFormFooter
            actionResult={saveSimSettingsResult}
            disabled={pristine}
          />
        </Form>
      </div>
    );
  }

  private async save(data: IFormData) {
    const { data: field, saveListSimConfig } = this.props;
    const simConfig = {
      classificationMode: data.classificationMode,
      csServerId: data.csServerId,
      displayName: field.displayName,
      fieldId: field.fieldId,
      fieldType: field.fieldType,
      locked: field.locked,
      articleType: data.articleType,
      bodyType: data.bodyType,
      clusteringThreshold: data.clusteringThreshold,
      clusteringType: data.clusteringType,
      isRoot: field.isRoot,
      selectedRulebaseClasses: data.selectedRulebaseClasses,
      threshold: data.threshold,
      inheritAdvancedOptions: data.inheritAdvancedOptions,
    };

    saveListSimConfig(simConfig);
  }

  private handleCsServerChanged(event: React.ChangeEvent<HTMLSelectElement>) {
    const { loadAvailableRulebases } = this.props;
    const csServerId = event.currentTarget.value;
    if (csServerId) {
      loadAvailableRulebases(csServerId);
      const { change } = this.props;
      change('selectedRulebaseClasses', []);
    }
  }

  private handleClassificationModeChanged(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const classificationMode = parseInt(event.currentTarget.value, 10);
    if (classificationMode === ClassificationMode.None) {
      const { change } = this.props;
      change('selectedRulebaseClasses', []);
      change('csServerId', '00000000-0000-0000-0000-000000000000');
      change('threshold', null);
      change('bodyType', null);
      change('articleType', null);
      change('clusteringType', null);
      change('clusteringThreshold', null);
    }
    this.setState({
      classificationActive: classificationMode !== ClassificationMode.None,
    });
  }
}

const EditListColumnModalForm = reduxForm<IFormData, IProps>({
  form: 'editListColumn',
})(EditListColumnModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const selector = formValueSelector('editListColumn');
  const inheritAdvancedOptions = selector(state, 'inheritAdvancedOptions');
  return {
    saveSimSettingsResult: state.simSettings.saveSimSettingsResult,
    availableRulebasesResult: state.rulebase.availableRulebasesResult,
    initialValues: {
      csServerId: ownProps.data.csServerId,
      classificationMode: ownProps.data.classificationMode,
      selectedRulebaseClasses: ownProps.data.selectedRulebaseClasses ?? [],
      threshold: ownProps.data.threshold,
      bodyType: ownProps.data.bodyType,
      articleType: ownProps.data.articleType,
      clusteringType: ownProps.data.clusteringType,
      clusteringThreshold: ownProps.data.clusteringThreshold,
      inheritAdvancedOptions: ownProps.data.inheritAdvancedOptions,
    },
    inheritAdvancedOptions,
    form: 'editListColumn',
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => {
  const { siteLibraryId } = ownProps.params;
  return {
    loadAvailableRulebases: (serverId: string) =>
      dispatch(fetchAvailableRulebases(serverId)),
    saveListSimConfig: (configuration: IFieldConfiguration) =>
      dispatch(saveListSimConfig(siteLibraryId, configuration)),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditListColumnModalForm);
