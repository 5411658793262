import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import {
  IServiceGroup,
  IServiceViewModel,
  ModalContents,
  ServerType,
} from 'types';
import { fetchAvailableVmValues, fetchDefaultVmValues } from 'slices/vmSlice';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import NameResolver from 'utils/nameResolver';
import PlusIcon from 'mdi-react/PlusIcon';
import Service from './service';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchDefaultTsValues } from 'slices/tsSlice';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: IServiceViewModel[];
}

interface IStateProps {
  serverType: ServerType;
  serviceGroup: IServiceGroup;
}

interface IDispatchProps {
  openCreateModal: (serverType: ServerType, groupId: string) => void;
  fetchDefaultVmValues: () => void;
  fetchAvailableVmValues: (serviceGroupId: string) => void;
  fetchDefaultTsValues: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const modalContents: { [key in ServerType]: ModalContents } = {
  [ServerType.AzureVirtualMachineService]: ModalContents.CreateVm,
  [ServerType.ClassificationService]: ModalContents.CreateCs,
  [ServerType.OntologyEditor]: ModalContents.CreateOe,
  [ServerType.SemanticEnhancementService]: ModalContents.CreateSes,
  [ServerType.TripleStore]: ModalContents.CreateTs,
  [ServerType.UnmanagedHostService]: ModalContents.CreateUh,
  [ServerType.Workbench]: ModalContents.CreteWb,
  [ServerType.StudioService]: ModalContents.CreateStudio,
};

export class Services extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.create = this.create.bind(this);
  }

  public render() {
    const { serverType, data } = this.props;
    const nameResolver = new NameResolver();
    const serverName = nameResolver.getServerTypeName(serverType);
    const relevantServices = data.filter((s) => s.serverType === serverType);
    const services = relevantServices.map((service, index) => (
      <Service service={service} key={index} />
    ));
    return (
      <Col as="aside" className="bg-white">
        <Row>
          <Col as="header" className="bg-light border-bottom">
            <p className="mt-3">
              <strong>{serverName}</strong>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="pt-3">
            <Button variant="link" size="sm" onClick={this.create}>
              <PlusIcon size="1.2rem" className="mr-1" />
              {serverName}
            </Button>
          </Col>
        </Row>
        <Row>{services}</Row>
      </Col>
    );
  }

  private create() {
    const {
      openCreateModal,
      serverType,
      serviceGroup,
      fetchDefaultVmValues,
      fetchAvailableVmValues,
      fetchDefaultTsValues,
    } = this.props;
    switch (serverType) {
      case ServerType.AzureVirtualMachineService:
        fetchDefaultVmValues();
        fetchAvailableVmValues(serviceGroup.id!);
        break;
      case ServerType.TripleStore:
        fetchDefaultTsValues();
        break;
    }
    openCreateModal(serverType, serviceGroup.id!);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  serverType: state.serviceType.selected!,
  serviceGroup: state.serviceGroup.selected!,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => {
  const nameResolver = new NameResolver();
  return {
    openCreateModal: (serverType: ServerType, groupId: string) =>
      dispatch(
        openModal(
          `Create ${nameResolver.getServerTypeName(serverType)}`,
          modalContents[serverType],
          {
            position: 'right',
            size: 'lg',
          },
          groupId
        )
      ),
    fetchDefaultVmValues: () => dispatch(fetchDefaultVmValues()),
    fetchAvailableVmValues: (serviceGroupId: string) =>
      dispatch(fetchAvailableVmValues(serviceGroupId)),
    fetchDefaultTsValues: () => dispatch(fetchDefaultTsValues()),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Services);
