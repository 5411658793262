import * as React from 'react';

import { Alert, Button, Col, Container, Row, Table } from 'react-bootstrap';
import {
  ClassificationMode,
  IConfigurableFields,
  IFieldConfigurationInfo,
  ISiteLibrary,
  ModalContents,
  ModuleActivationState,
  SpoFieldGroup,
} from 'types';
import LockOutlineIcon from 'mdi-react/LockOutlineIcon';
import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchListField } from 'slices/simSettingsSlice';
import { openModal } from 'slices/modalSlice';

interface IParams {
  siteLibrary: ISiteLibrary;
  spHostUrl: string;
}

interface IOwnProps {
  data: IConfigurableFields;
  params: IParams;
}

interface IDispatchProps {
  openColumnSettingsModal: (field: IFieldConfigurationInfo) => void;
  loadListField: (fieldId: string) => void;
  openDeleteColumnSettingsModal: (field: IFieldConfigurationInfo) => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ListSimSettings extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.openColumnSettingsModal = this.openColumnSettingsModal.bind(this);
  }
  public render() {
    const { data, params } = this.props;
    const { siteLibrary, spHostUrl } = params;
    const informationProtectionFields = data.configurableFields.filter(
      (cf) => cf.spoFieldGroup === SpoFieldGroup.InformationProtection
    );
    const standardFields = data.configurableFields.filter(
      (cf) => cf.spoFieldGroup === SpoFieldGroup.Standard
    );
    const sortedFields = standardFields.concat(informationProtectionFields);
    const fields = sortedFields.map((field, index) => {
      return (
        <tr key={index}>
          <td>
            <Button
              onClick={this.openColumnSettingsModal(field)}
              variant="link"
              className="p-0 d-flex"
            >
              {field.spoFieldGroup === SpoFieldGroup.InformationProtection ? (
                <LockOutlineIcon size="20" />
              ) : null}
              {field.displayName}
            </Button>
          </td>
          <td>
            {field.classificationMode === ClassificationMode.None
              ? 'Disabled'
              : 'Enabled'}
          </td>
          <td>
            {field.hasConfiguration
              ? field.isDirectConfiguration
                ? 'Own'
                : 'Inherited'
              : 'None'}
          </td>
          <td>
            {field.hasConfiguration && field.isDirectConfiguration ? (
              <Button
                onClick={this.openDeleteColumnSettingsModal(field)}
                variant="danger"
                size="sm"
                className="py-0 px-1"
              >
                Remove config
              </Button>
            ) : null}
          </td>
        </tr>
      );
    });

    return (
      <>
        <Row className="bg-light p-3 mb-3">
          <Col>
            <h1 className="d-inline">
              Semaphore Intelligent Metadata Settings
            </h1>
            <Button
              variant="primary"
              className="float-right"
              href={`${spHostUrl}/_layouts/15/listedit.aspx?List=${siteLibrary.listId}`}
            >
              Return to settings
            </Button>
          </Col>
        </Row>
        <Row>
          <Container className="p-4">
            {data.activationState === ModuleActivationState.NotActivated &&
            !data.activationExpected ? (
              <Alert variant="info">
                Semaphore Intelligent Metadata is not currently active for this
                list. It will be activated when a column is configured to use
                assisted or background mode classification.
              </Alert>
            ) : null}
            {data.activationState === ModuleActivationState.NotActivated &&
            data.activationExpected ? (
              <Alert variant="warning">
                Semaphore Intelligent Metadata is configured but is not yet
                active for this list.  After configuration please expect a short
                delay of a few minutes before this feature can be used.
              </Alert>
            ) : null}
            {data.activationState === ModuleActivationState.Activated &&
            !data.activationExpected ? (
              <Alert variant="warning">
                Semaphore Intelligent Metadata is not configured but is still
                active for this list.  After updating the configuration please
                expect a short delay of a few minutes before this feature is
                deactivated.
              </Alert>
            ) : null}
            {data.activationState === ModuleActivationState.FailedActivation ? (
              <Alert variant="danger">
                Semaphore classification module is not properly activated.
                Reason: {data.activationMessage}
              </Alert>
            ) : null}
            <Table size="sm">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Classification state</th>
                  <th>Configuration</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{fields}</tbody>
            </Table>
          </Container>
        </Row>
      </>
    );
  }

  private openColumnSettingsModal(field: IFieldConfigurationInfo) {
    const { openColumnSettingsModal, loadListField } = this.props;
    return () => {
      loadListField(field.fieldId);
      openColumnSettingsModal(field);
    };
  }

  private openDeleteColumnSettingsModal(field: IFieldConfigurationInfo) {
    const { openDeleteColumnSettingsModal } = this.props;
    return () => {
      openDeleteColumnSettingsModal(field);
    };
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openColumnSettingsModal: (field: IFieldConfigurationInfo) =>
    dispatch(
      openModal(
        `SIM settings for "${field.displayName}" column`,
        ModalContents.ListColumnSettings,
        {
          position: 'right',
          size: 'lg',
        },
        {
          fieldId: field.fieldId,
          siteLibraryId: ownProps.params.siteLibrary.id,
        }
      )
    ),
  openDeleteColumnSettingsModal: (field: IFieldConfigurationInfo) =>
    dispatch(
      openModal(
        `Delete list level SIM configuration of "${field.displayName}" column`,
        ModalContents.DeleteListSimConfig,
        {
          position: 'right',
        },
        {
          fieldId: field.fieldId,
          siteLibraryId: ownProps.params.siteLibrary.id,
        }
      )
    ),
  loadListField: (fieldId: string) =>
    dispatch(fetchListField(fieldId, ownProps.params.siteLibrary.id)),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ListSimSettings);
