import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, ITenantContactDetail } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from '../modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';
import { updateContact } from 'slices/tenantSettingsSlice';

interface IOwnProps {
  contact: ITenantContactDetail;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
}

interface IDispatchProps {
  updateContact: (contact: ITenantContactDetail) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  type: string;
  name: string;
  email: string;
}

export class EditContactModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, updateResult } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter contact name"
            label="Contact Name"
            validate={formValidators.required}
          />
          <Field
            name="type"
            component={textAreaComponent}
            placeholder="Please enter contact type"
            label="Contact Type"
            validate={formValidators.required}
          />
          <Field
            name="email"
            component={inputComponent}
            type="email"
            placeholder="Please enter email address"
            label="Email Address"
            validate={formValidators.required}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { updateContact, contact } = this.props;
    const updatedContact = Object.assign({}, contact, data);

    updateContact(updatedContact);
  }
}

const EditContactModalForm = reduxForm<IFormData, IProps>({
  form: 'updateContact',
})(EditContactModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      email: ownProps.contact.email,
      name: ownProps.contact.name,
      type: ownProps.contact.type,
    },
    form: 'updateContact',
    updateResult: state.tenantSettings.updateContactResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateContact: (contact: ITenantContactDetail) =>
    dispatch(updateContact(contact)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditContactModalForm);
