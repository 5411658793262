import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  IClassificationError,
  ISequentialListClassification,
  ISequentialListClassificationDetails,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import ClassifyListEndpoint from 'endpoints/classifyListEndpoint';

interface IClassifyListState {
  listClassificationsResult: IActionResult<ISequentialListClassification[]>;
  listClassificationResult: IActionResult<ISequentialListClassification>;
  listClassificationsForSiteResult: IActionResult<
    ISequentialListClassification[]
  >;
  listClassificationDetailsResult: IActionResult<ISequentialListClassificationDetails>;
  listClassificationErrorsResult: IActionResult<IClassificationError[]>;
  cancelClassificationResults: {
    [listClassificationId: string]: IActionResult<boolean>;
  };
  pauseClassificationResults: {
    [listClassificationId: string]: IActionResult<boolean>;
  };
  resumeClassificationResults: {
    [listClassificationId: string]: IActionResult<boolean>;
  };
}

const classifyListSlice = createSlice({
  name: 'classifyList',
  initialState: {
    listClassificationsResult: { processing: false },
    listClassificationResult: { processing: false },
    listClassificationsForSiteResult: { processing: false },
    listClassificationDetailsResult: { processing: false },
    listClassificationErrorsResult: { processing: false },
    cancelClassificationResults: {},
    pauseClassificationResults: {},
    resumeClassificationResults: {},
  } as IClassifyListState,
  reducers: {
    fetchingListClassifications(state) {
      state.listClassificationsResult = { processing: true };
    },
    fetchedListClassifications(
      state,
      action: PayloadAction<ISequentialListClassification[]>
    ) {
      state.listClassificationsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchListClassificationsFailed(state, action: PayloadAction<IError>) {
      state.listClassificationsResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingListClassification(state) {
      state.listClassificationResult = { processing: true };
    },
    fetchedListClassification(
      state,
      action: PayloadAction<ISequentialListClassification>
    ) {
      if (
        Array.isArray(state.listClassificationsResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsResult.data[index] = action.payload;
        } else {
          state.listClassificationsResult.data.push(action.payload);
        }
      }
      if (
        Array.isArray(state.listClassificationsForSiteResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsForSiteResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsForSiteResult.data[index] = action.payload;
        }
      }
      if (
        state.listClassificationDetailsResult.data?.id === action.payload?.id
      ) {
        Object.assign(
          state.listClassificationDetailsResult.data,
          action.payload
        );
      }
      state.listClassificationResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchListClassificationFailed(state, action: PayloadAction<IError>) {
      state.listClassificationResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingListClassificationsForSite(state) {
      state.listClassificationsForSiteResult = { processing: true };
    },
    fetchedListClassificationsForSite(
      state,
      action: PayloadAction<ISequentialListClassification[]>
    ) {
      state.listClassificationsForSiteResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchListClassificationsForSiteFailed(
      state,
      action: PayloadAction<IError>
    ) {
      state.listClassificationsForSiteResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingListClassificationDetails(state) {
      state.listClassificationDetailsResult = { processing: true };
    },
    fetchedListClassificationDetails(
      state,
      action: PayloadAction<ISequentialListClassificationDetails>
    ) {
      state.listClassificationDetailsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchListClassificationDetailsFailed(state, action: PayloadAction<IError>) {
      state.listClassificationDetailsResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingListClassificationErrors(state) {
      state.listClassificationErrorsResult = { processing: true };
    },
    fetchedListClassificationErrors(
      state,
      action: PayloadAction<IClassificationError[]>
    ) {
      state.listClassificationErrorsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchListClassificationErrorsFailed(state, action: PayloadAction<IError>) {
      state.listClassificationErrorsResult = {
        processing: false,
        error: action.payload,
      };
    },
    cancellingClassification(state, action: PayloadAction<string>) {
      state.cancelClassificationResults[action.payload] = { processing: true };
    },
    cancelledClassification(
      state,
      action: PayloadAction<ISequentialListClassification>
    ) {
      if (
        Array.isArray(state.listClassificationsResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsResult.data[index] = action.payload;
        } else {
          state.listClassificationsResult.data.push(action.payload);
        }
      }
      if (
        Array.isArray(state.listClassificationsForSiteResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsForSiteResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsForSiteResult.data[index] = action.payload;
        }
      }
      if (
        state.listClassificationDetailsResult.data?.id === action.payload?.id
      ) {
        Object.assign(
          state.listClassificationDetailsResult.data,
          action.payload
        );
      }
      state.cancelClassificationResults[action.payload.id] = {
        processing: false,
        data: true,
      };
    },
    cancelClassificationFailed(
      state,
      action: PayloadAction<{ error: IError; listClassificationId: string }>
    ) {
      state.cancelClassificationResults[action.payload.listClassificationId] = {
        processing: false,
        error: action.payload.error,
      };
    },
    pausingClassification(state, action: PayloadAction<string>) {
      state.pauseClassificationResults[action.payload] = { processing: true };
    },
    pausedClassification(
      state,
      action: PayloadAction<ISequentialListClassification>
    ) {
      if (
        Array.isArray(state.listClassificationsResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsResult.data[index] = action.payload;
        } else {
          state.listClassificationsResult.data.push(action.payload);
        }
      }
      if (
        Array.isArray(state.listClassificationsForSiteResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsForSiteResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsForSiteResult.data[index] = action.payload;
        }
      }
      if (
        state.listClassificationDetailsResult.data?.id === action.payload?.id
      ) {
        Object.assign(
          state.listClassificationDetailsResult.data,
          action.payload
        );
      }
      state.pauseClassificationResults[action.payload.id] = {
        processing: false,
        data: true,
      };
    },
    pauseClassificationFailed(
      state,
      action: PayloadAction<{ error: IError; listClassificationId: string }>
    ) {
      state.pauseClassificationResults[action.payload.listClassificationId] = {
        processing: false,
        error: action.payload.error,
      };
    },
    resumingClassification(state, action: PayloadAction<string>) {
      state.resumeClassificationResults[action.payload] = { processing: true };
    },
    resumedClassification(
      state,
      action: PayloadAction<ISequentialListClassification>
    ) {
      if (
        Array.isArray(state.listClassificationsResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsResult.data[index] = action.payload;
        } else {
          state.listClassificationsResult.data.push(action.payload);
        }
      }
      if (
        Array.isArray(state.listClassificationsForSiteResult.data) &&
        action.payload
      ) {
        const index = state.listClassificationsForSiteResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.listClassificationsForSiteResult.data[index] = action.payload;
        }
      }
      if (
        state.listClassificationDetailsResult.data?.id === action.payload?.id
      ) {
        Object.assign(
          state.listClassificationDetailsResult.data,
          action.payload
        );
      }
      state.resumeClassificationResults[action.payload.id] = {
        processing: false,
        data: true,
      };
    },
    resumeClassificationFailed(
      state,
      action: PayloadAction<{ error: IError; listClassificationId: string }>
    ) {
      state.resumeClassificationResults[action.payload.listClassificationId] = {
        processing: false,
        error: action.payload.error,
      };
    },
  },
});

export const fetchListClassificationsForSite = (
  siteDeploymentId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(fetchingListClassificationsForSite());
  try {
    const response = await endpoint.getListClassificationsBySite(
      siteDeploymentId
    );
    dispatch(fetchedListClassificationsForSite(response.data));
  } catch (error) {
    dispatch(fetchListClassificationsForSiteFailed(Errors.getError(error)));
  }
};

export const fetchListClassifications = (
  interfaceId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(fetchingListClassifications());
  try {
    const response = await endpoint.getListClassifications(interfaceId);
    dispatch(fetchedListClassifications(response.data));
  } catch (error) {
    dispatch(fetchListClassificationsFailed(Errors.getError(error)));
  }
};

export const fetchListClassification = (
  listClassificationId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(fetchingListClassification());
  try {
    const response = await endpoint.getListClassification(listClassificationId);
    dispatch(fetchedListClassification(response.data));
  } catch (error) {
    dispatch(fetchListClassificationFailed(Errors.getError(error)));
  }
};

export const fetchListClassificationDetails = (
  listClassificationId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(fetchingListClassificationDetails());
  try {
    const response = await endpoint.getListClassificationDetails(
      listClassificationId
    );
    dispatch(fetchedListClassificationDetails(response.data));
  } catch (error) {
    dispatch(fetchListClassificationDetailsFailed(Errors.getError(error)));
  }
};

export const fetchListClassificationErrors = (
  listClassificationId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(fetchingListClassificationErrors());
  try {
    const response = await endpoint.getListClassificationErrors(
      listClassificationId
    );
    dispatch(fetchedListClassificationErrors(response.data));
  } catch (error) {
    dispatch(fetchListClassificationErrorsFailed(Errors.getError(error)));
  }
};

export const cancelListClassification = (
  siteLibraryId: string,
  listClassificationId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(cancellingClassification(listClassificationId));
  try {
    const response = await endpoint.cancelListClassification(siteLibraryId);
    dispatch(cancelledClassification(response.data));
  } catch (error) {
    dispatch(
      cancelClassificationFailed({
        error: Errors.getError(error),
        listClassificationId,
      })
    );
  }
};

export const pauseListClassification = (
  siteLibraryId: string,
  listClassificationId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(pausingClassification(listClassificationId));
  try {
    const response = await endpoint.pauseListClassification(siteLibraryId);
    dispatch(pausedClassification(response.data));
  } catch (error) {
    dispatch(
      pauseClassificationFailed({
        error: Errors.getError(error),
        listClassificationId,
      })
    );
  }
};

export const resumeListClassification = (
  siteLibraryId: string,
  listClassificationId: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassifyListEndpoint();
  dispatch(resumingClassification(listClassificationId));
  try {
    const response = await endpoint.resumeListClassification(
      siteLibraryId,
      listClassificationId
    );
    dispatch(resumedClassification(response.data));
  } catch (error) {
    dispatch(
      resumeClassificationFailed({
        error: Errors.getError(error),
        listClassificationId,
      })
    );
  }
};

const { actions, reducer } = classifyListSlice;
export const {
  fetchListClassificationsFailed,
  fetchedListClassifications,
  fetchingListClassifications,
  fetchListClassificationDetailsFailed,
  fetchedListClassificationDetails,
  fetchingListClassificationDetails,
  fetchListClassificationsForSiteFailed,
  fetchedListClassificationsForSite,
  fetchingListClassificationsForSite,
  fetchListClassificationErrorsFailed,
  fetchedListClassificationErrors,
  fetchingListClassificationErrors,
  fetchListClassificationFailed,
  fetchedListClassification,
  fetchingListClassification,
  cancelClassificationFailed,
  cancelledClassification,
  cancellingClassification,
  pauseClassificationFailed,
  pausedClassification,
  pausingClassification,
  resumeClassificationFailed,
  resumedClassification,
  resumingClassification,
} = actions;
export default reducer;
