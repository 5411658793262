import * as React from 'react';

import { ISyncRun, ModalContents, SyncStatus } from 'types';
import { getDuration, localLongDate } from 'utils/dateUtils';

import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchTermStoreSyncRun } from 'slices/termStoreSlice';
import { openModal } from 'slices/modalSlice';
import { syncStatus } from './statusIndicators';

interface IState {
  showSyncRuns: boolean;
}

interface IOwnProps {
  data: ISyncRun;
  interfaceId: string;
  syncJobId: string;
}

interface IDispatchProps {
  openDetailsModal: () => void;
  fetchTermStoreSyncRun: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class SyncJob extends React.Component<IProps, IState> {
  private timerHandler?: number;

  constructor(props: IProps) {
    super(props);
    this.state = { showSyncRuns: false };
    this.checkStatus = this.checkStatus.bind(this);
  }

  public componentDidMount() {
    const { data } = this.props;
    if (
      data.status === SyncStatus.Queued ||
      data.status === SyncStatus.InProgress
    ) {
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    }
  }

  public componentDidUpdate() {
    const { data } = this.props;
    if (
      data.status === SyncStatus.Queued ||
      data.status === SyncStatus.InProgress
    ) {
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    } else {
      if (this.timerHandler) {
        clearTimeout(this.timerHandler);
      }
    }
  }

  public componentWillUnmount() {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }

  public render() {
    const { data, openDetailsModal } = this.props;
    return (
      <tr>
        <td>{data.startDate ? localLongDate(data.startDate) : null}</td>
        <td>
          {(data.finishDate ? localLongDate(data.finishDate) : '') +
            (data.startDate
              ? ` (${getDuration(data.finishDate, data.startDate)})`
              : '')}
        </td>
        <td>{syncStatus[data.status]}</td>
        <td>
          <Button
            size="sm"
            className="py-0 px-1"
            variant="outline-dark"
            onClick={openDetailsModal}
          >
            Details
          </Button>
        </td>
      </tr>
    );
  }

  private checkStatus() {
    const { fetchTermStoreSyncRun } = this.props;
    fetchTermStoreSyncRun();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDetailsModal: () =>
    dispatch(
      openModal(
        'Sync Run Details',
        ModalContents.SyncRunDetails,
        {
          position: 'right',
        },
        { syncJobId: ownProps.syncJobId, syncRunId: ownProps.data.id }
      )
    ),
  fetchTermStoreSyncRun: () =>
    dispatch(
      fetchTermStoreSyncRun(
        ownProps.interfaceId,
        ownProps.syncJobId,
        ownProps.data.id
      )
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(SyncJob);
