import * as React from 'react';

import { IFeedItem } from 'types';
import { ListGroupItem } from 'react-bootstrap';

interface IProps {
  data: IFeedItem;
}

class AdminFeedItem extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    return (
      <ListGroupItem>
        <h6 className="mb-0">{data.title}</h6>
        <p
          className="small mb-0"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </ListGroupItem>
    );
  }
}

export default AdminFeedItem;
