import * as React from 'react';

import AccountCircleOutlineIcon from 'mdi-react/AccountCircleOutlineIcon';
import ArchiveIcon from 'mdi-react/ArchiveIcon';
import FlaskIcon from 'mdi-react/FlaskIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import LogoutIcon from 'mdi-react/LogoutIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import StudioIcon from './studioIcon';
import { iconComponentType } from 'types';

interface IProps {
  size?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  className?: string;
  iconComponent?: iconComponentType;
}

class MdIcon extends React.Component<IProps> {
  public render() {
    const { size, onClick, className, iconComponent } = this.props;

    const icon = {
      'information-outline': InformationOutlineIcon,
      flask: FlaskIcon,
      'square-edit-outline': SquareEditOutlineIcon,
      studio: StudioIcon,
      archive: ArchiveIcon,
      menu: MenuIcon,
      logout: LogoutIcon,
      'account-circle-outline': AccountCircleOutlineIcon,
    };

    const Icon = iconComponent ? icon[iconComponent] : null;

    return Icon ? (
      <Icon size={size} onClick={onClick} className={className} />
    ) : null;
  }
}

export default MdIcon;
