import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { ISiteLibrary } from 'types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class SiteLibraryEndpoint {
  public readonly ROOT = 'siteLibrary';

  public getSiteLibrary(
    interfaceId: string,
    listId: string,
    spHostUrl: string
  ): Promise<AxiosResponse<ISiteLibrary>> {
    const queryItems = [
      new QueryItem('listId', listId),
      new QueryItem('spHostUrl', spHostUrl),
    ];
    const url = Endpoint.getUrl([interfaceId, this.ROOT], queryItems);
    return api.get(url);
  }
}
