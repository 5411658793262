import * as React from 'react';

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Button, Col, Row } from 'react-bootstrap';

import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import Authorizer from 'utils/authorizer';
import EmoticonDeadOutlineIcon from 'mdi-react/EmoticonDeadOutlineIcon';
import { IReduxState } from 'reducers';
import { ModalContents } from 'types';
import ReloadIcon from 'mdi-react/ReloadIcon';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IState {
  correlationIdShown: boolean;
}

interface IStateProps {
  canViewLogs: boolean;
}

interface IDispatchProps {
  openLogModal: (correlationId: string) => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class ErrorPage extends React.Component<IProps, IState> {
  private message: string;
  private correlationId: string;
  private requestUrl: string;

  constructor(props: IProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.message = params.get('message')!;
    this.correlationId = params.get('correlationId')!;
    this.requestUrl = params.get('requestUrl')!;
    this.onDeveloperInfoClick = this.onDeveloperInfoClick.bind(this);
    this.state = { correlationIdShown: false };
  }

  public render() {
    const { message, requestUrl, correlationId } = this;
    return (
      <Row className="flex-grow-1 justify-content-md-center bg-light align-items-center">
        <Col md={4} className="text-center">
          <EmoticonDeadOutlineIcon size="4rem" className="mb-3" />
          <h1>An error has occurred</h1>
          <h4 className="mb-3">{message}</h4>
          <p>
            <Button variant="light" onClick={this.onDeveloperInfoClick}>
              {this.state.correlationIdShown
                ? 'Hide developer info'
                : 'Show developer info'}
            </Button>
          </p>
          {this.state.correlationIdShown ? (
            <dl>
              <dt>Correlation Id</dt>
              <dd>{correlationId}</dd>
            </dl>
          ) : null}
          <p>
            <Button
              variant="secondary"
              className="badge-pill mr-2"
              size="lg"
              href="/"
            >
              <ArrowLeftIcon size="1.2em" className="mr-1" />
              Back to Home
            </Button>
            <Button className="badge-pill" size="lg" href={requestUrl}>
              <ReloadIcon size="1.2em" className="mr-1" />
              Try again
            </Button>
          </p>
        </Col>
      </Row>
    );
  }

  private onDeveloperInfoClick() {
    const { canViewLogs, openLogModal } = this.props;
    if (canViewLogs && !this.state.correlationIdShown) {
      openLogModal(this.correlationId);
    }
    this.setState({ correlationIdShown: !this.state.correlationIdShown });
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canViewLogs: authorizer.canViewLogs(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openLogModal: (correlationId: string) =>
    dispatch(
      openModal(
        'Error logs',
        ModalContents.CorrelatedLogs,
        {
          position: 'right',
          size: 'xl',
        },
        correlationId
      )
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage);
