import * as React from 'react';

import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteBapiInterface } from 'slices/bapiSlice';

interface IOwnProps {
  bapiInterface: IInterfaceConfigured;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteBapiInterface: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteBapiModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { deleteResult, bapiInterface } = this.props;

    return (
      <>
        <Modal.Body>
          Are you sure you want to delete the "{bapiInterface.name}" interface
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
          onSubmit={this.delete}
        />
      </>
    );
  }

  private delete() {
    const { deleteBapiInterface } = this.props;
    deleteBapiInterface();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    deleteResult: state.bapi.deleteInterfaceResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteBapiInterface: () =>
    dispatch(deleteBapiInterface(ownProps.bapiInterface.id!)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteBapiModal);
