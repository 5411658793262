import { Modal, Table } from 'react-bootstrap';

import { ILanguagePack } from 'types';
import ModalFormFooter from 'components/common/modalFormFooter';
import React from 'react';

interface IProps {
  data: ILanguagePack[];
}

class LanguagePacksModal extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const languagePacks = data.map((languagePack, index) => (
      <tr key={index}>
        <td>{languagePack.name}</td>
        <td>{languagePack.version}</td>
      </tr>
    ));
    return (
      <>
        <Modal.Body className="m-0">
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>{languagePacks}</tbody>
          </Table>
        </Modal.Body>
        <ModalFormFooter noSubmit noError />
      </>
    );
  }
}

export default LanguagePacksModal;
