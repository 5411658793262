import * as React from 'react';

import { Badge, ListGroup } from 'react-bootstrap';
import { IServiceViewModel, ServerType } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import NameResolver from 'utils/nameResolver';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { select } from 'slices/serviceTypeSlice';

interface IOwnProps {
  serverType: ServerType;
  services: IServiceViewModel[];
}

interface IStateProps {
  isSelected: boolean;
}

interface IDispatchProps {
  select: () => void;
}

interface IProps extends IOwnProps, IDispatchProps, IStateProps {}

export class ServiceType extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.select = this.select.bind(this);
  }

  public render() {
    const { serverType, isSelected, services } = this.props;
    const nameResolver = new NameResolver();
    return (
      <ListGroup.Item
        action
        className={`rounded-0 border-right-0 border-left-0 border-top-0 d-flex align-items-center justify-content-between${
          isSelected ? ' bg-light' : ''
        }`}
        onClick={this.select}
      >
        {nameResolver.getServerTypeName(serverType)}
        <Badge pill variant="info">
          {services.filter((s) => s.serverType === serverType).length}
        </Badge>
      </ListGroup.Item>
    );
  }

  private select(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.stopPropagation();
    const { select } = this.props;
    select();
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => ({
  isSelected:
    state.serviceType.selected != null &&
    state.serviceType.selected === ownProps.serverType,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  select: () => dispatch(select(ownProps.serverType)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ServiceType);
