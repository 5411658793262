import {
  IAssociatedService,
  IServiceBasicInfo,
  IServiceViewModel,
  IStatusResponse,
  ServerType,
} from 'types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class ServiceEndpoint {
  public readonly ROOT = 'service';

  public list(): Promise<AxiosResponse<IAssociatedService[]>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.get(url);
  }

  public listServices(
    groupId: string
  ): Promise<AxiosResponse<IServiceViewModel[]>> {
    const queryItems = [new QueryItem('groupId', groupId)];
    const url = Endpoint.getUrl([this.ROOT, 'getByGroup'], queryItems);
    return api.get(url);
  }

  public getServicesOfType(
    serverTypes: ServerType[]
  ): Promise<AxiosResponse<IServiceBasicInfo[]>> {
    const queryItems = serverTypes.map(
      (serverType) => new QueryItem('serverTypes', serverType.toString())
    );
    const url = Endpoint.getUrl([this.ROOT, 'byType'], queryItems);
    return api.get(url);
  }

  public getStatus(serviceId: string): Promise<AxiosResponse<IStatusResponse>> {
    const url = Endpoint.getUrl([this.ROOT, serviceId, 'status']);
    return api.get(url);
  }

  public deleteService(id: string): Promise<AxiosResponse<IServiceViewModel>> {
    const queryItems = [new QueryItem('id', id)];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.delete(url);
  }

  public createService(
    service: IServiceViewModel
  ): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.post(url, service);
  }

  public updateService(
    service: IServiceViewModel
  ): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.put(url, service);
  }
}
