import { IActionResult, IServiceViewModel, ServerType } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import React from 'react';
import ReplaceCsExtra from './replaceCsExtraForm';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchNumberOfConfigurations } from 'slices/csSlice';

interface IOwnProps {
  service: IServiceViewModel;
  setSubmitEnabled: (value: boolean) => void;
}

interface IStateProps {
  fetchNumberOfConfigurationsResult: IActionResult<number>;
}

interface IDispatchProps {
  fetchNumberOfConfigurations: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteServiceExtras extends React.Component<IProps> {
  public componentDidMount() {
    const { service, fetchNumberOfConfigurations } = this.props;
    switch (service.serverType) {
      case ServerType.ClassificationService:
        fetchNumberOfConfigurations();
        break;
    }
  }

  public render() {
    const {
      service,
      fetchNumberOfConfigurationsResult,
      setSubmitEnabled,
    } = this.props;
    switch (service.serverType) {
      case ServerType.ClassificationService:
        return (
          <LoaderWithParams
            component={ReplaceCsExtra}
            actionresult={fetchNumberOfConfigurationsResult}
            params={{ service, setSubmitEnabled }}
          />
        );
      default:
        setSubmitEnabled(true);
        return null;
    }
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    fetchNumberOfConfigurationsResult:
      state.cs.fetchNumberOfConfigurationsResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchNumberOfConfigurations: () =>
    dispatch(fetchNumberOfConfigurations(ownProps.service.serverId!)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteServiceExtras);
