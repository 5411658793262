import * as React from 'react';

import { IActionResult, ITermStoreSyncJob, ModalContents } from 'types';
import SpoSubscriptionSelector, {
  IParams as SpoSubscriptionSelectorParams,
} from './spoSubscriptionSelector';
import TermStoreSyncJobs, {
  IParams as TermStoreSyncJobsParams,
} from './termStoreSyncJobs';
import {
  cleanSyncJobs,
  fetchSyncJobs,
  fetchTermStores,
} from 'slices/termStoreSlice';

import { AnyAction } from '@reduxjs/toolkit';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import PlusIcon from 'mdi-react/PlusIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchSpoSubscriptions } from 'slices/spoSubscriptionSlice';
import { openModal } from 'slices/modalSlice';

interface IState {
  spoSubscriptionUrl?: string;
}

interface IOwnProps {
  spHostUrl?: string;
  interfaceId: string;
}

interface IStateProps {
  fetchSyncJobsResult: IActionResult<ITermStoreSyncJob[]>;
  spoSubscriptionsResult: IActionResult<string[]>;
}

interface IDispatchProps {
  openCreateModal: (spoSubscriptionUrl: string, spHostUrl: string) => void;
  fetchTermStores: (spHostUrl: string) => void;
  fetchSyncJobs: (spoSubscriptionUrl: string) => void;
  fetchSpoSubscriptions: () => void;
  cleanSyncJobs: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class TermStoreSync extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onSubscriptionChanged = this.onSubscriptionChanged.bind(this);
    this.state = { spoSubscriptionUrl: undefined };
  }

  public componentDidMount() {
    const { fetchSpoSubscriptions } = this.props;
    fetchSpoSubscriptions();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { fetchSpoSubscriptions, cleanSyncJobs, interfaceId } = this.props;
    if (prevProps.interfaceId !== interfaceId) {
      fetchSpoSubscriptions();
      cleanSyncJobs();
    }
  }

  public render() {
    const {
      fetchSyncJobsResult,
      interfaceId,
      spHostUrl,
      spoSubscriptionsResult,
    } = this.props;
    const { spoSubscriptionUrl } = this.state;
    return (
      <>
        <hr />
        <h5 className="font-weight-bold">Term Store Synchronization Jobs:</h5>
        <LoaderWithParams<string[], SpoSubscriptionSelectorParams>
          actionresult={spoSubscriptionsResult}
          component={SpoSubscriptionSelector}
          params={{
            spHostUrl,
            onSubscriptionChanged: this.onSubscriptionChanged,
          }}
        />
        {spoSubscriptionUrl ? (
          <>
            {spHostUrl && spHostUrl.includes(spoSubscriptionUrl) ? (
              <p>
                <Button
                  size="sm"
                  variant="link"
                  onClick={this.onCreateClick(spoSubscriptionUrl, spHostUrl)}
                >
                  <PlusIcon size="1.2rem" className="mr-1" />
                  Create a new job
                </Button>
              </p>
            ) : null}
            <LoaderWithParams<ITermStoreSyncJob[], TermStoreSyncJobsParams>
              component={TermStoreSyncJobs}
              actionresult={fetchSyncJobsResult}
              params={{ interfaceId, spHostUrl }}
            />
          </>
        ) : null}
      </>
    );
  }

  private onCreateClick = (
    spoSubscriptionUrl: string,
    spHostUrl: string
  ) => () => {
    const { openCreateModal, fetchTermStores } = this.props;
    fetchTermStores(spHostUrl);
    openCreateModal(spoSubscriptionUrl, spHostUrl);
  };

  private onSubscriptionChanged(spoSubscriptionUrl: string) {
    const { fetchSyncJobs } = this.props;
    this.setState({ spoSubscriptionUrl });
    if (spoSubscriptionUrl) {
      fetchSyncJobs(spoSubscriptionUrl);
    }
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchSyncJobsResult: state.termStore.fetchSyncJobsResult,
  spoSubscriptionsResult: state.spoSubscription.spoSubscriptionsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openCreateModal: (spoSubscriptionUrl: string, spHostUrl: string) =>
    dispatch(
      openModal(
        'Create',
        ModalContents.CreateTermStoreSyncJob,
        {
          position: 'right',
        },
        {
          interfaceId: ownProps.interfaceId,
          spoSubscriptionUrl: spoSubscriptionUrl,
          spHostUrl: spHostUrl,
        }
      )
    ),
  fetchTermStores: (spHostUrl: string) =>
    dispatch(fetchTermStores(ownProps.interfaceId, spHostUrl)),
  fetchSyncJobs: (spoSubscriptionUrl: string) =>
    dispatch(
      fetchSyncJobs(
        spoSubscriptionUrl,
        ownProps.interfaceId,
        ownProps.spHostUrl
      )
    ),
  fetchSpoSubscriptions: () =>
    dispatch(fetchSpoSubscriptions(ownProps.interfaceId)),
  cleanSyncJobs: () => dispatch(cleanSyncJobs()),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(TermStoreSync);
