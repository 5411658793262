import { AxiosError } from 'axios';

export interface IError {
  title: string;
  subTitle?: string;
  stackTrace?: string;
  status?: number;
}

export class Errors {
  public static getError(reason: AxiosError): IError {
    return {
      title: reason.name,
      subTitle:
        reason.response?.data?.message ||
        reason.response?.data ||
        reason.message,
      stackTrace: reason.stack,
      status: reason.response?.status,
    };
  }
}
