import * as React from 'react';

import { IActionResult, IUserResult } from 'types';
import { getAvailableRoleGroups, getAvailableRoles } from 'slices/userSlice';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import { Button } from 'react-bootstrap';
import DownloadIcon from 'mdi-react/DownloadIcon';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import UserSearchResult from './userSearchResult';
import UsersSearchForm from './usersSearchForm';
import { connect } from 'react-redux';
import { searchTenancies } from 'slices/tenantSlice';

interface IStateProps {
  searchResult: IActionResult<IUserResult[]>;
  canChangeTenant: boolean;
}

interface IDispatchProps {
  getAvailableRoleGroups: () => void;
  getAvailableRoles: () => void;
  getAvailableTenancies: () => void;
}

interface IProps extends IDispatchProps, IStateProps {}

export class UsersPage extends React.Component<IProps> {
  public componentDidMount() {
    const {
      getAvailableRoleGroups,
      getAvailableRoles,
      getAvailableTenancies,
      canChangeTenant,
    } = this.props;
    getAvailableRoleGroups();
    getAvailableRoles();
    if (canChangeTenant) {
      getAvailableTenancies();
    }
  }

  public render() {
    const { searchResult } = this.props;
    return (
      <>
        <h1 className="font-weight-light mt-5 mb-2">
          <Button
            variant="link"
            className="float-right mt-3"
            href="/User/GetUserPermissions"
          >
            <DownloadIcon size="1.4rem" className="mr-1" />
            User Permission Report
          </Button>
          Manage Users
        </h1>
        <UsersSearchForm />
        <Loader actionresult={searchResult} component={UserSearchResult} />
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    searchResult: state.user.searchResult,
    canChangeTenant: authorizer.canChangeTenant(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  getAvailableRoleGroups: () => dispatch(getAvailableRoleGroups()),
  getAvailableRoles: () => dispatch(getAvailableRoles()),
  getAvailableTenancies: () => dispatch(searchTenancies()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(UsersPage);
