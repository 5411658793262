import * as React from 'react';

import { AnyAction } from 'redux';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import { ITenancyDomain } from 'types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteDomain } from 'slices/domainSlice';

interface IOwnProps {
  domain: ITenancyDomain;
  userCanDeleteDomain: boolean;
}

interface IDispatchProps {
  deleteDomain: (domainId: string) => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class DomainRow extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { domain, userCanDeleteDomain } = this.props;
    return (
      <tr>
        <td className="align-middle text-break">{domain.name}</td>
        <td className="align-middle">
          <Button
            variant="danger"
            size="sm"
            block
            onClick={this.delete}
            disabled={!userCanDeleteDomain}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  }

  private delete() {
    const { domain, deleteDomain } = this.props;
    deleteDomain(domain.id);
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  deleteDomain: (domainId: string) => dispatch(deleteDomain(domainId)),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(DomainRow);
