import * as React from 'react';

import { Field } from 'redux-form';
import { IAvailableAzureVmValues } from 'types';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IParams {
  isRequired: boolean;
}

interface IProps {
  data: IAvailableAzureVmValues;
  params: IParams;
}

export class VmTypeSelector extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { isRequired } = params;

    const availableVmTypes = data.roleSizes.map((type, index) => (
      <option value={type.name} key={index} title={type.details}>
        {type.displayName}
      </option>
    ));
    return (
      <Field
        name="roleSize"
        component={selectComponent}
        label="Role Size"
        validate={isRequired ? formValidators.required : undefined}
      >
        <option value="">Select role size</option>
        {availableVmTypes}
      </Field>
    );
  }
}

export default VmTypeSelector;
