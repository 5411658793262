import * as React from 'react';

import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createSpoInterface } from 'slices/spoSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IStateProps {
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createSpoInterface: (interface_: IInterfaceConfigured) => void;
}

export interface IFormData {
  name: string;
  description: string;
}

export interface IProps extends IStateProps, IDispatchProps {}

export class CreateSpoModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { createResult, handleSubmit, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            type="text"
            placeholder="Please enter a description"
            label="Description"
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { createSpoInterface } = this.props;
    const newInterface: IInterfaceConfigured = {
      active: true,
      description: data.description,
      name: data.name,
    };
    createSpoInterface(newInterface);
  }
}

const CreateSpoModalForm = reduxForm<IFormData, IProps>({
  form: 'createSpo',
})(CreateSpoModal);

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    createResult: state.spo.createInterfaceResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createSpoInterface: (interface_: IInterfaceConfigured) =>
    dispatch(createSpoInterface(interface_)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateSpoModalForm);
