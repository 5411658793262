import { Errors, IError } from 'utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { IActionResult } from 'types';
import { SesEndpoint } from 'endpoints/sesEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISesState {
  sesIndexesResult: IActionResult<string[]>;
}

const sesSlice = createSlice({
  name: 'ses',
  initialState: {
    sesIndexesResult: {
      processing: false,
    },
  } as ISesState,
  reducers: {
    fetchingIndexes(state) {
      state.sesIndexesResult = { processing: true };
    },
    fetchedIndexes(state, action: PayloadAction<string[]>) {
      state.sesIndexesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchIndexesFailed(state, action: PayloadAction<IError>) {
      state.sesIndexesResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchSesIndexes = (sesServerId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SesEndpoint();
  dispatch(fetchingIndexes());
  try {
    const response = await endpoint.getIndexes(sesServerId);
    dispatch(fetchedIndexes(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchIndexesFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = sesSlice;
export const { fetchIndexesFailed, fetchedIndexes, fetchingIndexes } = actions;
export default reducer;
