import { Errors, IError } from 'utils/errors';
import { IActionResult, IClassificationConfig } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { ClassificationSettingsEndpoint } from 'endpoints/classificationSettingsEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IClassificationSettingsState {
  fetchClassificationSettingsResult: IActionResult<IClassificationConfig>;
  saveClassificationSettingsResult: IActionResult<boolean>;
}

const classificationSettingsSlice = createSlice({
  name: 'classificationSettings',
  initialState: {
    fetchClassificationSettingsResult: {
      processing: false,
    },
    saveClassificationSettingsResult: {
      processing: false,
    },
  } as IClassificationSettingsState,
  reducers: {
    fetching(state) {
      state.fetchClassificationSettingsResult = { processing: true };
    },
    fetched(state, action: PayloadAction<IClassificationConfig>) {
      state.fetchClassificationSettingsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.fetchClassificationSettingsResult = {
        processing: false,
        error: action.payload,
      };
    },
    saving(state) {
      state.saveClassificationSettingsResult = { processing: true };
    },
    saved(state) {
      state.saveClassificationSettingsResult = {
        processing: false,
        data: true,
      };
    },
    saveFailed(state, action: PayloadAction<IError>) {
      state.saveClassificationSettingsResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchClassificationSettings = (
  interfaceId: string,
  spHostUrl: string,
  listId?: string,
  cTypeId?: string
): AppThunk => async (dispatch) => {
  const endpoint = new ClassificationSettingsEndpoint();
  dispatch(fetching());
  try {
    const response = await endpoint.getClassificationSettings(
      interfaceId,
      spHostUrl,
      listId,
      cTypeId
    );
    dispatch(fetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

export const saveClassificationSettings = (
  interfaceId: string,
  spHostUrl: string,
  classificationConfig: IClassificationConfig
): AppThunk => async (dispatch) => {
  const endpoint = new ClassificationSettingsEndpoint();
  dispatch(saving());
  try {
    await endpoint.saveClassificationSettings(
      interfaceId,
      spHostUrl,
      classificationConfig
    );
    dispatch(saved());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(saveFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = classificationSettingsSlice;
export const {
  fetchFailed,
  fetched,
  fetching,
  saveFailed,
  saved,
  saving,
} = actions;
export default reducer;
