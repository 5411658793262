import * as React from 'react';

import { Button, ProgressBar } from 'react-bootstrap';
import {
  ISequentialListClassification,
  ModalContents,
  SequentialListClassificationState,
} from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import ClassificationButtons from './classificationButtons';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchListClassificationDetails } from 'slices/classifyListSlice';
import { getDuration } from 'utils/dateUtils';
import { listClassificationStatusStatus } from './statusIndicators';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  classification: ISequentialListClassification;
}

interface IDispatchProps {
  openDetailsModal: () => void;
  fetchListClassificationDetails: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class SmallListClassification extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.details = this.details.bind(this);
  }

  public render() {
    const { classification } = this.props;
    const inProgress =
      classification.state === SequentialListClassificationState.InProgress;
    return (
      <tr>
        <td className="text-nowrap">
          <ProgressBar>
            <ProgressBar
              variant="success"
              now={
                (classification.completedItems / classification.queuedItems) *
                100
              }
              animated={inProgress}
              key={1}
            />
            <ProgressBar
              variant="danger"
              now={
                (classification.failedItems / classification.queuedItems) * 100
              }
              animated={inProgress}
              key={2}
            />
            <ProgressBar
              variant="warning"
              now={
                (classification.ignoredItems / classification.queuedItems) * 100
              }
              animated={inProgress}
              key={3}
            />
          </ProgressBar>
        </td>
        <td className="text-nowrap">
          {listClassificationStatusStatus[classification.state]}
        </td>
        <td className="text-nowrap">
          {classification.startDate
            ? getDuration(classification.finishDate, classification.startDate)
            : ''}
        </td>
        <ClassificationButtons classification={classification} />
        <td>
          <Button
            size="sm"
            variant="outline-dark"
            className="py-0 px-1"
            onClick={this.details}
          >
            Details
          </Button>
        </td>
      </tr>
    );
  }

  private details() {
    const { openDetailsModal, fetchListClassificationDetails } = this.props;
    fetchListClassificationDetails();
    openDetailsModal();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDetailsModal: () =>
    dispatch(
      openModal(
        'List classification details',
        ModalContents.ListClassificationDetails,
        { position: 'right' }
      )
    ),
  fetchListClassificationDetails: () =>
    dispatch(fetchListClassificationDetails(ownProps.classification.id)),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(SmallListClassification);
