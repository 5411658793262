import * as React from 'react';

import { IActionResult, IClassificationConfig } from 'types';

import { AnyAction } from 'redux';
import ContentTypeClassificationSettingsForm from './contentTypeClassificationSettingsForm';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchClassificationSettings } from 'slices/classificationSettingsSlice';

interface IStateProps {
  fetchClassificationSettingsResult: IActionResult<IClassificationConfig>;
}

interface IDispatchProps {
  fetchClassificationSettings: (
    interfaceId: string,
    spHostUrl: string,
    cTypeId: string,
    listId?: string
  ) => void;
}

interface IOwnProps {
  interfaceId: string;
}

interface IProps extends IStateProps, IOwnProps, IDispatchProps {}

export class ContentTypeClassificationSettingsPage extends React.Component<IProps> {
  private spHostUrl: string;
  private cTypeId: string;
  private listId?: string;

  constructor(props: IProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.spHostUrl = params.get('spHostUrl')!;
    this.cTypeId = params.get('ctype')!;
    this.listId = params.get('List') ?? undefined;
  }

  public componentDidMount() {
    const { fetchClassificationSettings, interfaceId } = this.props;
    const { cTypeId, spHostUrl, listId } = this;
    fetchClassificationSettings(interfaceId, spHostUrl, cTypeId, listId);
  }

  public render() {
    const { spHostUrl, cTypeId, listId } = this;
    const { fetchClassificationSettingsResult, interfaceId } = this.props;
    return (
      <LoaderWithParams
        actionresult={fetchClassificationSettingsResult}
        component={ContentTypeClassificationSettingsForm}
        params={{ interfaceId, cTypeId, spHostUrl, listId }}
      />
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchClassificationSettingsResult:
    state.classificationSettings.fetchClassificationSettingsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchClassificationSettings: (
    interfaceId: string,
    spHostUrl: string,
    cTypeId: string,
    listId?: string
  ) =>
    dispatch(
      fetchClassificationSettings(interfaceId, spHostUrl, listId, cTypeId)
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ContentTypeClassificationSettingsPage);
