import * as React from 'react';

import { Container } from 'react-bootstrap';

interface IAnonymousLayoutProps {
  showCopyright?: boolean;
}

class AnonymousLayout extends React.Component<IAnonymousLayoutProps> {
  public render() {
    const { showCopyright } = this.props;
    const currentYear = new Date().getFullYear();

    return (
      <Container
        fluid
        className="d-flex flex-column flex-grow-1 vh-100 bg-light"
      >
        {this.props.children}
        {showCopyright ? (
          <p className="w-100 text-black text-center fw-normal">
            Copyright © {currentYear} Progress Software Corporation and/or its
            subsidiaries or affiliates. All Rights Reserved.
          </p>
        ) : null}
      </Container>
    );
  }
}

export default AnonymousLayout;
