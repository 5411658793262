import * as React from 'react';

import { Col } from 'react-bootstrap';
import { ComponentType } from 'react';
import Error from './error';
import { IActionResult } from 'types';
import SmallLoadingSpinner from './smallLoadingSpinner';

interface IProps<T, T2> {
  actionresult: IActionResult<T>;
  component: ComponentType<WrappedProps<T, T2>>;
  params: T2;
}

interface WrappedProps<T, T2> {
  data: T;
  params: T2;
}

class LoaderWithParams<T, T2> extends React.Component<IProps<T, T2>> {
  public render() {
    const { actionresult, params } = this.props;
    const Component = this.props.component;
    const { processing, data, error } = actionresult;
    if (error !== undefined) {
      return <Error error={error} />;
    } else if (data !== undefined) {
      return <Component data={data} params={params} />;
    } else if (processing) {
      return (
        <Col className="d-flex">
          <SmallLoadingSpinner />
        </Col>
      );
    }

    return null;
  }
}

export default LoaderWithParams;
