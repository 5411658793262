import * as React from 'react';

import { Card, ListGroup } from 'react-bootstrap';
import { IMenuGroup, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import MdIcon from 'components/common/icons/mdIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: IMenuGroup[];
}

interface IDispatchProps {
  openModal: (title: string, type: ModalContents, data?: any) => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class Shortcuts extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const dashboardData = data.find((group) => group.group === 'Dashboard');
    const dashboardItems = dashboardData ? dashboardData.sections[0].items : [];
    const tiles = dashboardItems.map((tile, index) => {
      return (
        <ListGroup.Item
          action
          {...(tile.isModal && {
            onClick: this.onModalClick(tile.modalTitle || '', tile.url),
          })}
          href={!tile.isModal ? tile.url : undefined}
          target={tile.urlTarget}
          className={`list-group-item-${tile.backgroundClass} text-default`}
          title={tile.tooltipText}
          key={index}
        >
          <MdIcon
            iconComponent={tile.iconComponent}
            size="1.5rem"
            className="mr-2"
          />{' '}
          {tile.title}
        </ListGroup.Item>
      );
    });
    return (
      <Card className="border-0 tenant-shortcuts">
        <ListGroup className="border-0" variant="flush">
          {tiles}
        </ListGroup>
      </Card>
    );
  }

  private onModalClick = (title: string, url: string) => () => {
    const { openModal } = this.props;
    openModal(title, ModalContents.Iframe, url);
  };
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openModal: (title: string, type: ModalContents, data?: any) =>
    dispatch(
      openModal(
        title,
        type,
        {
          position: 'right',
          size: 'lg',
        },
        data
      )
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(Shortcuts);
