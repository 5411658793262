import * as React from 'react';

import { Button } from 'react-bootstrap';
import CheckIcon from 'mdi-react/CheckIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import copy from 'copy-to-clipboard';

interface IProps {
  text: string;
  size?: 'sm' | 'lg';
  iconSize?: string;
  className?: string;
}

interface IState {
  copied: boolean;
}

class CopyButton extends React.Component<IProps, IState> {
  public static defaultProps = {
    iconSize: '1.3rem',
  };

  private timerHandler?: number;
  constructor(props: IProps) {
    super(props);
    this.onCopy = this.onCopy.bind(this);
    this.reset = this.reset.bind(this);
    this.state = { copied: false };
  }

  public componentWillUnmount() {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }

  public render() {
    const { size, iconSize, className } = this.props;
    const { copied } = this.state;
    return copied ? (
      <Button size={size} variant="primary" className={className} disabled>
        <CheckIcon size={iconSize} />
      </Button>
    ) : (
      <Button
        size={size}
        variant="primary"
        className={className}
        onClick={this.onCopy}
      >
        <ContentCopyIcon size={iconSize} />
      </Button>
    );
  }

  private onCopy() {
    const { text } = this.props;
    copy(text);
    this.setState({ copied: true });
    this.timerHandler = window.setTimeout(() => {
      this.reset();
    }, 1000);
  }

  private reset() {
    this.setState({ copied: false });
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }
}

export default CopyButton;
