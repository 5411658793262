import { Errors, IError } from 'utils/errors';
import { IActionResult, ISubnet } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import ServiceGroupEndpoint from 'endpoints/serviceGroupEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISubnetState {
  fetchAllResult: IActionResult<ISubnet[]>;
}

const subnetSlice = createSlice({
  name: 'subnet',
  initialState: {
    fetchAllResult: {
      processing: false,
    },
  } as ISubnetState,
  reducers: {
    fetchingAll(state) {
      state.fetchAllResult = { processing: true };
    },
    fetchedAll(state, action: PayloadAction<ISubnet[]>) {
      state.fetchAllResult = { processing: false, data: action.payload };
    },
    fetchAllFailed(state, action: PayloadAction<IError>) {
      state.fetchAllResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchAllNetworks = (): AppThunk => async (dispatch) => {
  const endpoint = new ServiceGroupEndpoint();
  dispatch(fetchingAll());
  try {
    const response = await endpoint.listNetworks();
    dispatch(fetchedAll(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAllFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = subnetSlice;
export const { fetchAllFailed, fetchedAll, fetchingAll } = actions;
export default reducer;
