import * as React from 'react';

import { Field } from 'redux-form';
import { IAvailableAzureVmValues } from 'types';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IParams {
  isRequired: boolean;
  disabled: boolean;
}

interface IProps {
  data: IAvailableAzureVmValues;
  params: IParams;
}

export class TemplateSelector extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { isRequired, disabled } = params;

    const availableTemplates = data.templates.map((template, index) => (
      <option value={template} key={index}>
        {template}
      </option>
    ));
    return (
      <Field
        name="templateName"
        component={selectComponent}
        label="Template Name"
        validate={isRequired ? formValidators.required : undefined}
        disabled={disabled}
      >
        <option value="">Select a template</option>
        {availableTemplates}
      </Field>
    );
  }
}

export default TemplateSelector;
