import { Errors, IError } from 'utils/errors';
import { IActionResult, ITenancyDomain } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import DomainEndpoint from 'endpoints/domainEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IDomainState {
  fetchAllResult: IActionResult<ITenancyDomain[]>;
  createResult: IActionResult<boolean>;
  deleteResult: IActionResult<boolean>;
}

const domainSlice = createSlice({
  name: 'domain',
  initialState: {
    fetchAllResult: {
      processing: false,
    },
    createResult: {
      processing: false,
    },
    deleteResult: {
      processing: false,
    },
  } as IDomainState,
  reducers: {
    fetchingAll(state) {
      state.fetchAllResult = { processing: true };
    },
    fetchedAll(state, action: PayloadAction<ITenancyDomain[]>) {
      state.fetchAllResult = { processing: false, data: action.payload };
    },
    fetchAllFailed(state, action: PayloadAction<IError>) {
      state.fetchAllResult = { processing: false, error: action.payload };
    },
    creating(state) {
      state.createResult = { processing: true };
    },
    created(state, action: PayloadAction<ITenancyDomain>) {
      state.createResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllResult.data)) {
        state.fetchAllResult.data.push(action.payload);
      }
    },
    createFailed(state, action: PayloadAction<IError>) {
      state.createResult = { processing: false, error: action.payload };
    },
    deleting(state) {
      state.deleteResult = { processing: true };
    },
    deleted(state, action: PayloadAction<string>) {
      state.deleteResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllResult.data)) {
        state.fetchAllResult.data = state.fetchAllResult.data.filter(
          (d) => d.id !== action.payload
        );
      }
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.deleteResult = { processing: false, error: action.payload };
    },
  },
});

export const fetchAllDomains = (): AppThunk => async (dispatch) => {
  const endpoint = new DomainEndpoint();
  dispatch(fetchingAll());
  try {
    const response = await endpoint.getList();
    dispatch(fetchedAll(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAllFailed(Errors.getError(error)));
  }
};

export const createDomain = (name: string): AppThunk => async (dispatch) => {
  const endpoint = new DomainEndpoint();
  dispatch(creating());
  try {
    const response = await endpoint.create(name);
    dispatch(created(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(createFailed(Errors.getError(error)));
  }
};

export const deleteDomain = (id: string): AppThunk => async (dispatch) => {
  const endpoint = new DomainEndpoint();
  dispatch(deleting());
  try {
    await endpoint.delete(id);
    dispatch(deleted(id));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = domainSlice;
export const {
  fetchAllFailed,
  fetchedAll,
  fetchingAll,
  created,
  createFailed,
  creating,
  deleteFailed,
  deleted,
  deleting,
} = actions;
export default reducer;
