import * as React from 'react';

import { Col, ListGroup } from 'react-bootstrap';

import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import ReplyIcon from 'mdi-react/ReplyIcon';
import RocketIcon from 'mdi-react/RocketIcon';

interface IProps {}

export class DeploymentOptionsDummy extends React.Component<IProps> {
  public render() {
    return (
      <Col md={6} lg={4}>
        <h5 className="font-weight-bold">Deployment Options:</h5>
        <ListGroup className="shadow mb-2">
          <ListGroup.Item action disabled>
            <RocketIcon size="1rem" className="mr-3" />
            Deploy
          </ListGroup.Item>
          <ListGroup.Item action disabled>
            <ArrowUpIcon size="1rem" className="mr-3" />
            Upgrade
          </ListGroup.Item>
          <ListGroup.Item action disabled>
            <ReplyIcon size="1rem" className="mr-3" />
            Retract
          </ListGroup.Item>
          <ListGroup.Item action disabled>
            <CancelIcon size="1rem" className="mr-3" />
            Cancel
          </ListGroup.Item>
        </ListGroup>
      </Col>
    );
  }
}

export default DeploymentOptionsDummy;
