import accountReducer from 'slices/accountSlice';
import bapiReducer from 'slices/bapiSlice';
import classificationSettingsReducer from 'slices/classificationSettingsSlice';
import classifyItemReducer from 'slices/classifyItemSlice';
import classifyListReducer from 'slices/classifyListSlice';
import { combineReducers } from 'redux';
import csReducer from 'slices/csSlice';
import deploymentReducer from 'slices/deploymentSlice';
import domainReducer from 'slices/domainSlice';
import { reducer as formReducer } from 'redux-form';
import interfaceReducer from 'slices/interfaceSlice';
import messageReducer from 'slices/messageSlice';
import modalReducer from 'slices/modalSlice';
import navigationReducer from 'slices/navigationSlice';
import notificationReducer from 'slices/notificationSlice';
import reportingReducer from 'slices/reportingSlice';
import rssFeedReducer from 'slices/rssFeedSlice';
import rulebaseReducer from 'slices/rulebaseSlice';
import serviceGroupReducer from 'slices/serviceGroupSlice';
import serviceReducer from 'slices/serviceSlice';
import serviceTypeReducer from 'slices/serviceTypeSlice';
import sesReducer from 'slices/sesSlice';
import sharePointErrorsReducer from 'slices/sharePointErrorsSlice';
import simSettingsReducer from 'slices/simSettingsSlice';
import siteLibraryReducer from 'slices/siteLibrarySlice';
import spoReducer from 'slices/spoSlice';
import spoSubscriptionReducer from 'slices/spoSubscriptionSlice';
import subnetReducer from 'slices/subnetSlice';
import supportReducer from 'slices/supportSlice';
import tenantReducer from 'slices/tenantSlice';
import tenantSettingsReducer from 'slices/tenantSettingsSlice';
import termStoreReducer from 'slices/termStoreSlice';
import tsReducer from 'slices/tsSlice';
import usageDataReducer from 'slices/usageDataSlice';
import userReducer from 'slices/userSlice';
import vmReducer from 'slices/vmSlice';

const rootReducer = combineReducers({
  form: formReducer,
  account: accountReducer,
  classificationSettings: classificationSettingsReducer,
  classifyItem: classifyItemReducer,
  classifyList: classifyListReducer,
  cs: csReducer,
  deployment: deploymentReducer,
  user: userReducer,
  tenant: tenantReducer,
  tenantSettings: tenantSettingsReducer,
  domain: domainReducer,
  interface: interfaceReducer,
  message: messageReducer,
  modal: modalReducer,
  notification: notificationReducer,
  rulebase: rulebaseReducer,
  support: supportReducer,
  subnet: subnetReducer,
  serviceGroup: serviceGroupReducer,
  service: serviceReducer,
  serviceType: serviceTypeReducer,
  sharePointErrors: sharePointErrorsReducer,
  simSettings: simSettingsReducer,
  siteLibrary: siteLibraryReducer,
  vm: vmReducer,
  ts: tsReducer,
  bapi: bapiReducer,
  spo: spoReducer,
  spoSubscription: spoSubscriptionReducer,
  usageData: usageDataReducer,
  ses: sesReducer,
  termStore: termStoreReducer,
  reporting: reportingReducer,
  rssFeed: rssFeedReducer,
  navigation: navigationReducer,
});

export type IReduxState = ReturnType<typeof rootReducer>;
export default rootReducer;
