import * as React from 'react';

import { Card, Table } from 'react-bootstrap';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, ITenancyDomain } from 'types';

import { AnyAction } from 'redux';
import DomainRow from './domainRow';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { asyncValidators } from 'utils/formValidators';
import { connect } from 'react-redux';
import { createDomain } from 'slices/domainSlice';
import submittableInputComponent from 'components/common/formComponents/submittableInputComponent';
import Authorizer from 'utils/authorizer';

interface IOwnProps {
  data: ITenancyDomain[];
}

interface IStateProps {
  createResult: IActionResult<boolean>;
  canManageDomains: boolean;
}

interface IDispatchProps {
  createDomain: (name: string) => void;
}

export interface IFormData {
  name: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ManageDomains extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      createResult,
      handleSubmit,
      pristine,
      data,
      canManageDomains,
    } = this.props;
    const Domains: React.FC = () => {
      if (data.length === 0)
        return (
          <tr>
            <td>None associated</td>
          </tr>
        );
      const domains = data.map((d, i) => (
        <DomainRow domain={d} key={i} userCanDeleteDomain={canManageDomains} />
      ));
      return <>{domains}</>;
    };

    return (
      <>
        <Form onSubmit={handleSubmit(this.save)}>
          <Field
            label="Manage Domains"
            name="name"
            component={submittableInputComponent}
            type="text"
            placeholder="domain.com"
            defaultMessage="Submit"
            progressMessage="Submitting"
            inProgress={createResult.processing}
            disabled={pristine || !canManageDomains}
            inputDisabled={!canManageDomains}
          />
        </Form>
        <Card className="mb-2">
          <Table hover size="sm" className="mb-0">
            <thead className="bg-light">
              <tr>
                <th>Associated Organization Domains</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <Domains />
            </tbody>
          </Table>
        </Card>
      </>
    );
  }

  private save(data: IFormData) {
    const { createDomain, reset } = this.props;
    createDomain(data.name);
    reset();
  }
}

const ManageDomainsForm = reduxForm<IFormData, IProps>({
  form: 'manageDomains',
  asyncValidate: asyncValidators.domainValid,
  asyncChangeFields: ['name'],
})(ManageDomains);

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    createResult: state.domain.createResult,
    canManageDomains: authorizer.canManageDomains(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createDomain: (name: string) => dispatch(createDomain(name)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ManageDomainsForm);
