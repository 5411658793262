import { Errors, IError } from 'utils/errors';
import { IActionResult, IServiceGroup } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import ServiceGroupEndpoint from 'endpoints/serviceGroupEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IServiceGroupState {
  fetchAllResult: IActionResult<IServiceGroup[]>;
  deleteResult: IActionResult<boolean>;
  createResult: IActionResult<boolean>;
  updateResult: IActionResult<boolean>;
  selected?: IServiceGroup;
}

const servieGroupSlice = createSlice({
  name: 'serviceGroup',
  initialState: {
    fetchAllResult: {
      processing: false,
    },
    deleteResult: {
      processing: false,
    },
    createResult: {
      processing: false,
    },
    updateResult: {
      processing: false,
    },
  } as IServiceGroupState,
  reducers: {
    fetchingAll(state) {
      state.fetchAllResult = { processing: true };
    },
    fetchedAll(state, action: PayloadAction<IServiceGroup[]>) {
      state.fetchAllResult = { processing: false, data: action.payload };
    },
    fetchAllFailed(state, action: PayloadAction<IError>) {
      state.fetchAllResult = {
        processing: false,
        error: action.payload,
      };
    },
    deleting(state) {
      state.deleteResult = { processing: true };
    },
    deleted(state, action: PayloadAction<string>) {
      state.deleteResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllResult.data)) {
        state.fetchAllResult.data = state.fetchAllResult.data.filter(
          (g) => g.id !== action.payload
        );
      }
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.deleteResult = { processing: false, error: action.payload };
    },
    creating(state) {
      state.createResult = { processing: true };
    },
    created(state, action: PayloadAction<IServiceGroup>) {
      state.createResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllResult.data)) {
        state.fetchAllResult.data.push(action.payload);
      }
    },
    creationFailed(state, action: PayloadAction<IError>) {
      state.createResult = { processing: false, error: action.payload };
    },
    updating(state) {
      state.updateResult = { processing: true };
    },
    updated(state, action: PayloadAction<IServiceGroup>) {
      state.updateResult = { processing: false, data: true };
      if (Array.isArray(state.fetchAllResult.data)) {
        state.fetchAllResult.data = [
          ...state.fetchAllResult.data.filter(
            (g) => g.id !== action.payload.id
          ),
          action.payload,
        ];
      }
    },
    updateFailed(state, action: PayloadAction<IError>) {
      state.updateResult = { processing: false, error: action.payload };
    },
    selectServiceGroup(state, action: PayloadAction<IServiceGroup>) {
      state.selected = action.payload;
    },
    deselectServiceGroup(state) {
      state.selected = undefined;
    },
  },
});

export const fetchAllGroups = (): AppThunk => async (dispatch) => {
  const endpoint = new ServiceGroupEndpoint();
  dispatch(fetchingAll());
  try {
    const response = await endpoint.list();
    dispatch(fetchedAll(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchAllFailed(Errors.getError(error)));
  }
};

export const deleteGroup = (id: string): AppThunk => async (dispatch) => {
  const endpoint = new ServiceGroupEndpoint();
  dispatch(deleting());
  try {
    await endpoint.delete(id);
    dispatch(deleted(id));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
};

export const createGroup = (group: IServiceGroup): AppThunk => async (
  dispatch
) => {
  const endpoint = new ServiceGroupEndpoint();
  dispatch(creating());
  try {
    const response = await endpoint.create(group);
    dispatch(created(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(creationFailed(Errors.getError(error)));
  }
};

export const updateGroup = (group: IServiceGroup): AppThunk => async (
  dispatch
) => {
  const endpoint = new ServiceGroupEndpoint();
  dispatch(updating());
  try {
    const response = await endpoint.update(group);
    dispatch(updated(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = servieGroupSlice;
export const {
  fetchAllFailed,
  fetchedAll,
  fetchingAll,
  created,
  creating,
  creationFailed,
  deleteFailed,
  deleted,
  deleting,
  updateFailed,
  updated,
  updating,
  deselectServiceGroup,
  selectServiceGroup,
} = actions;
export default reducer;
