import * as React from 'react';

import Authorizer from 'utils/authorizer';
import { Field } from 'redux-form';
import { IReduxState } from 'reducers';
import { ITenancyDetail } from 'types';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IOwnProps {
  data: ITenancyDetail[];
}

interface IStateProps {
  canChangeTenant: boolean;
}

interface IProps extends IOwnProps, IStateProps {}

export class TenancySelector extends React.Component<IProps> {
  public render() {
    const { canChangeTenant, data } = this.props;
    const options = data.map((t, i) => (
      <option value={t.id} key={i}>
        {t.name}
      </option>
    ));
    return (
      <Field
        name="tenantId"
        label="Tenant"
        component={selectComponent}
        validate={formValidators.required}
        disabled={!canChangeTenant}
      >
        <option value="">No tenancy</option>
        {options}
      </Field>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canChangeTenant: authorizer.canChangeTenant(),
  };
};

export default connect<IStateProps, {}, IOwnProps, IReduxState>(
  mapStateToProps
)(TenancySelector);
