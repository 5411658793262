import * as React from 'react';

import { IActionResult, IConfigurableFields, ISiteLibrary } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchListConfigurableFields } from 'slices/simSettingsSlice';
import listSimSettings from './listSimSettings';

interface IStateProps {
  fetchFieldsResult: IActionResult<IConfigurableFields>;
}

interface IParams {
  spHostUrl: string;
}

interface IOwnProps {
  data: ISiteLibrary;
  params: IParams;
}

interface IDispatchProps {
  loadListConfigurableFields: (siteLibraryId: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ListSimSettingsPreLoad extends React.Component<IProps> {
  public componentDidMount() {
    const { loadListConfigurableFields, data } = this.props;

    loadListConfigurableFields(data.id);
  }

  public render() {
    const { fetchFieldsResult, data, params } = this.props;
    const { spHostUrl } = params;
    return (
      <LoaderWithParams
        actionresult={fetchFieldsResult}
        component={listSimSettings}
        params={{
          siteLibrary: data,
          spHostUrl,
        }}
      />
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchFieldsResult: state.simSettings.fetchFieldsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  loadListConfigurableFields: (siteLibraryId: string) =>
    dispatch(fetchListConfigurableFields(siteLibraryId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ListSimSettingsPreLoad);
