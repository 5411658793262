import { Button } from 'react-bootstrap';
import { Endpoint } from 'endpoints/base/endpoint';
import GoogleIcon from 'mdi-react/GoogleIcon';
import MicrosoftWindowsIcon from 'mdi-react/MicrosoftWindowsIcon';
import { QueryItem } from 'endpoints/base/queryItem';
import React from 'react';
import SalesforceIcon from 'mdi-react/SalesforceIcon';

interface IProps {
  data: string[];
}

const diplayNameMap: {
  [key: string]: string;
} = {
  MicrosoftUSGov: 'Microsoft work account (Azure Government)',
  Microsoft: 'Microsoft work account',
  Google: 'Google',
  Salesforce: 'Salesforce',
};

const displayOrder: string[] = [
  'Microsoft',
  'MicrosoftUSGov',
  'Google',
  'Salesforce',
];

const iconMap: {
  [key: string]: JSX.Element;
} = {
  Microsoft: <MicrosoftWindowsIcon className="mr-1" />,
  MicrosoftUSGov: <MicrosoftWindowsIcon className="mr-1" />,
  Google: <GoogleIcon className="mr-1" />,
  Salesforce: <SalesforceIcon className="mr-1" />,
};

const buttonVariantMap: {
  [key: string]:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
} = {
  Microsoft: 'primary',
  MicrosoftUSGov: 'primary',
  Google: 'danger',
  Salesforce: 'info',
};

class ExternalLoginProviders extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const values = new URLSearchParams(window.location.search);
    const returnUri = values.get('redirectUrl')! || window.location.href;
    const parameters = [
      new QueryItem(
        'redirectUri',
        encodeURIComponent(
          (returnUri +
            (window.location.hash ? window.location.hash : '')) as string
        )
      ),
    ];
    const loginButtons = displayOrder.map((provider, index) =>
      data.indexOf(provider) >= 0 ? (
        <Button
          href={Endpoint.getApiUrl(
            ['account', 'externalLogin', provider],
            parameters
          )}
          variant={buttonVariantMap[provider]}
          size="lg"
          className="btn-block font-normal"
          key={index}
        >
          {iconMap[provider]}
          {diplayNameMap[provider]}
        </Button>
      ) : null
    );
    return <>{loginButtons}</>;
  }
}

export default ExternalLoginProviders;
