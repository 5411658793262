import { Errors, IError } from 'utils/errors';
import { IActionResult, IDefaultTripleStoreValues } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import TripleStoreEndpoint from 'endpoints/tripleStoreEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ITsState {
  defaultTsValuesResult: IActionResult<IDefaultTripleStoreValues>;
}

const tsSlice = createSlice({
  name: 'ts',
  initialState: {
    defaultTsValuesResult: {
      processing: false,
    },
  } as ITsState,
  reducers: {
    fetchingDefault(state) {
      state.defaultTsValuesResult = { processing: true };
    },
    fetchedDefault(state, action: PayloadAction<IDefaultTripleStoreValues>) {
      state.defaultTsValuesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchDefaultFailed(state, action: PayloadAction<IError>) {
      state.defaultTsValuesResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchDefaultTsValues = (): AppThunk => async (dispatch) => {
  const endpoint = new TripleStoreEndpoint();
  dispatch(fetchingDefault());
  try {
    const response = await endpoint.getDefaultValues();
    dispatch(fetchedDefault(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchDefaultFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = tsSlice;
export const { fetchDefaultFailed, fetchedDefault, fetchingDefault } = actions;
export default reducer;
