import * as React from 'react';

import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, IClassificationConfig } from 'types';

import { AnyAction } from 'redux';
import { ConfigProps } from 'redux-form';
import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';
import { IReduxState } from 'reducers';
import SmallError from 'components/common/smallError';
import SubmitButton from 'components/common/submitButton';
import { ThunkDispatch } from 'redux-thunk';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import { connect } from 'react-redux';
import { saveClassificationSettings } from 'slices/classificationSettingsSlice';
import inputComponent from 'components/common/formComponents/inputComponent';
import { formValidators } from 'utils/formValidators';

export type IFormData = INonBooleanFormParameters & IBooleanFormParameters;

interface INonBooleanFormParameters {
  maxClassifyFileSize: number;
  patternsToIgnore: string;
  patternsToClassifyMetadataOnly: string;
}

interface IBooleanFormParameters {
  ignoreInitialEvents: boolean;
  includeBody: boolean;
  [key: string]: boolean;
}

interface IParams {
  cTypeId: string;
  interfaceId: string;
  spHostUrl: string;
  listId?: string;
}

interface IOwnProps {
  data: IClassificationConfig;
  params: IParams;
}

interface IStateProps {
  saveClassificationSettingsResult: IActionResult<boolean>;
}

interface IDispatchProps {
  saveClassificationSettings: (
    classificationConfig: IClassificationConfig
  ) => void;
}

interface IState {
  documentBodyIncluded: boolean;
  definedFields: string[];
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ContentTypeClassificationSettings extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
    this.handleIncludeBodyChanged = this.handleIncludeBodyChanged.bind(this);
    this.handleDefinedHereChanged = this.handleDefinedHereChanged.bind(this);
    this.state = {
      documentBodyIncluded: props.data.includeBody,
      definedFields: props.data.configurableFields
        .filter((field) => field.definedHere)
        .map((field) => field.internalName),
    };
  }

  public render() {
    const {
      data,
      handleSubmit,
      params,
      pristine,
      saveClassificationSettingsResult,
    } = this.props;
    const { spHostUrl, cTypeId, listId } = params;
    const { definedFields, documentBodyIncluded } = this.state;
    const settingsAddress: string = this.getSettingsAddress(
      spHostUrl,
      cTypeId,
      listId
    );
    const events = data.triggeringEventDetails.map((event, index) => (
      <tr key={index}>
        <td>{event.name}</td>
        <td>
          <Field
            name={`event-examine-${event.value}`}
            component="input"
            type="checkbox"
          />
        </td>
        <td>{event.description}</td>
      </tr>
    ));

    const columns = data.configurableFields.map((field, index) => {
      if (field.trackManualEdit) {
        return null;
      }

      const definedHere = Boolean(
        definedFields.find(
          (definedField) => definedField === field.internalName
        )
      );

      return (
        <tr key={index}>
          <td>{field.displayName}</td>
          <td>{field.metadataName}</td>
          <td>
            <Field
              name={`field-process-${field.internalName}`}
              component="input"
              type="checkbox"
              disabled={!definedHere}
            />
          </td>
          <td>
            <Field
              name={`field-inclMeta-${field.internalName}`}
              component="input"
              type="checkbox"
              disabled={!definedHere}
            />
          </td>
          {!documentBodyIncluded ? (
            <td>
              <Field
                name={`field-inclBody-${field.internalName}`}
                component="input"
                type="checkbox"
                disabled={!definedHere}
              />
            </td>
          ) : null}
          <td>
            <Field
              name={`field-define-${field.internalName}`}
              component="input"
              type="checkbox"
              onChange={this.handleDefinedHereChanged(field.internalName)}
            />
          </td>
        </tr>
      );
    });

    const properties = data.properties.map((property, index) => (
      <tr key={index}>
        <td>{property.displayName}</td>
        <td>{property.metadataName}</td>
        <td>
          <Field
            name={`property-inclMeta-${property.internalName}`}
            component="input"
            type="checkbox"
          />
        </td>
        {!documentBodyIncluded ? (
          <td>
            <Field
              name={`property-inclBody-${property.internalName}`}
              component="input"
              type="checkbox"
            />
          </td>
        ) : null}
      </tr>
    ));

    const profileProperties = data.userProfileProperties.map(
      (property, index) => (
        <tr key={index}>
          <td>{property.name}</td>
          <td>{`user/user_${property.name}`}</td>
          <td>
            <Field
              name={`profileProperty-inclMeta-${property.name}`}
              component="input"
              type="checkbox"
            />
          </td>
        </tr>
      )
    );

    return (
      <>
        <Row className="bg-light p-3 mb-2">
          <Col>
            <h1 className="d-inline">Semaphore Classification Settings</h1>
            <Button
              variant="primary"
              className="float-right"
              href={settingsAddress}
            >
              Return to settings
            </Button>
          </Col>
        </Row>
        <Row>
          <Container className="p-4">
            <Form onSubmit={handleSubmit(this.save)}>
              <h4>Content Type Information</h4>
              <Table size="sm">
                <tbody>
                  <tr>
                    <th>Name:</th>
                    <td>{data.contentTypeName}</td>
                  </tr>
                </tbody>
              </Table>
              <Field
                name="includeBody"
                component={checkBoxComponent}
                label="Include document body in classification request"
                id="includeBody"
                onChange={this.handleIncludeBodyChanged}
              />
              <h4>Automatic Document Classification</h4>
              <Field
                name="maxClassifyFileSize"
                component={inputComponent}
                type="number"
                label="Maximum size of documents to classify (in Mb):"
                tooltip="Documents exceeding this size (in Mb) will not be sent for automatic classification. Leave empty or with a value of 0 to disable."
                id="maxClassifyFileSize"
                min="0"
                step="1"
                validate={formValidators.largerThanZeroOrEmpty}
              />
              <Field
                name="patternsToIgnore"
                component={inputComponent}
                type="text"
                label="Do not classify documents with file names matching:"
                emptyPlaceholder
                tooltip="Documents with file names matching the provided wildcard specifications will not be sent for automatic classification. Wildcard specifications should be separated by spaces. Leave blank to disable."
                id="patternsToIgnore"
                validate={formValidators.wildcardPatternValid}
              />
              <Field
                name="patternsToClassifyMetadataOnly"
                component={inputComponent}
                type="text"
                label="Submit metadata only for automatic classification for documents with file names matching:"
                emptyPlaceholder
                tooltip="Documents with file names matching the provided wildcard specifications will only have their SharePoint Online metadata sent for automatic classification, not the document itself. Wildcard specifications should be separated by spaces. Leave blank to disable."
                id="patternsToClassifyMetadataOnly"
                validate={formValidators.wildcardPatternValid}
              />
              <h4>
                Events{' '}
                <span title="Classification can be triggered whenever any change is made to the configured columns below. By default column values are examined whenever they change, however this can be restricted to particular item events:">
                  <HelpOutlineIcon size="1.5rem" />
                </span>
              </h4>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Event Name</th>
                    <th>Examine Columns</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {events}
                  <tr>
                    <td colSpan={3}>
                      <Field
                        name="ignoreInitialEvents"
                        component={checkBoxComponent}
                        label="Ignore initial events"
                        tooltip="Select on pages library to avoid conflicts when creating new Wiki page."
                        id="ignoreInitialEvents"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h4>
                Columns{' '}
                <span title="Columns can be included as additional evidence for classification and can trigger classification when changed. The following columns are currently available in this list:">
                  <HelpOutlineIcon size="1.5rem" />
                </span>
              </h4>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Metadata Name</th>
                    <th>Process On Change</th>
                    <th>Include As Metadata</th>
                    {!documentBodyIncluded ? <th>Include In Body</th> : null}
                    <th>Define</th>
                  </tr>
                </thead>
                <tbody>{columns}</tbody>
              </Table>
              <h4>
                Properties{' '}
                <span title="Other properties can be included as additional evidence for classification. The following properties are available:">
                  <HelpOutlineIcon size="1.5rem" />
                </span>
              </h4>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Property Name</th>
                    <th>Metadata Name</th>
                    <th>Include As Metadata</th>
                    {!documentBodyIncluded ? <th>Include In Body</th> : null}
                  </tr>
                </thead>
                <tbody>{properties}</tbody>
              </Table>
              <h4>
                User Profile Properties{' '}
                <span title="User Profile properties from the modifying user can be included as additional evidence for classification. The following properties are available for the current user:">
                  <HelpOutlineIcon size="1.5rem" />
                </span>
              </h4>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Property Name</th>
                    <th>Metadata Name</th>
                    <th>Include As Metadata</th>
                  </tr>
                </thead>
                <tbody>{profileProperties}</tbody>
              </Table>
              <SmallError error={saveClassificationSettingsResult.error} />
              <SubmitButton
                inProgress={saveClassificationSettingsResult.processing}
                disabled={pristine}
                variant="primary"
                className="float-right"
              />
            </Form>
          </Container>
        </Row>
      </>
    );
  }

  private getSettingsAddress(
    spHostUrl: string,
    cTypeId: string,
    listId?: string
  ) {
    const listParam = listId ? `&List=${listId}` : '';
    return `${spHostUrl}/_layouts/15/ManageContentType.aspx?ctype=${cTypeId}${listParam}`;
  }

  private async save(data: IFormData) {
    const {
      data: classificationSettings,
      params,
      saveClassificationSettings,
    } = this.props;
    const { spHostUrl, cTypeId, listId } = params;
    const patternsToIgnore = data.patternsToIgnore
      ? data.patternsToIgnore.split(' ')
      : [];
    const patternsToClassifyMetadataOnly = data.patternsToClassifyMetadataOnly
      ? data.patternsToClassifyMetadataOnly.split(' ')
      : [];
    const updatedSettings = Object.assign({}, classificationSettings, {
      ignoreNoContentTypeEvents: data.ignoreInitialEvents === true,
      includeBody: data.includeBody === true,
      triggeringEventDetails: classificationSettings.triggeringEventDetails.map(
        (event) =>
          Object.assign({}, event, {
            isSelected: data[`event-examine-${event.value}`] === true,
          })
      ),
      configurableFields: classificationSettings.configurableFields.map(
        (field) =>
          Object.assign({}, field, {
            trackChanges: data[`field-process-${field.internalName}`] === true,
            includeAsMetadata:
              data[`field-inclMeta-${field.internalName}`] === true,
            includeInBody:
              data[`field-inclBody-${field.internalName}`] === true,
            definedHere: data[`field-define-${field.internalName}`] === true,
          })
      ),
      properties: classificationSettings.properties.map((property) =>
        Object.assign({}, property, {
          includeAsMetadata:
            data[`property-inclMeta-${property.internalName}`] === true,
          includeInBody:
            data[`property-inclBody-${property.internalName}`] === true,
        })
      ),
      userProfileProperties: classificationSettings.userProfileProperties.map(
        (profileProperty) =>
          Object.assign({}, profileProperty, {
            include:
              data[`profileProperty-inclMeta-${profileProperty.name}`] === true,
          })
      ),
      patternsToIgnore: patternsToIgnore,
      patternsToClassifyMetadataOnly: patternsToClassifyMetadataOnly,
      maxClassifyFileSize: data.maxClassifyFileSize,
    });

    saveClassificationSettings(updatedSettings);
    const settingsAddress: string = this.getSettingsAddress(
      spHostUrl,
      cTypeId,
      listId
    );
    window.location.assign(settingsAddress);
  }

  private handleIncludeBodyChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      documentBodyIncluded: event.currentTarget.checked,
    });
  }

  private handleDefinedHereChanged(internalName: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { definedFields } = this.state;
      const { change, data } = this.props;
      const checked = event.currentTarget.checked;
      let updatedDefinedFields: string[];
      if (checked) {
        updatedDefinedFields = [...definedFields, internalName];
      } else {
        const initialField = data.configurableFields.find(
          (field) => field.internalName === internalName
        )!;
        change(`field-process-${internalName}`, initialField.trackChanges);
        change(
          `field-inclMeta-${internalName}`,
          initialField.includeAsMetadata
        );
        change(`field-inclBody-${internalName}`, initialField.includeInBody);
        updatedDefinedFields = definedFields.filter(
          (field) => field !== internalName
        );
      }

      this.setState({
        definedFields: updatedDefinedFields,
      });
    };
  }
}

const ContentTypeClassificationSettingsForm = reduxForm<IFormData, IProps>({
  form: 'contentTypeClassificationSettings',
})(ContentTypeClassificationSettings);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const values: any = {};
  ownProps.data.triggeringEventDetails.forEach((event) => {
    values[`event-examine-${event.value}`] = event.isSelected;
  });

  ownProps.data.configurableFields.forEach((field) => {
    values[`field-process-${field.internalName}`] = field.trackChanges;
    values[`field-inclMeta-${field.internalName}`] = field.includeAsMetadata;
    values[`field-inclBody-${field.internalName}`] = field.includeInBody;
    values[`field-define-${field.internalName}`] = field.definedHere;
  });

  ownProps.data.properties.forEach((property) => {
    values[`property-inclMeta-${property.internalName}`] =
      property.includeAsMetadata;
    values[`property-inclBody-${property.internalName}`] =
      property.includeInBody;
  });

  ownProps.data.userProfileProperties.forEach((profileProperties) => {
    values[`profileProperty-inclMeta-${profileProperties.name}`] =
      profileProperties.include;
  });

  return {
    initialValues: {
      ...values,
      includeBody: ownProps.data.includeBody,
      ignoreInitialEvents: ownProps.data.ignoreNoContentTypeEvents,
      maxClassifyFileSize: ownProps.data.maxClassifyFileSize,
      patternsToIgnore: ownProps.data.patternsToIgnore.join(' '),
      patternsToClassifyMetadataOnly: ownProps.data.patternsToClassifyMetadataOnly.join(
        ' '
      ),
    },
    form: 'contentTypeClassificationSettings',
    saveClassificationSettingsResult:
      state.classificationSettings.saveClassificationSettingsResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => {
  const { interfaceId, spHostUrl } = ownProps.params;
  return {
    saveClassificationSettings: (classificationConfig: IClassificationConfig) =>
      dispatch(
        saveClassificationSettings(interfaceId, spHostUrl, classificationConfig)
      ),
  };
};

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ContentTypeClassificationSettingsForm);
