import { QueryItem } from './queryItem';

export class Endpoint {
  public static readonly backendUrl = `${
    process.env.REACT_APP_BACKEND_ADDRESS ??
    window.location.origin.replace(':3000', '')
  }`;
  public static readonly apiUrl = `${Endpoint.backendUrl}/api/`;

  public static getUrl(actions: string[], queryItems?: QueryItem[]): string {
    const actionsString = actions.join('/');
    return queryItems
      ? actionsString + '?' + queryItems.join('&')
      : actionsString;
  }

  public static getApiUrl(actions: string[], queryItems?: QueryItem[]): string {
    return `${this.apiUrl}${this.getUrl(actions, queryItems)}`;
  }
}
