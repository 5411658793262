import * as React from 'react';

import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AnonymousLayout from 'components/common/layout/anonymousLayout';
import BapiHelpPage from 'components/pages/bapiHelp/bapiHelpPage';
import ClassifyItemPage from 'components/pages/classifyItem/classifyItemPage';
import ContentTypeClassificationSettingsPage from 'components/pages/classificationSettings/contentTypeClassificationSettingsPage';
import ContentTypeSimSettingsPage from 'components/pages/simSettings/contentTypeSimSettingsPage';
import Dashboard from 'components/pages/dashboard/dashboardPage';
import Error from 'components/common/error';
import ErrorPage from 'components/pages/error/errorPage';
import InterfacesPage from 'components/pages/interfaces/interfacesPage';
import ListClassificationSettingsPage from './components/pages/classificationSettings/listClassificationSettingsPage';
import ListSimSettingsPage from 'components/pages/simSettings/listSimSettingsPage';
import LoginPage from 'components/pages/authentication/loginPage';
import LogoutPage from 'components/pages/authentication/logoutPage';
import ManageAccount from 'components/pages/manage/manageAccountPage';
import NarrowLayout from 'components/common/layout/narrowLayout';
import RoleChecker from 'components/common/roleChecker';
import ServicesPage from 'components/pages/services/servicesPage';
import SpoInstructionPage from 'components/pages/spoInstruction/spoInstructionPage';
import SupportPage from 'components/pages/support/supportPage';
import TenantsPage from 'components/pages/tenants/tenantsPage';
import UsersPage from 'components/pages/users/usersPage';
import WideLayout from 'components/common/layout/wideLayout';

export default class Routes extends React.Component {
  public render() {
    return (
      <Switch>
        <Redirect strict from="/register/" to="/" />,
        <Route exact path="/" component={this.home} />
        <Route exact path="/error/" component={this.error} />
        <Route exact path="/support/" component={this.support} />
        <Route exact path="/services/" component={this.services} />
        <Route exact path="/users/" component={this.users} />
        <Route exact path="/account/" component={this.manage} />
        <Route exact path="/logout/" component={this.logout} />
        <Route path="/relogin" component={this.relogin} />
        <Route
          path="/interfaces/:interfaceType/"
          render={this.interfaceTypes}
        />
        <Route path="/interfaces/" component={this.interfaces} />
        <Route exact path="/tenants/" component={this.tenants} />
        <Route exact path="/spoInstruction/" component={this.spoInstruction} />
        <Route
          path="/:interfaceId/list/simSettings/"
          exact
          render={this.listSimSettings}
        />
        <Route
          path="/:interfaceId/contentType/simSettings/"
          exact
          render={this.contentTypeSimSettings}
        />
        <Route
          path="/:interfaceId/list/classificationSettings/"
          exact
          render={this.listClassificationSettings}
        />
        <Route
          path="/:interfaceId/contentType/classificationSettings/"
          exact
          render={this.contentTypeClassificationSettings}
        />
        <Route
          path="/:interfaceId/classifyItem/"
          exact
          render={this.classifyItem}
        />
        <Route path="/bapiHelp/:interfaceId" exact render={this.bapiHelp} />
        <Route path="/" render={this.noPage} />
      </Switch>
    );
  }
  private noPage = () => (
    <Error error={{ title: 'Page does not exist' }}></Error>
  );

  private home = () => (
    <WideLayout>
      <Dashboard />
    </WideLayout>
  );
  private error = () => (
    <WideLayout>
      <ErrorPage />
    </WideLayout>
  );
  private support = () => (
    <RoleChecker
      showAccessError
      requiredRoles={[
        'SupportManager',
        'InfrastructureManager',
        'GlobalAdministrator',
        'GlobalCoAdministrator',
      ]}
    >
      <WideLayout>
        <SupportPage />
      </WideLayout>
    </RoleChecker>
  );
  private services = () => (
    <RoleChecker showAccessError requiredRoles={['InfrastructureManager']}>
      <WideLayout>
        <ServicesPage />
      </WideLayout>
    </RoleChecker>
  );
  private users = () => (
    <RoleChecker
      showAccessError
      requiredRoles={[
        'TenantAdministrator',
        'TenantCoAdministrator',
        'GlobalAdministrator',
        'GlobalCoAdministrator',
        'AccountManager',
        'AccountReader',
      ]}
    >
      <NarrowLayout>
        <UsersPage />
      </NarrowLayout>
    </RoleChecker>
  );
  private manage = () => (
    <NarrowLayout>
      <ManageAccount />
    </NarrowLayout>
  );
  private tenants = () => (
    <RoleChecker
      showAccessError
      requiredRoles={[
        'AccountManager',
        'InfrastructureManager',
        'GlobalAdministrator',
        'GlobalCoAdministrator',
        'AccountReader',
      ]}
    >
      <NarrowLayout>
        <TenantsPage />
      </NarrowLayout>
    </RoleChecker>
  );
  private spoInstruction = () => <SpoInstructionPage />;
  private interfaces = () => (
    <NarrowLayout>
      <InterfacesPage />
    </NarrowLayout>
  );
  private logout = () => <LogoutPage />;
  private interfaceTypes = (
    props: RouteComponentProps<{ interfaceType: 'bapi' | 'spo' }>
  ) => {
    const { interfaceType } = props.match.params;
    return (
      <NarrowLayout>
        <InterfacesPage interfaceType={interfaceType} />
      </NarrowLayout>
    );
  };
  private listClassificationSettings = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => (
    <WideLayout>
      <ListClassificationSettingsPage
        interfaceId={props.match.params.interfaceId}
      />
    </WideLayout>
  );
  private contentTypeClassificationSettings = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => (
    <WideLayout>
      <ContentTypeClassificationSettingsPage
        interfaceId={props.match.params.interfaceId}
      />
    </WideLayout>
  );

  private listSimSettings = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => (
    <WideLayout>
      <ListSimSettingsPage interfaceId={props.match.params.interfaceId} />
    </WideLayout>
  );

  private contentTypeSimSettings = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => (
    <WideLayout>
      <ContentTypeSimSettingsPage
        interfaceId={props.match.params.interfaceId}
      />
    </WideLayout>
  );

  private classifyItem = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => <ClassifyItemPage interfaceId={props.match.params.interfaceId} />;

  private bapiHelp = (props: RouteComponentProps<{ interfaceId: string }>) => (
    <BapiHelpPage interfaceId={props.match.params.interfaceId} />
  );

  private relogin = () => {
    return (
      <AnonymousLayout showCopyright={true}>
        <LoginPage
          error={{
            title: 'Oops...somethings up!',
            subTitle:
              'Your session has timed out or you have been restricted from accessing this page. Please login and try again',
          }}
        />
      </AnonymousLayout>
    );
  };
}
