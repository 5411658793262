import { IServiceBasicInfo } from 'types';
import React from 'react';

interface IOwnProps {
  data: IServiceBasicInfo[];
  params: { excludedServiceIds: string[] };
}

interface IProps extends IOwnProps {}

class CsServerOptions extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const services = params
      ? data.filter((s) => !params.excludedServiceIds.includes(s.serverId!))
      : data;
    return services.map((service, index) => (
      <option value={service.serverId} key={index}>
        {service.name}
      </option>
    ));
  }
}

export default CsServerOptions;
