import { Errors, IError } from 'utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import DownloadEndpoint from 'endpoints/downloadEndpoint';
import { IActionResult } from 'types';
import fileDownload from 'js-file-download';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IDownloadState {
  downloadResult: IActionResult<boolean>;
}

const downloadSlice = createSlice({
  name: 'download',
  initialState: {
    downloadResult: {
      processing: false,
    },
  } as IDownloadState,
  reducers: {
    downloading(state) {
      state.downloadResult = { processing: true };
    },
    downloaded(state) {
      state.downloadResult = {
        processing: false,
        data: true,
      };
    },
    downloadFailed(state, action: PayloadAction<IError>) {
      state.downloadResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const downloadApp = (): AppThunk => async (dispatch) => {
  const endpoint = new DownloadEndpoint();
  dispatch(downloading());
  try {
    const response = await endpoint.getApp();
    fileDownload(response.data, 'Smartlogic.Semaphore.SharePoint.app');
    dispatch(downloaded());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(downloadFailed(Errors.getError(error)));
  }
};

export const downloadModernUiApp = (): AppThunk => async (dispatch) => {
  const endpoint = new DownloadEndpoint();
  dispatch(downloading());
  try {
    const response = await endpoint.getModernUiApp();
    fileDownload(
      response.data,
      'Smartlogic.Semaphore.Sharepoint.ModernUI.sppkg'
    );
    dispatch(downloaded());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(downloadFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = downloadSlice;
export const { downloadFailed, downloaded, downloading } = actions;
export default reducer;
