import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export class SesEndpoint {
  public readonly ROOT = 'SemanticEnhancementServer';

  public getIndexes(sesId: string): Promise<AxiosResponse<string[]>> {
    const queryItems = [new QueryItem('sesId', sesId)];
    const url = Endpoint.getUrl([this.ROOT, 'GetIndexes'], queryItems);
    return api.get(url);
  }
}
