import * as React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';
import SubmitButton from 'components/common/submitButton';

const submittableInputComponent = ({
  input,
  label,
  tooltip,
  type,
  inProgress,
  disabled,
  defaultMessage,
  progressMessage,
  inputDisabled,
  meta: { touched, error, warning },
}: {
  input: any;
  label: string;
  tooltip: string;
  type: string;
  inProgress: boolean;
  disabled: boolean;
  inputDisabled: boolean;
  defaultMessage: string;
  progressMessage: string;
  meta: any;
}) => {
  const help =
    tooltip !== undefined ? (
      <span title={tooltip}>
        <HelpOutlineIcon size="1rem" />
      </span>
    ) : null;
  return (
    <Form.Group>
      <Form.Label>
        {label} {help}
      </Form.Label>
      <InputGroup>
        <Form.Control
          {...input}
          placeholder={label}
          type={type}
          disabled={inputDisabled}
        />
        <InputGroup.Append>
          <SubmitButton
            variant="success"
            inProgress={inProgress}
            disabled={disabled}
            defaultMessage={defaultMessage}
            progressMessage={progressMessage}
          />
        </InputGroup.Append>
      </InputGroup>
      {touched &&
        ((error && <Form.Text className="text-danger">{error}</Form.Text>) ||
          (warning && (
            <Form.Text className="text-warning">{warning}</Form.Text>
          )))}
    </Form.Group>
  );
};

export default submittableInputComponent;
