import { Errors, IError } from 'utils/errors';
import { IActionResult, IDeployment } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import SpoDeploymentEndpoint from 'endpoints/spoDeploymentEndpoint';
import { closeModal } from './modalSlice';
import { fetchNotifications } from './notificationSlice';
import { newErrorMessage } from './messageSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IDeploymentState {
  deploymentsResult: IActionResult<IDeployment[]>;
  deleteSiteDeploymentResult: IActionResult<boolean>;
  deploymentResult: IActionResult<boolean>;
  retractionResult: IActionResult<boolean>;
  upgradeResult: IActionResult<boolean>;
  statusResult: IActionResult<IDeployment>;
}

const deploymentSlice = createSlice({
  name: 'deployment',
  initialState: {
    deploymentsResult: { processing: false },
    deleteSiteDeploymentResult: { processing: false },
    deploymentResult: { processing: false },
    retractionResult: { processing: false },
    upgradeResult: { processing: false },
    statusResult: { processing: false },
  } as IDeploymentState,
  reducers: {
    fetchingStatus(state) {
      state.statusResult.processing = true;
    },
    fetchedStatus(state, action: PayloadAction<IDeployment>) {
      state.statusResult = { processing: false, data: action.payload };
      if (Array.isArray(state.deploymentsResult.data) && action.payload) {
        const index = state.deploymentsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.deploymentsResult.data[index] = action.payload;
        } else {
          state.deploymentsResult.data.push(action.payload);
        }
      }
    },
    fetchStatusFailed(state, action: PayloadAction<IError>) {
      state.statusResult = { processing: false, error: action.payload };
    },
    fetchingDeployments(state) {
      state.deploymentsResult.processing = true;
    },
    fetchedDeployments(state, action: PayloadAction<IDeployment[]>) {
      state.deploymentsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchDeploymentsFailed(state, action: PayloadAction<IError>) {
      state.deploymentsResult = {
        processing: false,
        error: action.payload,
      };
    },
    deletingSiteDeployment(state) {
      state.deleteSiteDeploymentResult = { processing: true };
    },
    deletedSiteDeployment(state, action: PayloadAction<string>) {
      state.deleteSiteDeploymentResult = { processing: false, data: true };
      if (Array.isArray(state.deploymentsResult.data)) {
        state.deploymentsResult.data = state.deploymentsResult.data.filter(
          (d) => d.id !== action.payload
        );
      }
    },
    deleteSiteDeploymentFailed(state, action: PayloadAction<IError>) {
      state.deleteSiteDeploymentResult = {
        processing: false,
        error: action.payload,
      };
    },
    deploying(state) {
      state.deploymentResult = { processing: true };
    },
    deployed(state, action: PayloadAction<IDeployment>) {
      state.statusResult.data = action.payload;
      if (Array.isArray(state.deploymentsResult.data)) {
        const index = state.deploymentsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.deploymentsResult.data[index] = action.payload;
        } else {
          state.deploymentsResult.data.push(action.payload);
        }
      }
      state.deploymentResult = { processing: false, data: true };
    },
    deployFailed(state, action: PayloadAction<IError>) {
      state.deploymentResult = {
        processing: false,
        error: action.payload,
      };
    },
    retracting(state) {
      state.retractionResult = { processing: true };
    },
    retracted(state, action: PayloadAction<IDeployment>) {
      state.statusResult.data = action.payload;
      if (Array.isArray(state.deploymentsResult.data)) {
        const index = state.deploymentsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.deploymentsResult.data[index] = action.payload;
        } else {
          state.deploymentsResult.data.push(action.payload);
        }
      }
      state.retractionResult = { processing: false, data: true };
    },
    retractFailed(state, action: PayloadAction<IError>) {
      state.retractionResult = {
        processing: false,
        error: action.payload,
      };
    },
    upgrading(state) {
      state.upgradeResult = { processing: true };
    },
    upgraded(state, action: PayloadAction<IDeployment>) {
      state.statusResult.data = action.payload;
      if (Array.isArray(state.deploymentsResult.data)) {
        const index = state.deploymentsResult.data.findIndex(
          (d) => d.id === action.payload.id
        );
        if (index !== -1) {
          state.deploymentsResult.data[index] = action.payload;
        } else {
          state.deploymentsResult.data.push(action.payload);
        }
      }
      state.upgradeResult = { processing: false, data: true };
    },
    upgradeFailed(state, action: PayloadAction<IError>) {
      state.upgradeResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchDeploymentStatus = (
  interfaceId: string,
  siteUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new SpoDeploymentEndpoint(interfaceId);
  dispatch(fetchingStatus());
  try {
    const response = await endpoint.getDeploymentStatus(siteUrl);
    dispatch(fetchedStatus(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchStatusFailed(Errors.getError(error)));
  }
};

export const fetchDeployments = (interfaceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new SpoDeploymentEndpoint(interfaceId);
  dispatch(fetchingDeployments());
  try {
    const response = await endpoint.getDeployments();
    dispatch(fetchedDeployments(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchDeploymentsFailed(Errors.getError(error)));
  }
};

export const deleteSiteDeployment = (
  interfaceId: string,
  deploymentId: string
): AppThunk => async (dispatch) => {
  const endpoint = new SpoDeploymentEndpoint(interfaceId);
  dispatch(deletingSiteDeployment());
  try {
    await endpoint.deleteSiteDeployment(deploymentId);
    dispatch(deletedSiteDeployment(deploymentId));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteSiteDeploymentFailed(Errors.getError(error)));
  }
};

export const deployToSite = (
  interfaceId: string,
  spHostUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new SpoDeploymentEndpoint(interfaceId);
  dispatch(deploying());
  try {
    const response = await endpoint.deployToSite(spHostUrl);
    dispatch(deployed(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deployFailed(Errors.getError(error)));
    dispatch(newErrorMessage(error.response.data.message));
  }
  dispatch(fetchNotifications());
};

export const retractFromSite = (
  interfaceId: string,
  spHostUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new SpoDeploymentEndpoint(interfaceId);
  dispatch(retracting());
  try {
    const response = await endpoint.retractFromSite(spHostUrl);
    dispatch(retracted(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(retractFailed(Errors.getError(error)));
  }
  dispatch(fetchNotifications());
};

export const upgradeSite = (
  interfaceId: string,
  spHostUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new SpoDeploymentEndpoint(interfaceId);
  dispatch(upgrading());
  try {
    const response = await endpoint.upgradeSite(spHostUrl);
    dispatch(upgraded(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(upgradeFailed(Errors.getError(error)));
  }
  dispatch(fetchNotifications());
};

const { actions, reducer } = deploymentSlice;
export const {
  fetchStatusFailed,
  fetchedStatus,
  fetchingStatus,
  fetchDeploymentsFailed,
  fetchedDeployments,
  fetchingDeployments,
  deleteSiteDeploymentFailed,
  deletedSiteDeployment,
  deletingSiteDeployment,
  deployFailed,
  deployed,
  deploying,
  retractFailed,
  retracted,
  retracting,
  upgradeFailed,
  upgraded,
  upgrading,
} = actions;
export default reducer;
