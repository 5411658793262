import { RegisterEndpoint } from 'endpoints/registerEndpoint';

const formValidators = {
  required: (value: any) => {
    return value === null ||
      value === undefined ||
      value === '' ||
      value === '00000000-0000-0000-0000-000000000000'
      ? 'Required'
      : undefined;
  },
  minLength: (length: number) => (value: string) => {
    return value && value.length < length
      ? `Minimum ${length} characters.`
      : undefined;
  },
  atLeastOneChecked: (value: string[]) => {
    return value === undefined || value.length === 0
      ? 'Select at least one'
      : undefined;
  },
  passwordsMatch: (value: string, allValues: { password: string }) => {
    return value !== allValues.password ? "Passwords don't match" : undefined;
  },
  maxLength: (length: number) => (value: string) => {
    return value && value.length > length
      ? `Maximum ${length} characters.`
      : undefined;
  },
  regex: (regex: RegExp, errorMessage: string) => (value: string) => {
    return !value.match(regex) ? errorMessage : undefined;
  },
  requireDigit: (value: string) => {
    return !value.match(new RegExp('\\d'))
      ? 'Must contain at least one digit'
      : undefined;
  },
  requireLowerCaseCharacter: (value: string) => {
    return !value.match(new RegExp('[a-z]'))
      ? 'Must contain at least one lower case character'
      : undefined;
  },
  requireUpperCaseCharacter: (value: string) => {
    return !value.match(new RegExp('[A-Z]'))
      ? 'Must contain at least one upper case character'
      : undefined;
  },
  requireSpecialCharacter: (value: string) => {
    return !value.match(new RegExp('[^A-Za-z0-9]'))
      ? 'Must contain at least one special character'
      : undefined;
  },
  requireThreeTypesOfCharacters: (value: string) => {
    let typesOfCharacters = 0;
    if (!formValidators.requireLowerCaseCharacter(value)) typesOfCharacters++;
    if (!formValidators.requireUpperCaseCharacter(value)) typesOfCharacters++;
    if (!formValidators.requireDigit(value)) typesOfCharacters++;
    if (!formValidators.requireSpecialCharacter(value)) typesOfCharacters++;

    return typesOfCharacters < 3
      ? 'Must contain at least 3 types of characters: lowercase letter, uppercase letter, digit, special character'
      : undefined;
  },
  largerThanZeroOrEmpty: (value: number) => {
    return value != null && value < 0
      ? 'The input should either be empty or a non-negative number, where 0 or empty input indicates no limit.'
      : undefined;
  },
  wildcardPatternValid: (value: string) => {
    const maxLength = 100;
    const maxWildcards = 10;
    if (/\s{2,}/.test(value)) {
      return 'Consecutive whitespaces are not allowed.';
    }
    if (value) {
      const splitValues = value.split(' ');
      for (const pattern of splitValues) {
        const displayPattern =
          pattern.substring(0, 50) + (pattern.length > 50 ? '...' : '');
        if (pattern.length > maxLength) {
          return (
            'One of the patterns is over the limit of 100 allowed characters: ' +
            displayPattern
          );
        }
        const wildcardCount = (pattern.match(/[*?]/g) || []).length;
        if (wildcardCount > maxWildcards) {
          return (
            'One of the patterns has more wildcard chatacters than the allowed limit (10): ' +
            displayPattern
          );
        }
        if (/(\*\*)|(\*\?)|(\?\*)/.test(value)) {
          return 'Consecutive star (*) or mixed (*?) wildcards are not allowed.';
        }
        if (/[/\\]/.test(value)) {
          return 'The use of / or \\ characters is not allowed.';
        }
      }
    }
    return undefined;
  },
};

const asyncValidators = {
  emailValid: async (values: { email: string }) => {
    const registerEndpoint = new RegisterEndpoint();
    const response = await registerEndpoint.validateEmail(values.email);
    if (response.data) {
      return Promise.reject({ email: response.data });
    }
  },
  domainValid: async (values: { name: string }) => {
    const registerEndpoint = new RegisterEndpoint();
    const response = await registerEndpoint.validateDomain(values.name);
    if (response.data) {
      return Promise.reject({ name: response.data });
    }
  },
};

export { formValidators, asyncValidators };
