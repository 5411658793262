import * as React from 'react';

import { Field, formValueSelector } from 'redux-form';

import { IReduxState } from 'reducers';
import { ITermStoreData } from 'types';
import TermStoreGroupSelector from './termStoreGroupSelector';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IParams {
  formName: string;
}

interface IOwnProps {
  data: ITermStoreData[];
  params: IParams;
}

interface IStateProps {
  termstoreId: string;
}

interface IProps extends IOwnProps, IStateProps {}

export class TermStoreSelector extends React.Component<IProps> {
  public render() {
    const { data, termstoreId } = this.props;
    const options = data.map((termstore, index) => (
      <option value={termstore.termStoreId} key={index}>
        {termstore.name}
      </option>
    ));
    const groups = data.find((t) => t.termStoreId === termstoreId)?.groups;
    return (
      <>
        <Field
          name="termStoreId"
          component={selectComponent}
          label="Term Store"
          validate={formValidators.required}
          defaltValue={Array.isArray(data) ? data[0] : undefined}
        >
          <option value="">Select term store</option>
          {options}
        </Field>
        {groups ? <TermStoreGroupSelector groups={groups} /> : null}
      </>
    );
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => {
  const selector = formValueSelector(ownProps.params.formName);
  return {
    termstoreId: selector(state, 'termStoreId'),
  };
};

export default connect<IStateProps, {}, IOwnProps, IReduxState>(
  mapStateToProps
)(TermStoreSelector);
