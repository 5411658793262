import * as React from 'react';

import DeploymentDetails from './deploymentDetails';
import DeploymentOptions from './deploymentOptions';
import DeploymentOptionsDummy from './deploymentOptionsDummy';
import { IDeployment } from 'types';

export interface IParams {
  interfaceId: string;
  spHostUrl?: string;
}

interface IOwnProps {
  data: IDeployment;
  params: IParams;
}

interface IProps extends IOwnProps {}

export class Deployment extends React.Component<IProps> {
  public render() {
    const { params, data } = this.props;
    const { spHostUrl, interfaceId } = params;
    return (
      <>
        <DeploymentDetails deployment={data} />
        {spHostUrl ? (
          <DeploymentOptions
            spHostUrl={spHostUrl}
            interfaceId={interfaceId}
            deployment={data}
          />
        ) : (
          <DeploymentOptionsDummy />
        )}
      </>
    );
  }
}

export default Deployment;
