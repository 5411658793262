import { ServerType } from '../types';

export default class NameResolver {
  public getServerTypeName(type: ServerType): string {
    switch (type) {
      case ServerType.AzureVirtualMachineService:
        return 'Azure Virtual Machine Service';
      case ServerType.UnmanagedHostService:
        return 'Unmanaged Host Service';
      case ServerType.OntologyEditor:
        return 'Knowledge Model Management';
      case ServerType.ClassificationService:
        return 'Classification Language Service';
      case ServerType.SemanticEnhancementService:
        return 'Semantic Integration Service';
      case ServerType.TripleStore:
        return 'Triple Store';
      case ServerType.Workbench:
        return 'Workbench';
      case ServerType.StudioService:
        return 'Studio Service';
    }
  }
}
