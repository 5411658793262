import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';
import {
  IActionResult,
  IDeployment,
  ISequentialListClassification,
  ModalContents,
} from 'types';
import { closeModal, openModal } from 'slices/modalSlice';
import { getDuration, localLongDate } from 'utils/dateUtils';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import SmallListClassifications from './smallListClassifications';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deploymentStatus } from './statusIndicators';

interface IOwnProps {
  deployment: IDeployment;
}

interface IStateProps {
  listClassificationsForSiteResult: IActionResult<
    ISequentialListClassification[]
  >;
}

interface IDispatchProps {
  onClose: () => void;
  openLogModal: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeploymentDetailsModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  public render() {
    const {
      deployment,
      openLogModal,
      listClassificationsForSiteResult,
    } = this.props;

    return (
      <>
        <Modal.Body>
          <dl>
            <dt>Site Url</dt>
            <dd className="text-break">
              <Button variant="link" className="p-0">
                {deployment.siteUrl}
              </Button>
            </dd>
          </dl>
          <dl>
            <dt>Site Id</dt>
            <dd>{deployment.siteId}</dd>
          </dl>
          <dl>
            <dt>Web Id</dt>
            <dd>{deployment.webId}</dd>
          </dl>
          <dl>
            <dt>Queue Time</dt>
            <dd>{localLongDate(deployment.queueDate)}</dd>
          </dl>
          {deployment.startDate ? (
            <dl>
              <dt>Start Time</dt>
              <dd>{localLongDate(deployment.startDate)}</dd>
            </dl>
          ) : null}
          {deployment.finishDate ? (
            <dl>
              <dt>End Time (Duration)</dt>
              <dd>
                {localLongDate(deployment.finishDate) +
                  (deployment.startDate
                    ? ` (${getDuration(
                        deployment.finishDate,
                        deployment.startDate
                      )})`
                    : '')}
              </dd>
            </dl>
          ) : null}
          <dl>
            <dt>Status</dt>
            <dd>{deploymentStatus[deployment.status]}</dd>
          </dl>
          <dl>
            <dt>Correlation Id</dt>
            <dd>{deployment.correlationId}</dd>
          </dl>
          <dl>
            <dt>Full Log</dt>
            <dd>
              <Button size="sm" variant="info" onClick={openLogModal}>
                View full log
              </Button>
            </dd>
          </dl>
          <Loader
            component={SmallListClassifications}
            actionresult={listClassificationsForSiteResult}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.cancel}>
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }

  private cancel() {
    const { onClose } = this.props;
    onClose();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  listClassificationsForSiteResult:
    state.classifyList.listClassificationsForSiteResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  onClose: () => dispatch(closeModal()),
  openLogModal: () =>
    dispatch(
      openModal(
        'Deployment Logs',
        ModalContents.CorrelatedLogs,
        {
          position: 'right',
          size: 'xl',
        },
        ownProps.deployment.correlationId
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentDetailsModal);
