import * as React from 'react';

import { IActionResult, IServiceGroup, IServiceViewModel } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { Row } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchAllGroups } from 'slices/serviceGroupSlice';
import serviceGroups from './serviceGroups';
import serviceTypes from './serviceTypes';
import services from './services';

interface IStateProps {
  fetchAllGroupsResult: IActionResult<IServiceGroup[]>;
  fetchAllServicesResult: IActionResult<IServiceViewModel[]>;
  groupSelected: boolean;
  serviceTypeSelected: boolean;
}
interface IDispatchProps {
  fetchAllGroups: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class ServicesPage extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchAllGroups } = this.props;
    fetchAllGroups();
  }

  public render() {
    const {
      fetchAllGroupsResult,
      fetchAllServicesResult,
      groupSelected,
      serviceTypeSelected,
    } = this.props;
    return (
      <Row className="flex-grow-1">
        <Loader component={serviceGroups} actionresult={fetchAllGroupsResult} />
        {groupSelected ? (
          <Loader
            component={serviceTypes}
            actionresult={fetchAllServicesResult}
          />
        ) : null}
        {groupSelected && serviceTypeSelected ? (
          <Loader component={services} actionresult={fetchAllServicesResult} />
        ) : null}
      </Row>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchAllGroupsResult: state.serviceGroup.fetchAllResult,
  fetchAllServicesResult: state.service.fetchAllInAGroupResult,
  groupSelected: state.serviceGroup.selected != null,
  serviceTypeSelected: state.serviceType.selected != null,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchAllGroups: () => dispatch(fetchAllGroups()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ServicesPage);
