import * as React from 'react';

import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

import { IConfiguredProvider } from 'types';

interface IProps {
  configuredProviders: IConfiguredProvider[];
}

class ExternalLogins extends React.Component<IProps> {
  render() {
    const { configuredProviders } = this.props;
    if (configuredProviders.length === 0) return null;
    const externalProviders = configuredProviders.map((p, i) => (
      <FormControl as={Button} variant="info" key={i}>
        {p.displayName}
      </FormControl>
    ));

    return (
      <FormGroup>
        <FormLabel>External Logins</FormLabel>
        {externalProviders}
      </FormGroup>
    );
  }
}

export default ExternalLogins;
