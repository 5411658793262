import * as React from 'react';

import { Col, ListGroup } from 'react-bootstrap';
import { DeploymentStatus, IDeployment } from 'types';
import {
  deployToSite,
  fetchDeploymentStatus,
  retractFromSite,
  upgradeSite,
} from 'slices/deploymentSlice';

import { AnyAction } from '@reduxjs/toolkit';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import ReplyIcon from 'mdi-react/ReplyIcon';
import RocketIcon from 'mdi-react/RocketIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface IOwnProps {
  deployment?: IDeployment;
  interfaceId: string;
  spHostUrl: string;
}

interface IStateProps {
  canDeploy: boolean;
}

interface IDispatchProps {
  deploy: () => void;
  retract: () => void;
  upgrade: () => void;
  fetchDeploymentStatus: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeploymentOptions extends React.Component<IProps> {
  private timerHandler?: number;

  constructor(props: IProps) {
    super(props);
    this.deploy = this.deploy.bind(this);
    this.retract = this.retract.bind(this);
    this.upgrade = this.upgrade.bind(this);
  }

  public componentDidMount() {
    const { deployment } = this.props;
    if (
      deployment != null &&
      (deployment.status === DeploymentStatus.Pending ||
        deployment.status === DeploymentStatus.InProgress)
    ) {
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    }
  }

  public componentDidUpdate() {
    const { deployment } = this.props;
    if (
      deployment != null &&
      (deployment.status === DeploymentStatus.Pending ||
        deployment.status === DeploymentStatus.InProgress)
    ) {
      this.timerHandler = window.setTimeout(() => {
        this.checkStatus();
      }, 3000);
    } else {
      if (this.timerHandler) {
        clearTimeout(this.timerHandler);
      }
    }
  }

  public componentWillUnmount() {
    if (this.timerHandler) {
      clearTimeout(this.timerHandler);
    }
  }

  public render() {
    const { deployment, canDeploy } = this.props;
    return (
      <Col md={6} lg={4}>
        <h5 className="font-weight-bold">Deployment Options:</h5>
        <ListGroup className="shadow mb-2">
          <ListGroup.Item
            action
            onClick={this.deploy}
            disabled={
              !canDeploy ||
              (deployment != null &&
                (deployment.status === DeploymentStatus.Completed ||
                  deployment.status === DeploymentStatus.InProgress ||
                  deployment.status === DeploymentStatus.Pending))
            }
          >
            <RocketIcon size="1rem" className="mr-3" />
            Deploy
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={this.upgrade}
            disabled={
              !canDeploy ||
              deployment == null ||
              deployment.status !== DeploymentStatus.Completed
            }
          >
            <ArrowUpIcon size="1rem" className="mr-3" />
            Upgrade
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={this.retract}
            disabled={
              !canDeploy ||
              deployment == null ||
              deployment.status !== DeploymentStatus.Completed
            }
          >
            <ReplyIcon size="1rem" className="mr-3" />
            Retract
          </ListGroup.Item>
        </ListGroup>
      </Col>
    );
  }

  private deploy() {
    const { deploy } = this.props;
    deploy();
  }

  private retract() {
    const { retract } = this.props;
    retract();
  }

  private upgrade() {
    const { upgrade } = this.props;
    upgrade();
  }

  private checkStatus() {
    const { fetchDeploymentStatus } = this.props;
    fetchDeploymentStatus();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canDeploy: authorizer.canDeploy(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deploy: () =>
    dispatch(deployToSite(ownProps.interfaceId, ownProps.spHostUrl)),
  retract: () =>
    dispatch(retractFromSite(ownProps.interfaceId, ownProps.spHostUrl)),
  upgrade: () =>
    dispatch(upgradeSite(ownProps.interfaceId, ownProps.spHostUrl)),
  fetchDeploymentStatus: () =>
    dispatch(fetchDeploymentStatus(ownProps.interfaceId, ownProps.spHostUrl)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeploymentOptions);
