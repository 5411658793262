import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IInterfaceConfigured } from 'types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class BapiInterfaceEndpoint {
  public readonly ROOT = 'basicapi';

  public getConfiguredList(): Promise<AxiosResponse<IInterfaceConfigured[]>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.get(url);
  }

  public update(item: IInterfaceConfigured): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.put(url, item);
  }
  public create(item: IInterfaceConfigured): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.post(url, item);
  }
  public delete(id: string): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('id', id)];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.delete(url);
  }
  public getInterface(
    id: string
  ): Promise<AxiosResponse<IInterfaceConfigured>> {
    const url = Endpoint.getUrl([this.ROOT, id]);
    return api.get(url);
  }
}
