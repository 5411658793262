import * as React from 'react';

import { Col, Table } from 'react-bootstrap';
import { getDuration, localLongDate } from 'utils/dateUtils';

import { IDeployment } from 'types';
import { deploymentStatus } from './statusIndicators';

interface IProps {
  deployment?: IDeployment;
}

class DeploymentDetails extends React.Component<IProps> {
  public render() {
    const { deployment } = this.props;
    const content = deployment ? (
      <tbody>
        <tr>
          <th className="w-50">Installed version</th>
          <td>{deployment.codeVersion}</td>
        </tr>
        <tr>
          <th className="w-50">Latest available version</th>
          <td>1.2.1.0</td>
        </tr>
        <tr>
          <th className="w-50">Last deployed</th>
          <td>
            {deployment.finishDate
              ? localLongDate(deployment.finishDate)
              : 'never'}
          </td>
        </tr>
        <tr>
          <th className="w-50">Deployment duration</th>
          <td>{getDuration(deployment.finishDate, deployment.startDate)}</td>
        </tr>
        <tr>
          <th className="w-50">Deployment status</th>
          <td>{deploymentStatus[deployment.status]}</td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        <tr>
          <th className="w-50">Deployment status</th>
          <td>Not deployed</td>
        </tr>
      </tbody>
    );
    return (
      <Col md={6} lg={8}>
        <h5 className="font-weight-bold">Deployment Details:</h5>
        <Table hover size="sm">
          {content}
        </Table>
      </Col>
    );
  }
}

export default DeploymentDetails;
