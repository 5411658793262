import * as React from 'react';

import { ISyncRun } from 'types';
import SyncJobRun from './syncJobRun';
import { Table } from 'react-bootstrap';

interface IParams {
  interfaceId: string;
  syncJobId: string;
}

interface IProps {
  data: ISyncRun[];
  params: IParams;
}

export class SyncJobRuns extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { interfaceId, syncJobId } = params;
    const syncRuns = data.map((syncRun, index) => (
      <SyncJobRun
        interfaceId={interfaceId}
        syncJobId={syncJobId}
        data={syncRun}
        key={index}
      />
    ));
    if (!data.length) return null;
    return (
      <Table size="sm" hover>
        <thead>
          <tr>
            <th>Start Time</th>
            <th>End Time (Duration)</th>
            <th>Status</th>
            <th>Job Details</th>
          </tr>
        </thead>
        <tbody>{syncRuns}</tbody>
      </Table>
    );
  }
}

export default SyncJobRuns;
