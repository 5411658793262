import { Form } from 'react-bootstrap';
import React from 'react';

export interface IParams {
  spHostUrl?: string;
  onSubscriptionChanged: (subscriptionUrl: string) => void;
}

interface IOwnProps {
  data: string[];
  params: IParams;
}

interface IProps extends IOwnProps {}

export class SpoSubscriptionSelector extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleSubscriptionChanged = this.handleSubscriptionChanged.bind(this);
  }

  public componentDidMount() {
    const { data, params } = this.props;
    const { spHostUrl, onSubscriptionChanged } = params;
    const defaultOption = spHostUrl
      ? data.find((subscriptionUrl) => spHostUrl.startsWith(subscriptionUrl))
      : undefined ?? (data.length ? data[0] : undefined);
    onSubscriptionChanged(defaultOption!);
  }

  public render() {
    const { data, params } = this.props;
    const { spHostUrl } = params;
    const options = data.map((url, index) => (
      <option value={url} key={index}>
        {url}
      </option>
    ));
    const defaultOption = spHostUrl
      ? data.find((subscriptionUrl) => spHostUrl.startsWith(subscriptionUrl))
      : undefined ?? (data.length ? data[0] : undefined);
    if (!data.length) return null;
    return (
      <Form.Group>
        <Form.Label>SharePoint Subscription</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          onChange={this.handleSubscriptionChanged}
          defaultValue={defaultOption}
        >
          {options}
        </Form.Control>
      </Form.Group>
    );
  }

  private handleSubscriptionChanged(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const { params } = this.props;
    const { onSubscriptionChanged } = params;
    const subscriptionUrl = event.currentTarget.value;
    onSubscriptionChanged(subscriptionUrl);
  }
}

export default SpoSubscriptionSelector;
