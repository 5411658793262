import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IDeployment } from 'types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

interface ICreate {
  name: string;
  namespace: string;
  description: string;
}
export default class SpoDeploymentEndpoint {
  public ROOT: string;

  constructor(interfaceId: string) {
    this.ROOT = interfaceId;
  }

  public create(data: ICreate): Promise<AxiosResponse<any>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.post(url, data);
  }

  public getDeployments(): Promise<AxiosResponse<IDeployment[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'deployment']);
    return api.get(url);
  }

  public getDeploymentStatus(
    siteUrl: string
  ): Promise<AxiosResponse<IDeployment>> {
    const queryItems = [new QueryItem('siteUrl', siteUrl)];
    const url = Endpoint.getUrl(
      [this.ROOT, 'siteDeploymentStatus'],
      queryItems
    );
    return api.get(url);
  }

  public deployToSite(site: string): Promise<AxiosResponse<IDeployment>> {
    const queryItems = [new QueryItem('spHostUrl', site)];
    const url = Endpoint.getUrl([this.ROOT, 'deploy'], queryItems);
    return api.put(url, {});
  }

  public deleteSiteDeployment(
    deploymentId: string
  ): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('siteDeploymentId', deploymentId)];
    const url = Endpoint.getUrl([this.ROOT, 'deployment'], queryItems);
    return api.delete(url);
  }

  public retractFromSite(site: string): Promise<AxiosResponse<IDeployment>> {
    const queryItems = [new QueryItem('spHostUrl', site)];
    const url = Endpoint.getUrl([this.ROOT, 'retract'], queryItems);
    return api.put(url, {});
  }

  public upgradeSite(site: string): Promise<AxiosResponse<IDeployment>> {
    const queryItems = [new QueryItem('spHostUrl', site)];
    const url = Endpoint.getUrl([this.ROOT, 'upgrade'], queryItems);
    return api.put(url, {});
  }
}
