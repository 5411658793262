import * as React from 'react';

import { IActionResult, IUsageViewModel } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import UsageDataChart from './usageDataChart';
import { connect } from 'react-redux';
import { fetchUsageData } from 'slices/usageDataSlice';

interface IOwnProps {
  interfaceId: string;
}

interface IStateProps {
  interfaceUsageResult: IActionResult<IUsageViewModel>;
}

interface IDispatchProps {
  fetchUsageData: () => void;
}

interface IProps extends IOwnProps, IDispatchProps, IStateProps {}

export class UsageData extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchUsageData } = this.props;
    fetchUsageData();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { fetchUsageData, interfaceId } = this.props;
    if (prevProps.interfaceId !== interfaceId) {
      fetchUsageData();
    }
  }

  public render() {
    const { interfaceUsageResult } = this.props;
    return (
      <Loader component={UsageDataChart} actionresult={interfaceUsageResult} />
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  interfaceUsageResult: state.usageData.interfaceUsageResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchUsageData: () => dispatch(fetchUsageData(ownProps.interfaceId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(UsageData);
