import { Errors, IError } from 'utils/errors';
import { IActionResult, ISiteLibrary } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import SiteLibraryEndpoint from 'endpoints/siteLibraryEndpoint';

interface ISiteLibraryState {
  fetchResult: IActionResult<ISiteLibrary>;
}

const siteLibrarySlice = createSlice({
  name: 'siteLibrary',
  initialState: {
    fetchResult: { processing: false },
  } as ISiteLibraryState,
  reducers: {
    fetching(state) {
      state.fetchResult = { processing: true };
    },
    fetched(state, action: PayloadAction<ISiteLibrary>) {
      state.fetchResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.fetchResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchSiteLibrary = (
  interfaceId: string,
  listId: string,
  spHostUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new SiteLibraryEndpoint();
  dispatch(fetching());
  try {
    const response = await endpoint.getSiteLibrary(
      interfaceId,
      listId,
      spHostUrl
    );
    dispatch(fetched(response.data));
  } catch (error) {
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = siteLibrarySlice;
export const { fetchFailed, fetched, fetching } = actions;
export default reducer;
