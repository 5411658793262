import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceViewModel } from 'types';

import { AnyAction } from 'redux';
import Dependencies from './dependencies';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';
import { updateService } from 'slices/serviceSlice';

interface IOwnProps {
  service: IServiceViewModel;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
}

interface IDispatchProps {
  updateService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  url: string;
  publishingEnabled: boolean;
}

export class EditStudioModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, service, pristine, updateResult } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="url"
            component={inputComponent}
            type="text"
            placeholder="Please enter a url"
            label="Url"
            disabled={true}
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Dependencies
            dependentUpon={service.dependentUpon!}
            dependsUpon={service.dependsUpon}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { service, updateService } = this.props;

    const updatedService = Object.assign({}, service, {
      name: data.name,
      description: data.description,
    });
    updateService(updatedService);
  }
}

const EditStudioModalForm = reduxForm<IFormData, IProps>({
  form: 'editStudio',
})(EditStudioModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      name: ownProps.service.name,
      description: ownProps.service.description,
      url: ownProps.service.url,
    },
    form: 'editStudio',
    updateResult: state.service.updateResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateService: (service: IServiceViewModel) =>
    dispatch(updateService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditStudioModalForm);
