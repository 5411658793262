import * as React from 'react';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import MdIcon from 'components/common/icons/mdIcon';
import { iconComponentType } from 'types';

export interface IProps {
  key: number;
  iconComponent?: iconComponentType;
  text: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
  title?: string;
  href?: string;
  onClick?: () => void;
  to?: string;
  target?: '_blank';
}

class ShortcutTile extends React.Component<IProps, any> {
  public render() {
    const {
      key,
      to,
      onClick,
      href,
      target,
      text,
      title,
      variant,
      iconComponent,
    } = this.props;

    const buttonChildren = (
      <span className="d-flex justify-content-center align-items-center h-100">
        <span className="w-100">
          <MdIcon iconComponent={iconComponent} size="4rem" />
          <br />
          <small className="truncate mt-2 px-2 d-block">{text}</small>
        </span>
      </span>
    );

    if (to) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${key}`}>{title}</Tooltip>}
        >
          <Button
            as={Link}
            to={to}
            variant={variant}
            className="mr-3 mb-3 text-white btn-icon-lg shadow"
          >
            {buttonChildren}
          </Button>
        </OverlayTrigger>
      );
    }

    if (href) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${key}`}>{title}</Tooltip>}
        >
          <Button
            as="a"
            href={href}
            variant={variant}
            className="mr-3 mb-3 text-white btn-icon-lg shadow"
            target={target}
          >
            {buttonChildren}
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${key}`}>{title}</Tooltip>}
      >
        <Button
          onClick={onClick}
          variant={variant}
          className="mr-3 mb-3 text-white btn-icon-lg shadow"
        >
          {buttonChildren}
        </Button>
      </OverlayTrigger>
    );
  }
}

export default ShortcutTile;
