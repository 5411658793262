import * as React from 'react';

import { Button, Media } from 'react-bootstrap';
import { ITenantContactDetail, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import CloseIcon from 'mdi-react/CloseIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import { IReduxState } from 'reducers';
import { ListGroup } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteContact } from 'slices/tenantSettingsSlice';
import { openModal } from 'slices/modalSlice';

interface IDispatchProps {
  deleteContact: () => void;
  openEditModal: () => void;
}

interface IOwnProps {
  contact: ITenantContactDetail;
}

interface IStateProps {
  canManageContacts: boolean;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class Contact extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
  }

  public render() {
    const { contact, canManageContacts } = this.props;
    return (
      <Media as={ListGroup.Item}>
        <Media.Body>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              variant="link"
              className="p-0"
              title="Edit contact"
              onClick={this.edit}
              disabled={!canManageContacts}
            >
              <h5>{contact.name}</h5>
            </Button>
            <Button
              variant="link"
              className="p-0 text-danger"
              title="Delete"
              onClick={this.delete}
              disabled={!canManageContacts}
            >
              <CloseIcon size="1.2rem" />
            </Button>
          </div>
          <p>{contact.type}</p>
          <p className="mb-1">
            <Button
              block
              variant="outline-dark"
              className="d-flex align-items-center justify-content-center"
              href={`mailto:${contact.email}`}
              title={`Email: ${contact.email}`}
            >
              <EmailOutlineIcon size="1.2rem" className="mr-1" />
              Email
            </Button>
          </p>
        </Media.Body>
      </Media>
    );
  }

  private delete() {
    const { deleteContact } = this.props;
    deleteContact();
  }

  private edit() {
    const { openEditModal } = this.props;
    openEditModal();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canManageContacts: authorizer.canManageContacts(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteContact: () => dispatch(deleteContact(ownProps.contact.id!)),
  openEditModal: () =>
    dispatch(
      openModal(
        'Edit contact',
        ModalContents.EditContact,
        {
          position: 'right',
        },
        ownProps.contact
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
