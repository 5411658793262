import * as React from 'react';

import DatePicker from 'react-datepicker';
import InputComponent from './inputComponent';

interface IOwnProps {
  input: {
    value: string;
    onChange: (value: string) => void;
    name: string;
  };
  id: any;
  label: string;
  tooltip: string;
  placeholder: string;
  disabled: boolean;
  meta: { touched: boolean; error: string; warning: string };
}

class DatePickerComponent extends React.Component<IOwnProps> {
  constructor(props: IOwnProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  public handleChange(date: Date) {
    this.props.input.onChange(date ? date.toISOString() : '');
  }

  public render() {
    const { input, placeholder, disabled } = this.props;
    return (
      <DatePicker
        dateFormat="MM/d/yyyy h:mm aa"
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        showTimeSelect={true}
        selected={!input.value ? null : new Date(input.value)}
        onChange={this.handleChange}
        placeholderText={placeholder}
        customInput={<InputComponent type="text" {...this.props} />}
        disabled={disabled}
      />
    );
  }
}

export default DatePickerComponent;
