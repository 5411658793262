import * as React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { IActionResult, ModalContents } from 'types';

import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import { AnyAction } from 'redux';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import Error from 'components/common/error';
import { IError } from 'utils/errors';
import { IReduxState } from 'reducers';
import { Link } from 'react-router-dom';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import externalLoginProviders from './externalLoginProviders';
import ProgressSemaphoreLogo from 'components/common/progressSemaphoreLogo';
import { fetchLoginProviders } from 'slices/accountSlice';
import { openModal } from 'slices/modalSlice';

interface IDispatchProps {
  openModal: () => void;
  fetchLoginProviders: () => void;
}

interface IOwnProps {
  error?: IError;
}

interface IStateProps {
  fetchLoginProvidersResult: IActionResult<string[]>;
}

interface IProps extends IDispatchProps, IOwnProps, IStateProps {}

export class LoginPage extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchLoginProviders } = this.props;
    fetchLoginProviders();
  }

  public render() {
    const { openModal, error, fetchLoginProvidersResult } = this.props;
    const errorMessage = error ? <Error error={error} /> : null;
    return (
      <Row className="flex-grow-1 justify-content-md-center bg-light align-items-center">
        <Col md={6} lg={4}>
          <ProgressSemaphoreLogo />
          {errorMessage}
          <Card className="mb-4 border-0 shadow">
            <Card.Body className="p-5">
              <h2 className="mb-4">Sign in with</h2>
              <div className="mb-3">
                <Loader
                  component={externalLoginProviders}
                  actionresult={fetchLoginProvidersResult}
                />
                <Button
                  variant="dark"
                  size="lg"
                  className="btn-block font-normal"
                  onClick={openModal}
                >
                  <EmailOutlineIcon className="mr-1" />
                  Email Address
                </Button>
              </div>
              <p className="text-center">Or</p>
              <Button
                as={Link}
                to="/register/"
                variant="light"
                size="lg"
                className="btn-block font-normal"
              >
                <AccountPlusIcon className="mr-1" />
                Register
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchLoginProvidersResult: state.account.fetchLoginProvidersResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openModal: () =>
    dispatch(
      openModal('Sign in', ModalContents.Login, {
        position: 'right',
        size: 'sm',
      })
    ),
  fetchLoginProviders: () => dispatch(fetchLoginProviders()),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
