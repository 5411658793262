import * as React from 'react';

import BapiInterface from './bapiInterface';
import { IInterfaceConfigured } from 'types';

interface IParams {
  interfaceId: string;
}

interface IOwnProps {
  data: IInterfaceConfigured[];
  params: IParams;
}

interface IProps extends IOwnProps {}

export class BapiInterfaceSubPage extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { interfaceId } = params;
    const interface_ = data.find((i) => i.id === interfaceId);
    return interface_ ? <BapiInterface data={interface_} /> : null;
  }
}

export default BapiInterfaceSubPage;
