import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/object';
import './styles.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import Authenticator from './components/pages/authentication/authenticator';
import { BrowserRouter } from 'react-router-dom';
import Messages from 'components/common/messages/messages';
import ModalContentsSelector from 'components/common/modalContentsSelector';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';
import { redirectToLogin } from 'utils/unauthorized';
import store from './store';

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/spa">
      <Authenticator />
      <ModalContentsSelector />
    </BrowserRouter>
    <Messages />
  </Provider>,
  document.getElementById('root') as HTMLElement
);

window.addEventListener(
  'message',
  (event) => {
    if (event.data === 'relogin') {
      redirectToLogin();
    }
  },
  false
);

serviceWorker.unregister();
