import { Container } from 'react-bootstrap';

function PageFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="mt-auto py-3 footer row">
      <Container fluid className="text-right">
        <span>
          © {currentYear} Progress Software Corporation and/or its subsidiaries
          or affiliates. All Rights Reserved.
        </span>
      </Container>
    </footer>
  );
}

export default PageFooter;
