import * as React from 'react';

import Contact from './contact';
import { ITenantContactDetail } from 'types';
import { ListGroup } from 'react-bootstrap';

interface IProps {
  data: ITenantContactDetail[];
}

class Contacts extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const contacts = data.map((contact, index) => (
      <Contact contact={contact} key={index} />
    ));
    return <ListGroup variant="flush">{contacts}</ListGroup>;
  }
}

export default Contacts;
