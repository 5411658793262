import * as React from 'react';

import { Col, Dropdown } from 'react-bootstrap';
import { IServiceGroup, ModalContents } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Card } from 'react-bootstrap';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deselect } from 'slices/serviceTypeSlice';
import { fetchAllServicesInAGroup } from 'slices/serviceSlice';
import { openModal } from 'slices/modalSlice';
import { selectServiceGroup } from 'slices/serviceGroupSlice';

interface IOwnProps {
  serviceGroup: IServiceGroup;
}

interface IStateProps {
  isSelected: boolean;
}

interface IDispatchProps {
  openEditModal: () => void;
  openDeleteModal: () => void;
  select: () => void;
  fetchAllServicesInAGroup: () => void;
  deselectServiceType: () => void;
}

interface IProps extends IDispatchProps, IOwnProps, IStateProps {}

export class ServiceGroup extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.select = this.select.bind(this);
  }

  public render() {
    const { serviceGroup, isSelected } = this.props;
    const description = serviceGroup.description ? (
      <span className="text-sm mb-1 d-block">
        <small className="text-uppercase">Description:</small>
        <br />
        <span>{serviceGroup.description}</span>
      </span>
    ) : null;
    return (
      <Col xs={12} className="mt-3">
        <Card
          className={`shadow-sm mb-2 pointer-cursor border-0 ${
            isSelected ? ' bg-primary text-white' : 'bg-light'
          }`}
          onClick={this.select}
        >
          <Card.Body>
            <span className="align-items-center d-flex justify-content-between">
              <h5 className="font-weight-bold mb-1 block">
                {serviceGroup.name}
              </h5>
              <Dropdown
                alignRight
                className="float-right"
                onToggle={this.onToggle}
              >
                <Dropdown.Toggle
                  bsPrefix="no-arrow"
                  variant="link"
                  title="Menu Options"
                  id="dropdown-service-group"
                >
                  <DotsVerticalIcon size="1.2rem" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm border-0">
                  <Dropdown.Header className="text-uppercase">
                    Menu
                  </Dropdown.Header>
                  <Dropdown.Item onClick={this.edit}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={this.delete}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
            <span className="text-sm mb-1 d-block">
              <small className="text-uppercase">Region:</small>
              <br />
              {serviceGroup.regionName}
            </span>
            {description}
          </Card.Body>
        </Card>
      </Col>
    );
  }

  private edit() {
    const { openEditModal } = this.props;
    openEditModal();
  }

  private delete() {
    const { openDeleteModal } = this.props;
    openDeleteModal();
  }

  private select(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    const {
      select,
      fetchAllServicesInAGroup,
      deselectServiceType,
    } = this.props;
    select();
    fetchAllServicesInAGroup();
    deselectServiceType();
  }

  private onToggle(
    _isOpen: boolean,
    event: React.SyntheticEvent<Dropdown, Event>,
    metadata: {
      source: 'select' | 'click' | 'rootClose' | 'keydown';
    }
  ) {
    if (metadata.source === 'click') event.stopPropagation();
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => ({
  isSelected:
    state.serviceGroup.selected != null &&
    state.serviceGroup.selected.id === ownProps.serviceGroup.id,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openEditModal: () =>
    dispatch(
      openModal(
        'Edit Service Group',
        ModalContents.EditServiceGroup,
        {
          position: 'right',
          size: 'lg',
        },
        ownProps.serviceGroup
      )
    ),
  openDeleteModal: () =>
    dispatch(
      openModal(
        'Delete Service Group',
        ModalContents.DeleteServiceGroup,
        {
          position: 'right',
          size: 'lg',
        },
        ownProps.serviceGroup
      )
    ),
  select: () => dispatch(selectServiceGroup(ownProps.serviceGroup)),
  deselectServiceType: () => dispatch(deselect()),
  fetchAllServicesInAGroup: () =>
    dispatch(fetchAllServicesInAGroup(ownProps.serviceGroup.id!)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ServiceGroup);
