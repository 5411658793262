import * as React from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { IActionResult, IHistorySearchResult, INotification } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { IReduxState } from 'reducers';
import Loader from '../loader';
import NotificationHistory from './notificationHistory';
import { ThunkDispatch } from 'redux-thunk';
import { closeModal } from 'slices/modalSlice';
import { connect } from 'react-redux';
import { localLongDate } from 'utils/dateUtils';

interface IOwnProps {
  notification: INotification;
}

interface IStateProps {
  historyResult: IActionResult<IHistorySearchResult>;
}

interface IDispatchProps {
  closeModal: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class NotificationDetailsModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.close = this.close.bind(this);
  }

  public render() {
    const { notification, historyResult } = this.props;
    return (
      <>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Created</Form.Label>
              <Form.Control
                disabled
                value={localLongDate(notification.created)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>CorrelationId</Form.Label>
              <Form.Control disabled value={notification.correlationId} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Status Message</Form.Label>
              <Form.Control
                as="textarea"
                disabled
                rows={3}
                value={notification.responseHtml}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Modified</Form.Label>
              <Form.Control
                disabled
                value={localLongDate(notification.lastModified)}
              />
            </Form.Group>
          </Form>
          <Loader
            component={NotificationHistory}
            actionresult={historyResult}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.close}>
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }

  private close() {
    const { closeModal } = this.props;
    closeModal();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  historyResult: state.notification.historyResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetailsModal);
