import * as React from 'react';

import { Alert, Button, Col, Container, Row, Table } from 'react-bootstrap';
import {
  ClassificationMode,
  IConfigurableFields,
  ModalContents,
  ModuleActivationState,
} from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchContentTypeField } from 'slices/simSettingsSlice';
import { openModal } from 'slices/modalSlice';

interface IParams {
  cTypeId: string;
  interfaceId: string;
  spHostUrl: string;
  listId?: string;
}

interface IOwnProps {
  data: IConfigurableFields;
  params: IParams;
}

interface IDispatchProps {
  openColumnSettingsModal: (fieldName: string, fieldId: string) => void;
  loadContentTypeField: (fieldId: string) => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ContentTypeSimSettings extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleColumnClick = this.handleColumnClick.bind(this);
  }
  public render() {
    const { data, params } = this.props;
    const { cTypeId, spHostUrl, listId } = params;
    const settingsAddress: string = this.getSettingsAddress(
      spHostUrl,
      cTypeId,
      listId
    );
    const fields = data.configurableFields.map((field, index) => {
      return (
        <tr key={index}>
          <td>
            <Button
              onClick={this.handleColumnClick(field.fieldId, field.displayName)}
              variant="link"
              className="p-0"
            >
              {field.displayName}
            </Button>
          </td>
          <td>
            {field.classificationMode === ClassificationMode.None
              ? 'Disabled'
              : 'Enabled'}
          </td>
          <td>
            {field.hasConfiguration
              ? field.isDirectConfiguration
                ? 'Own'
                : 'Inherited'
              : 'None'}
          </td>
        </tr>
      );
    });

    return (
      <>
        <Row className="bg-light p-3 mb-3">
          <Col>
            <h1 className="d-inline">
              Semaphore Intelligent Metadata Settings
            </h1>
            <Button
              variant="primary"
              className="float-right"
              href={settingsAddress}
            >
              Return to settings
            </Button>
          </Col>
        </Row>
        <Row>
          <Container className="p-4">
            {data.activationState === ModuleActivationState.NotActivated &&
            !data.activationExpected ? (
              <Alert variant="info">
                Semaphore Intelligent Metadata is not currently active for this
                list. It will be activated when a column is configured to use
                assisted or background mode classification.
              </Alert>
            ) : null}
            {data.activationState === ModuleActivationState.NotActivated &&
            data.activationExpected ? (
              <Alert variant="warning">
                Semaphore Intelligent Metadata is configured but is not yet
                active for this list.  After configuration please expect a short
                delay of a few minutes before this feature can be used.
              </Alert>
            ) : null}
            {data.activationState === ModuleActivationState.Activated &&
            !data.activationExpected ? (
              <Alert variant="warning">
                Semaphore Intelligent Metadata is not configured but is still
                active for this list.  After updating the configuration please
                expect a short delay of a few minutes before this feature is
                deactivated.
              </Alert>
            ) : null}
            {data.activationState === ModuleActivationState.FailedActivation ? (
              <Alert variant="danger">
                Semaphore classification module is not properly activated.
                Reason: {data.activationMessage}
              </Alert>
            ) : null}
            <Table size="sm">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Classification state</th>
                  <th>Configuration</th>
                </tr>
              </thead>
              <tbody>{fields}</tbody>
            </Table>
          </Container>
        </Row>
      </>
    );
  }

  private getSettingsAddress(
    spHostUrl: string,
    cTypeId: string,
    listId?: string
  ) {
    const listParam = listId ? `&List=${listId}` : '';
    return `${spHostUrl}/_layouts/15/ManageContentType.aspx?ctype=${cTypeId}${listParam}`;
  }

  private handleColumnClick(fieldId: string, fieldName: string) {
    const { openColumnSettingsModal, loadContentTypeField } = this.props;
    return () => {
      loadContentTypeField(fieldId);
      openColumnSettingsModal(fieldName, fieldId);
    };
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => {
  const { interfaceId, spHostUrl, cTypeId, listId } = ownProps.params;
  return {
    openColumnSettingsModal: (fieldName: string, fieldId: string) =>
      dispatch(
        openModal(
          `SIM settings for "${fieldName}" column`,
          ModalContents.ContentTypeColumnSettings,
          {
            position: 'right',
            size: 'lg',
          },
          {
            fieldId,
            cTypeId,
            interfaceId,
            spHostUrl,
            listId,
          }
        )
      ),
    loadContentTypeField: (fieldId: string) =>
      dispatch(fetchContentTypeField(fieldId, cTypeId, spHostUrl, listId)),
  };
};

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ContentTypeSimSettings);
