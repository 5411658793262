import {
  IConfigurableFields,
  IFieldConfiguration,
  IFieldConfigurationGetModel,
} from 'types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class SimEndpoint {
  public readonly ROOT = 'sim';

  public saveListSimConfig(
    siteLibraryId: string,
    configuration: IFieldConfiguration
  ): Promise<AxiosResponse<void>> {
    const url = Endpoint.getUrl([this.ROOT, 'list', siteLibraryId, 'field']);
    return api.post(url, configuration);
  }

  public deleteListFieldConfig(
    fieldId: string,
    siteLibraryId: string
  ): Promise<AxiosResponse<IFieldConfigurationGetModel>> {
    const url = Endpoint.getUrl([
      this.ROOT,
      'list',
      siteLibraryId,
      'field',
      fieldId,
    ]);
    return api.delete(url);
  }

  public saveContentTypeSimConfig(
    interfaceId: string,
    cTypeId: string,
    spHostUrl: string,
    configuration: IFieldConfiguration,
    listId?: string
  ): Promise<AxiosResponse<void>> {
    const queryItems = [
      new QueryItem('interfaceId', interfaceId),
      new QueryItem('spHostUrl', spHostUrl),
    ];
    if (listId !== undefined) {
      queryItems.push(new QueryItem('listId', listId));
    }
    const url = Endpoint.getUrl(
      [this.ROOT, 'contentType', cTypeId, 'field'],
      queryItems
    );
    return api.post(url, configuration);
  }

  public getListFields(
    siteLibraryId: string
  ): Promise<AxiosResponse<IConfigurableFields>> {
    const url = Endpoint.getUrl([this.ROOT, 'list', siteLibraryId, 'field']);
    return api.get(url);
  }

  public getContentTypeFields(
    cTypeId: string,
    spHostUrl: string,
    listId?: string
  ): Promise<AxiosResponse<IConfigurableFields>> {
    const queryItems = [new QueryItem('spHostUrl', spHostUrl)];
    if (listId !== undefined) {
      queryItems.push(new QueryItem('listId', listId));
    }
    const url = Endpoint.getUrl(
      [this.ROOT, 'contentType', cTypeId, 'field'],
      queryItems
    );
    return api.get(url);
  }

  public getListField(
    fieldId: string,
    siteLibraryId: string
  ): Promise<AxiosResponse<IFieldConfigurationGetModel>> {
    const url = Endpoint.getUrl([
      this.ROOT,
      'list',
      siteLibraryId,
      'field',
      fieldId,
    ]);
    return api.get(url);
  }

  public getContentTypeField(
    fieldId: string,
    cTypeId: string,
    spHostUrl: string,
    listId?: string
  ): Promise<AxiosResponse<IFieldConfigurationGetModel>> {
    const queryItems = [new QueryItem('spHostUrl', spHostUrl)];
    if (listId !== undefined) {
      queryItems.push(new QueryItem('listId', listId));
    }
    const url = Endpoint.getUrl(
      [this.ROOT, 'contentType', cTypeId, 'field', fieldId],
      queryItems
    );
    return api.get(url);
  }
}
