import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import {
  IActionResult,
  IAssociatedService,
  IInterfaceConfigured,
  IServiceBasicInfo,
  ServerType,
} from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import { connect } from 'react-redux';
import { createBapiInterface } from 'slices/bapiSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IOwnProps {
  data: IServiceBasicInfo[];
}

interface IStateProps {
  createResult: IActionResult<boolean>;
  sesList: IAssociatedService[];
  csList: IAssociatedService[];
  selectedCs: boolean;
  selectedSes: boolean;
}

interface IDispatchProps {
  createBapiInterface: (interface_: IInterfaceConfigured) => void;
}

export interface IFormData {
  name: string;
  description: string;
  checkedCs: boolean;
  checkedSes: boolean;
  selectedSesServerId?: string;
  selectedCsServerId?: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class CreateBapiModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      createResult,
      handleSubmit,
      pristine,
      sesList,
      csList,
      selectedCs,
      selectedSes,
    } = this.props;

    const sesSeverOptions = sesList.map((server, index) => (
      <option value={server.serverId} key={index}>
        {server.name}
      </option>
    ));

    const csSeverOptions = csList.map((server, index) => (
      <option value={server.serverId} key={index}>
        {server.name}
      </option>
    ));

    const sesField = (
      <Field name="selectedSesServerId" component={selectComponent}>
        {sesSeverOptions}
      </Field>
    );

    const csField = (
      <Field name="selectedCsServerId" component={selectComponent}>
        {csSeverOptions}
      </Field>
    );

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            type="text"
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="checkedCs"
            component={checkBoxComponent}
            label="Classification Server"
          />
          {selectedCs ? csField : null}
          <Field
            name="checkedSes"
            component={checkBoxComponent}
            label="Semantic Enhancement Server"
          />
          {selectedSes ? sesField : null}
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { createBapiInterface } = this.props;
    const newInterface: IInterfaceConfigured = {
      active: true,
      description: data.description,
      name: data.name,
      selectedCsServerId: data.checkedCs ? data.selectedCsServerId : undefined,
      selectedSesServerId: data.checkedSes
        ? data.selectedSesServerId
        : undefined,
    };
    createBapiInterface(newInterface);
  }
}

const CreateBapiModalForm = reduxForm<IFormData, IProps>({
  form: 'createBapi',
  validate: (values: IFormData) =>
    !values.checkedCs && !values.checkedSes
      ? { checkedSes: 'At least one service type must be selected.' }
      : {},
})(CreateBapiModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const selector = formValueSelector('createBapi');
  const sesList = ownProps.data.filter(
    (s) => s.serverType === ServerType.SemanticEnhancementService
  );
  const csList = ownProps.data.filter(
    (s) => s.serverType === ServerType.ClassificationService
  );
  return {
    createResult: state.bapi.createInterfaceResult,
    sesList,
    csList,
    initialValues: {
      selectedCsServerId: csList.map((s) => s.serverId)[0],
      selectedSesServerId: sesList.map((s) => s.serverId)[0],
    },
    form: 'createBapi',
    selectedCs: selector(state, 'checkedCs'),
    selectedSes: selector(state, 'checkedSes'),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createBapiInterface: (interface_: IInterfaceConfigured) =>
    dispatch(createBapiInterface(interface_)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateBapiModalForm);
