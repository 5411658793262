import * as React from 'react';

import {
  ISequentialListClassification,
  SequentialListClassificationState,
} from 'types';

import ListClassification from './listClassification';
import { Table } from 'react-bootstrap';

interface IProps {
  data: ISequentialListClassification[];
}

class ListClassifications extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    if (!data.length) return null;

    const sortedClassifications = data
      .slice()
      .sort(
        (a, b) =>
          new Date(b.queueDate).getTime() - new Date(a.queueDate).getTime()
      );
    const deployments = sortedClassifications.map((classification, index) => (
      <ListClassification classification={classification} key={index} />
    ));
    return (
      <>
        <hr />
        <span className="float-right">
          <b>
            {
              data.filter(
                (classification) =>
                  classification.state ===
                  SequentialListClassificationState.InProgress
              ).length
            }
          </b>{' '}
          running and{' '}
          <b>
            {
              data.filter(
                (classification) =>
                  classification.state ===
                  SequentialListClassificationState.Queued
              ).length
            }
          </b>{' '}
          queued
        </span>
        <h5 className="font-weight-bold">List Classification Jobs:</h5>
        <div className="mh-25">
          <Table size="sm" hover responsive>
            <thead>
              <tr>
                <th>Progresss</th>
                <th>Status</th>
                <th>Queued date</th>
                <th>Start date</th>
                <th>Finish date</th>
                <th colSpan={2}>Actions</th>
                <th>Logs</th>
              </tr>
            </thead>
            <tbody>{deployments}</tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default ListClassifications;
