import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IInterfaceRow } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class InterfaceEndpoint {
  public readonly ROOT = 'interface';
  public list(name?: string): Promise<AxiosResponse<IInterfaceRow[]>> {
    const queryItems = name != null ? [new QueryItem('name', name)] : [];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }
}
