import * as React from 'react';

import { Col } from 'react-bootstrap';
import { ComponentType } from 'react';
import Error from './error';
import { IActionResult } from 'types';
import SmallLoadingSpinner from './smallLoadingSpinner';

interface IProps<T> {
  actionresult: IActionResult<T>;
  component: ComponentType<WrappedProps<T>>;
}

interface WrappedProps<T> {
  data: T;
}

class Loader<T> extends React.Component<IProps<T>> {
  public render() {
    const { actionresult, component: Component } = this.props;
    const { processing, data, error } = actionresult;
    if (error !== undefined) {
      return <Error error={error} />;
    } else if (data !== undefined) {
      return <Component data={data} />;
    } else if (processing) {
      return (
        <Col className="d-flex m-2">
          <SmallLoadingSpinner />
        </Col>
      );
    }

    return null;
  }
}

export default Loader;
