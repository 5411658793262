import * as React from 'react';

import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { IActionResult, IRegisterForm } from 'types';
import { asyncValidators, formValidators } from 'utils/formValidators';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import inputComponent from 'components/common/formComponents/inputComponent';
import { register } from 'slices/accountSlice';

interface IStateProps {
  registrationResult: IActionResult<boolean>;
}

interface IDispatchProps {
  register: (registerForm: IRegisterForm) => void;
}

export interface IProps extends IStateProps, IDispatchProps {}

export interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export class RegisterModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }
  public render() {
    const { handleSubmit, pristine, registrationResult } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="firstName"
            component={inputComponent}
            type="text"
            placeholder="Please enter your first name..."
            label="First Name"
            validate={formValidators.required}
          />
          <Field
            name="lastName"
            component={inputComponent}
            type="text"
            placeholder="Please enter your last name..."
            label="Last Name"
            validate={formValidators.required}
          />
          <Field
            name="email"
            component={inputComponent}
            type="email"
            placeholder="Please enter your email address..."
            label="Email Address"
            validate={formValidators.required}
          />
          <Field
            name="password"
            component={inputComponent}
            type="password"
            placeholder="Please enter a password..."
            label="Password"
            validate={[
              formValidators.required,
              formValidators.minLength(9),
              formValidators.requireThreeTypesOfCharacters,
            ]}
            autoComplete="off"
          />
          <Field
            name="passwordConfirm"
            component={inputComponent}
            type="password"
            placeholder="Please reenter a password..."
            label="Confirm Password"
            validate={[formValidators.required, formValidators.passwordsMatch]}
            autoComplete="off"
          />
        </Modal.Body>
        <ModalFormFooter
          actionResult={registrationResult}
          submitMessage="Submit"
          submittingMessage="Submitting"
          submitVariant="success"
          disabled={pristine}
        />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { register } = this.props;
    register({
      emailAddress: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      displayName: `${data.firstName} ${data.lastName}`,
    });
  }
}

const RegisterModalForm = reduxForm<IFormData, IProps>({
  form: 'register',
  asyncValidate: asyncValidators.emailValid,
  asyncBlurFields: ['email'],
})(RegisterModal);

const mapStateToProps = (state: IReduxState): IStateProps => ({
  registrationResult: state.account.registrationResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  register: (registerForm: IRegisterForm) => dispatch(register(registerForm)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(RegisterModalForm);
