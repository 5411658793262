import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import api from './base/api';

export default class DownloadEndpoint {
  public readonly ROOT = 'download';

  public getApp(): Promise<AxiosResponse<Blob>> {
    const url = Endpoint.getUrl([this.ROOT, 'app']);
    return api.get(url, { responseType: 'blob' });
  }

  public getModernUiApp(): Promise<AxiosResponse<Blob>> {
    const url = Endpoint.getUrl([this.ROOT, 'modernUiApp']);
    return api.get(url, { responseType: 'blob' });
  }
}
