import * as React from 'react';

import { Field } from 'redux-form';
import { ITermStoreGroupData } from 'types';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IOwnProps {
  groups: ITermStoreGroupData[];
}
interface IProps extends IOwnProps {}

class TermStoreGroupSelector extends React.Component<IProps> {
  public render() {
    const { groups } = this.props;
    const groupOptions = groups.map((group, index) => (
      <option value={group.id} key={index}>
        {group.name}
        {group.isSiteCollectionGroup ? ' (site level term group)' : null}
      </option>
    ));
    return (
      <>
        <Field
          name="termStoreGroupId"
          component={selectComponent}
          label="Group"
          validate={formValidators.required}
        >
          <option value="">Select a group</option>
          {groupOptions}
        </Field>
      </>
    );
  }
}

export default TermStoreGroupSelector;
