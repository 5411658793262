import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import PageFooter from '../footer';
import NavigationBar from '../navigation/navigationBar';

interface IProps {}

class NarrowLayout extends React.Component<IProps> {
  public render() {
    return (
      <Container fluid className="d-flex flex-column flex-grow-1 min-vh-100">
        <Row className="h-100">
          <Col className="bg-white">
            <NavigationBar />
            <Container>{this.props.children}</Container>
          </Col>
        </Row>
        <PageFooter />
      </Container>
    );
  }
}
export default NarrowLayout;
