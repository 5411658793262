import { Button, Tooltip } from 'react-bootstrap';
import React, { MouseEventHandler } from 'react';

import { MdiReactIconComponentType } from 'mdi-react';
import { OverlayTrigger } from 'react-bootstrap';

interface IProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
  disabled?: boolean;
  icon: MdiReactIconComponentType;
  onClick?: MouseEventHandler;
  className?: string;
  title: string;
}

class RoundButton extends React.Component<IProps> {
  public render() {
    const {
      variant,
      disabled,
      icon: Icon,
      onClick,
      className,
      title,
    } = this.props;

    const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        {title}
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 50, hide: 50 }}
        overlay={renderTooltip}
      >
        <Button
          variant={variant}
          className={`btn-circle ${className}`}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon size="1.4rem" />
        </Button>
      </OverlayTrigger>
    );
  }
}

export default RoundButton;
