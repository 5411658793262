import * as React from 'react';

import { Field } from 'redux-form';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IOwnProps {
  data: string[];
}

interface IProps extends IOwnProps {}

export class SesIndexSelector extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const sesIndexOptions = data.map((sesIndex, index) => (
      <option value={sesIndex} key={index}>
        {sesIndex}
      </option>
    ));
    return (
      <Field
        name="sesIndex"
        component={selectComponent}
        label="SES index"
        validate={formValidators.required}
      >
        <option value="">Select SES index</option>
        {sesIndexOptions}
      </Field>
    );
  }
}

export default SesIndexSelector;
