import * as React from 'react';

import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import BapiHelp from './bapiHelp';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { Modal } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchBapiInterface } from 'slices/bapiSlice';

interface IOwnProps {
  interfaceId: string;
}

interface IStateProps {
  fetchInterfaceResult: IActionResult<IInterfaceConfigured>;
}

interface IDispatchProps {
  fetchBapiInterface: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class BapiModal extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchBapiInterface } = this.props;
    fetchBapiInterface();
  }

  public render() {
    const { fetchInterfaceResult } = this.props;
    return (
      <Modal.Body>
        <Loader component={BapiHelp} actionresult={fetchInterfaceResult} />
      </Modal.Body>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchInterfaceResult: state.bapi.fetchInterfaceResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  fetchBapiInterface: () => dispatch(fetchBapiInterface(ownProps.interfaceId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(BapiModal);
