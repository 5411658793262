import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IDefaultTripleStoreValues } from '../types';
import api from './base/api';

export default class TripleStoreEndpoint {
  public readonly ROOT = 'tripleStore';

  public getDefaultValues(): Promise<AxiosResponse<IDefaultTripleStoreValues>> {
    const url = Endpoint.getUrl([this.ROOT, 'getDefaultValues']);
    return api.get(url);
  }
}
