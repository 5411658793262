import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  IConfigurableFields,
  IFieldConfiguration,
  IFieldConfigurationGetModel,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import SimEndpoint from 'endpoints/simEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ISimSettingsState {
  saveSimSettingsResult: IActionResult<boolean>;
  deleteSimSettingsResult: IActionResult<boolean>;
  fetchFieldsResult: IActionResult<IConfigurableFields>;
  fetchFieldResult: IActionResult<IFieldConfigurationGetModel>;
}

const simSettingsSlice = createSlice({
  name: 'simSettings',
  initialState: {
    saveSimSettingsResult: {
      processing: false,
    },
    deleteSimSettingsResult: {
      processing: false,
    },
    fetchFieldsResult: {
      processing: false,
    },
    fetchFieldResult: {
      processing: false,
    },
  } as ISimSettingsState,
  reducers: {
    fetchingFields(state) {
      state.fetchFieldsResult = { processing: true };
    },
    fetchedFields(state, action: PayloadAction<IConfigurableFields>) {
      state.fetchFieldsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFieldsFailed(state, action: PayloadAction<IError>) {
      state.fetchFieldResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingField(state) {
      state.fetchFieldResult = { processing: true };
    },
    fetchedField(state, action: PayloadAction<IFieldConfigurationGetModel>) {
      state.fetchFieldResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFieldFailed(state, action: PayloadAction<IError>) {
      state.fetchFieldResult = {
        processing: false,
        error: action.payload,
      };
    },
    saving(state) {
      state.saveSimSettingsResult = { processing: true };
    },
    saved(state) {
      state.saveSimSettingsResult = {
        processing: false,
        data: true,
      };
    },
    saveFailed(state, action: PayloadAction<IError>) {
      state.saveSimSettingsResult = {
        processing: false,
        error: action.payload,
      };
    },
    deleting(state) {
      state.deleteSimSettingsResult = { processing: true };
    },
    deleted(state) {
      state.deleteSimSettingsResult = {
        processing: false,
        data: true,
      };
    },
    deleteFailed(state, action: PayloadAction<IError>) {
      state.deleteSimSettingsResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const saveListSimConfig = (
  siteLibraryId: string,
  configuration: IFieldConfiguration
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(saving());
  try {
    await endpoint.saveListSimConfig(siteLibraryId, configuration);
    dispatch(saved());
    dispatch(fetchListConfigurableFields(siteLibraryId));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(saveFailed(Errors.getError(error)));
  }
};

export const deleteListSimConfig = (
  fieldId: string,
  siteLibraryId: string
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(deleting());
  try {
    await endpoint.deleteListFieldConfig(fieldId, siteLibraryId);
    dispatch(deleted());
    dispatch(fetchListConfigurableFields(siteLibraryId));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteFailed(Errors.getError(error)));
  }
};

export const saveContentTypeSimConfig = (
  interfaceId: string,
  cTypeId: string,
  spHostUrl: string,
  configuration: IFieldConfiguration,
  listId?: string
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(saving());
  try {
    await endpoint.saveContentTypeSimConfig(
      interfaceId,
      cTypeId,
      spHostUrl,
      configuration,
      listId
    );
    dispatch(saved());
    dispatch(fetchContentTypeConfigurableFields(spHostUrl, cTypeId));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(saveFailed(Errors.getError(error)));
  }
};

export const fetchListConfigurableFields = (
  siteLibraryId: string
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(fetchingFields());
  try {
    const response = await endpoint.getListFields(siteLibraryId);
    dispatch(fetchedFields(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFieldsFailed(Errors.getError(error)));
  }
};

export const fetchContentTypeConfigurableFields = (
  spHostUrl: string,
  cTypeId: string,
  listId?: string
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(fetchingFields());
  try {
    const response = await endpoint.getContentTypeFields(
      cTypeId,
      spHostUrl,
      listId
    );
    dispatch(fetchedFields(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFieldsFailed(Errors.getError(error)));
  }
};

export const fetchListField = (
  fieldId: string,
  siteLibraryId: string
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(fetchingField());
  try {
    const response = await endpoint.getListField(fieldId, siteLibraryId);
    dispatch(fetchedField(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFieldFailed(Errors.getError(error)));
  }
};

export const fetchContentTypeField = (
  fieldId: string,
  cTypeId: string,
  spHostUrl: string,
  listId?: string
): AppThunk => async (dispatch) => {
  const endpoint = new SimEndpoint();
  dispatch(fetchingField());
  try {
    const response = await endpoint.getContentTypeField(
      fieldId,
      cTypeId,
      spHostUrl,
      listId
    );
    dispatch(fetchedField(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFieldFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = simSettingsSlice;
export const {
  saveFailed,
  saved,
  saving,
  fetchFieldFailed,
  fetchFieldsFailed,
  fetchedField,
  fetchedFields,
  fetchingField,
  fetchingFields,
  deleteFailed,
  deleted,
  deleting,
} = actions;
export default reducer;
