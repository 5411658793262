import * as React from 'react';

import { Form } from 'react-bootstrap';
import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';

const selectComponent = ({
  input,
  label,
  tooltip,
  type,
  meta: { touched, error, warning },
  children,
  ...other
}: {
  input: any;
  label: string;
  tooltip: string;
  type: string;
  meta: any;
  children: any;
}) => {
  const help =
    tooltip !== undefined ? (
      <span title={tooltip}>
        <HelpOutlineIcon size="1rem" />
      </span>
    ) : null;
  return (
    <Form.Group>
      <Form.Label>
        {label} {help}
      </Form.Label>
      <Form.Control
        as="select"
        {...input}
        placeholder={label}
        type={type}
        {...other}
      >
        {children}
      </Form.Control>
      {touched &&
        ((error && <Form.Text className="text-danger">{error}</Form.Text>) ||
          (warning && (
            <Form.Text className="text-warning">{warning}</Form.Text>
          )))}
    </Form.Group>
  );
};

export default selectComponent;
