import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { IActionResult, ITenancyDetail, IUserResult } from 'types';

import { AnyAction } from 'redux';
import Authorizer from 'utils/authorizer';
import ExternalLogins from './externalLogins';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import TenancySelector from './tenancySelector';
import { ThunkDispatch } from 'redux-thunk';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import roleSelectComponent from './roleSelectComponent';
import { saveUser } from 'slices/userSlice';

interface IOwnProps {
  data: IUserResult;
}

interface IStateProps {
  availableTenanciesResult: IActionResult<ITenancyDetail[]>;
  saveUserResult: IActionResult<boolean>;
  canToggleUserActive: boolean;
  canChangeTenant: boolean;
  isActive: boolean;
  canEditUser: boolean;
  canViewUserActive: boolean;
}

interface IDispatchProps {
  saveUser: (user: IUserResult) => void;
}

export interface IFormData {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  roles: string[];
  tenantId: string;
  emailConfirmed: boolean;
  active: boolean;
  inactivityReason: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class EditUserModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      handleSubmit,
      availableTenanciesResult,
      data,
      saveUserResult,
      pristine,
      canToggleUserActive,
      isActive,
      canChangeTenant,
      canEditUser,
      canViewUserActive,
    } = this.props;

    const emailConfirmed = data.hasPassword ? (
      <Field
        name="emailConfirmed"
        label="Email confirmed"
        type="switch"
        id="emailConfirmed"
        component={checkBoxComponent}
        disabled={!canEditUser}
      />
    ) : null;

    const userActive = canViewUserActive ? (
      <Field
        name="active"
        label="User active"
        type="switch"
        id="active"
        component={checkBoxComponent}
        disabled={!canToggleUserActive}
      />
    ) : null;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="firstName"
            label="First Name"
            component={inputComponent}
            type="text"
            placeholder="Please enter first name"
            validate={formValidators.required}
            disabled={!canEditUser}
          />
          <Field
            name="lastName"
            label="Last Name"
            component={inputComponent}
            type="text"
            placeholder="Please enter last name"
            validate={formValidators.required}
            disabled={!canEditUser}
          />
          <Field
            name="displayName"
            label="Display Name"
            component={inputComponent}
            type="text"
            placeholder="Please enter display name"
            validate={formValidators.required}
            disabled={!canEditUser}
          />
          <Field
            name="email"
            label="Email"
            component={inputComponent}
            type="email"
            placeholder="Please enter email address"
            validate={formValidators.required}
            disabled={!canEditUser}
          />
          {emailConfirmed}
          {userActive}
          {!isActive ? (
            <Field
              name="inactivityReason"
              label="Inactivity Reason"
              component={inputComponent}
              disabled={!canToggleUserActive}
            />
          ) : null}
          {canChangeTenant ? (
            <Loader
              component={TenancySelector}
              actionresult={availableTenanciesResult}
            />
          ) : null}
          <ExternalLogins configuredProviders={data.configuredProviders} />
          <Field name="roles" component={roleSelectComponent} />
        </Modal.Body>
        <ModalFormFooter actionResult={saveUserResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { data: user, saveUser } = this.props;
    const updatedUser = Object.assign({}, user, data);
    saveUser(updatedUser);
  }
}

const EditUserModalForm = reduxForm<IFormData, IProps>({
  form: 'editUser',
})(EditUserModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const authorizer = new Authorizer(state);
  const selector = formValueSelector('editUser');
  return {
    availableTenanciesResult: state.tenant.searchResult,
    saveUserResult: state.user.saveUserResult,
    initialValues: {
      roles: ownProps.data.roles,
      firstName: ownProps.data.firstName,
      lastName: ownProps.data.lastName,
      displayName: ownProps.data.displayName,
      email: ownProps.data.email,
      tenantId: ownProps.data.tenantId,
      emailConfirmed: ownProps.data.emailConfirmed,
      active: ownProps.data.active,
      inactivityReason: ownProps.data.inactivityReason,
    },
    form: 'editUser',
    canToggleUserActive: authorizer.canToggleUserActive(),
    canChangeTenant: authorizer.canChangeTenant(),
    isActive: selector(state, 'active'),
    canEditUser: authorizer.canEditUser(),
    canViewUserActive: authorizer.canViewUserActive(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  saveUser: (user: IUserResult) => dispatch(saveUser(user)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditUserModalForm);
