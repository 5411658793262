import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { ISpoQueryError } from 'types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class SharePointErrorsEndpoint {
  public readonly ROOT = 'sharePointErrors';

  public getErrors(
    interfaceId: string
  ): Promise<AxiosResponse<ISpoQueryError[]>> {
    const queryItems = [new QueryItem('interfaceId', interfaceId)];
    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }
}
