import * as React from 'react';

import { Button, Modal, ProgressBar, Table } from 'react-bootstrap';
import { IActionResult, ISyncRun, ModalContents, SyncStatus } from 'types';
import { closeModal, openModal } from 'slices/modalSlice';
import { getDuration, localLongDate } from 'utils/dateUtils';
import { messageClasses, syncStatus } from './statusIndicators';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface IOwnProps {
  syncRun: ISyncRun;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  onClose: () => void;
  openLogModal: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class SyncRunDetailsModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  public render() {
    const { syncRun, openLogModal } = this.props;
    return (
      <>
        {syncRun.status === SyncStatus.InProgress && syncRun.range ? (
          <ProgressBar
            variant="success"
            animated
            now={(syncRun.progress / syncRun.range) * 100}
            className="rounded-0"
          />
        ) : null}
        <Modal.Header className={messageClasses[syncRun.status]}>
          <span dangerouslySetInnerHTML={{ __html: syncRun.currentMessage }} />
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          {syncRun.queueDate ? (
            <dl>
              <dt>Queue Time</dt>
              <dd>{localLongDate(syncRun.queueDate)}</dd>
            </dl>
          ) : null}
          {syncRun.startDate ? (
            <dl>
              <dt>Start Time</dt>
              <dd>{localLongDate(syncRun.startDate)}</dd>
            </dl>
          ) : null}
          {syncRun.finishDate ? (
            <dl>
              <dt>End Time (Duration)</dt>
              <dd>
                {localLongDate(syncRun.finishDate) +
                  (syncRun.startDate
                    ? ` (${getDuration(syncRun.finishDate, syncRun.startDate)})`
                    : '')}
              </dd>
            </dl>
          ) : null}
          <dl>
            <dt>Status</dt>
            <dd>{syncStatus[syncRun.status]}</dd>
          </dl>
          <dl>
            <dt>Correlation Id</dt>
            <dd>{syncRun.correlationId}</dd>
          </dl>
          <dl>
            <dt>Full Log</dt>
            <dd>
              <Button size="sm" variant="info" onClick={openLogModal}>
                View full log
              </Button>
            </dd>
          </dl>
          {syncRun.errors && syncRun.errors.length ? (
            <dl>
              <dt>Errors</dt>
              <dd>
                <Table responsive hover size="sm">
                  <thead>
                    <tr>
                      <th>Term</th>
                      <th>Parent</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {syncRun.errors.map((error, index) => (
                      <tr key={index}>
                        <td title={error.nodeId}>{error.nodeName}</td>
                        <td title={error.parentNodeId}>
                          {error.parentNodeName}
                        </td>
                        <td>{error.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </dd>
            </dl>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.cancel}>
            Dismiss
          </Button>
        </Modal.Footer>
      </>
    );
  }

  private cancel() {
    const { onClose } = this.props;
    onClose();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    deleteResult: state.spo.deleteInterfaceResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  onClose: () => dispatch(closeModal()),
  openLogModal: () =>
    dispatch(
      openModal(
        'Sync Run Logs',
        ModalContents.CorrelatedLogs,
        {
          position: 'right',
          size: 'xl',
        },
        ownProps.syncRun.correlationId
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SyncRunDetailsModal);
