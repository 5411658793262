import * as React from 'react';

import { IActionResult, IDeployment } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteSiteDeployment } from 'slices/deploymentSlice';

interface IOwnProps {
  deployment: IDeployment;
  interfaceId: string;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteSiteDeployment: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteSiteDeploymentModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { deleteResult, deployment } = this.props;

    return (
      <>
        <Modal.Body>
          Are you sure you want to delete deployment to{' '}
          <p className="text-break">"{deployment.siteUrl}"?</p>
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          onSubmit={this.delete}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
        />
      </>
    );
  }

  private delete() {
    const { deleteSiteDeployment } = this.props;
    deleteSiteDeployment();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    deleteResult: state.deployment.deleteSiteDeploymentResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteSiteDeployment: () =>
    dispatch(
      deleteSiteDeployment(ownProps.interfaceId, ownProps.deployment.id)
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSiteDeploymentModal);
