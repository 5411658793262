import * as React from 'react';

import { Accordion, Card } from 'react-bootstrap';

import { IAssociatedService } from 'types';

interface IProps {
  dependsUpon: IAssociatedService[];
  dependentUpon: IAssociatedService[];
}

class Dependencies extends React.Component<IProps> {
  public render() {
    const { dependsUpon, dependentUpon } = this.props;
    const dependsUponElements = dependsUpon.map(
      (d: IAssociatedService, i: number) => (
        <li key={i}>
          <strong>{d.name}</strong>
        </li>
      )
    );
    const dependentUponElements = dependentUpon.map(
      (d: IAssociatedService, i: number) => (
        <li key={i}>
          <strong>{d.name}</strong>
        </li>
      )
    );
    return (
      <Accordion defaultActiveKey="0">
        <Card bg="info">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Dependencies
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="bg-white">
              {[
                dependsUponElements.length !== 0 ? (
                  <div key={0}>
                    This service depends on the following services:
                    <ul>{dependsUponElements}</ul>
                  </div>
                ) : null,
                dependentUponElements.length !== 0 ? (
                  <div key={1}>
                    The following services are dependent upon this service:
                    <ul>{dependentUponElements}</ul>
                  </div>
                ) : null,
                dependsUponElements.length === 0 &&
                dependentUponElements.length === 0 ? (
                  <div key={2}>
                    <em>No dependencies</em>
                  </div>
                ) : null,
              ]}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default Dependencies;
