import * as React from 'react';

import { Button, Card, Modal } from 'react-bootstrap';
import { IActionResult, ITenantContactDetail, ModalContents } from 'types';

import { AnyAction } from 'redux';
import Authorizer from 'utils/authorizer';
import Contacts from './contacts';
import EditIcon from 'mdi-react/EditIcon';
import { IReduxState } from 'reducers';
import Loader from '../loader';
import Note from './note';
import PlusIcon from 'mdi-react/PlusIcon';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IStateProps {
  contactsResult: IActionResult<ITenantContactDetail[]>;
  noteResult: IActionResult<string>;
  canSeeTenantNote: boolean;
  canManageContacts: boolean;
  canEditTenantNote: boolean;
}

interface IDispatchProps {
  openCreateContactModal: () => void;
  openNoteModal: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class SidePanelModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.createContact = this.createContact.bind(this);
    this.editNote = this.editNote.bind(this);
  }

  public render() {
    const {
      contactsResult,
      noteResult,
      canSeeTenantNote,
      canManageContacts,
      canEditTenantNote,
    } = this.props;
    return (
      <Modal.Body>
        <Card className="mb-4 border-0">
          <Card.Header className="border-0 align-items-center d-flex justify-content-between">
            Contacts
            <Button
              variant="link"
              className="p-0"
              onClick={this.createContact}
              disabled={!canManageContacts}
            >
              <PlusIcon size="1.4rem" />
            </Button>
          </Card.Header>
          <Loader component={Contacts} actionresult={contactsResult} />
        </Card>
        {canSeeTenantNote ? (
          <Card className="mb-4 border-0">
            <Card.Header className="border-0 align-items-center d-flex justify-content-between">
              Notes
              <Button
                variant="link"
                className="p-0"
                onClick={this.editNote}
                disabled={!canEditTenantNote}
              >
                <EditIcon size="1.2rem" />
              </Button>
            </Card.Header>
            <Card.Body>
              <Loader component={Note} actionresult={noteResult} />
            </Card.Body>
          </Card>
        ) : null}
      </Modal.Body>
    );
  }

  private createContact() {
    const { openCreateContactModal } = this.props;
    openCreateContactModal();
  }

  private editNote() {
    const { openNoteModal } = this.props;
    openNoteModal();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    contactsResult: state.tenantSettings.contactsResult,
    noteResult: state.tenantSettings.noteResult,
    canSeeTenantNote: authorizer.canSeeTenantNote(),
    canEditTenantNote: authorizer.canEditTenantNote(),
    canManageContacts: authorizer.canManageContacts(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openCreateContactModal: () =>
    dispatch(
      openModal('Create contact', ModalContents.CreateContact, {
        position: 'right',
      })
    ),
  openNoteModal: () =>
    dispatch(
      openModal('Tenant Note', ModalContents.EditNote, {
        position: 'right',
      })
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SidePanelModal);
