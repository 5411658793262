import * as React from 'react';

import { IMenuItem, ModalContents } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import ShortcutTile from './shortcutTile';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IStateProps {}

interface IOwnProps {
  data: IMenuItem[];
}

interface IDispatchProps {
  openModal: (title: string, type: ModalContents, data?: any) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class Shortcuts extends React.Component<IProps, any> {
  public render() {
    const { data } = this.props;

    const shortcuts = data.map((tile, index) => {
      return (
        <ShortcutTile
          text={tile.title}
          iconComponent={tile.iconComponent}
          title={tile.tooltipText}
          variant={tile.backgroundClass}
          href={!tile.isModal ? tile.url : undefined}
          key={index}
          {...(tile.isModal && {
            onClick: this.onModalClick(tile.modalTitle || '', tile.url),
          })}
        />
      );
    });

    return <div className="clear block m-b-sm">{shortcuts}</div>;
  }

  private onModalClick = (title: string, url: string) => () => {
    const { openModal } = this.props;
    openModal(title, ModalContents.Iframe, url);
  };
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openModal: (title: string, type: ModalContents, data?: any) =>
    dispatch(
      openModal(
        title,
        type,
        {
          position: 'right',
          size: 'lg',
        },
        data
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(Shortcuts);
