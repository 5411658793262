import * as React from 'react';

import { ContentState, EditorState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface IProps {
  input: {
    onChange: (value: string) => void;
    value: string;
  };

  toolbar: object;
}

class TextEditorComponent extends React.Component<IProps> {
  public render() {
    const { input, toolbar } = this.props;
    const { value } = input;
    const contentBlock = htmlToDraft(value);
    const defaultEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlock.contentBlocks)
    );
    return (
      <Editor
        placeholder="Start typing..."
        toolbar={toolbar}
        toolbarClassName="cloud-editor-toolbar"
        onChange={this.onChange}
        defaultEditorState={defaultEditorState}
      />
    );
  }

  private onChange = (valueFromEditor: any) => {
    const { input } = this.props;
    const { onChange } = input;
    let htmlContent = draftToHtml(valueFromEditor);
    const emptyContent = '<p></p>\n';
    if (emptyContent === htmlContent) {
      // When edition window is empty "<p></p>\n" is returned
      // and needs to be cleaned manually.
      htmlContent = '';
    }
    onChange(htmlContent);
  };
}

export default TextEditorComponent;
