import * as React from 'react';

import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { AnyAction } from 'redux';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import inputComponent from 'components/common/formComponents/inputComponent';
import { sendResetPasswordEmail } from 'slices/accountSlice';

interface IStateProps {
  sendResetPasswordEmailResult: IActionResult<boolean>;
}

interface IDispatchProps {
  sendResetPasswordEmail: (email: string) => void;
}

export interface IProps extends IDispatchProps, IStateProps {}

export interface IFormData {
  email: string;
}

export class ForgotPassordModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }
  public render() {
    const { handleSubmit, pristine, sendResetPasswordEmailResult } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="email"
            component={inputComponent}
            type="email"
            placeholder="Please enter your email address..."
            label="Email Address"
            autoComplete="off"
          />
        </Modal.Body>
        <ModalFormFooter
          actionResult={sendResetPasswordEmailResult}
          submitMessage="Submit"
          submittingMessage="Submitting"
          disabled={pristine}
        />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { sendResetPasswordEmail } = this.props;
    sendResetPasswordEmail(data.email);
  }
}

const ForgotPasswordModalForm = reduxForm<IFormData, IProps>({
  form: 'forgotPassword',
})(ForgotPassordModal);

const mapStateToProps = (state: IReduxState): IStateProps => ({
  sendResetPasswordEmailResult: state.account.sendResetPasswordEmailResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  sendResetPasswordEmail: (email: string) =>
    dispatch(sendResetPasswordEmail(email)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModalForm);
