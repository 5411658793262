import * as React from 'react';

import { IConfiguredProvider, ModalContents } from 'types';

import { AnyAction } from 'redux';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  provider: IConfiguredProvider;
  showRemoveButton: boolean;
  user: string;
}

interface IDispatchProps {
  openRemoveExternalLoginModal: () => void;
}

interface IProps extends IOwnProps, IDispatchProps {}

export class ExternalLogin extends React.Component<IProps, any> {
  public render() {
    const {
      provider,
      showRemoveButton,
      openRemoveExternalLoginModal,
    } = this.props;

    return (
      <tr>
        <td className="text-break">{provider.displayName}</td>

        <td className="text-break">{provider.userName}</td>
        <td>
          {showRemoveButton ? (
            <Button
              onClick={openRemoveExternalLoginModal}
              size="sm"
              className="px-1 py-0"
              variant="danger"
              value="Remove"
              title={
                'Remove ' + provider.displayName + ' login from your account'
              }
            >
              Remove
            </Button>
          ) : null}
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openRemoveExternalLoginModal: () =>
    dispatch(
      openModal(
        'Confirm removing external login',
        ModalContents.RemoveExternalLogin,
        {
          position: 'right',
        },
        { provider: ownProps.provider, user: ownProps.user }
      )
    ),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ExternalLogin);
