import * as React from 'react';

import { Button, Card, ListGroup } from 'react-bootstrap';

import AdminFeedItem from './adminFeedItem';
import { IFeed } from 'types';
import RssIcon from 'mdi-react/RssIcon';

interface IProps {
  data: IFeed;
}

class AdminFeed extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const feedEntries = data.items.map((item, index) => (
      <AdminFeedItem data={item} key={index} />
    ));
    return (
      <Card className="border-0 mt-3">
        <Card.Header className="border-0">
          <Button
            as="a"
            size="sm"
            href={data.link}
            target="_blank"
            className="float-right py-0 px-1 d-flex align-items-center"
            variant="link"
          >
            <RssIcon size="1rem" className="text-danger mr-1" />
            RSS
          </Button>
          <b className="m-b-none m-t-none">{data.title}</b>
        </Card.Header>
        <ListGroup variant="flush">{feedEntries}</ListGroup>
      </Card>
    );
  }
}

export default AdminFeed;
