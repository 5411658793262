import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceViewModel, ServerType } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createService } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IOwnProps {
  groupId: string;
}

interface IStateProps {
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  url: string;
  studioPort: number;
  studioPath: string;
  oePort: number;
  oePath: string;
  wbPort: number;
  wbPath: string;
  sesPort: number;
  sesPath: string;
  csPort: number;
}

export class CreateUhModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, createResult } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="url"
            component={inputComponent}
            type="text"
            placeholder="Please enter a url"
            label="Url"
            validate={formValidators.required}
          />
          <Field
            name="studioPath"
            component={inputComponent}
            type="text"
            placeholder="Please enter studio server path"
            label="Studio Server Path"
          />
          <Field
            name="studioPort"
            component={inputComponent}
            type="number"
            placeholder="Please enter studio server port"
            label="Studio Server Port"
          />
          <Field
            name="oePort"
            component={inputComponent}
            type="number"
            placeholder="Please enter ontology editor port"
            label="Ontology Editor Port"
          />
          <Field
            name="oePath"
            component={inputComponent}
            type="text"
            placeholder="Please enter ontology editor path"
            label="Ontology Editor Path"
          />
          <Field
            name="wbPort"
            component={inputComponent}
            type="number"
            placeholder="Please enter workbench port"
            label="Workbench Port"
          />
          <Field
            name="wbPath"
            component={inputComponent}
            type="text"
            placeholder="Please enter workbench path"
            label="Workbench Path"
          />
          <Field
            name="sesPort"
            component={inputComponent}
            type="number"
            placeholder="Please semantic enhancement server port"
            label="Semantic Enhancement Server Port"
          />
          <Field
            name="sesPath"
            component={inputComponent}
            type="text"
            placeholder="Please semantic enhancement server path"
            label="Semantic Enhancement Server Path"
          />
          <Field
            name="csPort"
            component={inputComponent}
            type="number"
            placeholder="Please enter classification server port"
            label="Classification Server Port"
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { groupId, createService } = this.props;
    const service: IServiceViewModel = {
      name: data.name,
      description: data.description,
      groupId,
      configData: {
        ClassificationServerPort: data.csPort,
        OntologyEditorPath: data.oePath,
        OntologyEditorPort: data.oePort,
        SemanticEnhancementServerPath: data.sesPath,
        SemanticEnhancementServerPort: data.sesPort,
        WorkbenchPath: data.wbPath,
        WorkbenchPort: data.wbPort,
        StudioServicePath: data.studioPath,
        StudioServicePort: data.studioPort,
      },
      active: true,
      serverType: ServerType.UnmanagedHostService,
      url: data.url,
      dependsUpon: [],
    };

    createService(service);
  }
}

const CreateUhModalForm = reduxForm<IFormData, IProps>({
  form: 'createUh',
})(CreateUhModal);

const mapStateToProps = (
  state: IReduxState
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      oePort: 8080,
      oePath: '/oe/',
      studioPath: '/',
      studioPort: 8080,
      wbPort: 8080,
      wbPath: '/workbench/',
      sesPort: 80,
      sesPath: '/ses/',
      csPort: 5058,
    },
    form: 'createUh',
    createResult: state.service.createResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createService: (service: IServiceViewModel) =>
    dispatch(createService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateUhModalForm);
