import { Errors, IError } from 'utils/errors';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import CsEndpoint from 'endpoints/csEndpoint';
import { IActionResult } from 'types';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ICsState {
  fetchNumberOfConfigurationsResult: IActionResult<number>;
  replaceCsInConfigurationsResult: IActionResult<boolean>;
  removeConfigurationsResult: IActionResult<boolean>;
}

const tsSlice = createSlice({
  name: 'cs',
  initialState: {
    fetchNumberOfConfigurationsResult: {
      processing: false,
    },
    replaceCsInConfigurationsResult: {
      processing: false,
    },
    removeConfigurationsResult: {
      processing: false,
    },
  } as ICsState,
  reducers: {
    fetchingNumberOfConfigurations(state) {
      state.fetchNumberOfConfigurationsResult = { processing: true };
    },
    fetchedNumberOfConfigurations(state, action: PayloadAction<number>) {
      state.fetchNumberOfConfigurationsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchNumberOfConfigurationsFailed(state, action: PayloadAction<IError>) {
      state.fetchNumberOfConfigurationsResult = {
        processing: false,
        error: action.payload,
      };
    },
    replacingCsInConfigurations(state) {
      state.replaceCsInConfigurationsResult = { processing: true };
    },
    replacedCsInConfigurations(state) {
      state.replaceCsInConfigurationsResult = {
        processing: false,
        data: true,
      };
    },
    replaceCsInConfigurationsFailed(state, action: PayloadAction<IError>) {
      state.replaceCsInConfigurationsResult = {
        processing: false,
        error: action.payload,
      };
    },
    removingConfigurations(state) {
      state.removeConfigurationsResult = { processing: true };
    },
    removedConfigurations(state) {
      state.removeConfigurationsResult = {
        processing: false,
        data: true,
      };
    },
    removingConfigurationsFailed(state, action: PayloadAction<IError>) {
      state.removeConfigurationsResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchNumberOfConfigurations = (
  classificationSererId: string
): AppThunk => async (dispatch) => {
  const endpoint = new CsEndpoint();
  dispatch(fetchingNumberOfConfigurations());
  try {
    const response = await endpoint.getNumberOfConfigurations(
      classificationSererId
    );
    dispatch(fetchedNumberOfConfigurations(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchNumberOfConfigurationsFailed(Errors.getError(error)));
  }
};

export const replaceCsInConfigurations = (
  classificationSererId: string,
  newClassificationServerId: string
): AppThunk => async (dispatch) => {
  const endpoint = new CsEndpoint();
  dispatch(replacingCsInConfigurations());
  try {
    await endpoint.replaceCsInConfigurations(
      classificationSererId,
      newClassificationServerId
    );
    dispatch(replacedCsInConfigurations());
    dispatch(fetchNumberOfConfigurations(classificationSererId));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(replaceCsInConfigurationsFailed(Errors.getError(error)));
  }
};

export const removeConfigurations = (
  classificationSererId: string
): AppThunk => async (dispatch) => {
  const endpoint = new CsEndpoint();
  dispatch(removingConfigurations());
  try {
    await endpoint.removeConfigurations(classificationSererId);
    dispatch(removedConfigurations());
    dispatch(fetchNumberOfConfigurations(classificationSererId));
  } catch (error) {
    dispatch(removingConfigurationsFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = tsSlice;
export const {
  fetchNumberOfConfigurationsFailed,
  fetchedNumberOfConfigurations,
  fetchingNumberOfConfigurations,
  replaceCsInConfigurationsFailed,
  replacedCsInConfigurations,
  replacingCsInConfigurations,
  removingConfigurationsFailed,
  removedConfigurations,
  removingConfigurations,
} = actions;
export default reducer;
