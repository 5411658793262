import * as React from 'react';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { logOut } from 'slices/accountSlice';

interface IDispatchProps {
  logOut: () => void;
}

interface IProps extends IDispatchProps {}

export class LogoutPage extends React.Component<IProps> {
  //this class exists so that logout can be performed from the menu inside OE
  //it can be removed after all environments are converted to Semaphore 5 or greater
  public componentDidMount() {
    const { logOut } = this.props;
    logOut();
  }

  public render() {
    return null;
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  logOut: () => dispatch(logOut()),
});

export default connect<{}, IDispatchProps, {}, IReduxState>(
  null,
  mapDispatchToProps
)(LogoutPage);
