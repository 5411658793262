import * as React from 'react';

import { IActionResult, IFeed } from 'types';
import { loadAdminFeed, loadUserFeed } from 'slices/rssFeedSlice';

import AdminFeed from './adminFeed';
import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import { Col } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import UserFeed from './userFeed';
import { connect } from 'react-redux';

interface IStateProps {
  canViewAdminFeed: boolean;
  adminFeedResult: IActionResult<IFeed>;
  userFeedResult: IActionResult<IFeed>;
}

interface IDispatchProps {
  loadAdminFeed: () => void;
  loadUserFeed: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class Feed extends React.Component<IProps> {
  public componentDidMount() {
    const { canViewAdminFeed, loadAdminFeed, loadUserFeed } = this.props;
    loadUserFeed();
    if (canViewAdminFeed) {
      loadAdminFeed();
    }
  }

  public render() {
    const { canViewAdminFeed, adminFeedResult, userFeedResult } = this.props;
    return (
      <Col xs={12} sm={5} md={3} lg={2} className="bg-light border-left">
        {canViewAdminFeed ? (
          <Loader component={AdminFeed} actionresult={adminFeedResult} />
        ) : null}
        <Loader component={UserFeed} actionresult={userFeedResult} />
      </Col>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canViewAdminFeed: authorizer.isTenantAdmin(),
    adminFeedResult: state.rssFeed.adminFeedResult,
    userFeedResult: state.rssFeed.userFeedResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  loadAdminFeed: () => dispatch(loadAdminFeed()),
  loadUserFeed: () => dispatch(loadUserFeed()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Feed);
