import {
  IClassificationError,
  ISequentialListClassification,
  ISequentialListClassificationDetails,
} from 'types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import api from './base/api';

export default class ClassifyListEndpoint {
  public readonly ROOT = 'classify';
  public readonly ROOT2 = 'listClassification';

  public getListClassificationsBySite(
    siteDeploymentId: string
  ): Promise<AxiosResponse<ISequentialListClassification[]>> {
    const url = Endpoint.getUrl([this.ROOT2, 'bySite', siteDeploymentId]);
    return api.get(url);
  }

  public getListClassifications(
    interfaceId: string
  ): Promise<AxiosResponse<ISequentialListClassification[]>> {
    const url = Endpoint.getUrl([this.ROOT2, 'byInterface', interfaceId]);
    return api.get(url);
  }

  public getListClassificationErrors(
    listClassificationId: string
  ): Promise<AxiosResponse<IClassificationError[]>> {
    const url = Endpoint.getUrl([this.ROOT2, listClassificationId, 'errors']);
    return api.get(url);
  }

  public getListClassificationDetails(
    listClassificationId: string
  ): Promise<AxiosResponse<ISequentialListClassificationDetails>> {
    const url = Endpoint.getUrl([this.ROOT2, listClassificationId, 'details']);
    return api.get(url);
  }

  public getListClassification(
    listClassificationId: string
  ): Promise<AxiosResponse<ISequentialListClassification>> {
    const url = Endpoint.getUrl([this.ROOT2, listClassificationId]);
    return api.get(url);
  }

  public cancelListClassification(
    siteLibraryId: string
  ): Promise<AxiosResponse<ISequentialListClassification>> {
    const url = Endpoint.getUrl([this.ROOT, siteLibraryId]);
    return api.delete(url);
  }

  public pauseListClassification(
    siteLibraryId: string
  ): Promise<AxiosResponse<ISequentialListClassification>> {
    const url = Endpoint.getUrl([this.ROOT, siteLibraryId, 'pause']);
    return api.post(url);
  }

  public resumeListClassification(
    siteLibraryId: string,
    listClassificationId: string
  ): Promise<AxiosResponse<ISequentialListClassification>> {
    const url = listClassificationId
      ? Endpoint.getUrl([
          this.ROOT,
          siteLibraryId,
          'resume',
          listClassificationId,
        ])
      : Endpoint.getUrl([this.ROOT, siteLibraryId, 'resume']);
    return api.post(url);
  }
}
