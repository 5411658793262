import { Col } from 'react-bootstrap';
import { IPopupMessage } from 'types';
import { IReduxState } from 'reducers';
import Message from './message';
import React from 'react';
import { connect } from 'react-redux';

interface IStateProps {
  messages: IPopupMessage[];
}

interface IProps extends IStateProps {}

export class Messages extends React.Component<IProps> {
  public render() {
    const { messages } = this.props;
    const mappedMessages = messages.map((message, index) => (
      <Message message={message} key={index} />
    ));
    return (
      <div className="d-flex fixed-bottom">
        <Col>{mappedMessages}</Col>
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  messages: state.message.messages,
});

export default connect<IStateProps, {}, {}, IReduxState>(mapStateToProps)(
  Messages
);
