import * as React from 'react';

import { Button, Modal, ProgressBar } from 'react-bootstrap';
import {
  IActionResult,
  IClassificationError,
  ISequentialListClassificationDetails,
  ModalContents,
  SequentialListClassificationState,
} from 'types';
import {
  classificationMessageClasses,
  listClassificationStatusStatus,
} from './statusIndicators';
import { closeModal, openModal } from 'slices/modalSlice';
import { getDuration, localLongDate } from 'utils/dateUtils';

import { AnyAction } from 'redux';
import ClassificationErrors from './classificationErrors';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchListClassificationErrors } from 'slices/classifyListSlice';
import { pluralize } from 'utils/textUtils';

interface IOwnProps {
  data: ISequentialListClassificationDetails;
}

interface IStateProps {
  listClassificationErrorsResult: IActionResult<IClassificationError[]>;
}

interface IDispatchProps {
  onClose: () => void;
  openLogModal: () => void;
  fetchListClassificationErrors: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ListClassificationDetailsModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  public componentDidMount() {
    const { fetchListClassificationErrors } = this.props;
    fetchListClassificationErrors();
  }

  public render() {
    const { data, openLogModal, listClassificationErrorsResult } = this.props;
    const inProgress =
      data.state === SequentialListClassificationState.InProgress;
    return (
      <>
        <ProgressBar className="rounded-0">
          <ProgressBar
            variant="success"
            now={(data.completedItems / data.queuedItems) * 100}
            animated={inProgress}
            striped
            key={1}
            label={data.completedItems}
            title={`${data.completedItems} ${pluralize(
              'item',
              data.failedItems
            )} sucessfully classified`}
          />
          <ProgressBar
            variant="danger"
            now={(data.failedItems / data.queuedItems) * 100}
            animated={inProgress}
            striped
            key={2}
            label={data.failedItems}
            title={`${data.failedItems} ${pluralize(
              'item',
              data.failedItems
            )} skipped`}
          />
          <ProgressBar
            variant="warning"
            now={(data.ignoredItems / data.queuedItems) * 100}
            animated={inProgress}
            striped
            key={3}
            label={data.ignoredItems}
            title={`${data.ignoredItems} ${pluralize(
              'item',
              data.ignoredItems
            )} ignored`}
          />
        </ProgressBar>
        <Modal.Header className={classificationMessageClasses[data.state]}>
          <span dangerouslySetInnerHTML={{ __html: data.message }} />
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <dl>
            <dt>List Title</dt>
            <dd>{data.listName}</dd>
          </dl>
          <dl>
            <dt>List Id</dt>
            <dd>{data.listId}</dd>
          </dl>
          <dl>
            <dt>Site Url</dt>
            <dd className="text-break">
              <Button variant="link" className="p-0">
                {data.siteUrl}
              </Button>
            </dd>
          </dl>
          {data.startDate ? (
            <dl>
              <dt>Start Time</dt>
              <dd>{localLongDate(data.startDate)}</dd>
            </dl>
          ) : null}
          {data.finishDate ? (
            <dl>
              <dt>End Time (Duration)</dt>
              <dd>
                {localLongDate(data.finishDate) +
                  (data.startDate
                    ? ` (${getDuration(data.finishDate, data.startDate)})`
                    : '')}
              </dd>
            </dl>
          ) : null}
          <dl>
            <dt>Status</dt>
            <dd>{listClassificationStatusStatus[data.state]}</dd>
          </dl>
          <dl>
            <dt>Correlation Id</dt>
            <dd>{data.correlationId}</dd>
          </dl>
          <dl>
            <dt>Full Log</dt>
            <dd>
              <Button size="sm" variant="info" onClick={openLogModal}>
                View full log
              </Button>
            </dd>
          </dl>
          <Loader
            component={ClassificationErrors}
            actionresult={listClassificationErrorsResult}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.cancel}>
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }

  private cancel() {
    const { onClose } = this.props;
    onClose();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  listClassificationErrorsResult:
    state.classifyList.listClassificationErrorsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  onClose: () => dispatch(closeModal()),
  openLogModal: () =>
    dispatch(
      openModal(
        'List Classification Logs',
        ModalContents.CorrelatedLogs,
        {
          position: 'right',
          size: 'xl',
        },
        ownProps.data.correlationId
      )
    ),
  fetchListClassificationErrors: () =>
    dispatch(fetchListClassificationErrors(ownProps.data.id)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ListClassificationDetailsModal);
