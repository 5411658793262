import moment from 'moment';

export const getDuration = (date1?: string, date2?: string) => {
  const d1 = moment(date1 ? moment.utc(date1) : moment());
  const d2 = moment(date2 ? moment.utc(date2) : moment());
  const dur = moment.duration(d1.diff(d2));
  return dur.humanize();
};

export const localLongDate = (date: string) =>
  moment.utc(date).local().format('LLL');

export const localDate = (date: string) =>
  moment.utc(date).local().format('lll');
