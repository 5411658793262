import * as React from 'react';

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { IAccountResult, IActionResult, IConfiguredProvider } from 'types';

import ExternalLogin from './externalLogin';
import ExternalLoginProviders from './externalLoginProviders';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchLoginProviders } from 'slices/accountSlice';

interface IOwnProps {
  data: IAccountResult;
}

interface IStateProps {
  fetchLoginProvidersResult: IActionResult<string[]>;
}

interface IDispatchProps {
  fetchLoginProviders: () => void;
}

interface IProps extends IDispatchProps, IOwnProps, IStateProps {}

export class ExternalLogins extends React.Component<IProps, any> {
  public componentDidMount() {
    const { fetchLoginProviders } = this.props;
    fetchLoginProviders();
  }

  public render() {
    const { data, fetchLoginProvidersResult } = this.props;

    const configuredProviders = data.configuredProviders || [];
    const showRemoveButton =
      data!.hasPassword || configuredProviders.length > 1;
    return (
      <div>
        <h5>
          External logins:
          <Loader
            component={ExternalLoginProviders}
            actionresult={fetchLoginProvidersResult}
          />
        </h5>
        <div>
          {configuredProviders.length ? (
            <Table size="sm" className="mt-2" hover responsive>
              <thead>
                <tr>
                  <th>Provider</th>
                  <th>Login</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.configuredProvidersLayout(
                  configuredProviders,
                  showRemoveButton,
                  data.id
                )}
              </tbody>
            </Table>
          ) : (
            <small className="text-muted mt-1">No associated accounts</small>
          )}
        </div>
      </div>
    );
  }

  private configuredProvidersLayout(
    configuredProviders: any,
    showRemoveButton: boolean,
    userId: string
  ) {
    return configuredProviders.map(
      (provider: IConfiguredProvider, index: number) => {
        return (
          <ExternalLogin
            provider={provider}
            showRemoveButton={showRemoveButton}
            user={userId}
            key={index}
          />
        );
      }
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  fetchLoginProvidersResult: state.account.fetchLoginProvidersResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchLoginProviders: () => dispatch(fetchLoginProviders()),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ExternalLogins);
