import * as React from 'react';

import { IUserResult, ModalContents } from 'types';

import { AnyAction } from 'redux';
import Authorizer from 'utils/authorizer';
import { Button } from 'react-bootstrap';
import CheckIcon from 'mdi-react/CheckIcon';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { loadUser } from 'slices/userSlice';
import { localDate } from 'utils/dateUtils';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  user: IUserResult;
}

interface IStateProps {
  userDisplayTenantColumn: boolean;
  userCanDeleteUser: boolean;
  userCanDeleteUsers: boolean;
  canEditUser: boolean;
}

interface IDispatchProps {
  openEditModal: (userId: string) => void;
  openDeleteModal: (user: IUserResult) => void;
  loadUser: (userId: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class UserRow extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
  }

  public render() {
    const {
      user,
      userDisplayTenantColumn,
      userCanDeleteUser,
      userCanDeleteUsers,
      canEditUser,
    } = this.props;
    const tenantTd = userDisplayTenantColumn ? (
      <td className="align-middle">{user.tenantName}</td>
    ) : null;
    return (
      <tr>
        <td className="align-middle">{user.displayName}</td>
        <td className="align-middle">{user.email}</td>
        <td className="align-middle text-nowrap">
          {user.lastLogin ? localDate(user.lastLogin) : 'Never'}
        </td>
        {tenantTd}
        <td className="align-middle">
          {user.active ? (
            <CheckIcon className="text-success" size="1.4rem" />
          ) : null}
        </td>
        <td className="align-middle">
          {user.isAdmin ? (
            <CheckIcon className="text-success" size="1.4rem" />
          ) : null}
        </td>
        <td className="align-middle">
          {user.confirmed ? (
            <CheckIcon className="text-success" size="1.4rem" />
          ) : null}
        </td>
        <td className="align-middle">
          <Button variant="primary" size="sm" block onClick={this.edit}>
            {canEditUser ? 'Edit' : 'View'}
          </Button>
        </td>
        {userCanDeleteUsers ? (
          <td className="align-middle">
            <Button
              variant="danger"
              size="sm"
              block
              onClick={this.delete}
              disabled={!userCanDeleteUser}
            >
              Delete
            </Button>
          </td>
        ) : null}
      </tr>
    );
  }

  private delete() {
    const { openDeleteModal, user } = this.props;
    openDeleteModal(user);
  }

  private edit() {
    const { openEditModal, user, loadUser } = this.props;
    loadUser(user.id);
    openEditModal(user.id);
  }
}

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    userDisplayTenantColumn: authorizer.userDisplayTenantColumn(),
    userCanDeleteUser: authorizer.canDeleteUser(ownProps.user),
    userCanDeleteUsers: authorizer.canDeleteUsers(),
    canEditUser: authorizer.canEditUser(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openEditModal: (userId: string) =>
    dispatch(
      openModal(
        'Edit User',
        ModalContents.EditUser,
        { position: 'right', size: 'lg' },
        userId
      )
    ),
  openDeleteModal: (user: IUserResult) =>
    dispatch(
      openModal(
        'Delete User',
        ModalContents.DeleteUser,
        { position: 'right', size: 'lg' },
        user
      )
    ),
  loadUser: (userId: string) => dispatch(loadUser(userId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(UserRow);
