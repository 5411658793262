import * as React from 'react';

import AssociatedSite from './associatedSite';
import { IDeployment } from 'types';
import { Table } from 'react-bootstrap';

interface IParams {
  interfaceId: string;
}

interface IProps {
  data: IDeployment[];
  params: IParams;
}

export class AssociatedSites extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { interfaceId } = params;
    if (!data.length) return null;

    const sortedDeployments = data
      .slice()
      .sort((a, b) => a.siteUrl.localeCompare(b.siteUrl));
    const deployments = sortedDeployments.map((deployment, index) => (
      <AssociatedSite
        deployment={deployment}
        interfaceId={interfaceId}
        key={index}
      />
    ));
    return (
      <>
        <hr />
        <h5 className="font-weight-bold">
          SharePoint Online Associated Sites:
        </h5>
        <div className="mh-25">
          <Table size="sm" hover responsive>
            <thead>
              <tr>
                <th>URL</th>
                <th>Version</th>
                <th>Status</th>
                <th>Updated</th>
                <th>Action</th>
                <th>Logs</th>
              </tr>
            </thead>
            <tbody>{deployments}</tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default AssociatedSites;
