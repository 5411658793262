import { Errors, IError } from 'utils/errors';
import { IActionResult, IMenuGroup, IMenuItem } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import NavigationEndpoint from 'endpoints/navigationEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface INavigationState {
  dashboardNavigationResult: IActionResult<IMenuItem[]>;
  navigationTilesResult: IActionResult<IMenuItem[]>;
  menuResult: IActionResult<IMenuGroup[]>;
  userMenuResult: IActionResult<IMenuGroup>;
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    dashboardNavigationResult: { processing: false },
    navigationTilesResult: { processing: false },
    menuResult: { processing: false },
    userMenuResult: { processing: false },
  } as INavigationState,
  reducers: {
    fetchingDashboardNavigation(state) {
      state.dashboardNavigationResult = { processing: true };
    },
    fetchedDashboardNavigation(state, action: PayloadAction<IMenuItem[]>) {
      state.dashboardNavigationResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchDashboardNavigationFailed(state, action: PayloadAction<IError>) {
      state.dashboardNavigationResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingTiles(state) {
      state.navigationTilesResult = { processing: true };
    },
    fetchedTiles(state, action: PayloadAction<IMenuItem[]>) {
      state.navigationTilesResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchTilesFailed(state, action: PayloadAction<IError>) {
      state.navigationTilesResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingMenu(state) {
      state.menuResult = { processing: true };
    },
    fetchedMenu(state, action: PayloadAction<IMenuGroup[]>) {
      state.menuResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchMenuFailed(state, action: PayloadAction<IError>) {
      state.menuResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingUserMenu(state) {
      state.userMenuResult = { processing: true };
    },
    fetchedUserMenu(state, action: PayloadAction<IMenuGroup>) {
      state.userMenuResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchUserMenuFailed(state, action: PayloadAction<IError>) {
      state.userMenuResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchDashboardNavigation = (): AppThunk => async (dispatch) => {
  const endpoint = new NavigationEndpoint();
  dispatch(fetchingDashboardNavigation());
  try {
    const response = await endpoint.getShortcuts();
    var dashboard = response.data[0].sections[0].items;
    dispatch(fetchedDashboardNavigation(dashboard));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchDashboardNavigationFailed(Errors.getError(error)));
  }
};

export const fetchTilesNavigation = (): AppThunk => async (dispatch) => {
  const endpoint = new NavigationEndpoint();
  dispatch(fetchingTiles());
  try {
    const response = await endpoint.getShortcuts();
    var tiles = response.data[0].sections[0].items;
    dispatch(fetchedTiles(tiles));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchTilesFailed(Errors.getError(error)));
  }
};

export const fetchMenuAndTilesNavigation = (): AppThunk => async (dispatch) => {
  const endpoint = new NavigationEndpoint();
  dispatch(fetchingMenu());
  try {
    const response = await endpoint.getMenu();
    dispatch(fetchedMenu(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchMenuFailed(Errors.getError(error)));
  }
};

export const fetchUserMenuNavigation = (): AppThunk => async (dispatch) => {
  const endpoint = new NavigationEndpoint();
  dispatch(fetchingUserMenu());
  try {
    const response = await endpoint.getUserMenu();
    dispatch(fetchedUserMenu(response.data[0]));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchUserMenuFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = navigationSlice;
export const {
  fetchDashboardNavigationFailed,
  fetchedDashboardNavigation,
  fetchingDashboardNavigation,
  fetchTilesFailed,
  fetchedTiles,
  fetchingTiles,
  fetchMenuFailed,
  fetchedMenu,
  fetchingMenu,
  fetchUserMenuFailed,
  fetchedUserMenu,
  fetchingUserMenu,
} = actions;
export default reducer;
