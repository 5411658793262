import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IPopupMessage } from 'types';

interface IMessageState {
  messages: IPopupMessage[];
  lastId: number;
}

const interfaceSlice = createSlice({
  name: 'message',
  initialState: {
    messages: [],
    lastId: 0,
  } as IMessageState,
  reducers: {
    newErrorMessage(state, action: PayloadAction<string>) {
      state.messages.push({ contents: action.payload, id: state.lastId });
      state.lastId += 1;
    },
    messageDismissed(state, action: PayloadAction<number>) {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
    },
  },
});

const { actions, reducer } = interfaceSlice;
export const { newErrorMessage, messageDismissed } = actions;
export default reducer;
