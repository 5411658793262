import * as React from 'react';

import { IActionResult, IServiceBasicInfo, ServerType } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import { Field } from 'redux-form';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import SesIndexSelector from './sesIndexSelector';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchSesIndexes } from 'slices/sesSlice';
import { formValidators } from 'utils/formValidators';
import selectComponent from 'components/common/formComponents/selectComponent';

interface IOwnProps {
  data: IServiceBasicInfo[];
}

interface IStateProps {
  sesIndexesResult: IActionResult<string[]>;
}

interface IDispatchProps {
  fetchSesIndexes: (sesServerId: string) => void;
}

interface IProps extends IOwnProps, IDispatchProps, IStateProps {}

export class SesServerSelector extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onSesChange = this.onSesChange.bind(this);
  }

  public render() {
    const { sesIndexesResult, data } = this.props;
    const sesSeverOptions = data
      .filter((s) => s.serverType === ServerType.SemanticEnhancementService)
      .map((server, index) => (
        <option value={server.serverId} key={index}>
          {server.name}
        </option>
      ));
    return (
      <>
        <Field
          name="sesServerId"
          component={selectComponent}
          label="SES server"
          onChange={this.onSesChange}
          validate={formValidators.required}
        >
          <option value="">Select SES server</option>
          {sesSeverOptions}
        </Field>
        <Loader component={SesIndexSelector} actionresult={sesIndexesResult} />
      </>
    );
  }

  private onSesChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { fetchSesIndexes } = this.props;
    const sesServerId = event.currentTarget.value;
    fetchSesIndexes(sesServerId);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  sesIndexesResult: state.ses.sesIndexesResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchSesIndexes: (sesServerId: string) =>
    dispatch(fetchSesIndexes(sesServerId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SesServerSelector);
