import * as React from 'react';

import { Row, Spinner } from 'react-bootstrap';

interface IProps {
  text?: string;
}

class SmallLoadingSpinner extends React.Component<IProps> {
  public render() {
    const { text } = this.props;
    return (
      <Row className="text-center align-items-center justify-content-center w-100">
        <div>
          <h6 className="text-default">
            <Spinner animation="border" />
          </h6>
          <h6 className="text-default text-xs text-uc">
            {text === undefined ? 'Loading' : text}
          </h6>
        </div>
      </Row>
    );
  }
}

export default SmallLoadingSpinner;
