import * as React from 'react';

interface IProps {
  data: string;
}

class Note extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  }
}

export default Note;
