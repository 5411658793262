import * as React from 'react';

import { ITenancyDetail } from 'types';
import { Table } from 'react-bootstrap';
import TenantRow from './tenantRow';

interface IOwnProps {
  data: ITenancyDetail[];
}

interface IProps extends IOwnProps {}

class TenantSearchResult extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    const tenants = data.slice().sort((a, b) => a.name.localeCompare(b.name));
    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>Current</th>
            <th>Tenant Name</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map((tenant, index) => (
            <TenantRow tenant={tenant} key={index} />
          ))}
        </tbody>
      </Table>
    );
  }
}

export default TenantSearchResult;
