import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceGroup, ISubnet } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createGroup } from 'slices/serviceGroupSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IOwnProps {
  data: ISubnet[];
}

interface IStateProps {
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createGroup: (group: IServiceGroup) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  subnetId: string;
}

export class CreateServiceGroupModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      data: availableSubnets,
      handleSubmit,
      pristine,
      createResult,
    } = this.props;
    const subnetOptions = availableSubnets.map((subnet, index) => (
      <option
        value={subnet.subnetId}
        key={index}
      >{`${subnet.networkName}.${subnet.subnetName}(${subnet.subnetCIDR})@${subnet.regionName}`}</option>
    ));
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="subnetId"
            component={selectComponent}
            label="Network"
            validate={formValidators.required}
          >
            {subnetOptions}
          </Field>
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { createGroup } = this.props;
    const group: IServiceGroup = {
      name: data.name,
      description: data.description,
      subnetId: data.subnetId,
    };

    createGroup(group);
  }
}

const CreateServiceGroupModalForm = reduxForm<IFormData, IProps>({
  form: 'createServiceGroup',
})(CreateServiceGroupModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const { data: availableSubnets } = ownProps;
  return {
    initialValues: {
      subnetId:
        availableSubnets.length !== 0
          ? availableSubnets[0].subnetId
          : undefined,
    },
    form: 'createServiceGroup',
    createResult: state.serviceGroup.createResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createGroup: (group: IServiceGroup) => dispatch(createGroup(group)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateServiceGroupModalForm);
