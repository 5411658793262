import * as React from 'react';

import { Nav } from 'react-bootstrap';
import cookie from 'react-cookies';

interface IProps {}

class SharePointButtons extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.removeCookie = this.removeCookie.bind(this);
  }

  public render() {
    const sharePointUrl = cookie.load('SPHostUrl');
    if (sharePointUrl === undefined) return null;
    return (
      <>
        <Nav.Link
          href={sharePointUrl}
          className="bg-primary p-3 my-n2 d-flex align-items-center text-center"
        >
          Back to SharePoint
        </Nav.Link>
        <Nav.Link
          onClick={this.removeCookie}
          title="Use Cloud without SharePoint context"
          className="bg-danger p-3 my-n2 d-flex align-items-center text-center"
        >
          Exit SharePoint mode
        </Nav.Link>
      </>
    );
  }

  private removeCookie() {
    cookie.remove('SPHostUrl', { path: '/' });
    this.forceUpdate();
  }
}

export default SharePointButtons;
