import * as React from 'react';

import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import { IUserResult } from 'types';
import { Table } from 'react-bootstrap';
import UserRow from './userRow';
import { connect } from 'react-redux';

interface IStateProps {
  userDisplayTenantColumn: boolean;
}

interface IOwnProps {
  data: IUserResult[];
}

interface IProps extends IOwnProps, IStateProps {}

export class UserSearchResult extends React.Component<IProps> {
  public render() {
    const { userDisplayTenantColumn, data } = this.props;
    const users = data
      .slice()
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
    const tenantTh = userDisplayTenantColumn ? <th>Tenant</th> : null;
    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Username</th>
            <th>Last Login</th>
            {tenantTh}
            <th>Enabled</th>
            <th>Admin</th>
            <th>Verified</th>
            <th colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <UserRow user={user} key={index} />
          ))}
        </tbody>
      </Table>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    userDisplayTenantColumn: authorizer.userDisplayTenantColumn(),
  };
};

export default connect<IStateProps, {}, IOwnProps, IReduxState>(
  mapStateToProps
)(UserSearchResult);
