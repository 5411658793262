import * as React from 'react';

import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { IActionResult, ModalContents } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Link } from 'react-router-dom';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import externalLoginProviders from './externalLoginProviders';
import ProgressSemaphoreLogo from 'components/common/progressSemaphoreLogo';
import { fetchLoginProviders } from 'slices/accountSlice';
import { openModal } from 'slices/modalSlice';

interface IStateProps {
  registrationResult: IActionResult<boolean>;
  fetchLoginProvidersResult: IActionResult<string[]>;
}

interface IDispatchProps {
  openModal: () => void;
  fetchLoginProviders: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class RegisterPage extends React.Component<IProps> {
  public componentDidMount() {
    const { fetchLoginProviders } = this.props;
    fetchLoginProviders();
  }

  public render() {
    const {
      openModal,
      registrationResult,
      fetchLoginProvidersResult,
    } = this.props;
    return (
      <Row className="flex-grow-1 justify-content-md-center bg-light align-items-center">
        <Col sm={12} md={6} lg={4}>
          <ProgressSemaphoreLogo />
          {registrationResult.data ? (
            <Alert variant="success">
              <Alert.Heading>
                Almost there... please verify account
              </Alert.Heading>
              <p>
                To verify your account, all you have to do is click the link
                sent to you in the Smartlogic Cloud verification email. You'll
                receive this shortly.
              </p>
              <p>
                If you're not sure whether your account is verified, you can
                find out by signing in. You'll see a message asking you to
                verify your account if it has not yet been verified.
              </p>
              <hr />
              <div className="d-flex justify-content-end">
                <Alert.Link as={Link} to="/">
                  Return to home page
                </Alert.Link>
              </div>
            </Alert>
          ) : (
            <Card className="mb-4 border-0 shadow">
              <Card.Body className="p-5">
                <h2 className="mb-4">Register a new account</h2>
                <div>
                  <Loader
                    component={externalLoginProviders}
                    actionresult={fetchLoginProvidersResult}
                  />
                </div>
                <div className="mt-4 text-right">
                  <Button
                    as={Link}
                    variant="secondary"
                    size="lg"
                    to="/"
                    className="float-left"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="link"
                    size="lg"
                    data-toggle="modal"
                    onClick={openModal}
                  >
                    Click here for email registration
                  </Button>
                </div>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  registrationResult: state.account.registrationResult,
  fetchLoginProvidersResult: state.account.fetchLoginProvidersResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  openModal: () =>
    dispatch(
      openModal('Register a new account', ModalContents.Register, {
        position: 'right',
      })
    ),
  fetchLoginProviders: () => dispatch(fetchLoginProviders()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
