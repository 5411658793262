import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IFeed } from '../types';
import api from './base/api';

export default class RssFeedEndpoint {
  public readonly ADMIN_FEED_URL =
    'https://status.smartlogic.com/pending_rss.php?filter=cloud';
  public readonly USER_FEED_URL = 'https://life.smartlogic.com/feed/';
  public readonly ROOT = 'feed';

  public getAdminFeed(): Promise<AxiosResponse<IFeed>> {
    const url = Endpoint.getUrl([this.ROOT, 'admin']);
    return api.get(url);
  }

  public getUserFeed(): Promise<AxiosResponse<IFeed>> {
    const url = Endpoint.getUrl([this.ROOT, 'user']);
    return api.get(url);
  }
}
