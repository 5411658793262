import { IServiceGroup, ISubnet } from '../types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class ServiceGroupEndpoint {
  public readonly ROOT = 'servicegroup';
  public readonly NETWORKS = 'networks';

  public create(group: IServiceGroup): Promise<AxiosResponse<IServiceGroup>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.post(url, group);
  }

  public update(group: IServiceGroup): Promise<AxiosResponse<IServiceGroup>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.put(url, group);
  }

  public delete(id: string): Promise<AxiosResponse<{ id: string }>> {
    const queryItems = [new QueryItem('id', id)];

    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.delete(url);
  }

  public load(id: string): Promise<AxiosResponse<IServiceGroup>> {
    const queryItems = [new QueryItem('id', id)];

    const url = Endpoint.getUrl([this.ROOT], queryItems);
    return api.get(url);
  }

  public list(): Promise<AxiosResponse<IServiceGroup[]>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.get(url);
  }

  public listNetworks(): Promise<AxiosResponse<ISubnet[]>> {
    const url = Endpoint.getUrl([this.ROOT, this.NETWORKS]);
    return api.get(url);
  }

  public addNetwork(network: ISubnet): Promise<AxiosResponse<string>> {
    const url = Endpoint.getUrl([this.ROOT, this.NETWORKS]);
    return api.post(url, network);
  }
}
