import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export default class RulebaseEndpoint {
  public readonly ROOT = 'rulebase';

  public getAvailableRulebases(
    serverId: string
  ): Promise<AxiosResponse<string[]>> {
    const queryItems = [new QueryItem('serverId', serverId)];
    const url = Endpoint.getUrl([this.ROOT, 'getAvailable'], queryItems);
    return api.get(url);
  }
}
