import * as React from 'react';

import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IProps {
  data: boolean;
}

class EmailConfirmationResult extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    if (data) {
      return (
        <Alert variant="success">
          <Alert.Heading>Confirmed</Alert.Heading>
          <p>
            Your email address has been confirmed. You can now use your e-mail
            and password to sign in.
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Alert.Link as={Link} to="/">
              Go to login page
            </Alert.Link>
          </div>
        </Alert>
      );
    } else {
      return (
        <Alert variant="danger">
          <Alert.Heading>Unable to confirm email</Alert.Heading>
          <p>
            Please note that confirmation links expire after 24 hours. If your
            confirmation has expired please log into Semaphore Cloud to have
            another confirmation link sent to you.
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Alert.Link>Go to login page</Alert.Link>
          </div>
        </Alert>
      );
    }
  }
}

export default EmailConfirmationResult;
