import * as React from 'react';

import { ReactComponent as Logo } from 'assets/semaphore-logo.svg';
import { MdiReactIconComponentType } from 'mdi-react';

interface IProps {
  size?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  className?: string;
}

class SemaphoreLogo extends React.Component<IProps> {
  public static defaultProps = {
    size: '24',
  };

  public render() {
    const { size, onClick, className } = this.props;
    return (
      <Logo
        height={size}
        width={size}
        fill="currentColor"
        onClick={onClick}
        className={className}
      />
    );
  }
}

export default SemaphoreLogo as MdiReactIconComponentType;
