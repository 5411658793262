import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { IActionResult, ITenancyDetail } from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import Feed from './feed';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getCurrentTenant } from 'slices/tenantSlice';
import home from './home';

interface IStateProps {
  currentTenantResult: IActionResult<ITenancyDetail | null>;
}

interface IDispatchProps {
  getCurrentTenant: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class DashboardPage extends React.Component<IProps> {
  public componentDidMount() {
    const { getCurrentTenant } = this.props;
    getCurrentTenant();
  }

  public render() {
    const { currentTenantResult } = this.props;
    return (
      <Row className="flex-grow-1">
        <Col>
          <Container>
            <Loader component={home} actionresult={currentTenantResult} />
          </Container>
        </Col>
        <Feed />
      </Row>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  getCurrentTenant: () => dispatch(getCurrentTenant()),
});

const mapStateToProps = (state: IReduxState): IStateProps => ({
  currentTenantResult: state.tenant.fetchCurrentResult,
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
