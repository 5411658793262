import * as React from 'react';

import { Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { ILogResult, ModalContents } from 'types';
import { change, submit } from 'redux-form';
import { searchNextLogs, setPage } from 'slices/supportSlice';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import ReactTable from 'react-table';
import SupportUtilities from './supportUtilities';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IStateProps {
  items?: ILogResult[];
  nextPageShow: boolean;
  currentPage: number;
  loading: boolean;
}

interface IDispatchProps {
  searchNext: () => void;
  changeField: (name: string, value: any) => void;
  search: () => void;
  openLogModal: (category: string, log: ILogResult) => void;
  setPage: (number: number) => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class SupportTable extends React.Component<IProps> {
  private isLastPage: boolean = true;

  constructor(props: IProps) {
    super(props);
    this.setPaginationProps = this.setPaginationProps.bind(this);
    this.buttonFetchNext = this.buttonFetchNext.bind(this);
    this.setTrProps = this.setTrProps.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
    this.setForm = this.setForm.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
  }

  public render() {
    const { items, currentPage, loading, nextPageShow } = this.props;
    const columns = [
      {
        id: 'logDate',
        Header: 'Date',
        accessor: (row: any) => row.logDate,
        Cell: (row: any) => SupportUtilities.formatDate(row.value),
        width: 150,
      },
      {
        Header: 'Component',
        accessor: 'component',
        width: 132,
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: (row: any) => (
          <Button
            variant="link"
            className="p-0"
            onClick={this.setForm('logLevel', row.value, row.original)}
          >
            {SupportUtilities.getLevelName(row.value)}
          </Button>
        ),
        width: 63,
      },
      {
        Header: 'Category',
        accessor: 'category',
        width: 150,
      },
      {
        Header: 'Message',
        accessor: 'message',
        Cell: (row: any) => {
          const popover = (
            <Popover id={'popover_' + row.index}>
              <Popover.Title>Message</Popover.Title>
              <Popover.Content>
                <div className="text-sm wordwrap">{row.value}</div>
              </Popover.Content>
            </Popover>
          );
          const overlay = (
            <OverlayTrigger
              placement="right"
              overlay={popover}
              trigger={['hover', 'focus']}
            >
              <div className="truncate">{row.value}</div>
            </OverlayTrigger>
          );
          return overlay;
        },
      },
      {
        Header: 'Interface Name',
        accessor: 'interfaceName',
        width: 120,
      },
      {
        Header: 'Correlation Id',
        accessor: 'correlationId',
        Cell: (row: any) => (
          <Button
            variant="link"
            className="p-0"
            onClick={this.setForm('correlationId', row.value, row.original)}
          >
            {row.value}
          </Button>
        ),
        width: 275,
      },
      {
        Header: 'Username',
        accessor: 'username',
        Cell: (row: any) => (
          <Button
            variant="link"
            className="p-0"
            onClick={this.setForm('username', row.value, row.original)}
          >
            {row.value}
          </Button>
        ),
        width: 200,
      },
    ];
    return (
      <Col xs="6" md="9" lg="10" className="p-0">
        <ReactTable
          data={items}
          loading={loading}
          columns={columns}
          showPageSizeOptions={false}
          defaultPageSize={250}
          defaultSorted={[{ id: 'logDate', desc: true }]}
          page={currentPage}
          getTrProps={this.setTrProps}
          style={{ height: 'calc(100vh - 50px)' }}
          NextComponent={this.buttonFetchNext}
          getPaginationProps={this.setPaginationProps}
          onPageChange={this.pageChanged}
          //@ts-ignore --- 6.8.7 version of react-table types doesn't cover it
          renderTotalPagesCount={(pages: number) => (
            <span className="-totalPages">{`${pages}${
              nextPageShow ? ' (incomplete)' : ''
            }`}</span>
          )}
        />
      </Col>
    );
  }

  public setForm(field: string, value: any, item: any) {
    const { changeField, search } = this.props;
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      changeField(field, value);
      setPage(0);
      window.setTimeout(search, 0);
    };
  }

  private setPaginationProps(state: any) {
    this.isLastPage = state.page === state.pages - 1;

    return {};
  }

  private setTrProps(_state: any, rowInfo: any) {
    const { openLogModal } = this.props;
    return {
      className: rowInfo
        ? SupportUtilities.getlevelClass(rowInfo.original.level)
        : '',
      onClick: () => {
        const log = rowInfo.original as ILogResult;
        openLogModal(log.category, log);
      },
    };
  }

  private buttonFetchNext = (props: any) => {
    if (!this.props.nextPageShow || !this.isLastPage) {
      return this.defaultButton(props);
    }
    return (
      <button type="button" onClick={this.fetchNext} className="-btn">
        Retrieve Next Batch
      </button>
    );
  };

  private defaultButton = (props: any) => (
    <button type="button" {...props} className="-btn">
      {props.children}
    </button>
  );

  private fetchNext() {
    const { searchNext } = this.props;
    searchNext();
  }

  private pageChanged(pageIndex: number) {
    const { setPage } = this.props;
    setPage(pageIndex);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  items: state.support.logs,
  loading: state.support.searchResult.processing,
  nextPageShow: state.support.continuationToken != null,
  currentPage: state.support.currentPage,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  searchNext: () => dispatch(searchNextLogs()),
  changeField: (name: string, value: any) =>
    dispatch(change('supportSearch', name, value)),
  search: () => dispatch(submit('supportSearch')),
  openLogModal: (category: string, log: ILogResult) =>
    dispatch(
      openModal(
        category,
        ModalContents.Log,
        {
          position: 'right',
          size: 'lg',
        },
        log
      )
    ),
  setPage: (number: number) => dispatch(setPage(number)),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SupportTable);
