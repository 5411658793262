import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';

import { AnyAction } from 'redux';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import SmallError from 'components/common/smallError';
import SubmitButton from 'components/common/submitButton';
import { ThunkDispatch } from 'redux-thunk';
import { closeModal } from 'slices/modalSlice';
import { connect } from 'react-redux';

interface IOwnProps {
  as: React.ElementType;
  actionResult: IActionResult<any>;
  disabled?: boolean;
  submitVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
  submitMessage?: string;
  submittingMessage?: string;
  cancelMessage?: string;
  cancelVariant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light';
  onSubmit?: () => void;
  noCancel?: boolean;
  noSubmit?: boolean;
  noError?: boolean;
}

interface IDispatchProps {
  onClose: () => void;
}

export interface IProps extends IDispatchProps, IOwnProps {}

export class ModalFormFooter extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  public static defaultProps: Partial<IProps> = {
    submitMessage: 'Save',
    submittingMessage: 'Saving',
    submitVariant: 'primary',
    cancelVariant: 'secondary',
    cancelMessage: 'Cancel',
    as: Modal.Footer,
    noCancel: false,
    noSubmit: false,
    noError: false,
  };

  public render() {
    const {
      disabled,
      actionResult,
      cancelVariant,
      cancelMessage,
      submitVariant,
      submitMessage,
      submittingMessage,
      onSubmit,
      as: Footer,
      noCancel,
      noSubmit,
      noError,
    } = this.props;
    return (
      <Footer>
        {!noError ? <SmallError error={actionResult.error} /> : null}
        {!noCancel ? (
          <Button variant={cancelVariant} onClick={this.cancel}>
            {cancelMessage}
          </Button>
        ) : null}
        {!noSubmit ? (
          <SubmitButton
            inProgress={actionResult.processing}
            disabled={disabled}
            variant={submitVariant}
            defaultMessage={submitMessage}
            progressMessage={submittingMessage}
            onClick={onSubmit}
          />
        ) : null}
      </Footer>
    );
  }

  private cancel() {
    const { onClose } = this.props;
    onClose();
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  onClose: () => dispatch(closeModal()),
});

export default connect<{}, IDispatchProps, IOwnProps, IReduxState>(
  null,
  mapDispatchToProps
)(ModalFormFooter);
