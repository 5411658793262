import * as React from 'react';

import { Form, InjectedFormProps, reduxForm } from 'redux-form';
import {
  IActionResult,
  IServiceBasicInfo,
  ISyncJob,
  ITermStoreData,
} from 'types';

import { AnyAction } from 'redux';
import { ConfigProps } from 'redux-form';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import LoaderWithParams from 'components/common/loaderWithParams';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import TermStoreSelector from './termStoreSelector';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchSesIndexes } from 'slices/sesSlice';
import sesServerSelector from './sesServerSelector';
import { updateSyncJob } from 'slices/termStoreSlice';

interface IOwnProps {
  syncJob: ISyncJob;
  interfaceId: string;
  spHostUrl: string;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
  fetchServicesOfTypeResult: IActionResult<IServiceBasicInfo[]>;
  termStoreResult: IActionResult<ITermStoreData[]>;
}

interface IDispatchProps {
  updateSyncJob: (data: ISyncJob) => void;
  fetchSesIndexes: () => void;
}

export interface IFormData {
  termStoreId: string;
  termStoreGroupId: string;
  sesServerId: string;
  sesIndex: string;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class EditSyncJobModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public componentDidMount() {
    const { fetchSesIndexes } = this.props;
    fetchSesIndexes();
  }

  public render() {
    const {
      updateResult,
      handleSubmit,
      pristine,
      fetchServicesOfTypeResult,
      termStoreResult,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Loader
            component={sesServerSelector}
            actionresult={fetchServicesOfTypeResult}
          />
          <LoaderWithParams
            component={TermStoreSelector}
            actionresult={termStoreResult}
            params={{ formName: 'editSyncJob' }}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { updateSyncJob, syncJob } = this.props;
    const updatedSyncJob: ISyncJob = Object.assign({}, syncJob, {
      sesIndex: data.sesIndex,
      sesServerId: data.sesServerId,
      termStoreId: data.termStoreId,
    });

    updateSyncJob(updatedSyncJob);
  }
}

const EditSyncJobModalForm = reduxForm<IFormData, IProps>({
  form: 'editSyncJob',
})(EditSyncJobModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    updateResult: state.termStore.updateSyncJobResult,
    fetchServicesOfTypeResult: state.service.fetchOfTypeResult,
    termStoreResult: state.termStore.fetchResult,
    initialValues: {
      sesIndex: ownProps.syncJob.sesIndex,
      sesServerId: ownProps.syncJob.sesServerId,
      termStoreGroupId: ownProps.syncJob.termStoreGroupId,
      termStoreId: ownProps.syncJob.termStoreId,
    },
    form: 'editSyncJob',
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  updateSyncJob: (data: ISyncJob) =>
    dispatch(updateSyncJob(data, ownProps.spHostUrl, ownProps.interfaceId)),
  fetchSesIndexes: () =>
    dispatch(fetchSesIndexes(ownProps.syncJob.sesServerId)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditSyncJobModalForm);
