export enum TYPES {
  ANY,
  STRING,
  ODATA,
}
export class QueryItem {
  public static TYPES = TYPES;

  public key: string;
  public value?: string;
  public type: number;

  constructor(key: string, value?: string, type: TYPES = TYPES.ANY) {
    this.key = key;
    this.value = value;
    this.type = type;
  }

  public toString(): string {
    return this.value ? this.key + '=' + this.parsedValue : this.key;
  }
  private get parsedValue(): string | undefined {
    if (this.type === TYPES.STRING) {
      return '"' + this.value + '"';
    } else {
      return this.value;
    }
  }
}
