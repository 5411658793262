import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { IActionResult, IInterfaceConfigured, ModalContents } from 'types';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import SpoInterfaces, { IParams } from './spoInterfaces';

import { AnyAction } from '@reduxjs/toolkit';
import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import PlusIcon from 'mdi-react/PlusIcon';
import SpoInterfaceSubPage from './spoInterfaceSubPage';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { fetchAllSpoInterfaces } from 'slices/spoSlice';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  interfaceId?: string;
}

interface IStateProps {
  fetchAllInterfacesResult: IActionResult<IInterfaceConfigured[]>;
  canManageInterfaces: boolean;
}

interface IDispatchProps {
  fetchAllSpoInterfaces: () => void;
  openCreateModal: () => void;
}

interface IProps extends IOwnProps, IDispatchProps, IStateProps {}

export class SpoInterfacesSubPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.interfaceSubPage = this.interfaceSubPage.bind(this);
  }

  public componentDidMount() {
    const { fetchAllSpoInterfaces } = this.props;
    fetchAllSpoInterfaces();
  }

  public render() {
    const {
      fetchAllInterfacesResult,
      interfaceId,
      openCreateModal,
      canManageInterfaces,
    } = this.props;
    return (
      <Row>
        <Col sm={3}>
          {canManageInterfaces ? (
            <p>
              <Button size="sm" variant="link" onClick={openCreateModal}>
                <PlusIcon size="1.2rem" className="mr-1" />
                Add a SharePoint Interface
              </Button>
            </p>
          ) : null}
          <LoaderWithParams<IInterfaceConfigured[], IParams>
            component={SpoInterfaces}
            actionresult={fetchAllInterfacesResult}
            params={{ interfaceId }}
          />
        </Col>
        <Switch>
          <Route
            exact
            path="/interfaces/spo/:interfaceId"
            render={this.interfaceSubPage}
          />
        </Switch>
      </Row>
    );
  }

  private interfaceSubPage = (
    props: RouteComponentProps<{ interfaceId: string }>
  ) => {
    const { interfaceId } = props.match.params;
    const { fetchAllInterfacesResult } = this.props;
    return (
      <LoaderWithParams
        component={SpoInterfaceSubPage}
        actionresult={fetchAllInterfacesResult}
        params={{ interfaceId }}
      />
    );
  };
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    fetchAllInterfacesResult: state.spo.fetchAllInterfacesResult,
    canManageInterfaces: authorizer.canManageInterfaces(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchAllSpoInterfaces: () => dispatch(fetchAllSpoInterfaces()),
  openCreateModal: () =>
    dispatch(
      openModal('Add SharePoint Interface', ModalContents.CreateSpoInterface, {
        position: 'right',
      })
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SpoInterfacesSubPage);
