import { IModal, IModalMetadata, ModalContents } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';

interface IModalState {
  modals: IModal[];
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modals: [],
  } as IModalState,
  reducers: {
    closed(state) {
      state.modals.pop();
    },
    opened(state, action: PayloadAction<IModal>) {
      state.modals.push(action.payload);
    },
  },
});

export const openModal = (
  title: string,
  contents: ModalContents,
  meta: IModalMetadata,
  data?: any
): AppThunk => async (dispatch) => {
  dispatch(opened({ title, contents, data, meta }));
};

export const closeModal = (): AppThunk => async (dispatch) => {
  dispatch(closed());
};

const { actions, reducer } = modalSlice;
export const { closed, opened } = actions;
export default reducer;
