import {
  IActionResult,
  IDefaultAzureVmValues,
  IDefaultTripleStoreValues,
  IFieldConfigurationGetModel,
  ILanguagePack,
  IModal,
  ISequentialListClassificationDetails,
  IServiceBasicInfo,
  IServiceViewModel,
  ISpoQueryError,
  ISubnet,
  ISyncRun,
  IUserResult,
  ModalContents,
} from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import BapiModal from 'components/pages/bapiHelp/bapiModal';
import ChangePasswordForm from 'components/pages/manage/changePasswordForm';
import CorrelatedLogsModal from 'components/common/correlatedLogsModal';
import CreateContactModalForm from 'components/common/sidePanel/createContactModalForm';
import CreateCsModalForm from 'components/pages/services/createCsModalForm';
import CreateOeModalForm from 'components/pages/services/createOeModalForm';
import CreateSesModalForm from 'components/pages/services/createSesModalForm';
import CreateSpoModalForm from 'components/pages/interfaces/spo/createSpoModalForm';
import CreateStudioModalForm from 'components/pages/services/createStudioModalForm';
import CreateSyncJobModalForm from 'components/pages/interfaces/spo/createSyncJobModalForm';
import CreateTenantModalForm from 'components/pages/tenants/createTenantModalForm';
import CreateUhModalForm from 'components/pages/services/createUhModalForm';
import CreateWbModalForm from 'components/pages/services/createWbModalForm';
import DeleteBapiModal from 'components/pages/interfaces/bapi/deleteBapiModal';
import DeleteListSimConfigModal from 'components/pages/simSettings/deleteListSimConfigModal';
import DeleteServiceGroupModal from 'components/pages/services/deleteServiceGroupModal';
import DeleteServiceModal from 'components/pages/services/deleteServiceModal';
import DeleteSiteDeploymentModal from 'components/pages/interfaces/spo/deleteSiteDeploymentModal';
import DeleteSpoModal from 'components/pages/interfaces/spo/deleteSpoModal';
import DeleteSyncJobModal from 'components/pages/interfaces/spo/deleteSyncJobModal';
import DeleteTenantModal from 'components/pages/tenants/deleteTenantModal';
import DeleteUserModal from '../pages/users/deleteUserModal';
import DeploymentDetailsModal from 'components/pages/interfaces/spo/deploymentDetailsModal';
import EditContactModalForm from 'components/common/sidePanel/editContactModalForm';
import EditCsModalForm from 'components/pages/services/editCsModalForm';
import EditOeModalForm from 'components/pages/services/editOeModalForm';
import EditSesModalForm from 'components/pages/services/editSesModalForm';
import EditSpoModalForm from 'components/pages/interfaces/spo/editSpoModalForm';
import EditStudioModalForm from 'components/pages/services/editStudioModalForm';
import EditSyncJobModalForm from 'components/pages/interfaces/spo/editSyncJobModalForm';
import EditTenantModalForm from 'components/pages/tenants/editTenantModalForm';
import EditTsModalForm from 'components/pages/services/editTsModalForm';
import EditUhModalForm from 'components/pages/services/editUhModalForm';
import EditUserModalForm from 'components/pages/users/editUserModalForm';
import EditWbModalForm from 'components/pages/services/editWbModalForm';
import ExportUsageModalForm from 'components/pages/interfaces/exportUsageModalForm';
import ForgotPasswordModalForm from 'components/pages/authentication/forgotPasswordModalForm';
import { IReduxState } from 'reducers';
import IframeModal from './iframeModal';
import LanguagePacksModal from 'components/pages/services/languagePacksModal';
import LeftMenuModal from './navigation/leftMenuModal';
import ListClassificationDetails from 'components/pages/interfaces/spo/listClassificationDetails';
import Loader from './loader';
import LoaderWithParams from './loaderWithParams';
import LogModal from 'components/pages/support/logModal';
import LoginModalForm from 'components/pages/authentication/loginModalForm';
import Modal from 'react-bootstrap/Modal';
import NotificationDetailsModal from './notifications/notificationDetailsModal';
import React from 'react';
import RegisterModalForm from 'components/pages/authentication/registerModalForm';
import RemoveExternalLogin from 'components/pages/manage/removeExternalLogin';
import SidePanelModal from 'components/common/sidePanel/sidePanelModal';
import SemaphoreLogo from './icons/semaphoreLogo';
import SpoErrorsModal from 'components/pages/interfaces/spo/spoErrorsModal';
import SyncRunDetailsModalConnector from 'components/pages/interfaces/spo/syncRunDetailsModalConnector';
import { ThunkDispatch } from 'redux-thunk';
import WarnApiKeyRegenerationModal from 'components/pages/manage/warnApiKeyRegeneration';
import { closeModal } from 'slices/modalSlice';
import { connect } from 'react-redux';
import createBapiModalForm from 'components/pages/interfaces/bapi/createBapiModalForm';
import createServiceGroupModalForm from 'components/pages/services/createServiceGroupModalForm';
import createTsModalForm from 'components/pages/services/createTsModalForm';
import createVmModalForm from 'components/pages/services/createVmModalForm';
import editBapiModalForm from 'components/pages/interfaces/bapi/editBapiModalForm';
import editContentTypeColumnModalForm from 'components/pages/simSettings/editContentTypeColumnModalForm';
import editListColumnModalForm from 'components/pages/simSettings/editListColumnModalForm';
import editNoteModalForm from 'components/common/sidePanel/editNoteModalForm';
import editServiceGroupModalForm from 'components/pages/services/editServiceGroupModalForm';
import editVmModalForm from 'components/pages/services/editVmModalForm';
import { signInModalOpened } from 'slices/accountSlice';

interface IStateProps {
  modal?: IModal;

  userResult: IActionResult<IUserResult>;
  networksResult: IActionResult<ISubnet[]>;
  defaultVmValuesResult: IActionResult<IDefaultAzureVmValues>;
  defaultTsValuesResult: IActionResult<IDefaultTripleStoreValues>;
  currentSizeResult: IActionResult<string>;
  noteResult: IActionResult<string>;
  fetchFieldResult: IActionResult<IFieldConfigurationGetModel>;
  fetchServicesOfTypeResult: IActionResult<IServiceBasicInfo[]>;
  fetchSyncRunsResults: { [jobId: string]: IActionResult<ISyncRun[]> };
  fetchAllServicesInAGroupResult: IActionResult<IServiceViewModel[]>;
  listClassificationDetailsResult: IActionResult<ISequentialListClassificationDetails>;
  sharePointErrorsResult: IActionResult<ISpoQueryError[]>;
  languagePacksResult: IActionResult<ILanguagePack[]>;
}

interface IDispatchProps {
  onClose: () => void;
  signInModalOpened: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class ModalContentsSelector extends React.Component<IProps, any> {
  public render() {
    const {
      modal,
      onClose,
      userResult,
      networksResult,
      defaultVmValuesResult,
      defaultTsValuesResult,
      currentSizeResult,
      noteResult,
      fetchFieldResult,
      fetchServicesOfTypeResult,
      fetchSyncRunsResults,
      fetchAllServicesInAGroupResult,
      signInModalOpened,
      listClassificationDetailsResult,
      languagePacksResult,
      sharePointErrorsResult,
    } = this.props;

    if (!modal) return null;

    const { contents, title, data, meta } = modal;

    let modalBody = null;
    let modalTitle: any = title;
    switch (contents) {
      case ModalContents.Empty:
        modalBody = data;
        break;
      case ModalContents.Iframe:
        modalBody = <IframeModal url={data} />;
        break;
      case ModalContents.CreateCs:
        modalBody = (
          <LoaderWithParams
            component={CreateCsModalForm}
            actionresult={fetchAllServicesInAGroupResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditCs:
        modalBody = <EditCsModalForm service={data} />;
        break;
      case ModalContents.CreateOe:
        modalBody = (
          <LoaderWithParams
            component={CreateOeModalForm}
            actionresult={fetchAllServicesInAGroupResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditOe:
        modalBody = <EditOeModalForm service={data} />;
        break;
      case ModalContents.CreateSes:
        modalBody = (
          <LoaderWithParams
            component={CreateSesModalForm}
            actionresult={fetchAllServicesInAGroupResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditSes:
        modalBody = <EditSesModalForm service={data} />;
        break;
      case ModalContents.CreateTs:
        modalBody = (
          <LoaderWithParams
            component={createTsModalForm}
            actionresult={defaultTsValuesResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditTs:
        modalBody = <EditTsModalForm service={data} />;
        break;
      case ModalContents.CreteWb:
        modalBody = (
          <LoaderWithParams
            component={CreateWbModalForm}
            actionresult={fetchAllServicesInAGroupResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditWb:
        modalBody = <EditWbModalForm service={data} />;
        break;
      case ModalContents.CreateUh:
        modalBody = <CreateUhModalForm groupId={data} />;
        break;
      case ModalContents.EditUh:
        modalBody = <EditUhModalForm service={data} />;
        break;
      case ModalContents.CreateStudio:
        modalBody = (
          <LoaderWithParams
            component={CreateStudioModalForm}
            actionresult={fetchAllServicesInAGroupResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditStudio:
        modalBody = <EditStudioModalForm service={data} />;
        break;
      case ModalContents.CreateVm:
        modalBody = (
          <LoaderWithParams
            component={createVmModalForm}
            actionresult={defaultVmValuesResult}
            params={{ groupId: data }}
          />
        );
        break;
      case ModalContents.EditVm:
        modalBody = (
          <LoaderWithParams
            component={editVmModalForm}
            actionresult={currentSizeResult}
            params={{ service: data }}
          />
        );
        break;
      case ModalContents.DeleteService:
        modalBody = <DeleteServiceModal service={data} />;
        break;
      case ModalContents.ListColumnSettings:
        modalBody = (
          <LoaderWithParams
            component={editListColumnModalForm}
            actionresult={fetchFieldResult}
            params={data}
          />
        );
        break;
      case ModalContents.ContentTypeColumnSettings:
        modalBody = (
          <LoaderWithParams
            component={editContentTypeColumnModalForm}
            actionresult={fetchFieldResult}
            params={data}
          />
        );
        break;
      case ModalContents.Login:
        signInModalOpened();
        modalBody = <LoginModalForm />;
        break;
      case ModalContents.Register:
        modalBody = <RegisterModalForm />;
        break;
      case ModalContents.DeleteServiceGroup:
        modalBody = <DeleteServiceGroupModal group={data} />;
        break;
      case ModalContents.EditServiceGroup:
        modalBody = (
          <LoaderWithParams
            actionresult={networksResult}
            component={editServiceGroupModalForm}
            params={{ group: data }}
          />
        );
        break;
      case ModalContents.CreateServiceGroup:
        modalBody = (
          <Loader
            actionresult={networksResult}
            component={createServiceGroupModalForm}
          />
        );
        break;
      case ModalContents.EditUser:
        modalBody = (
          <Loader actionresult={userResult} component={EditUserModalForm} />
        );
        break;
      case ModalContents.DeleteUser:
        modalBody = <DeleteUserModal user={data} />;
        break;
      case ModalContents.DeleteTenant:
        modalBody = <DeleteTenantModal tenant={data} />;
        break;
      case ModalContents.CreateTenant:
        modalBody = <CreateTenantModalForm />;
        break;
      case ModalContents.EditTenant:
        modalBody = <EditTenantModalForm tenant={data} />;
        break;
      case ModalContents.Log:
        modalBody = <LogModal log={data} />;
        break;
      case ModalContents.Menu:
        modalTitle = (
          <SemaphoreLogo onClick={onClose} size="2rem" className="my-n3" />
        );
        modalBody = <LeftMenuModal />;
        break;
      case ModalContents.Notification:
        modalBody = <NotificationDetailsModal notification={data} />;
        break;
      case ModalContents.CreateContact:
        modalBody = <CreateContactModalForm />;
        break;
      case ModalContents.EditContact:
        modalBody = <EditContactModalForm contact={data} />;
        break;
      case ModalContents.EditNote:
        modalBody = (
          <Loader component={editNoteModalForm} actionresult={noteResult} />
        );
        break;
      case ModalContents.CreateBapiInterface:
        modalBody = (
          <Loader
            component={createBapiModalForm}
            actionresult={fetchServicesOfTypeResult}
          />
        );
        break;
      case ModalContents.EditBapiInterface:
        modalBody = (
          <LoaderWithParams
            component={editBapiModalForm}
            actionresult={fetchServicesOfTypeResult}
            params={{ bapiInterface: data }}
          />
        );
        break;
      case ModalContents.CreateSpoInterface:
        modalBody = <CreateSpoModalForm />;
        break;
      case ModalContents.EditSpoInterface:
        modalBody = <EditSpoModalForm spoInterface={data} />;
        break;
      case ModalContents.DeleteBapiInterface:
        modalBody = <DeleteBapiModal bapiInterface={data} />;
        break;
      case ModalContents.DeleteSpoInterface:
        modalBody = <DeleteSpoModal spoInterface={data} />;
        break;
      case ModalContents.CreateTermStoreSyncJob:
        modalBody = (
          <CreateSyncJobModalForm
            interfaceId={data.interfaceId}
            spHostUrl={data.spHostUrl}
            spoSubscriptionUrl={data.spoSubscriptionUrl}
          />
        );
        break;
      case ModalContents.EditTermStoreSyncJob:
        modalBody = (
          <EditSyncJobModalForm
            syncJob={data.syncJob}
            spHostUrl={data.spHostUrl}
            interfaceId={data.interfaceId}
          />
        );
        break;
      case ModalContents.SyncRunDetails:
        modalBody = (
          <LoaderWithParams
            component={SyncRunDetailsModalConnector}
            actionresult={fetchSyncRunsResults[data.syncJobId] || {}}
            params={{ syncRunId: data.syncRunId }}
          />
        );
        break;
      case ModalContents.CorrelatedLogs:
        modalBody = <CorrelatedLogsModal correlationId={data} />;
        break;
      case ModalContents.SidePanel:
        modalBody = <SidePanelModal />;
        break;
      case ModalContents.DeleteSyncJob:
        modalBody = (
          <DeleteSyncJobModal
            syncJob={data.syncJob}
            interfaceId={data.interfaceId}
          />
        );
        break;
      case ModalContents.DeploymentDetails:
        modalBody = <DeploymentDetailsModal deployment={data} />;
        break;
      case ModalContents.DeleteDeployment:
        modalBody = (
          <DeleteSiteDeploymentModal
            deployment={data.site}
            interfaceId={data.interfaceId}
          />
        );
        break;
      case ModalContents.UsageReport:
        modalBody = (
          <ExportUsageModalForm
            interfaceId={data.interfaceId}
            interfaceType={data.interfaceType}
          />
        );
        break;
      case ModalContents.ChangePassword:
        modalBody = <ChangePasswordForm />;
        break;
      case ModalContents.RegenerateApiKey:
        modalBody = <WarnApiKeyRegenerationModal />;
        break;
      case ModalContents.RemoveExternalLogin:
        modalBody = (
          <RemoveExternalLogin provider={data.provider} user={data.user} />
        );
        break;
      case ModalContents.ResetPassword:
        modalBody = <ForgotPasswordModalForm />;
        break;
      case ModalContents.ListClassificationDetails:
        modalBody = (
          <Loader
            component={ListClassificationDetails}
            actionresult={listClassificationDetailsResult}
          />
        );
        break;
      case ModalContents.BapiHelp:
        modalBody = <BapiModal interfaceId={data} />;
        break;
      case ModalContents.DeleteListSimConfig:
        modalBody = (
          <DeleteListSimConfigModal
            fieldId={data.fieldId}
            siteLibraryId={data.siteLibraryId}
          />
        );
        break;
      case ModalContents.LanguagePacks:
        modalBody = (
          <Loader
            component={LanguagePacksModal}
            actionresult={languagePacksResult}
          />
        );
        break;
      case ModalContents.SpoErrors:
        modalBody = (
          <Loader
            component={SpoErrorsModal}
            actionresult={sharePointErrorsResult}
          />
        );
        break;
    }

    return (
      <Modal show size={meta.size} onHide={onClose} className={meta.position}>
        <Modal.Header
          closeButton
          className="bg-primary text-white p-3 d-flex align-items-center"
        >
          {modalTitle}
        </Modal.Header>
        {modalBody}
      </Modal>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  modal: state.modal.modals[state.modal.modals.length - 1],
  userResult: state.user.userResult,
  networksResult: state.subnet.fetchAllResult,
  defaultVmValuesResult: state.vm.defaultVmValuesResult,
  defaultTsValuesResult: state.ts.defaultTsValuesResult,
  currentSizeResult: state.vm.currentSizeResult,
  noteResult: state.tenantSettings.noteResult,
  fetchFieldResult: state.simSettings.fetchFieldResult,
  fetchServicesOfTypeResult: state.service.fetchOfTypeResult,
  fetchSyncRunsResults: state.termStore.fetchSyncRunsResults,
  fetchAllServicesInAGroupResult: state.service.fetchAllInAGroupResult,
  listClassificationDetailsResult:
    state.classifyList.listClassificationDetailsResult,
  languagePacksResult: state.vm.languagePacksResult,
  sharePointErrorsResult: state.sharePointErrors.sharePointErrorsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  onClose: () => dispatch(closeModal()),
  signInModalOpened: () => dispatch(signInModalOpened()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ModalContentsSelector);
