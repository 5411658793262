import * as React from 'react';

import { fetchContacts, fetchNote } from 'slices/tenantSettingsSlice';

import { AnyAction } from '@reduxjs/toolkit';
import AssignmentIcon from 'mdi-react/AssignmentIcon';
import Authorizer from 'utils/authorizer';
import { IReduxState } from 'reducers';
import { ModalContents } from 'types';
import { Nav } from 'react-bootstrap';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IState {
  isOpen: boolean;
}

interface IStateProps {
  canSeeTenantNote: boolean;
}

interface IDispatchProps {
  fetchContacts: () => void;
  fetchNote: () => void;
  openSidePanel: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class SidePanelSwitch extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  public render() {
    return (
      <Nav.Link
        onClick={this.openModal}
        className="bg-primary p-3 my-n2 d-flex"
      >
        <AssignmentIcon size="1.5rem" />
      </Nav.Link>
    );
  }

  private openModal() {
    const {
      fetchContacts,
      fetchNote,
      openSidePanel,
      canSeeTenantNote,
    } = this.props;
    if (canSeeTenantNote) {
      fetchNote();
    }
    fetchContacts();
    openSidePanel();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    canSeeTenantNote: authorizer.canSeeTenantNote(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  fetchContacts: () => dispatch(fetchContacts()),
  fetchNote: () => dispatch(fetchNote()),
  openSidePanel: () =>
    dispatch(
      openModal('Contacts and Notes', ModalContents.SidePanel, {
        position: 'right',
        size: 'sm',
      })
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SidePanelSwitch);
