import { Errors, IError } from 'utils/errors';
import {
  IActionResult,
  ISyncJob,
  ISyncRun,
  ITermStoreData,
  ITermStoreSyncJob,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import TermStoreEndpoint from 'endpoints/termStoreEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ITermStoreState {
  fetchResult: IActionResult<ITermStoreData[]>;
  fetchSyncJobsResult: IActionResult<ITermStoreSyncJob[]>;
  fetchSyncRunsResults: { [jobId: string]: IActionResult<ISyncRun[]> };
  createSyncJobResult: IActionResult<boolean>;
  updateSyncJobResult: IActionResult<boolean>;
  deleteSyncJobResult: IActionResult<boolean>;
  queueSyncResult: IActionResult<boolean>;
  cancelSyncResult: IActionResult<boolean>;
  fetchSyncRunResult: IActionResult<ISyncRun>;
  fetchSyncJobResult: IActionResult<ITermStoreSyncJob>;
}

const termStoreSlice = createSlice({
  name: 'termStore',
  initialState: {
    fetchResult: { processing: false },
    fetchSyncJobsResult: { processing: false },
    fetchSyncRunsResults: {},
    createSyncJobResult: { processing: false },
    updateSyncJobResult: { processing: false },
    deleteSyncJobResult: { processing: false },
    queueSyncResult: { processing: false },
    cancelSyncResult: { processing: false },
    fetchSyncRunResult: { processing: false },
    fetchSyncJobResult: { processing: false },
  } as ITermStoreState,
  reducers: {
    cleanSyncJobs(state) {
      state.fetchSyncJobsResult = { processing: false };
    },
    fetching(state) {
      state.fetchResult = { processing: true };
    },
    fetched(state, action: PayloadAction<ITermStoreData[]>) {
      state.fetchResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.fetchResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingSyncJobs(state) {
      state.fetchSyncJobsResult = { processing: true };
    },
    fetchedSyncJobs(state, action: PayloadAction<ITermStoreSyncJob[]>) {
      state.fetchSyncJobsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchSyncJobsFailed(state, action: PayloadAction<IError>) {
      state.fetchSyncJobsResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingSyncRuns(state, action: PayloadAction<string>) {
      state.fetchSyncRunsResults[action.payload] = { processing: true };
    },
    fetchedSyncRuns(
      state,
      action: PayloadAction<{ jobId: string; response: ISyncRun[] }>
    ) {
      state.fetchSyncRunsResults[action.payload.jobId] = {
        processing: false,
        data: action.payload.response,
      };
    },
    fetchSyncRunsFailed(
      state,
      action: PayloadAction<{ jobId: string; error: IError }>
    ) {
      state.fetchSyncRunsResults[action.payload.jobId] = {
        processing: false,
        error: action.payload.error,
      };
    },
    deletingSyncJob(state) {
      state.deleteSyncJobResult = { processing: true };
    },
    deletedSyncJob(state, action: PayloadAction<string>) {
      state.deleteSyncJobResult = { processing: false, data: true };
      if (Array.isArray(state.fetchSyncJobsResult.data)) {
        state.fetchSyncJobsResult.data = state.fetchSyncJobsResult.data.filter(
          (i) => i.id !== action.payload
        );
      }
    },
    deleteSyncJobFailed(state, action: PayloadAction<IError>) {
      state.deleteSyncJobResult = {
        processing: false,
        error: action.payload,
      };
    },
    creatingSyncJob(state) {
      state.createSyncJobResult = { processing: true };
    },
    createdSyncJob(state, action: PayloadAction<ITermStoreSyncJob>) {
      state.createSyncJobResult = { processing: false, data: true };
      if (Array.isArray(state.fetchSyncJobsResult.data)) {
        state.fetchSyncJobsResult.data.push(action.payload);
      }
    },
    createSyncJobFailed(state, action: PayloadAction<IError>) {
      state.createSyncJobResult = {
        processing: false,
        error: action.payload,
      };
    },
    updatingSyncJob(state) {
      state.updateSyncJobResult = { processing: true };
    },
    updatedSyncJob(state, action: PayloadAction<ITermStoreSyncJob>) {
      state.updateSyncJobResult = { processing: false, data: true };
      if (Array.isArray(state.fetchSyncJobsResult.data)) {
        state.fetchSyncJobsResult.data = [
          ...state.fetchSyncJobsResult.data.filter(
            (i) => i.id !== action.payload.id
          ),
          action.payload,
        ];
      }
    },
    updateSyncJobFailed(state, action: PayloadAction<IError>) {
      state.updateSyncJobResult = {
        processing: false,
        error: action.payload,
      };
    },
    queuingSyncJob(state) {
      state.queueSyncResult = { processing: true };
    },
    queuedSyncJob(
      state,
      action: PayloadAction<{ jobId: string; syncRun: ISyncRun }>
    ) {
      state.queueSyncResult = { processing: false, data: true };
      if (state.fetchSyncRunsResults[action.payload.jobId]) {
        state.fetchSyncRunsResults[action.payload.jobId].data!.push(
          action.payload.syncRun
        );
      }
    },
    queueSyncJobFailed(state, action: PayloadAction<IError>) {
      state.queueSyncResult = {
        processing: false,
        error: action.payload,
      };
    },
    cancelingSyncJob(state) {
      state.cancelSyncResult = { processing: true };
    },
    canceledSyncJob(
      state,
      action: PayloadAction<{ jobId: string; syncRun: ISyncRun }>
    ) {
      state.cancelSyncResult = { processing: false, data: true };
      if (state.fetchSyncRunsResults[action.payload.jobId]) {
        const index = state.fetchSyncRunsResults[
          action.payload.jobId
        ].data!.findIndex((r) => r.id === action.payload.syncRun.id);
        if (index !== -1) {
          state.fetchSyncRunsResults[action.payload.jobId].data![index] =
            action.payload.syncRun;
        }
      }
    },
    cancelSyncJobFailed(state, action: PayloadAction<IError>) {
      state.cancelSyncResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingSyncJob(state) {
      state.fetchSyncJobResult = { processing: true };
    },
    fetchedSyncJob(state, action: PayloadAction<ITermStoreSyncJob>) {
      state.fetchSyncJobResult = { processing: false, data: action.payload };
      if (Array.isArray(state.fetchSyncJobsResult.data)) {
        const index = state.fetchSyncJobsResult.data.findIndex(
          (j) => j.id === action.payload.id
        );
        state.fetchSyncJobsResult.data[index] = action.payload;
      }
    },
    fetchSyncJobFailed(state, action: PayloadAction<IError>) {
      state.fetchSyncJobResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingSyncRun(state) {
      state.fetchSyncRunResult = { processing: true };
    },
    fetchedSyncRun(
      state,
      action: PayloadAction<{ jobId: string; syncRun: ISyncRun }>
    ) {
      state.fetchSyncRunResult = {
        processing: false,
        data: action.payload.syncRun,
      };
      const index = state.fetchSyncRunsResults[
        action.payload.jobId
      ].data?.findIndex((r) => r.id === action.payload.syncRun.id);
      if (index !== undefined && index !== -1) {
        state.fetchSyncRunsResults[action.payload.jobId].data![index] =
          action.payload.syncRun;
      }
    },
    fetchSyncRunFailed(state, action: PayloadAction<IError>) {
      state.fetchSyncRunResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchTermStores = (
  interfaceId: string,
  siteUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(fetching());
  try {
    const response = await endpoint.loadTermstores(siteUrl);
    dispatch(fetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

export const createSyncJob = (
  data: ISyncJob,
  interfaceId: string,
  siteUrl: string,
  spoSubscriptionUrl: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(creatingSyncJob());
  try {
    const response = await endpoint.createJob(
      data,
      spoSubscriptionUrl,
      siteUrl
    );
    dispatch(createdSyncJob(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(createSyncJobFailed(Errors.getError(error)));
  }
};

export const updateSyncJob = (
  data: ISyncJob,
  siteUrl: string,
  interfaceId: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(updatingSyncJob());
  try {
    const response = await endpoint.updateJob(data, siteUrl);
    dispatch(updatedSyncJob(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateSyncJobFailed(Errors.getError(error)));
  }
};

export const deleteSyncJob = (
  jobId: string,
  interfaceId: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(deletingSyncJob());
  try {
    await endpoint.deleteJob(jobId);
    dispatch(deletedSyncJob(jobId));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteSyncJobFailed(Errors.getError(error)));
  }
};

export const fetchSyncJobs = (
  spoSubscriptionUrl: string,
  interfaceId: string,
  siteUrl?: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(fetchingSyncJobs());
  try {
    const response = await endpoint.getJobs(spoSubscriptionUrl, siteUrl);
    dispatch(fetchedSyncJobs(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchSyncJobsFailed(Errors.getError(error)));
  }
};

export const fetchSyncRuns = (
  jobId: string,
  interfaceId: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(fetchingSyncRuns(jobId));
  try {
    const response = await endpoint.getJobRuns(jobId);
    dispatch(fetchedSyncRuns({ jobId, response: response.data }));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchSyncRunsFailed({ jobId, error: Errors.getError(error) }));
  }
};

export const queueTermStoreSync = (
  interfaceId: string,
  siteUrl: string,
  jobId: string,
  forced: boolean = false
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(queuingSyncJob());
  try {
    const response = await endpoint.queueSync(jobId, siteUrl, forced);
    dispatch(queuedSyncJob({ jobId, syncRun: response.data }));
    dispatch(fetchTermStoreSyncJob(interfaceId, siteUrl, jobId));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(queueSyncJobFailed(Errors.getError(error)));
  }
};

export const cancelTermStoreSync = (
  interfaceId: string,
  jobId: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(cancelingSyncJob());
  try {
    const response = await endpoint.cancelSync(jobId);
    dispatch(canceledSyncJob({ jobId, syncRun: response.data }));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(cancelSyncJobFailed(Errors.getError(error)));
  }
};

export const fetchTermStoreSyncRun = (
  interfaceId: string,
  jobId: string,
  runId: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(fetchingSyncRun());
  try {
    const response = await endpoint.getJobRun(runId);
    dispatch(fetchedSyncRun({ jobId, syncRun: response.data }));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchSyncRunFailed(Errors.getError(error)));
  }
};

export const fetchTermStoreSyncJob = (
  interfaceId: string,
  siteUrl: string,
  jobId: string
): AppThunk => async (dispatch) => {
  const endpoint = new TermStoreEndpoint(interfaceId);
  dispatch(fetchingSyncJob());
  try {
    const response = await endpoint.getJob(jobId, siteUrl);
    dispatch(fetchedSyncJob(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchSyncJobFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = termStoreSlice;
export const {
  fetchFailed,
  fetched,
  fetching,
  createSyncJobFailed,
  createdSyncJob,
  creatingSyncJob,
  deleteSyncJobFailed,
  deletedSyncJob,
  deletingSyncJob,
  fetchSyncJobsFailed,
  fetchedSyncJobs,
  fetchingSyncJobs,
  updateSyncJobFailed,
  updatedSyncJob,
  updatingSyncJob,
  fetchSyncRunsFailed,
  fetchedSyncRuns,
  fetchingSyncRuns,
  cancelSyncJobFailed,
  canceledSyncJob,
  cancelingSyncJob,
  queueSyncJobFailed,
  queuedSyncJob,
  queuingSyncJob,
  fetchSyncJobFailed,
  fetchedSyncJob,
  fetchingSyncJob,
  fetchSyncRunFailed,
  fetchedSyncRun,
  fetchingSyncRun,
  cleanSyncJobs,
} = actions;
export default reducer;
