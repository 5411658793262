import { ISupportQuery, ISupportResult } from 'types';

import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import api from './base/api';

export default class SupportEndpoint {
  public readonly ROOT = 'support';

  public search(form?: ISupportQuery): Promise<AxiosResponse<ISupportResult>> {
    const url = Endpoint.getUrl([this.ROOT]);
    return api.post(url, form);
  }

  public export(form?: ISupportQuery): Promise<AxiosResponse<File>> {
    const url = Endpoint.getUrl([this.ROOT, 'export']);
    return api.post(url, form);
  }

  public categories(): Promise<AxiosResponse<string[]>> {
    const url = Endpoint.getUrl([this.ROOT, 'categories']);
    return api.get(url);
  }

  public getLogsByCorrelationId(
    correlationId: string
  ): Promise<AxiosResponse<ISupportResult>> {
    const url = Endpoint.getUrl([this.ROOT, correlationId]);
    return api.get(url);
  }
}
