import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import {
  IActionResult,
  IInterfaceConfigured,
  IServiceBasicInfo,
  ModalContents,
} from 'types';

import { AnyAction } from '@reduxjs/toolkit';
import AssessmentIcon from 'mdi-react/AssessmentIcon';
import Authorizer from 'utils/authorizer';
import BapiDetails from './bapiDetails';
import BapiOptions from './bapiOptions';
import CloseIcon from 'mdi-react/CloseIcon';
import { IReduxState } from 'reducers';
import InterfaceDetails from '../interfaceDetails';
import LoaderWithParams from 'components/common/loaderWithParams';
import PencilIcon from 'mdi-react/PencilIcon';
import RoundButton from 'components/common/roundButton';
import { ThunkDispatch } from 'redux-thunk';
import UsageData from '../usageData';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';

interface IOwnProps {
  data: IInterfaceConfigured;
}

interface IStateProps {
  fetchOfTypeResult: IActionResult<IServiceBasicInfo[]>;
  canManageInterfaces: boolean;
}

interface IDispatchProps {
  openDeleteModal: () => void;
  openEditModal: () => void;
  openExportModal: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class BapiInterface extends React.Component<IProps> {
  public render() {
    const {
      data,
      openDeleteModal,
      openEditModal,
      fetchOfTypeResult,
      openExportModal,
      canManageInterfaces,
    } = this.props;

    return (
      <Col sm={9}>
        <RoundButton
          icon={CloseIcon}
          disabled={!canManageInterfaces}
          onClick={openDeleteModal}
          variant="danger"
          className="float-right"
          title="Delete interface"
        />
        <RoundButton
          icon={PencilIcon}
          disabled={!canManageInterfaces}
          onClick={openEditModal}
          variant="primary"
          className="float-right mr-1"
          title="Edit interface"
        />
        <RoundButton
          icon={AssessmentIcon}
          onClick={openExportModal}
          variant="info"
          className="float-right mr-1"
          title="Export usage data"
        />
        <h2 className="mt-0 mb-5">{data.name}</h2>
        <UsageData interfaceId={data.id!} />
        <InterfaceDetails data={data} />

        <Row className="mt-4">
          <LoaderWithParams
            component={BapiDetails}
            actionresult={fetchOfTypeResult}
            params={{ bapiInterface: data }}
          />
          <BapiOptions bapiInterface={data} />
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    fetchOfTypeResult: state.service.fetchOfTypeResult,
    canManageInterfaces: authorizer.canManageInterfaces(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  openDeleteModal: () =>
    dispatch(
      openModal(
        `Delete Basic API interface?`,
        ModalContents.DeleteBapiInterface,
        { position: 'right' },
        ownProps.data
      )
    ),
  openEditModal: () =>
    dispatch(
      openModal(
        'Edit Basic API Interface',
        ModalContents.EditBapiInterface,
        {
          position: 'right',
        },
        ownProps.data
      )
    ),
  openExportModal: () =>
    dispatch(
      openModal(
        'Export usage data',
        ModalContents.UsageReport,
        {
          position: 'right',
        },
        { interfaceId: ownProps.data.id!, interfaceType: 'bapi' }
      )
    ),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(BapiInterface);
