import * as React from 'react';

import { Col, Container } from 'react-bootstrap';
import { IAccountResult, IActionResult } from 'types';

import { AnyAction } from 'redux';
import Error from 'components/common/error';
import { IReduxState } from 'reducers';
import Routes from 'routes';
import SmallLoadingSpinner from 'components/common/smallLoadingSpinner';
import { ThunkDispatch } from 'redux-thunk';
import UnauthorizedRoutes from 'unauthorizedRoutes';
import { connect } from 'react-redux';
import { fetchAccountDetails } from 'slices/accountSlice';
import { inIframe } from 'utils/iframe';

interface IStateProps {
  accountResult: IActionResult<IAccountResult>;
}

interface IDispatchProps {
  loadAccountDetails: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class Authenticator extends React.Component<IProps> {
  public componentDidMount() {
    this.props.loadAccountDetails();
  }

  public render() {
    const { accountResult } = this.props;
    const { data, error, processing } = accountResult;
    if (error) {
      if (error.status === 401) {
        if (inIframe()) {
          window.parent.postMessage('relogin', '*');
        }
        return <UnauthorizedRoutes />;
      }
      return <Error error={error} />;
    } else if (processing) {
      return (
        <Container fluid className="d-flex flex-column flex-grow-1 vh-100">
          <Col className="d-flex">
            <SmallLoadingSpinner />
          </Col>
        </Container>
      );
    } else if (data) {
      return <Routes />;
    }

    return null;
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  accountResult: state.account.fetchDetailsResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  loadAccountDetails: () => dispatch(fetchAccountDetails()),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Authenticator);
