import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ServerType } from 'types';

interface ServiceTypeState {
  selected?: ServerType;
}

const serviceTypeSlice = createSlice({
  name: 'serviceType',
  initialState: {} as ServiceTypeState,
  reducers: {
    deselect(state) {
      state.selected = undefined;
    },
    select(state, action: PayloadAction<ServerType>) {
      state.selected = action.payload;
    },
  },
});

const { actions, reducer } = serviceTypeSlice;
export const { deselect, select } = actions;
export default reducer;
