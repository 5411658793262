import { Errors, IError } from 'utils/errors';
import { IActionResult, IUsageViewModel } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import ReportingEndpoint from 'endpoints/reportingEndpoint';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IUsageDataState {
  interfaceUsageResult: IActionResult<IUsageViewModel>;
}

const usageDataSlice = createSlice({
  name: 'usageData',
  initialState: {
    interfaceUsageResult: {
      processing: false,
    },
  } as IUsageDataState,
  reducers: {
    fetching(state) {
      state.interfaceUsageResult = { processing: true };
    },
    fetched(state, action: PayloadAction<IUsageViewModel>) {
      state.interfaceUsageResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchFailed(state, action: PayloadAction<IError>) {
      state.interfaceUsageResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchUsageData = (interfaceId: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new ReportingEndpoint();
  dispatch(fetching());
  try {
    const response = await endpoint.getUsageInterfaceLast30Days(interfaceId);
    dispatch(fetched(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = usageDataSlice;
export const { fetchFailed, fetched, fetching } = actions;
export default reducer;
