import * as React from 'react';

import { Row } from 'react-bootstrap';
import SupportSearchForm from './supportSearchForm';
import SupportTable from './supportTable';

interface IProps {}

class SupportPage extends React.Component<IProps> {
  public render() {
    return (
      <Row className="flex-grow-1 mb-2">
        <SupportSearchForm />
        <SupportTable />
      </Row>
    );
  }
}

export default SupportPage;
