import * as React from 'react';

import { Button, Table } from 'react-bootstrap';

import { ITermStoreSyncJob } from 'types';
import SyncJob from './syncJob';

export interface IParams {
  interfaceId: string;
  spHostUrl?: string;
}

interface IOwnProps {
  data: ITermStoreSyncJob[];
  params: IParams;
}

interface IProps extends IOwnProps {}

class TermStoreSyncJobs extends React.Component<IProps> {
  public render() {
    const { data, params } = this.props;
    const { interfaceId, spHostUrl } = params;
    const globalSyncJobs = data
      .filter((syncJob) => !syncJob.isSiteCollectionGroup)
      .map((syncJob, index) => (
        <SyncJob
          interfaceId={interfaceId}
          spHostUrl={spHostUrl}
          data={syncJob}
          key={index}
        />
      ));
    const siteLevelSyncJobs = data
      .filter(
        (syncJob) =>
          !!syncJob.isSiteCollectionGroup &&
          (syncJob.groupName !== '<Unknown>' || !spHostUrl)
      )
      .map((syncJob, index) => (
        <SyncJob
          interfaceId={interfaceId}
          spHostUrl={spHostUrl}
          data={syncJob}
          key={index}
        />
      ));
    const spHostLink = (
      <Button variant="link" href={spHostUrl} className="p-0 text-left">
        {spHostUrl}
      </Button>
    );
    if (!data.length) return null;
    const globalTermGroupJobsTable = globalSyncJobs.length ? (
      <>
        <h6 className="font-weight-bold">Global term groups jobs</h6>
        <Table size="sm" hover>
          <thead>
            <tr>
              <th>Model</th>
              <th>Term Store</th>
              <th>Group</th>
              <th>Last Synced</th>
              <th>Status</th>
              {<th>Action</th>}
              <th>Syncs</th>
            </tr>
          </thead>
          <tbody>{globalSyncJobs}</tbody>
        </Table>
      </>
    ) : null;
    const siteLevelTermGroupJobsTable = siteLevelSyncJobs.length ? (
      <>
        <h6 className="font-weight-bold">
          Site level term groups jobs
          {spHostUrl ? (
            <>
              <span> for </span>
              {spHostLink}
            </>
          ) : null}
        </h6>
        <Table size="sm" hover>
          <thead>
            <tr>
              <th>Model</th>
              <th>Term Store</th>
              <th>Group</th>
              <th>Last Synced</th>
              <th>Status</th>
              {<th>Action</th>}
              <th>Syncs</th>
            </tr>
          </thead>
          <tbody>{siteLevelSyncJobs}</tbody>
        </Table>
      </>
    ) : null;
    return (
      <>
        {globalTermGroupJobsTable}
        {siteLevelTermGroupJobsTable}
      </>
    );
  }
}

export default TermStoreSyncJobs;
