import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IClassificationPreviewResponse } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export class ClassifyItemEndpoint {
  public readonly ROOT = 'sharePointClassification';

  public getPreview(
    interfaceId: string,
    spHostUrl: string,
    listId: string,
    itemId: string
  ): Promise<AxiosResponse<IClassificationPreviewResponse>> {
    const queryItems = [
      new QueryItem('spHostUrl', spHostUrl),
      new QueryItem('listId', listId),
      new QueryItem('itemId', itemId),
    ];
    const url = Endpoint.getUrl([interfaceId, this.ROOT], queryItems);
    return api.get(url);
  }

  public savePreview(
    interfaceId: string,
    spHostUrl: string,
    listId: string,
    itemId: string,
    values: string[]
  ): Promise<AxiosResponse<any>> {
    const queryItems = [new QueryItem('spHostUrl', spHostUrl)];
    const url = Endpoint.getUrl([interfaceId, this.ROOT], queryItems);
    return api.post(url, {
      itemId: parseInt(itemId, 10),
      listId,
      previews: values,
    });
  }
}
