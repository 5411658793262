import * as React from 'react';

import { IHistorySearchResult } from 'types';
import { Table } from 'react-bootstrap';

interface IOwnProps {
  data: IHistorySearchResult;
}

interface IProps extends IOwnProps {}

class NotificationHistory extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    if (data.results.length === 0)
      return <div>Nothing to show yet. Try again later.</div>;
    const logs = data.results.map((log, index) => (
      <tr key={index}>
        <td>{new Date(log.logDate).toLocaleTimeString()}</td>
        <td>{log.message}</td>
      </tr>
    ));
    return (
      <Table hover size="sm">
        <thead>
          <tr>
            <th>Time</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>{logs}</tbody>
      </Table>
    );
  }
}

export default NotificationHistory;
