import * as React from 'react';

import { Button, Spinner } from 'react-bootstrap';

import { AnyAction } from '@reduxjs/toolkit';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { resendConfirmationEmail } from 'slices/accountSlice';

interface IOwnProps {
  email: string;
}

interface IStateProps {
  resendConfirmationEmailResult: IActionResult<boolean>;
}

interface IDispatchProps {
  resendConfirmationEmail: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ResendVerificationEmailButton extends React.Component<IProps> {
  public render() {
    const {
      resendConfirmationEmailResult,
      resendConfirmationEmail,
    } = this.props;
    if (resendConfirmationEmailResult.data) {
      return (
        <Button variant="link" size="sm" className="p-0 text-success" disabled>
          Reset link sent
        </Button>
      );
    }
    if (resendConfirmationEmailResult.processing) {
      return (
        <Button variant="link" size="sm" className="p-0 text-info" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-1"
          />
          <span>Sending</span>
        </Button>
      );
    }

    if (resendConfirmationEmailResult.error) {
      return (
        <Button variant="link" size="sm" className="p-0 text-danger" disabled>
          {resendConfirmationEmailResult.error.subTitle}
        </Button>
      );
    }

    return (
      <Button
        variant="link"
        size="sm"
        className="p-0"
        onClick={resendConfirmationEmail}
      >
        Resend verification email
      </Button>
    );
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => ({
  resendConfirmationEmailResult: state.account.resendConfirmationEmailResult,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  resendConfirmationEmail: () =>
    dispatch(resendConfirmationEmail(ownProps.email)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ResendVerificationEmailButton);
