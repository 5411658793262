import { Errors, IError } from 'utils/errors';
import { IActionResult, ITenantContactDetail } from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import TenantSettingsEndpoint from 'endpoints/tenantSettingsEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface ITenantSettingsState {
  noteResult: IActionResult<string>;
  contactsResult: IActionResult<ITenantContactDetail[]>;
  createContactResult: IActionResult<boolean>;
  deleteContactResult: IActionResult<boolean>;
  updateContactResult: IActionResult<boolean>;
  updateNoteResult: IActionResult<boolean>;
}

const tenantSettingsSlice = createSlice({
  name: 'tenantSettings',
  initialState: {
    noteResult: {
      processing: false,
    },
    contactsResult: {
      processing: false,
    },
    createContactResult: {
      processing: false,
    },
    deleteContactResult: {
      processing: false,
    },
    updateContactResult: {
      processing: false,
    },
    updateNoteResult: {
      processing: false,
    },
  } as ITenantSettingsState,
  reducers: {
    fetchingNote(state) {
      state.noteResult.processing = true;
    },
    fetchedNote(state, action: PayloadAction<string>) {
      state.noteResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchNoteFailed(state, action: PayloadAction<IError>) {
      state.noteResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingContacts(state) {
      state.contactsResult = { processing: true };
    },
    fetchedContacts(state, action: PayloadAction<ITenantContactDetail[]>) {
      state.contactsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchContactsFailed(state, action: PayloadAction<IError>) {
      state.contactsResult = {
        processing: false,
        error: action.payload,
      };
    },
    creatingContact(state) {
      state.createContactResult = { processing: true };
    },
    createdContact(state, action: PayloadAction<ITenantContactDetail>) {
      state.createContactResult = { processing: false, data: true };
      if (Array.isArray(state.contactsResult.data)) {
        state.contactsResult.data.push(action.payload);
      }
    },
    createContactFailed(state, action: PayloadAction<IError>) {
      state.createContactResult = { processing: false, error: action.payload };
    },
    updatingContact(state) {
      state.updateContactResult = { processing: true };
    },
    updatedContact(state, action: PayloadAction<ITenantContactDetail>) {
      state.updateContactResult = { processing: false, data: true };
      if (Array.isArray(state.contactsResult.data)) {
        state.contactsResult.data = [
          ...state.contactsResult.data.filter(
            (u) => u.id !== action.payload.id
          ),
          action.payload,
        ];
      }
    },
    updateContactFailed(state, action: PayloadAction<IError>) {
      state.updateContactResult = { processing: false, error: action.payload };
    },
    deletingContact(state) {
      state.deleteContactResult = { processing: true };
    },
    deletedContact(state, action: PayloadAction<string>) {
      state.deleteContactResult = { processing: false, data: true };
      if (Array.isArray(state.contactsResult.data)) {
        state.contactsResult.data = state.contactsResult.data.filter(
          (t) => t.id !== action.payload
        );
      }
    },
    deleteContactFailed(state, action: PayloadAction<IError>) {
      state.deleteContactResult = { processing: false, error: action.payload };
    },
    updatingNote(state) {
      state.updateNoteResult = { processing: true };
    },
    updatedNote(state, action: PayloadAction<string>) {
      state.updateNoteResult = { processing: false, data: true };
      state.noteResult.data = action.payload;
    },
    updateNoteFailed(state, action: PayloadAction<IError>) {
      state.updateNoteResult = { processing: false, error: action.payload };
    },
  },
});

export const fetchNote = (): AppThunk => async (dispatch) => {
  const endpoint = new TenantSettingsEndpoint();
  dispatch(fetchingNote());
  try {
    const response = await endpoint.getNote();
    dispatch(fetchedNote(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchNoteFailed(Errors.getError(error)));
  }
};

export const fetchContacts = (): AppThunk => async (dispatch) => {
  const endpoint = new TenantSettingsEndpoint();
  dispatch(fetchingContacts());
  try {
    const response = await endpoint.getContacts();
    dispatch(fetchedContacts(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchContactsFailed(Errors.getError(error)));
  }
};

export const deleteContact = (id: string): AppThunk => async (dispatch) => {
  const endpoint = new TenantSettingsEndpoint();
  dispatch(deletingContact());
  try {
    await endpoint.deleteContact(id);
    dispatch(deletedContact(id));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(deleteContactFailed(Errors.getError(error)));
  }
};

export const updateContact = (
  contact: ITenantContactDetail
): AppThunk => async (dispatch) => {
  const endpoint = new TenantSettingsEndpoint();
  dispatch(updatingContact());
  dispatch(closeModal());
  try {
    const result = await endpoint.editContact(contact);
    dispatch(updatedContact(result.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateContactFailed(Errors.getError(error)));
  }
};

export const createContact = (
  contact: ITenantContactDetail
): AppThunk => async (dispatch) => {
  const endpoint = new TenantSettingsEndpoint();
  dispatch(creatingContact());
  try {
    const result = await endpoint.addContact(contact);
    dispatch(createdContact(result.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(createContactFailed(Errors.getError(error)));
  }
};

export const updateNote = (note: string): AppThunk => async (dispatch) => {
  const endpoint = new TenantSettingsEndpoint();
  dispatch(updatingNote());
  try {
    const result = await endpoint.editNote(note);
    dispatch(updatedNote(result.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(updateNoteFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = tenantSettingsSlice;
export const {
  fetchNoteFailed,
  fetchedNote,
  fetchingNote,
  fetchContactsFailed,
  fetchedContacts,
  fetchingContacts,
  createContactFailed,
  createdContact,
  creatingContact,
  deleteContactFailed,
  deletedContact,
  deletingContact,
  updateContactFailed,
  updatedContact,
  updatingContact,
  updateNoteFailed,
  updatedNote,
  updatingNote,
} = actions;
export default reducer;
