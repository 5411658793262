import { Errors, IError } from 'utils/errors';
import {
  IAccountResult,
  IActionResult,
  IConfiguredProvider,
  ILoginForm,
  IPasswordForm,
  IRegisterForm,
  IResetPasswordModel,
} from 'types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AccountEndpoint } from 'endpoints/accountEndpoint';
import { AppThunk } from 'store';
import { Endpoint } from 'endpoints/base/endpoint';
import UsersEndpoint from 'endpoints/usersEndpoint';
import { closeModal } from './modalSlice';
import { redirectToLoginWhenUnauthorized } from 'utils/unauthorized';

interface IAccountState {
  fetchDetailsResult: IActionResult<IAccountResult>;
  registrationResult: IActionResult<boolean>;
  loginResult: IActionResult<boolean>;
  logoutResult: IActionResult<boolean>;
  passwordResult: IActionResult<boolean>;
  apiKeyRegenerationResult: IActionResult<string>;
  apiKeyResult: IActionResult<string>;
  externalLoginResult: IActionResult<boolean>;
  confirmEmailResult: IActionResult<boolean>;
  sendResetPasswordEmailResult: IActionResult<boolean>;
  resetPasswordResult: IActionResult<boolean>;
  resendConfirmationEmailResult: IActionResult<boolean>;
  fetchLoginProvidersResult: IActionResult<string[]>;
}

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    fetchDetailsResult: { processing: false },
    registrationResult: { processing: false },
    loginResult: { processing: false },
    logoutResult: { processing: false },
    passwordResult: { processing: false },
    apiKeyRegenerationResult: { processing: false },
    apiKeyResult: { processing: false },
    externalLoginResult: { processing: false },
    confirmEmailResult: { processing: false },
    sendResetPasswordEmailResult: { processing: false },
    resetPasswordResult: { processing: false },
    resendConfirmationEmailResult: { processing: false },
    fetchLoginProvidersResult: { processing: false },
  } as IAccountState,
  reducers: {
    fetchingLoginProviders(state) {
      state.fetchLoginProvidersResult = { processing: true };
    },
    fetchedLoginProviders(state, action: PayloadAction<string[]>) {
      state.fetchLoginProvidersResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchLoginProvidersFailed(state, action: PayloadAction<IError>) {
      state.fetchLoginProvidersResult = {
        processing: false,
        error: action.payload,
      };
    },
    signInModalOpened(state) {
      state.loginResult = { processing: false };
    },
    fetchingDetails(state) {
      state.fetchDetailsResult = { processing: true };
    },
    fetchedDetails(state, action: PayloadAction<IAccountResult>) {
      state.fetchDetailsResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchDetailsFailed(state, action: PayloadAction<IError>) {
      state.fetchDetailsResult = {
        processing: false,
        error: action.payload,
      };
    },
    loggingIn(state) {
      state.loginResult = { processing: true };
    },
    loggedIn(state) {
      state.loginResult = {
        processing: false,
        data: true,
      };
    },
    logInFailed(state, action: PayloadAction<IError>) {
      state.loginResult = {
        processing: false,
        error: action.payload,
      };
    },
    loggingOut(state) {
      state.logoutResult = { processing: true };
    },
    loggedOut(state) {
      state.logoutResult = {
        processing: false,
        data: true,
      };
    },
    logOutFailed(state, action: PayloadAction<IError>) {
      state.logoutResult = {
        processing: false,
        error: action.payload,
      };
    },
    registering(state) {
      state.registrationResult = { processing: true };
    },
    registered(state) {
      state.registrationResult = {
        processing: false,
        data: true,
      };
    },
    registerFailed(state, action: PayloadAction<IError>) {
      state.registrationResult = {
        processing: false,
        error: action.payload,
      };
    },
    passwordSetting(state) {
      state.passwordResult = { processing: true };
    },
    passwordSet(state) {
      state.passwordResult = {
        processing: false,
        data: true,
      };
    },
    passwordSettingFailed(state, action: PayloadAction<IError>) {
      state.passwordResult = {
        processing: false,
        error: action.payload,
      };
    },
    fetchingApiKey(state) {
      state.apiKeyResult = { processing: true };
    },
    fetchedApiKey(state, action: PayloadAction<string>) {
      state.apiKeyResult = {
        processing: false,
        data: action.payload,
      };
    },
    fetchingApiKeyFailed(state, action: PayloadAction<IError>) {
      state.apiKeyResult = {
        processing: false,
        error: action.payload,
      };
    },
    apiKeyRegenerating(state) {
      state.apiKeyRegenerationResult = { processing: true };
    },
    apiKeyRegenerated(state, action: PayloadAction<string>) {
      state.apiKeyRegenerationResult = {
        processing: false,
        data: action.payload,
      };
      state.apiKeyResult.data = action.payload;
    },
    apiKeyRegenerationFailed(state, action: PayloadAction<IError>) {
      state.apiKeyRegenerationResult = {
        processing: false,
        error: action.payload,
      };
    },
    externalLoginRemoving(state) {
      state.externalLoginResult = { processing: true };
    },
    externalLoginRemoved(state) {
      state.externalLoginResult = {
        processing: false,
        data: true,
      };
    },
    externalLoginRemovalFailed(state, action: PayloadAction<IError>) {
      state.externalLoginResult = {
        processing: false,
        error: action.payload,
      };
    },
    confirmingEmail(state) {
      state.confirmEmailResult = { processing: true };
    },
    confirmedEmail(state, action: PayloadAction<boolean>) {
      state.confirmEmailResult = {
        processing: false,
        data: action.payload,
      };
    },
    confirmEmailFailed(state, action: PayloadAction<IError>) {
      state.confirmEmailResult = {
        processing: false,
        error: action.payload,
      };
    },
    sendingResetPasswordEmail(state) {
      state.sendResetPasswordEmailResult = { processing: true };
    },
    sentResetPasswordEmail(state) {
      state.sendResetPasswordEmailResult = {
        processing: false,
        data: true,
      };
    },
    sendResetPasswordEmailFailed(state, action: PayloadAction<IError>) {
      state.sendResetPasswordEmailResult = {
        processing: false,
        error: action.payload,
      };
    },
    resettingPassword(state) {
      state.resetPasswordResult = { processing: true };
    },
    passwordReset(state) {
      state.resetPasswordResult = {
        processing: false,
        data: true,
      };
    },
    resetPasswordFailed(state, action: PayloadAction<IError>) {
      state.resetPasswordResult = {
        processing: false,
        error: action.payload,
      };
    },
    resendingConfirmationEmail(state) {
      state.resendConfirmationEmailResult = { processing: true };
    },
    resentConfirmationEmail(state) {
      state.resendConfirmationEmailResult = {
        processing: false,
        data: true,
      };
    },
    resendConfirmationEmailFailed(state, action: PayloadAction<IError>) {
      state.resendConfirmationEmailResult = {
        processing: false,
        error: action.payload,
      };
    },
  },
});

export const fetchAccountDetails = (): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(fetchingDetails());
  try {
    const response = await endpoint.getAccount();
    dispatch(fetchedDetails(response.data));
  } catch (error) {
    dispatch(fetchDetailsFailed(Errors.getError(error)));
  }
};

export const logIn = (loginForm: ILoginForm): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(loggingIn());
  try {
    const response = await endpoint.login(loginForm);
    dispatch(loggedIn());
    const values = new URLSearchParams(window.location.search);
    const returnUri = values.get('redirectUrl')!;
    if (returnUri) {
      window.location.href =
        returnUri + (window.location.hash ? window.location.hash : '');
    } else if (response.data) {
      window.location.href = `${Endpoint.backendUrl}${response.data}`;
    } else {
      dispatch(fetchAccountDetails());
      dispatch(closeModal());
    }
  } catch (error) {
    dispatch(logInFailed(Errors.getError(error)));
  }
};

export const logOut = (): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(loggingOut());
  try {
    await endpoint.logout();
    dispatch(loggedOut());
    window.location.href = '/spa/';
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(logOutFailed(Errors.getError(error)));
  }
};

export const register = (registerForm: IRegisterForm): AppThunk => async (
  dispatch
) => {
  const endpoint = new AccountEndpoint();
  dispatch(registering());
  try {
    await endpoint.register(registerForm);
    dispatch(registered());
    dispatch(closeModal());
  } catch (error) {
    dispatch(registerFailed(Errors.getError(error)));
  }
};

export const removeExternalLogin = (
  user: string,
  provider: IConfiguredProvider
): AppThunk => async (dispatch) => {
  const endpoint = new UsersEndpoint();
  dispatch(externalLoginRemoving());
  try {
    await endpoint.deleteProvider(user, provider);
    dispatch(externalLoginRemoved());
    dispatch(fetchAccountDetails());
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(externalLoginRemovalFailed(Errors.getError(error)));
  }
};

export const changePassword = (passwordForm: IPasswordForm): AppThunk => async (
  dispatch
) => {
  const endpoint = new AccountEndpoint();
  dispatch(passwordSetting());
  try {
    await endpoint.changePassword(passwordForm);
    dispatch(passwordSet());
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(passwordSettingFailed(Errors.getError(error)));
  }
};

export const regenerateApiKey = (): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(apiKeyRegenerating());
  try {
    const response = await endpoint.postApiKey();
    dispatch(apiKeyRegenerated(response.data));
    dispatch(closeModal());
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(apiKeyRegenerationFailed(Errors.getError(error)));
  }
};

export const fetchApiKey = (): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(fetchingApiKey());
  try {
    const response = await endpoint.getApiKey();
    dispatch(fetchedApiKey(response.data));
  } catch (error) {
    redirectToLoginWhenUnauthorized(error);
    dispatch(fetchingApiKeyFailed(Errors.getError(error)));
  }
};

export const confirmEmail = (userId: string, code: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new AccountEndpoint();
  dispatch(confirmingEmail());
  try {
    const response = await endpoint.confirmEmail(userId, code);
    dispatch(confirmedEmail(response.data));
  } catch (error) {
    dispatch(confirmEmailFailed(Errors.getError(error)));
  }
};

export const sendResetPasswordEmail = (email: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new AccountEndpoint();
  dispatch(sendingResetPasswordEmail());
  try {
    await endpoint.sendResetPasswordEmail(email);
    dispatch(sentResetPasswordEmail());
  } catch (error) {
    dispatch(sendResetPasswordEmailFailed(Errors.getError(error)));
  }
};

export const resetPassword = (
  userId: string,
  resetPasswordModel: IResetPasswordModel
): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(resettingPassword());
  try {
    await endpoint.resetPassword(userId, resetPasswordModel);
    dispatch(passwordReset());
    window.location.href = '/spa/';
  } catch (error) {
    dispatch(resetPasswordFailed(Errors.getError(error)));
  }
};

export const resendConfirmationEmail = (email: string): AppThunk => async (
  dispatch
) => {
  const endpoint = new AccountEndpoint();
  dispatch(resendingConfirmationEmail());
  try {
    await endpoint.resendConfirmationEmail(email);
    dispatch(resentConfirmationEmail());
  } catch (error) {
    dispatch(resendConfirmationEmailFailed(Errors.getError(error)));
  }
};

export const fetchLoginProviders = (): AppThunk => async (dispatch) => {
  const endpoint = new AccountEndpoint();
  dispatch(fetchingLoginProviders());
  try {
    const response = await endpoint.getLoginProviders();
    dispatch(fetchedLoginProviders(response.data));
  } catch (error) {
    dispatch(fetchLoginProvidersFailed(Errors.getError(error)));
  }
};

const { actions, reducer } = accountSlice;
export const {
  fetchDetailsFailed,
  fetchedDetails,
  fetchingDetails,
  logInFailed,
  logOutFailed,
  loggedIn,
  loggedOut,
  loggingIn,
  loggingOut,
  registerFailed,
  registered,
  registering,
  passwordSettingFailed,
  passwordSet,
  passwordSetting,
  apiKeyRegenerationFailed,
  apiKeyRegenerated,
  apiKeyRegenerating,
  fetchingApiKey,
  fetchedApiKey,
  fetchingApiKeyFailed,
  externalLoginRemoving,
  externalLoginRemoved,
  externalLoginRemovalFailed,
  confirmEmailFailed,
  confirmingEmail,
  confirmedEmail,
  resendConfirmationEmailFailed,
  resendingConfirmationEmail,
  resentConfirmationEmail,
  passwordReset,
  resetPasswordFailed,
  resettingPassword,
  sendResetPasswordEmailFailed,
  sendingResetPasswordEmail,
  sentResetPasswordEmail,
  signInModalOpened,
  fetchLoginProvidersFailed,
  fetchedLoginProviders,
  fetchingLoginProviders,
} = actions;
export default reducer;
