import * as React from 'react';

import { Col, Row } from 'react-bootstrap';

import { AnyAction } from 'redux';
import EmailConfirmationResult from './emailConfirmationResult';
import { IActionResult } from 'types';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import { ThunkDispatch } from 'redux-thunk';
import { confirmEmail } from 'slices/accountSlice';
import { connect } from 'react-redux';

interface IOwnProps {
  userId: string;
}

interface IStateProps {
  confirmEmailResult: IActionResult<boolean>;
}

interface IDispatchProps {
  confirmEmail: (code: string) => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ConfirmEmailPage extends React.Component<IProps> {
  private code: string;

  constructor(props: IProps) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.code = params.get('code')!;
  }

  public componentDidMount() {
    const { confirmEmail } = this.props;
    confirmEmail(this.code);
  }

  public render() {
    const { confirmEmailResult } = this.props;
    return (
      <Row className="flex-grow-1 justify-content-md-center align-items-center">
        <Col sm={12} md={6} lg={4}>
          <Loader
            component={EmailConfirmationResult}
            actionresult={confirmEmailResult}
          />
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  confirmEmail: (code: string) => dispatch(confirmEmail(ownProps.userId, code)),
});

const mapStateToProps = (state: IReduxState): IStateProps => ({
  confirmEmailResult: state.account.confirmEmailResult,
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEmailPage);
