import ProgSmLogo from 'assets/progress-semaphore-logo.png';
import * as React from 'react';
import { Row } from 'react-bootstrap';

export default class ProgressSemaphoreLogo extends React.Component {
  public render() {
    return (
      <Row className="d-flex justify-content-center">
        <div className="mb-4 px-2" style={{ width: 'min(100%, 350px)' }}>
          <img
            src={ProgSmLogo}
            className="img-fluid"
            alt="Progress Semaphore logo"
          />
        </div>
      </Row>
    );
  }
}
