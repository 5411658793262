import { AxiosResponse } from 'axios';
import { Endpoint } from './base/endpoint';
import { IClassificationConfig } from '../types';
import { QueryItem } from './base/queryItem';
import api from './base/api';

export class ClassificationSettingsEndpoint {
  public readonly ROOT = 'requestgeneration';

  public getClassificationSettings(
    interfaceId: string,
    spHostUrl: string,
    listId?: string,
    cTypeId?: string
  ): Promise<AxiosResponse<IClassificationConfig>> {
    const queryItems = [
      new QueryItem('listId', listId),
      new QueryItem('ctype', cTypeId),
      new QueryItem('spHostUrl', spHostUrl),
    ];
    const url = Endpoint.getUrl([interfaceId, this.ROOT], queryItems);
    return api.get(url);
  }

  public saveClassificationSettings(
    interfaceId: string,
    spHostUrl: string,
    classificationConfig: IClassificationConfig
  ): Promise<AxiosResponse<void>> {
    const queryItems = [new QueryItem('spHostUrl', spHostUrl)];
    const url = Endpoint.getUrl([interfaceId, this.ROOT], queryItems);
    return api.post(url, classificationConfig);
  }
}
