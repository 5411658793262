import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceViewModel, ServerType } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createService } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IParams {
  groupId: string;
}

interface IOwnProps {
  data: IServiceViewModel[];
  params: IParams;
}

interface IStateProps {
  availableHosts: IServiceViewModel[];
  availablePublishers: IServiceViewModel[];
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  timeout: number;
  hostService: string;
  publisher: string;
}

export class CreateCsModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      availableHosts,
      availablePublishers,
      handleSubmit,
      pristine,
      createResult,
    } = this.props;

    const hostOptions = availableHosts.map((host, index) => (
      <option value={host.serverId} key={index}>
        {host.name}
      </option>
    ));
    const publisherOptions = availablePublishers.map((server, index) => (
      <option value={server.serverId} key={index}>
        {server.name}
      </option>
    ));

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="timeout"
            component={inputComponent}
            type="number"
            placeholder="Please enter a timeout (in seconds)"
            label="Timeout"
          />
          <Field
            name="hostService"
            component={selectComponent}
            label="Host Service"
            validate={formValidators.required}
          >
            <option value="">Select a host service</option>
            {hostOptions}
          </Field>
          <Field
            name="publisher"
            component={selectComponent}
            label="Publisher"
            validate={formValidators.required}
          >
            <option value="">Select a publishing service</option>
            {publisherOptions}
          </Field>
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const {
      availableHosts,
      params,
      createService,
      availablePublishers,
    } = this.props;
    const { groupId } = params;
    const hostService = availableHosts.find(
      (s) => s.serverId === data.hostService
    );

    const service: IServiceViewModel = {
      name: data.name,
      description: data.description,
      groupId,
      configData: {
        CanManageStaticRulebases: true,
        Timeout: data.timeout,
      },
      active: true,
      serverType: ServerType.ClassificationService,
      dependsUpon: [
        {
          serverId: data.hostService,
          serverType: hostService!.serverType,
        },
        {
          serverId: data.publisher,
          serverType: availablePublishers.find(
            (p) => p.serverId === data.publisher
          )!.serverType,
        },
      ],
    };

    createService(service);
  }
}

const CreateCsModalForm = reduxForm<IFormData, IProps>({
  form: 'createCs',
})(CreateCsModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const availableHosts = ownProps.data.filter(
    (s) =>
      (s.serverType === ServerType.AzureVirtualMachineService ||
        s.serverType === ServerType.UnmanagedHostService) &&
      s.dependentUpon!.filter(
        (d) => d.serverType === ServerType.ClassificationService
      ).length === 0
  );
  const availablePublishers = ownProps.data.filter(
    (s) =>
      s.serverType === ServerType.OntologyEditor ||
      s.serverType === ServerType.StudioService
  );

  return {
    initialValues: {
      timeout: 120,
      hostService:
        availableHosts.length === 0 ? undefined : availableHosts[0].serverId,
      publisher:
        availablePublishers.length === 0
          ? undefined
          : availablePublishers[0].serverId,
    },
    form: 'createCs',
    availableHosts,
    availablePublishers,
    createResult: state.service.createResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createService: (service: IServiceViewModel) =>
    dispatch(createService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateCsModalForm);
