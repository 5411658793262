import * as React from 'react';

import { Form, Tab, Tabs } from 'react-bootstrap';
import { IActionResult, IRoleGroupResult, IRoleResult } from 'types';

import { IReduxState } from 'reducers';
import SmallLoadingSpinner from 'components/common/smallLoadingSpinner';
import { connect } from 'react-redux';
import Authorizer from 'utils/authorizer';

interface IStateProps {
  availableRoleGroupsResult: IActionResult<IRoleGroupResult[]>;
  availableRolesResult: IActionResult<IRoleResult[]>;
  canEditUser: boolean;
}

interface IOwnProps {
  input: {
    value: string[];
    onChange: (value: string[]) => void;
  };
}

interface IProps extends IOwnProps, IStateProps {}

export class RoleSelectComponent extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    const {
      availableRoleGroupsResult,
      availableRolesResult,
      input,
      canEditUser,
    } = this.props;
    const availableRoleGroups = availableRoleGroupsResult.data;
    const availableRoles = availableRolesResult.data;
    if (
      availableRoleGroupsResult.processing ||
      availableRolesResult.processing
    ) {
      return <SmallLoadingSpinner />;
    }
    if (availableRoleGroups == null || availableRoles == null) {
      return null;
    }

    const tabs = availableRoleGroups
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((g, i) => {
        const roles = availableRoles
          .filter((r) => r.provider === g.name)
          .sort((a, b) => a.name.localeCompare(b.name));
        const checkboxes = roles.map((r, i) => (
          <Form.Check
            type="switch"
            label={r.name}
            id={r.name}
            onChange={this.onChange}
            value={r.name}
            checked={
              input.value.find((value) => value === r.name) !== undefined
            }
            key={i}
            disabled={!r.canSelect || !canEditUser}
          />
        ));
        return (
          <Tab eventKey={g.name} title={g.name} key={i} className="p-3">
            <strong>{g.description}</strong>
            {checkboxes}
          </Tab>
        );
      });
    return (
      <Tabs defaultActiveKey={availableRoleGroups[0].name} id="role-group-tabs">
        {tabs}
      </Tabs>
    );
  }

  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let selected = this.props.input.value;
    const changedValue = event.currentTarget.value;
    if (event.currentTarget.checked) {
      selected = [...selected, changedValue];
    } else {
      selected = selected.filter((checkbox) => checkbox !== changedValue);
    }
    this.props.input.onChange(selected);
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    availableRoleGroupsResult: state.user.availableRoleGroupsResult,
    availableRolesResult: state.user.availableRolesResult,
    canEditUser: authorizer.canEditUser(),
  };
};

export default connect<IStateProps, {}, IOwnProps, IReduxState>(
  mapStateToProps
)(RoleSelectComponent);
