import * as React from 'react';

import {
  ISequentialListClassification,
  SequentialListClassificationState,
} from 'types';

import SmallListClassification from './smallListClassification';
import { Table } from 'react-bootstrap';

interface IProps {
  data: ISequentialListClassification[];
}

class SmallListClassifications extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    if (!data.length) return null;

    const sortedClassifications = data
      .slice()
      .sort(
        (a, b) =>
          new Date(b.queueDate).getTime() - new Date(a.queueDate).getTime()
      );
    const deployments = sortedClassifications.map((classification, index) => (
      <SmallListClassification classification={classification} key={index} />
    ));
    return (
      <dl>
        <span className="float-right">
          <b>
            {
              data.filter(
                (classification) =>
                  classification.state ===
                  SequentialListClassificationState.InProgress
              ).length
            }
          </b>{' '}
          running and{' '}
          <b>
            {
              data.filter(
                (classification) =>
                  classification.state ===
                  SequentialListClassificationState.Queued
              ).length
            }
          </b>{' '}
          queued
        </span>
        <dt>List Classification Jobs</dt>
        <Table size="sm" hover responsive>
          <thead>
            <tr>
              <th>Progresss</th>
              <th>Status</th>
              <th>Duration</th>
              <th colSpan={2}>Actions</th>
              <th>Logs</th>
            </tr>
          </thead>
          <tbody>{deployments}</tbody>
        </Table>
      </dl>
    );
  }
}

export default SmallListClassifications;
