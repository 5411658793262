import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import {
  IActionResult,
  IDefaultTripleStoreValues,
  IServiceViewModel,
  ServerType,
} from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { createService } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IParams {
  groupId: string;
}

interface IOwnProps {
  data: IDefaultTripleStoreValues;
  params: IParams;
}

interface IStateProps {
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  databaseName: string;
  username: string;
  password: string;
  backupStartTime: string;
  url: string;
}

export class CreateTsModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const { handleSubmit, pristine, createResult } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="databaseName"
            component={inputComponent}
            type="text"
            placeholder="Please enter database name"
            label="Database Name"
            validate={formValidators.required}
          />
          <Field
            name="username"
            component={inputComponent}
            type="text"
            placeholder="Please enter username"
            label="Username"
            validate={formValidators.required}
          />
          <Field
            name="password"
            component={inputComponent}
            type="text"
            placeholder="Please enter password"
            label="Password"
            validate={formValidators.required}
            autocomplete="off"
          />
          <Field
            name="backupStartTime"
            component={inputComponent}
            type="text"
            placeholder="Please enter backup start time"
            label="Backup Start Time"
            validate={formValidators.required}
          />
          <Field
            name="url"
            component={inputComponent}
            type="text"
            placeholder="Please enter an url"
            label="Url"
            validate={formValidators.required}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { params, createService } = this.props;
    const { groupId } = params;

    const service: IServiceViewModel = {
      name: data.name,
      description: data.description,
      groupId,
      configData: {
        BackupStartTime: data.backupStartTime,
        DatabaseName: data.databaseName,
        Password: data.password,
        Username: data.username,
      },
      url: data.url,
      active: true,
      serverType: ServerType.TripleStore,
      dependsUpon: [],
    };

    createService(service);
  }
}

const CreateTsModalForm = reduxForm<IFormData, IProps>({
  form: 'createTs',
})(CreateTsModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      databaseName: ownProps.data.databaseName,
      username: ownProps.data.username,
      password: ownProps.data.password,
      backupStartTime: ownProps.data.backupStartTime,
      url: ownProps.data.url,
    },
    form: 'createTs',
    createResult: state.service.createResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createService: (service: IServiceViewModel) =>
    dispatch(createService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateTsModalForm);
