import * as React from 'react';

import { IActionResult, IInterfaceConfigured } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { deleteSpoInterface } from 'slices/spoSlice';

interface IOwnProps {
  spoInterface: IInterfaceConfigured;
}

interface IStateProps {
  deleteResult: IActionResult<boolean>;
}

interface IDispatchProps {
  deleteSpoInterface: () => void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export class DeleteSpoModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  public render() {
    const { deleteResult, spoInterface } = this.props;

    return (
      <>
        <Modal.Body>
          Are you sure you want to delete the "{spoInterface.name}" interface
        </Modal.Body>
        <ModalFormFooter
          actionResult={deleteResult}
          onSubmit={this.delete}
          submitMessage="Delete"
          submittingMessage="Deleting"
          submitVariant="danger"
        />
      </>
    );
  }

  private delete() {
    const { deleteSpoInterface } = this.props;
    deleteSpoInterface();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  return {
    deleteResult: state.spo.deleteInterfaceResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>,
  ownProps: IOwnProps
): IDispatchProps => ({
  deleteSpoInterface: () =>
    dispatch(deleteSpoInterface(ownProps.spoInterface.id!)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSpoModal);
