import * as React from 'react';

import { IClassificationError } from 'types';
import { Table } from 'react-bootstrap';

interface IProps {
  data: IClassificationError[];
}

export class ClassificationErrors extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    if (data.length === 0) return null;
    return (
      <dl>
        <dt>Errors</dt>
        <dd>
          <Table responsive hover size="sm">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              {data.map((error, index) => (
                <tr key={index}>
                  <td title={error.itemId.toString()}>{error.itemName}</td>
                  <td>{error.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </dd>
      </dl>
    );
  }
}

export default ClassificationErrors;
