import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { IActionResult, IServiceViewModel, ServerType } from 'types';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import { ThunkDispatch } from 'redux-thunk';
import checkBoxComponent from 'components/common/formComponents/checkboxComponent';
import { connect } from 'react-redux';
import { createService } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import selectComponent from 'components/common/formComponents/selectComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';

interface IParams {
  groupId: string;
}

interface IOwnProps {
  data: IServiceViewModel[];
  params: IParams;
}

interface IStateProps {
  availableHosts: IServiceViewModel[];
  availableTripleStores: IServiceViewModel[];
  createResult: IActionResult<boolean>;
}

interface IDispatchProps {
  createService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  publishingEnabled: boolean;
  hostService: string;
  tripleStore: string;
}

export class CreateOeModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.save = this.save.bind(this);
  }

  public render() {
    const {
      availableHosts,
      availableTripleStores,
      handleSubmit,
      pristine,
      createResult,
    } = this.props;

    const hostOptions = availableHosts.map((host, index) => (
      <option value={host.serverId} key={index}>
        {host.name}
      </option>
    ));
    const triplestoreOptions = availableTripleStores.map((oe, index) => (
      <option value={oe.serverId} key={index}>
        {oe.name}
      </option>
    ));

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="hostService"
            component={selectComponent}
            label="Host Service"
            validate={formValidators.required}
          >
            <option value="">Select a host service</option>
            {hostOptions}
          </Field>
          <Field
            name="tripleStore"
            component={selectComponent}
            label="Triple Store (optional)"
          >
            <option value="">Select a triple store</option>
            {triplestoreOptions}
          </Field>
          <Field
            name="publishingEnabled"
            component={checkBoxComponent}
            label="Enable Publishing Configuration"
            id="publishingEnabled"
          />
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { availableHosts, params, createService } = this.props;
    const { groupId } = params;
    const hostService = availableHosts.find(
      (s) => s.serverId === data.hostService
    );

    const service: IServiceViewModel = {
      name: data.name,
      description: data.description,
      groupId,
      configData: {
        PublisherConfigEnabled: data.publishingEnabled,
      },
      active: true,
      serverType: ServerType.OntologyEditor,
      dependsUpon: [
        {
          serverId: data.hostService,
          serverType: hostService!.serverType,
        },
      ],
    };

    if (data.tripleStore) {
      service.dependsUpon.push({
        serverId: data.tripleStore,
        serverType: ServerType.TripleStore,
      });
    }

    createService(service);
  }
}

const CreateOeModalForm = reduxForm<IFormData, IProps>({
  form: 'createOe',
})(CreateOeModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  const availableHosts = ownProps.data.filter(
    (s) =>
      (s.serverType === ServerType.AzureVirtualMachineService ||
        s.serverType === ServerType.UnmanagedHostService) &&
      s.dependentUpon!.filter(
        (d) =>
          d.serverType === ServerType.OntologyEditor ||
          d.serverType === ServerType.StudioService
      ).length === 0
  );
  const availableTripleStores = ownProps.data.filter(
    (s) =>
      s.serverType === ServerType.TripleStore &&
      s.dependentUpon!.filter(
        (d) =>
          d.serverType === ServerType.OntologyEditor ||
          d.serverType === ServerType.StudioService
      ).length === 0
  );

  return {
    initialValues: {
      publishingEnabled: false,
      hostService:
        availableHosts.length === 0 ? undefined : availableHosts[0].serverId,
      tripleStore:
        availableTripleStores.length === 0
          ? undefined
          : availableTripleStores[0].serverId,
    },
    form: 'createOe',
    createResult: state.service.createResult,
    availableHosts,
    availableTripleStores,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createService: (service: IServiceViewModel) =>
    dispatch(createService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateOeModalForm);
