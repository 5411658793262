import * as React from 'react';

import { IActionResult, ITenancyDetail, ModalContents } from 'types';

import { AnyAction } from 'redux';
import { Button } from 'react-bootstrap';
import { IReduxState } from 'reducers';
import Loader from 'components/common/loader';
import PlusIcon from 'mdi-react/PlusIcon';
import TenantSearchForm from './tenantSearchForm';
import TenantSearchResult from './tenantSearchResult';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { openModal } from 'slices/modalSlice';
import { searchTenancies } from 'slices/tenantSlice';
import Authorizer from 'utils/authorizer';

interface IStateProps {
  searchResult: IActionResult<ITenancyDetail[]>;
  canAddTenant: boolean;
}

interface IDispatchProps {
  loadTenants: () => void;
  openCreateModal: () => void;
}

interface IProps extends IStateProps, IDispatchProps {}

export class TenantsPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.create = this.create.bind(this);
  }

  public componentDidMount() {
    const { loadTenants } = this.props;
    loadTenants();
  }

  public render() {
    const { searchResult, openCreateModal, canAddTenant } = this.props;
    return (
      <>
        <h1 className="font-weight-light mt-5 mb-2">
          {canAddTenant ? (
            <Button
              variant="link"
              className="float-right mt-3"
              onClick={openCreateModal}
            >
              <PlusIcon className="mr-1" />
              Add new tenant
            </Button>
          ) : null}
          Manage Tenants
        </h1>
        <TenantSearchForm />
        <Loader actionresult={searchResult} component={TenantSearchResult} />
      </>
    );
  }

  private create() {
    const { openCreateModal } = this.props;
    openCreateModal();
  }
}

const mapStateToProps = (state: IReduxState): IStateProps => {
  const authorizer = new Authorizer(state);
  return {
    searchResult: state.tenant.searchResult,
    canAddTenant: authorizer.canAddTenant(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  loadTenants: () => dispatch(searchTenancies()),
  openCreateModal: () =>
    dispatch(
      openModal('Create Tenant', ModalContents.CreateTenant, {
        position: 'right',
        size: 'lg',
      })
    ),
});

export default connect<IStateProps, IDispatchProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(TenantsPage);
